import React, { useState, useEffect, useRef } from 'react';
import PageTitle from '../../components/PageTitle';
import toast from 'react-hot-toast';
import Private from '../../context/Private';
import AddJudge from '../../components/judgement/AddJudge';
import Skeleton from '../../components/UIElements/Skeleton';
import Modal from '../../components/UIElements/Model';
import { DELETE_JUDGES } from '../../graphql/mutations/judgement';
import JudgeItem from '../../components/judgement/JudgeItem';
import { gql, useMutation, useQuery } from '@apollo/client';

const GET_JUDGEMENT = gql`
query Judges {
  judges {
    id
    name
    phoneNumber
    host {
      id
      name
      place
    }
  }
}
`;

const GET_HOSTS = gql`
  query Hosts {
    hosts {
      id
      name
      place
    }
  }
`;

// Define an interface for the Judge type
interface IJudges {
  id: string;
  name: string;
  host: {
    id: string;
    name: string;
    place: string;
    group: {
      id: string;
      name: string
    }
  };
  email: string;
  phoneNumber: string;
}

const Judges: React.FC = () => {

  const [searchQuery, setSearchQuery] = useState('');
  const [selectedJudge, setSelectedJudge] = useState<IJudges | null>(null); // State to hold the selected judge for editing
  const [filteredJudgeData, setFilteredJudgeData] = useState<IJudges[] | null>(null); // State to hold the selected judge for editing
  const [judge, setJudge] = useState<IJudges[] | null>(null); // State to hold the selected judge for editing
  const [selectedHost, setSelectedHost] = useState<string>("");

  const LIMIT = 12;
  const [hasMore, setHasMore] = useState(true);
  const [IsLoadingMore, setIsLoadingMore] = useState<boolean>(false)

  const [deleteModel, setDeleteModel] = useState<boolean>(false);
  const [judgeId, setJudgeId] = useState<string>("");
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const dropdownRef = useRef<HTMLDivElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);

  const { data, loading, error, refetch, fetchMore } = useQuery(GET_JUDGEMENT);
  const { data: hostData } = useQuery(GET_HOSTS)

  const [deleteJudge, { loading: deleteJudgeLoading }] = useMutation(DELETE_JUDGES);

  const loadMore = async () => {
    setIsLoadingMore(true)
    try {
      await fetchMore({
        variables: {
          search: searchQuery,
          limit: LIMIT,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult || fetchMoreResult?.judges?.length === 0) {
            setHasMore(false); // No more judges
            return prev;
          }
          setHasMore(true);
          // Append new judges to existing list
          return {
            ...prev,
            judge: [
              ...prev.judge,
              ...fetchMoreResult.judge,
            ],
          };
        },
      });
    } finally {
      setIsLoadingMore(false)
    }
  };

  const filteredOptions = data?.judges?.filter((judge: any) =>
    judge?.name?.toLowerCase().includes(searchTerm.toLowerCase())
  );


  // Load data into state
  useEffect(() => {
    if (data && data.judges) {
      setJudge(data.judges);
      if (data.judges.length < LIMIT) {
        setHasMore(false); // No more judges to fetch
      } else {
        setHasMore(true);
      }
    }
  }, [data]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }

    if (isOpen) {
      searchInputRef.current?.focus();
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };


  // Filtered list of judges based on search query
  useEffect(() => {
    console.log(data, judge, selectedHost, searchQuery);

    const filteredData = data?.judges?.filter((judge: IJudges) => {

      const searchLower = searchQuery.toLowerCase();

      // Filter by search query 
      const searchMatch = judge?.name?.toLowerCase().includes(searchLower) ||
        judge?.host?.name?.toLowerCase().includes(searchLower) ||
        judge?.host?.place?.toLowerCase().includes(searchLower)

      // Filter by host (if selected) 
      const hostMatch = selectedHost ? judge?.host?.name === selectedHost : true;

      // Return true if all conditions are met
      return (
        searchMatch &&
        hostMatch
      );
    });

    // Set the filtered data after computing
    setFilteredJudgeData(filteredData);
  }, [searchQuery, judge, data, selectedHost, setFilteredJudgeData]);


  // Handle editing an judge
  const handleEdit = (judge: IJudges) => {
    setSelectedJudge(judge); // Set the selected judge to edit
  };

  const handleDeleteConfirm = async (id: string) => {
    setDeleteModel(true);
    setJudgeId(id);
  };


  const handleDelete = async () => {
    try {
      const deleteloading = toast.loading('Deleting the judge')
      await deleteJudge({ variables: { id: judgeId } });
      refetch();
      toast.remove(deleteloading)
      toast.success("Judge deleted successfully!");
      setDeleteModel(false);
    } catch (error) {
      console.error("Failed to delete the judge", error);
      toast.error("Failed to delete the judge");
    }
  };


  // Close edit form and reset selected judge
  const handleSave = () => {
    setSelectedJudge(null); // Clear the selected judge after saving
    refetch()
  };

  // Handle cancel action to reset selected judge
  const handleCancel = () => {
    setSelectedJudge(null);
  };

  if (error) return <p>Error: {error.message}</p>;

  return (
    <>
      <PageTitle pagetitle="Judges" subtitle="Judgement" />
      <div className="w-full">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-y-6 md:gap-6">
          {/* Registerjudge component for both registering and editing judges */}
          <Private
            permissions={["create-judge", "update-judge"]}
            element={<AddJudge
              selectedJudge={selectedJudge} // Passing selected judge if editing
              onSave={handleSave} // Handle save action
              onCancel={handleCancel} // Handle cancel action
              refetch={refetch}
              setSelectedJudge={setSelectedJudge}
            />}
          />
          <div className="p-6 bg-white dark:bg-slate-800 rounded-lg col-span-2">
            <div className="flex justify-between align-center">
              <h2 className="text-base font-medium mb-4">Registered Judges</h2>

            </div>
            <div className="flex gap-2 mb-7 flex-col sm:flex-row">

              <div className="relative flex flex-row w-full" ref={dropdownRef}>
                {/* Selected Option Display */}
                <div className="w-full min-full sm:w-2/4 flex items-center">
                  <input
                    type="text"
                    value={searchQuery}
                    onChange={(e) => handleSearch(e)}
                    placeholder="Search.."
                    className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                  />
                </div>

                <div className="hidden sm:flex items-center w-2/4 ml-2">
                  <select
                    id="host"
                    name="host"
                    value={selectedHost}
                    onChange={(e) => { setSelectedHost(e.target.value) }}
                    className="text-sm m-1 ml-0 rounded-lg min-w-56 w-full border-2 border-gray-300 p-2 inline-block"
                  >
                    <option value="">Select Host</option>
                    {hostData?.hosts?.map((host: any) => (
                      <option key={host?.id} value={host?.name}>
                        {host?.name} | {host?.place}
                      </option>
                    ))}
                  </select>
                </div>
                {/* Dropdown Options */}
                {isOpen && !selectedJudge && (
                  <div className="absolute top-full left-0 w-full mt-2 bg-white border rounded shadow-lg z-10">

                    {/* Options List */}
                    <ul className="max-h-48 overflow-y-auto">
                      {filteredOptions?.map((judge: any, index: any) => (
                        <li
                          key={index}
                          onClick={() => {
                            setJudgeId(judge.id)
                            setIsOpen(false)
                            setSearchTerm(`${judge.name}`);

                          }}
                          className="px-4 py-2 cursor-pointer hover:bg-gray-500 hover:text-white"
                        >
                          {`${judge.name}`}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            {loading ? (
              <div className='grid place-items-center'>
                <button
                  type="button"
                  className="btn bg-transparent border-gray-300 dark:border-gray-700"
                >
                  <i className="mgc_loading_4_line me-2 animate-spin"></i>
                  <span>Loading</span>
                </button>
              </div>
            ) :
              (
                <div className="relative overflow-x-auto">
                  <table className="w-full divide-y divide-gray-300 dark:divide-gray-700">
                    <thead className="bg-slate-300 bg-opacity-20 border-t dark:bg-slate-800 divide-gray-300 dark:border-gray-700">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 ps-4 pe-3 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
                        >
                          Judge Name
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
                        >
                          Host
                        </th> 
                        {/* <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
                        >
                          Email
                        </th> */}
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
                        >
                          Phone Number
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900 dark:text-gray-200"
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    {loading ? (
                      <Skeleton />
                    ) : error ? (
                      <p>Error fetching candidates: {error}</p>
                    ) : (
                      <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                        {filteredJudgeData?.map((judge: IJudges) => (
                          <JudgeItem
                            key={judge.id}
                            judge={judge}
                            handleEdit={() => handleEdit(judge)}
                            handleDeleteButton={handleDeleteConfirm}
                          />
                        ))}
                      </tbody>
                    )}
                  </table>
                  <Modal
                    setIsOpen={setDeleteModel}
                    isOpen={deleteModel}
                    title="Delete Confirmation"
                    handleSubmit={handleDelete}
                    buttonText="Delete"
                    danger={true}
                    loading={deleteJudgeLoading}
                  >
                    <p className="text-center bg-red-100 text-red-800 rounded-md border border-red-600 p-3">
                      Are you sure want to delete this candidate?
                    </p>
                  </Modal>
                  {hasMore && <div className="flex justify-center">
                    <button
                      type="button"
                      className="mt-10 mb-10 btn bg-transparent border-gray-300 dark:border-gray-700"
                      onClick={async () => {
                        setIsLoadingMore(true);
                        await hasMore && await loadMore();
                        setIsLoadingMore(false)
                      }}
                    >
                      {IsLoadingMore && <i className="mgc_loading_4_line me-2 animate-spin"></i>}
                      <span>{IsLoadingMore ? "Loading..." : "Load More"}</span>
                    </button>
                  </div>}
                </div>)
            }
            
          </div>
        </div>
      </div>
    </>
  );
};

export default Judges;
