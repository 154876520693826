import React, { useState, useEffect } from 'react';

interface UploadImageProps {
    defaultImageUrl?: string | null;
    onFileChange: (file: File | null) => void;
    previewUrl: string | null;
    setPreviewUrl: (url: string | null) => void;
}

const UploadImage: React.FC<UploadImageProps> = ({ defaultImageUrl, onFileChange, previewUrl, setPreviewUrl }) => {
    const [fileType, setFileType] = useState<string | null>(null);

    useEffect(() => {
        if (defaultImageUrl && typeof defaultImageUrl === 'string') {
            const extension = defaultImageUrl.split('.').pop()?.toLowerCase();
            const isImage = ['jpg', 'jpeg', 'png', 'gif'].includes(extension || '');
            const isPdf = extension === 'pdf';
            setPreviewUrl(defaultImageUrl);
            setFileType(isImage ? 'image' : isPdf ? 'pdf' : null);
        }
    }, [defaultImageUrl]);

    const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files ? e.target.files[0] : null;
        if (file) {
            const extension = file.name.split('.').pop()?.toLowerCase();
            const isImage = extension && ['jpg', 'jpeg', 'png', 'gif'].includes(extension);
            const isPdf = extension === 'pdf';

            if (isImage || isPdf) {
                setPreviewUrl(URL.createObjectURL(file));
                setFileType(isImage ? 'image' : 'pdf');
            }
            onFileChange(file);
        } else {
            setPreviewUrl(null);
            onFileChange(null);
        }
    }; 

    return (
        <div className="mb-4">
            {previewUrl && fileType === 'image' && (
                <img src={previewUrl} alt="Preview" className="mb-2 max-h-40 w-auto rounded shadow" />
            )}
            {previewUrl && fileType === 'pdf' && (
                <a href={previewUrl} target="_blank" rel="noopener noreferrer" className="block mb-2 text-blue-500 underline">
                    View PDF
                </a>
            )}
            <input
                type="file"
                accept="image/*, application/pdf"
                onChange={handleFileInputChange}
                className="block w-full text-sm text-gray-500 border border-gray-300 rounded p-2"
            />
        </div>
    );
};

export default UploadImage;
