import React from "react";
import { Droppable, DroppableProvided } from "@hello-pangea/dnd";
interface KanbanBoardProps {
  data: {
    id: string;
    name: string;
    programCode: string;
  }[];
  name: string;
  id: string;
  index: number;
  isOpen: boolean;
  setIsOpen: (index: number) => void;
  handleEdit: () => void;
  handleDelete: () => void;
  removeItem: (removeId: string, curbId: string) => void;
}

const KanbanBoard: React.FC<KanbanBoardProps> = ({
  data,
  name,
  index,
  isOpen,
  id,
  setIsOpen,
  handleEdit,
  handleDelete,
  removeItem,
}) => {
  return (
    <Droppable droppableId={id}>
      {(provided: DroppableProvided) => (
        <div
          className="flex flex-col flex-shrink-0 h-[min-content] border rounded-md overflow-hidden border-gray-200 dark:border-gray-700"
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          <div className="flex justify-between items-center flex-shrink-0 h-10 p-4 bg-white dark:bg-slate-800">
            <span className="block text-sm font-semibold uppercase ">
              {name} ({data.length})
            </span>
            <span className="w-16 flex items-center justify-between">
              <span onClick={handleEdit}>
                <i className="mgc_edit_line cursor-pointer"></i>
              </span>
              <span onClick={handleDelete}>
                <i className="mgc_delete_line cursor-pointer"></i>
              </span>
              <span onClick={() => setIsOpen(index)} className="cursor-pointer">
                {isOpen ? (
                  <i className="mgc_up_line text-2xl"></i>
                ) : (
                  <i className="mgc_down_line text-2xl"></i>
                )}
              </span>
            </span>
          </div>
          <div
            className={`${
              isOpen ? "flex" : "hidden"
            } flex-col gap-4 overflow-auto p-4 h-[calc(100vh-300px)] kanban-board custom-scroll`}
            id="kanbanborad-two"
          >
            {data.map((item, index) => (
              <div className="border border-gray-200 bg-white dark:border-gray-800 dark:bg-gray-800 rounded-xl p-4" key={index}>
                <div className="flex justify-between items-center">
                  <div>
                    <p className="font-semibold">{item.name}</p>
                    <p className="text-xs">{item.programCode}</p>
                  </div>
                  <button
                    className="inline-flex flex-shrink-0 justify-center items-center h-8 w-8 dark:text-gray-200"
                    type="button"
                    onClick={() => removeItem(item.id, id)}
                  >
                    <span className="material-symbols-rounded">
                      <i className="mgc_close_line text-2xl"></i>
                    </span>
                  </button>
                </div>
              </div>
            ))}

            {provided.placeholder}
          </div>
        </div>
      )}
    </Droppable>
  );
};

export default KanbanBoard;
