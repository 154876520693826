import React, { useState } from 'react'
import MarkCard from './MarkCard';

interface Props {
    grades: [];
    category: string;
}

const GradeCardStructure: React.FC<Props> = ({ grades, category }) => {
    const [isOpen, setIsOpen] = useState(false);

    const filterData = (category: string) => {
        let filtered = grades.filter((mark: any) => {
            return mark.category === category;
        })
        filtered.sort((a: any, b: any) => (a.name > b.name) ? 1 : -1);
        return filtered;
    }

    const handleClick = () => {
        setIsOpen(!isOpen)
    }

    return (
        <div>
            <h3 onClick={handleClick} className="text-lg pr-1 cursor-pointer capitalize w-full inline-flex justify-between items-center"><span>{category}</span> <i className={`${isOpen ? "mgc_up_line" : "mgc_down_line"} text-lg`}></i></h3>
            {isOpen && <div className="space-y-4 bg-gray-100 rounded-xl overflow-hidden py-4">
                {filterData(category).map((position: any, index: number) => (
                    <MarkCard
                        key={index}
                        item={position}
                        title={position.name}
                        category={position.category}
                        mark={position.mark}
                        percentage={position.percentage}
                        isGrade={true}
                        textClass="text-base"
                    />
                ))}
            </div>}
        </div>
    )
}

export default GradeCardStructure