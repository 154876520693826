import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";

import { GET_SECTIONS } from "../../graphql/queries/section";

import Modal from "../UIElements/Model";
import Input from "../ui-elements/Input";
import Select from "../ui-elements/Select";
import { GET_CLUSTERS } from "../../graphql/queries/cluster";
import { CREATE_CLUSTER, DELETE_CLUSTER, UPDATE_CLUSTER } from "../../graphql/mutations/cluster";
import toast from "react-hot-toast";
import Card from "./Card";
import CardStructure from "./CardStructure";
import Skeleton from "../UIElements/Skeleton";
import DeleteModal from "../UIElements/DeleteModal";

const Clusters: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [cluster, setCluster] = useState<string>("");
  const [selectedSection, setSelectedSection] = useState<string>("");
  const [editCluster, setEditCluster] = useState<any>(null);
  const [deleteModel, setDeleteModel] = useState<boolean>(false);
  const [deleteClusterId, setDeleteClusterId] = useState<string>(""); 

  useEffect(() => {
    if (editCluster) {
      setCluster(editCluster.name);
      setSelectedSection(editCluster.section.id);
    }
  }, [editCluster]);

  // QUERY
  const {
    data: sectionData,
    loading: sectionLoading,
    error: sectionError,
  } = useQuery(GET_SECTIONS);
  const {
    data: clusterData,
    loading: clusterLoading,
    error: clusterError,
    refetch,
  } = useQuery(GET_CLUSTERS);

  // MUTATION
  const [createCluster, { loading: createClusterLoading }] =
    useMutation(CREATE_CLUSTER);
  const [updateCluster, { loading: updateClusterLoading }] =
    useMutation(UPDATE_CLUSTER);
  const [deleteCluster] =
    useMutation(DELETE_CLUSTER);

  if (sectionLoading || clusterLoading) return <Skeleton />;

  if (sectionError)
    return <p>Error loading sections: {sectionError.message}</p>;
  if (clusterError) return <p>Error loading sections: {clusterError.message}</p>;

  const handleAddCluster = async () => {
    setEditCluster(null);
    clearInput();
    setIsOpen(true);
  };

  const handleEditCluster = async (item: any) => {
    setEditCluster(item);
    setCluster(item.name);
    setSelectedSection(item.section.id);
    setIsOpen(true);
  };

  const handleDeleteConfirm = async (id: string) => {
    setDeleteModel(true);
    setDeleteClusterId(id);
  }

  const handleDelete = async() => {
    try {
      await deleteCluster({ variables: {id: deleteClusterId}});
      toast.success("Cluster deleted successfully!");
      setDeleteModel(false);
      clearInput();
      refetch();
    } catch (error) {
      console.error("Failed to delete the cluster", error);
      toast.error("Failed to delete the cluster")
    }
  }

  const clearInput = () => {
    setCluster("");
    setSelectedSection("");
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!cluster.trim() || !selectedSection.trim()) {
      toast.error("Please fill in all fields.");
      return;
    }

    try {
      if (editCluster) {
        await updateCluster({
          variables: {
            id: editCluster.id,
            name: cluster.trim(),
            section: selectedSection,
          },
        });
        toast.success("Cluster updated successfully!");
      } else {
        await createCluster({
          variables: {
            name: cluster.trim(),
            section: selectedSection,
          },
        });
        toast.success("Cluster created successfully!");
      }

      setIsOpen(false);
      clearInput();
      refetch();
    } catch (error) {
      console.error(
        editCluster ? `Failed to update the cluster:` : `Failed to create the cluster:`,
        error
      );
      toast.error(
        editCluster ? `Failed to update the cluster:` : `Failed to create the cluster:`
      );
    }
  };

  return (
    <>
      <CardStructure
        Heading="Clusters"
        addButton="Add"
        addButtonOnCLick={handleAddCluster}
        data={clusterData.clusters}
        renderCard={(item: any) => (
          <Card
            item={item}
            title={item.name}
            subTitle={item.section.name}
            handleEditButton={handleEditCluster}
            handleDeleteButton={handleDeleteConfirm}
            textClass="text-sm"
          />
        )}
      />
      <Modal
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        title={editCluster ? "Edit Cluster" : "Add Cluster"}
        handleSubmit={handleSubmit}
        loading={editCluster ? updateClusterLoading : createClusterLoading}
      >
        <form className="flex flex-col gap-3" onSubmit={handleSubmit}>
          <Input
            placeholder="Enter Cluster Name"
            label="name"
            value={cluster}
            onChange={(e) => setCluster(e.target.value)}
          />
          <Select
            label="section"
            value={selectedSection}
            onChange={(e) => setSelectedSection(e.target.value)}
            options={sectionData.sections}
          />
        </form>
      </Modal>
      <DeleteModal
        setIsOpen={setDeleteModel}
        isOpen={deleteModel}
        handleDelete={handleDelete}
      />
    </>
  );
};

export default Clusters;
