import React, { useRef, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import PageTitle from "../../components/PageTitle";
import DownloadItem from "../../components/downloads/DownloadItem";
import {
  CREATE_DOWNLOAD,
  DELETE_DOWNLOAD,
  UPDATE_DOWNLOAD,
} from "../../graphql/mutations/downloads";
import { GET_DOWNLOADS } from "../../graphql/queries/downloads";
import Skeleton from "../../components/UIElements/Skeleton";
import toast from "react-hot-toast";

// const CLOUD_NAME = "dyxfbp3tf";
// const UPLOAD_PRESET = "akjg9xbx";

const Downloads: React.FC = () => {
  const [title, setTitle] = useState("");
  const [status, setStatus] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const [editingId, setEditingId] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState("All Downloads");
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const {
    data,
    loading: queryLoading,
    error: queryError,
    refetch,
  } = useQuery(GET_DOWNLOADS);
  const [createDownload, { loading: mutationLoading }] =
    useMutation(CREATE_DOWNLOAD);
  const [updateDownload] = useMutation(UPDATE_DOWNLOAD);
  const [deleteDownload] = useMutation(DELETE_DOWNLOAD);

  const downloads = data?.downloads || [];

  const uploadFile = async (file: File): Promise<string> => {
    try {
      const uploadFormData = new FormData();
      uploadFormData.append("file", file);
      uploadFormData.append(
        "upload_preset",
        import.meta.env.VITE_UPLOAD_PRESET
      );

      const response = await fetch(
        `https://api.cloudinary.com/v1_1/${
          import.meta.env.VITE_CLOUD_NAME
        }/image/upload`,
        {
          method: "POST",
          body: uploadFormData,
        }
      );

      const data = await response.json();

      if (!response.ok) {
        // Use `response.ok` to check for HTTP errors
        const errorMessage = data.error?.message || "File upload failed";
        toast.error(errorMessage);
        throw new Error(errorMessage);
      }

      if (!data.secure_url) {
        // Handle unexpected API responses
        const errorMessage = "Unexpected response from the upload API.";
        toast.error(errorMessage);
        throw new Error(errorMessage);
      }

      return data.secure_url;
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error("An unexpected error occurred during file upload.");
      throw error; // Re-throw the error for higher-level handling if needed
    }
  };

  const filteredDownloads = downloads.filter((download: any) => {
    const matchesTab =
      activeTab === "All Downloads" ||
      download.status.toLowerCase() === activeTab.toLowerCase();
    const matchesSearch = download.title
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    return matchesTab && matchesSearch;
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!title || !status) {
      toast.error("Please fill all the data");
      return;
    }

    if (!file) {
      toast.error("No file selected");
      return;
    }

    const submitLoading = toast.loading(
      `${editingId ? "Updating" : "Creating"} the Download`
    );

    try {
      const uploadedFileUrl = file ? await uploadFile(file) : null;

      const inputData = {
        title,
        file:
          uploadedFileUrl ||
          downloads.find((download: any) => download.id === editingId)?.file,
        status,
      };

      if (editingId) {
        await updateDownload({
          variables: { id: editingId, input: inputData },
        });
        toast.success("Download updated successfully");
      } else {
        await createDownload({
          variables: { input: inputData },
        });
        toast.success("Download created successfully");
      }

      toast.remove(submitLoading);
      refetch();
      setTitle("");
      setFile(null);
      setEditingId(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    } catch (error) {
      console.error("Error during download submission:", error);
      toast.remove(submitLoading);
      toast.error("Error saving download");
    } finally {
      setLoading(false);
    }
  };

  const handleStatusRevert = async (download: any) => {
    const statusLoad = toast.loading("Updating the Download");
    var status = download.status === "published" ? "draft" : "published";
    try {
      const input = {
        status: status,
      };
      if (status) {
        await updateDownload({ variables: { id: download.id, input: input } });
        toast.remove(statusLoad);
        toast.success("Download updated successfully");
      }
      refetch();
      setTitle("");
      setFile(null);
      setEditingId(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    } catch (error) {
      toast.remove(statusLoad);
      console.error("Error during Download submission:", error);
      toast.error("Error saving Download");
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (download: any) => {
    setTitle(download.title);
    setFile(null);
    setEditingId(download.id);
  };

  const handleDelete = async (downloadId: string) => {
    try {
      await deleteDownload({ variables: { id: downloadId } });
      refetch();
    } catch (error) {
      console.error("Error deleting download:", error);
    }
  };

  return (
    <>
      <PageTitle pagetitle="Downloads" subtitle="Downloads" />
      <div className="grid place-items-center lg:grid-cols-5 gap-6 lg:gap-11 p-4 lg:p-6 w-full max-w-7xl mx-auto">
        <div className="h-full bg-white p-6 lg:p-8 rounded-lg shadow-lg lg:col-span-3 w-full">
          <h2 className="text-2xl font-bold text-center mb-6">Add Downloads</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Title
              </label>
              <input
                type="text"
                placeholder="Enter Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-green-500"
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                File
              </label>
              <input
                type="file"
                ref={fileInputRef}
                onChange={(e) => setFile(e.target.files?.[0] || null)}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-green-500"
              />
            </div>

            {loading && <p>Uploading file, please wait...</p>}

            <div className="flex mt-16 px-36 justify-around">
              <button
                type="submit"
                onClick={() => setStatus("draft")}
                className={`w-36 mr-4 bg-red-500  text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${
                  loading || mutationLoading
                    ? "cursor-not-allowed"
                    : "hover:bg-red-700"
                } `}
                disabled={loading || mutationLoading}
              >
                Draft
              </button>
              <button
                type="submit"
                onClick={() => setStatus("published")}
                className={`w-36 bg-green-500  text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${
                  loading || mutationLoading
                    ? "cursor-not-allowed"
                    : "hover:bg-green-700"
                }`}
                disabled={loading || mutationLoading}
              >
                Publish
              </button>
            </div>
          </form>
        </div>

        <div className="w-full lg:col-span-2 h-full bg-white shadow-md rounded-lg p-4 lg:p-6">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-xl font-bold">Downloads</h2>
            <input
              type="text"
              placeholder="Search Downloads"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="border border-gray-200 rounded-lg py-2 px-3 text-sm outline-none focus:outline-none"
            />
          </div>

          <div className="w-full flex justify-between items-center mb-4 border-b border-gray-200">
            <div className="flex items-center border-b border-gray-200">
              {queryLoading && <Skeleton />}
              {queryError && (
                <p>Error loading downloads: {queryError.message}</p>
              )}
              {["All Downloads", "Published", "Draft"].map((tab) => (
                <button
                  key={tab}
                  onClick={() => setActiveTab(tab)}
                  className={`px-4 py-2 text-sm font-semibold focus:outline-none ${
                    activeTab === tab
                      ? "border-b-2 border-blue-500 text-black"
                      : "text-gray-500"
                  }`}
                >
                  {tab}
                </button>
              ))}
            </div>
          </div>

          <div className="max-h-60 overflow-y-scroll no-scrollbar">
            {filteredDownloads.map((download: any) => (
              <DownloadItem
                key={download.id}
                title={download.title}
                status={download.status}
                file={download.file}
                onEdit={() => handleEdit(download)}
                onDelete={() => handleDelete(download.id)}
                onStatusRevert={handleStatusRevert}
                download={download}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Downloads;
