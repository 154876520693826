import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from "react";
import { GET_USER } from "../graphql/queries/admin";
import { jwtDecode } from "jwt-decode";
import { useQuery } from "@apollo/client";
import { Navigate } from "react-router-dom";

interface AuthContextType {
  isAuthenticated: boolean;
  user: any;
  setUser: (user: any) => void;
  login: (token: string, userData: any) => void;
  logout: () => void;
}

interface JwtPayload {
  exp: number;
}

// Custom hook to check if token is expired
const isTokenExpired = (token: string | null) => {
  if (!token) return true; // If no token, consider it expired
  try {
    const decoded: JwtPayload = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return decoded.exp < currentTime; // Check if token is expired
  } catch (error) {
    return true; // If error in decoding, treat as expired
  }
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

// Custom hook to fetch user data
const useFetchUser = () => {
  const { data, loading, error } = useQuery(GET_USER, {
    skip:
      !localStorage.getItem("token") ||
      isTokenExpired(localStorage.getItem("token")),
  });

  return { data, loading, error };
};

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [token, setToken] = useState<string | null>(
    localStorage.getItem("token")
  );
  const [user, setUser] = useState<any>(null);

  const { data } = useFetchUser();

  useEffect(() => {
    if (isTokenExpired(token)) {
      logout();
      <Navigate to={`/login?redirect=${location.pathname}`} />
    }
    if (!user && token && !isTokenExpired(token) && data?.getUser) {
      setUser(data.getUser);
    }
  }, [token, data]);

  const login = (token: string, userData: any) => {
    setToken(token);
    localStorage.setItem("token", token);
    setUser(userData);
  };

  const logout = async () => {
    setToken(null);
    localStorage.removeItem("token");
    localStorage.removeItem("section");
    setUser(null);
  };

  const isAuthenticated = Boolean(token) && !isTokenExpired(token);

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, user, login, logout, setUser }}
    >
      {children}
    </AuthContext.Provider>
  );
};
