import React, { useState } from "react";

const MenuToggleButton: React.FC = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
    const view = document.documentElement.getAttribute("data-sidenav-view");

    if (view === "mobile") {
      showBackdrop();
      document.documentElement.classList.toggle("sidenav-enable");
    } else {
      if (view === "sm") {
        document.documentElement.setAttribute("data-sidenav-view", "default");
      } else {
        document.documentElement.setAttribute("data-sidenav-view", "sm");
      }
    }
  };

  const showBackdrop = () => {
    const backdrop = document.createElement("div");
    backdrop.id = "backdrop";
    backdrop.className =
      "transition-all fixed inset-0 z-40 bg-gray-900 bg-opacity-50 dark:bg-opacity-80";
    document.body.appendChild(backdrop);
    backdrop.addEventListener("click", () => {
      document.documentElement.classList.remove("sidenav-enable");
      backdrop.remove();
    });
  };

  return (
    <button
      id="button-toggle-menu"
      className="nav-link p-2"
      onClick={toggleMenu}
    >
      <span className="sr-only">Menu Toggle Button</span>
      <span className="flex items-center justify-center h-6 w-6">
        <i className="mgc_menu_line text-xl"></i>
      </span>
    </button>
  );
};

export default MenuToggleButton;
