import { gql } from "@apollo/client";

// Update Role Mutation
export const UPDATE_SECTION = gql`
  mutation UpdateSection(
    $id: ID!
    $name: String
    $chestNoPrefix:String
    $year:String
  ) {
    updateSection(
      id: $id
      input: {
        name: $name
        chestNoPrefix: $chestNoPrefix 
        year: $year
      }
    ) {
      id
      name
    }
  }
`;