import { useQuery } from "@apollo/client";
import React, { useEffect } from "react";

import Select from "../ui-elements/Select";
import { useAuth } from "../../context/AuthContext";
import { GET_INSTITUTION_BY_ID, GET_INSTITUTIONS } from "./queryCandidates";

interface SelectionsProps {
  selectedInstitution: string;
  setData: (data: any) => void;
}

const InstitutionSelection: React.FC<SelectionsProps> = ({
  selectedInstitution,
  setData,
}) => {
  const { user } = useAuth();

  const isInstitutionLogin = user?.institution ? true : false;

  // Query for fetching all institutions
  const {
    data: institutionData,
    loading: institutionLoading,
    error: institutionError,
  } = useQuery(GET_INSTITUTIONS, {
    skip: isInstitutionLogin, // Skip if the user is already logged in to an institution
  });

  // If institution login, fetch the specific institution by ID
  const {
    data: singleInstitutionData,
    loading: singleInstitutionLoading,
    error: singleInstitutionError,
  } = useQuery(GET_INSTITUTION_BY_ID, {
    variables: { id: user?.institution?.id },
    skip: !isInstitutionLogin || !user?.institution?.id,
  });

  useEffect(() => {
    if (isInstitutionLogin && singleInstitutionData) {
      setData((prev: any) => ({
        ...prev,
        institution: singleInstitutionData.institution.id,
        section: singleInstitutionData.institution?.groupId?.section?.id,
      }));
    }
  }, [isInstitutionLogin, singleInstitutionData, setData]);

  if (institutionLoading || singleInstitutionLoading) {
    return <p>Loading...</p>;
  }

  if (institutionError || singleInstitutionError) {
    console.log(institutionError?.message || singleInstitutionError?.message);
    return <p>Error loading institution data.</p>;
  }

  const institutionOptions = isInstitutionLogin
    ? [singleInstitutionData?.institution]
    : institutionData?.institutions || [];

  const handleInstitutionChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    let selectedInstitution = institutionOptions.find(
      (item: any) => item.id === e.target.value
    );

    if (selectedInstitution) {
      setData((prev: any) => ({
        ...prev,
        institution: e.target.value,
        section: selectedInstitution?.groupId?.section?.id,
      }));
    }
  };

  return (
    <div className="card p-6">
      <div className="flex justify-between items-center mb-4">
        <label className="card-title">Select Institution</label>
        <div className="inline-flex items-center justify-center rounded-lg bg-slate-100 w-9 h-9">
          <i className="mgc_compass_line"></i>
        </div>
      </div>
      <div>
        <Select
          label="institution"
          value={selectedInstitution}
          options={institutionOptions}
          disabled={isInstitutionLogin}
          valueKey="shortName"
          flex="col"
          onChange={(e) => handleInstitutionChange(e)}
        />
      </div>
    </div>
  );
};

export default InstitutionSelection;
