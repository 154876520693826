import React from "react";

import { useQuery } from "@apollo/client";
import GradeCardStructure from "./GradeCardStructure";
import Skeleton from "../UIElements/Skeleton";
import { GET_GRADES } from "../../graphql/queries/grade";

const Grade: React.FC = () => {

  // QUERY
  const {
    data: gradeData,
    loading: gradeLoading,
    error: gradeError,
  } = useQuery(GET_GRADES);

  if (gradeLoading) return <Skeleton />;

  if (gradeError)
    return <p>Error loading grades: {gradeError.message}</p>;

  return (
    <div className="bg-white p-4 rounded-lg">
      <div className="flex justify-between items-center mb-2">
        <h2 className="text-xl">Grade</h2>
      </div>
      <div className="space-y-4 bg-gray-100 h-[250px] rounded-xl no-scrollbar overflow-auto px-3 py-4">
        <GradeCardStructure
          grades={gradeData.grades}
          category="starred"
        />
        <GradeCardStructure
          grades={gradeData.grades}
          category="unstarred"
        />
      </div>
    </div>
  );
};

export default Grade;
