import { gql } from "@apollo/client";

export const CREATE_PROGRAM_CATEGORY = gql`
  mutation CreateProgramCategory($name: String!, $details: String!) {
    createProgramCategory(input: { name: $name, details: $details }) {
      id
      name
      details
    }
  }
`;

export const UPDATE_PROGRAM_CATEGORY = gql`
  mutation UpdateProgramCategory($id: ID!, $name: String!, $details: String!) {
    updateProgramCategory(id: $id, input: { name: $name, details: $details }) {
      id
      name
    }
  }
`;

export const DELETE_PROGRAM_CATEGORY = gql`
  mutation DeleteCategory($id: ID!) {
    deleteProgramCategory(id: $id)
  }
`;
