import { gql } from "@apollo/client";

export const CREATE_ELIMINATION_RESULT = gql`
  mutation CreateEliminationResult($input: CreateEliminationResultInput) {
    createEliminationResult(input: $input) {
      id
    }
  }
`;

// Update Role Mutation
export const UPDATE_ELIMINATION_RESULT = gql`
  mutation UpdateEliminationResult(
    $id: ID!
    $input: UpdateEliminationResultInput
  ) {
    updateEliminationResult(id: $id, input: $input) {
      id
    }
  }
`;

export const UPDATE_ELIMINATION_RESULT_STATUS = gql`
  mutation UpdateEliminationResultStatus(
    $id: ID!
    $input: UpdateEliminationResultStatusInput!
  ) {
    updateEliminationResultStatus(id: $id, input: $input) {
      id
    }
  }
`;

export const DELETE_ELIMINATION_RESULT = gql`
  mutation DeleteEliminationResult($id: ID!) {
    deleteEliminationResult(id: $id)
  }
`;
