import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";

import { GET_SECTIONS } from "../../graphql/queries/section";

import Modal from "../UIElements/Model";
import Input from "../ui-elements/Input";
import Select from "../ui-elements/Select";
import { GET_CLASSES } from "../../graphql/queries/class";
import { CREATE_CLASS, DELETE_CLASS, UPDATE_CLASS } from "../../graphql/mutations/class";
import toast from "react-hot-toast";
import Card from "./Card";
import CardStructure from "./CardStructure";
import Skeleton from "../UIElements/Skeleton";
import { GET_CATEGORIES } from "../../graphql/queries/category";
import DeleteModal from "../UIElements/DeleteModal";

const Class: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [className, setClassName] = useState<string>("");
  const [selectedSection, setSelectedSection] = useState<string>("");
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [editClass, setEditClass] = useState<any>(null);
  const [deleteModel, setDeleteModel] = useState<boolean>(false);
  const [deleteClassId, setDeleteClassId] = useState<string>("");

  useEffect(() => {
    if (editClass) {
      setClassName(editClass.name);
      setSelectedSection(editClass?.section?.id);
      setSelectedCategory(editClass?.category?.id);
    }
  }, [editClass]);

  // QUERY
  const {
    data: sectionData,
    loading: sectionLoading,
    error: sectionError,
  } = useQuery(GET_SECTIONS);
  const {
    data: categoryData,
    loading: categoryLoading,
    error: categoryError,
  } = useQuery(GET_CATEGORIES);
  const {
    data: classData,
    loading: classLoading,
    error: classError,
    refetch,
  } = useQuery(GET_CLASSES);
  

  // MUTATION
  const [createClass, { loading: createClassLoading }] =
    useMutation(CREATE_CLASS);
  const [updateClass, { loading: updateClassLoading }] =
    useMutation(UPDATE_CLASS);
  const [deleteClass] =
    useMutation(DELETE_CLASS);

  if (sectionLoading || classLoading || categoryLoading) return <Skeleton />;

  if (sectionError)
    return <p>Error loading sections: {sectionError.message}</p>;
  if (classError) return <p>Error loading classes: {classError.message}</p>;
  if (categoryError) return <p>Error loading categories: {categoryError.message}</p>;

  const handleAddClass = async () => {
    setEditClass(null);
    clearInput();
    setIsOpen(true);
  };

  const handleEditClass = async (item: any) => {
    setEditClass(item);
    setClassName(item?.name);
    setSelectedSection(item?.section?.id);
    setSelectedCategory(item?.category?.id);
    setIsOpen(true);
  };

  const handleDeleteConfirm = async (id: string) => {
    setDeleteModel(true);
    setDeleteClassId(id);
  }

  const handleDelete = async() => {
    try {
      await deleteClass({ variables: {id: deleteClassId}});
      toast.success("Class deleted successfully!");
      setDeleteModel(false);
      clearInput();
      refetch();
    } catch (error) {
      console.error("Failed to delete the class", error);
      toast.error("Failed to delete the class")
    }
  }

  const clearInput = () => {
    setClassName("");
    setSelectedSection("");
    setSelectedCategory("");
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!className.trim() || !selectedSection.trim() || !selectedCategory) {
      toast.error("Please fill in all fields.");
      return;
    }

    try {
      if (editClass) {
        await updateClass({
          variables: {
            id: editClass.id,
            name: className.trim(),
            section: selectedSection,
            category: selectedCategory
          },
        });
        toast.success("Class updated successfully!");
      } else {
        await createClass({
          variables: {
            name: className.trim(),
            section: selectedSection,
            category: selectedCategory
          },
        });
        toast.success("Class created successfully!");
      }

      setIsOpen(false);
      clearInput();
      refetch();
    } catch (error) {
      console.error(
        editClass ? `Failed to update the class:` : `Failed to create the class:`,
        error
      );
      toast.error(
        editClass ? `Failed to update the class:` : `Failed to create the class:`
      );
    }
  };

  return (
    <>
      <CardStructure
        Heading="Class"
        addButton="Add"
        addButtonOnCLick={handleAddClass}
        data={classData?.classes}
        renderCard={(item: any) => (
          <Card
            item={item}
            title={item?.name}
            subTitle={item?.category?.name}
            handleEditButton={handleEditClass}
            handleDeleteButton={handleDeleteConfirm}
            textClass="text-sm"
          />
        )}
      />
      <Modal
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        title={editClass ? "Edit Class" : "Add Class"}
        handleSubmit={handleSubmit}
        loading={editClass ? updateClassLoading : createClassLoading}
      >
        <form className="flex flex-col gap-3" onSubmit={handleSubmit}>
          <Input
            placeholder="Enter Class Name"
            label="name"
            value={className}
            onChange={(e) => setClassName(e.target.value)}
          />
          <Select
            label="section"
            value={selectedSection}
            onChange={(e) => setSelectedSection(e.target.value)}
            options={sectionData?.sections}
          />
          <Select
            label="category"
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            options={categoryData?.categories}
          />
        </form>
      </Modal>
      <DeleteModal
        setIsOpen={setDeleteModel}
        isOpen={deleteModel}
        handleDelete={handleDelete}
      />
    </>
  );
};

export default Class;
