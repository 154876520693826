import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { DELETE_PROGRAM } from "../../graphql/mutations/program"; // Path to your mutation
import Private from "../../context/Private";
import ParticipationForm from "../participation/ParticipationForm";
import DeleteModal from "../UIElements/DeleteModal";
import { GET_PROGRAMS } from "../../pages/programs/programQuery";

interface CardHeaderProps {
  name: string;
  programCode: string;
  programId: string;
  noOfCandidates: number;
  category: {
    name: string;
    id: string;
  };
  isRegistrable: boolean
}

const CardHeader: React.FC<CardHeaderProps> = ({
  name,
  programCode,
  programId,
  noOfCandidates,
  category,
  isRegistrable
}) => {
  const [deleteModel, setDeleteModel] = useState<boolean>(false);
  const [isModalOpen, setModalOpen] = useState(false); // State to manage modal

  const [deleteProgram] = useMutation(
    DELETE_PROGRAM,
    {
      variables: { id: programId },
      refetchQueries: [{ query: GET_PROGRAMS }], // Refetch the programs list after deletion
      onCompleted: () => {
        setDeleteModel(false);
        toast.success("Program deleted successfully!");
      },
      onError: (error) => {
        console.error("Delete error:", error);
        toast.error("Failed to delete the program!");
      },
    }
  );

  const handleDeleteConfirm = async () => {
    setDeleteModel(true);
  };

  const handleDelete = () => {
    deleteProgram();
  };

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  return (
    <div className="card-header">
      <div className="flex justify-between items-center">
        <Link to={`/programs/${programId}`}>
          <h5 className="card-title hover:text-blue-600">
            {name} - {programCode}
          </h5>
        </Link>

        <div className="flex space-x-2">
          {/* When clicked, toggle the modal */}
          <Private
            permissions={["create-participation"]}
            element={
              <span className="text-xl cursor-pointer" onClick={toggleModal}>
                <i className="mgc_user_add_line"></i>
              </span>
            }
          />

          <Private
            permissions={["update-program"]}
            element={
              <Link to={`/programs/edit/${programId}`} className="text-xl">
                <i className="mgc_pencil_line"></i>
              </Link>
            }
          />
          <Private
            permissions={["delete-program"]}
            element={
              <a
                href="#"
                onClick={handleDeleteConfirm}
                className="text-xl text-red-600"
              >
                <i className="mgc_delete_2_line"></i>
              </a>
            }
          />
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div
            className="bg-black bg-opacity-50 absolute inset-0"
            onClick={toggleModal}
          ></div>
          <div className="bg-white p-6 rounded-lg z-10 max-w-lg w-full max-h-[90vh] overflow-y-auto no-scrollbar">
            <h2 className="text-2xl mb-4 text-gray-800">Add Participation</h2>
            <ParticipationForm
              programId={programId}
              programName={name}
              noOfCandidates={noOfCandidates}
              programCode={programCode}
              category={category}
              isRegistrable={isRegistrable}
              closeModal={toggleModal}
            />
          </div>
        </div>
      )}

      <DeleteModal
        setIsOpen={setDeleteModel}
        isOpen={deleteModel}
        handleDelete={handleDelete}
      />
    </div>
  );
};

export default CardHeader;
