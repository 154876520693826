import { gql } from "@apollo/client";

export const CREATE_CLUSTER = gql`
  mutation CreateCluster($name: String!, $section: ID!) {
    createCluster(input: { name: $name, section: $section }) {
      id
      name
    }
  }
`;

export const UPDATE_CLUSTER = gql`
  mutation UpdateCluster($id: ID!, $name: String!, $section: ID!) {
    updateCluster(id: $id, input: { name: $name, section: $section }) {
      id
      name
    }
  }
`;

export const DELETE_CLUSTER = gql`
  mutation DeleteCluster($id: ID!) {
    deleteCluster(id: $id)
  }
`;
