import { gql } from "@apollo/client";

export const CREATE_PROPOSAL_SUBMISSION = gql`
mutation CreateProposalSubmission($input: CreateProposalSubmissionInput) {
  createProposalSubmission(input: $input) {
    proposalText
    id
  }
}
`;

export const UPDATE_PROPOSAL_SUBMISSION = gql`
mutation UpdateProposalSubmission($id: ID!, $input: UpdateProposalSubmissionInput) {
  updateProposalSubmission(id: $id, input: $input) {
    id
  }
}
`;


export const DELETE_PROPOSAL_SUBMISSION = gql`
  mutation DeleteProposalSubmission($id: ID!) {
    deleteProposalSubmission(id: $id)
  }
`;

