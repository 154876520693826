import React from "react";
import StatusCountCard from "../../../components/result/elimination/dashboard/StatusCountCard";
import ChartSection from "../../../components/result/elimination/dashboard/ChartSection";
import Leaderboard from "../../../components/result/elimination/dashboard/Leaderboard";

const EliminationDashboard: React.FC = () => {
  const statusCountCardData = [
    {
      title: "Not_Entered",
      color: "red",
    },
    {
      title: "Entering",
      color: "pink",
    },
    {
      title: "Prepared",
      color: "violet",
    },
    {
      title: "To_Publish",
      color: "orange",
    },
    {
      title: "Published",
      color: "blue",
    },
    {
      title: "Announced",
      color: "green",
    },
  ];

  return (
    <div className="w-full p-2 py-4 bg-gray-100 space-y-4">
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4">
        {statusCountCardData.map((sccd, index) => (
          <StatusCountCard key={index} {...sccd} />
        ))}
      </div>
      <ChartSection />
      <Leaderboard />
    </div>
  );
};

export default EliminationDashboard;
