import React, { useEffect, useRef, useState } from "react";
import CodeLetterStudent from "./CodeLetterStudent";
import CodeLetterSpinner from "./CodeLetterSpinner";
import Modal from "../UIElements/Model";
import { gql, useMutation, useQuery } from "@apollo/client";
import { UPDATE_ELIMINATION_REGISTRATION_STATUS } from "../../graphql/mutations/program";
import toast from "react-hot-toast";
import Skeleton from "../UIElements/Skeleton";

const GET_GROUPS = gql`
  query Groups {
    groups {
      id
      name
    }
  }
`;

const GET_PROGRAM_BY_ID = gql`
  query GetProgramById($id: ID!) {
    program(id: $id) {
      id
      name
      programCode
      category {
        id
        name
      }
      eliminationRegistrationStatus {
        key
        value
      }
      eliminationDate {
        key
        value
      }
      eliminationEndingTime {
        key
        value
      }
      eliminationStartingTime {
        key
        value
      }
      eliminationVenue {
        key
        value {
          id
          name
        }
      }
    }
  }
`;

interface CodeLetterContentProps {
  programId: string;
  participants?: any;
  groupId?: string;
  clusterId?: string;
  eliminationRegistrationStatus: string;
  setEliminationRegistrationStatus: (status: string) => void;
}

const CodeLetterContent: React.FC<CodeLetterContentProps> = ({
  programId,
  participants,
  groupId,
  clusterId,
  eliminationRegistrationStatus,
  setEliminationRegistrationStatus,
}) => {
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setActiveGroupId(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toSpin = participants?.filter((participant: any) => {
    if (participant?.codeLetter) return false;
    return true;
  });

  const {
    data: programData,
    loading,
    error,
    refetch: refetchProgram,
  } = useQuery(GET_PROGRAM_BY_ID, {
    variables: { id: programId },
    skip: !programId,
  });

  const program = programData?.program;

  useEffect(() => {
    if (program) {
      setEliminationRegistrationStatus(
        program?.eliminationRegistrationStatus?.find(
          (item: any) => item?.key === groupId
        )?.value
      );
    }
    // console.log(programData?.program);

  }, [program]);

  useEffect(() => {
    if (toSpin) {
      setParticipationId(toSpin[0]?.id);
    }
  }, [participants]);

  let options = Array.from({ length: participants?.length }, (_, i) =>
    String.fromCharCode(65 + (i % 26))
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [participationId, setParticipationId] = useState("");
  const [activeGroupId, setActiveGroupId] = useState<string | null>(null);

  const { data: groupData } = useQuery(GET_GROUPS);
  const [updateEliminationRegistrationStatus] = useMutation(
    UPDATE_ELIMINATION_REGISTRATION_STATUS
  );

  function toDate(date: string) {
    const dateObj = new Date(date);
    const day = dateObj.getDate();
    const month = dateObj.getMonth() + 1;
    const year = dateObj.getFullYear();
    return `${day}-${month}-${year}`;
  }

  function toTime(time: string): string {
    const dateObj = new Date(time);
    let hours = dateObj.getHours();
    const minutes = dateObj.getMinutes();
    const period = hours >= 12 ? "PM" : "AM";

    hours = hours % 12 || 12;

    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");

    return `${formattedHours}:${formattedMinutes} ${period}`;
  }

  function toggleStatusOpen(groupId: string) {
    setActiveGroupId((prev) => (prev === groupId ? null : groupId));
  }

  const handleUpdate = async (groupId: string, value: string) => {
    const updateLoading = toast.loading("Updating status...");
    try {
      await updateEliminationRegistrationStatus({
        variables: {
          programId: program?.id,
          input: {
            eliminationRegistrationStatus: [
              {
                key: groupId,
                value,
              },
            ],
          },
        },
      });
      setActiveGroupId("");
      refetchProgram();
      toast.success("Status updated successfully!");
      toast.remove(updateLoading);
    } catch (error: any) {
      console.log(error);
      setActiveGroupId("");
      toast.remove(updateLoading);
      toast.error("Failed to update status");
    }
  };

  if (loading) {
    return <Skeleton />;
  }

  if (error) {
    return <p> Failed to Featch programData {error.message}</p>;
  }

  return (
    <>
      <div className="flex items-center justify-between py-4 md:p-11">
        <div className="w-1/2 flex flex-col">
          <h3 className="text-xl text-black font-semibold">
            {program?.programCode} - {program?.name}
          </h3>
          <p className="text-sm text-gray-600">{program?.category?.name}</p>
        </div>
        <div className="flex flex-col items-start justify-between">
          {groupData && !groupId?.length ? (
            groupData.groups.map((group: any, groupIndex: number) => (
              <div
                key={groupIndex}
                className="flex items-center justify-center gap-4 border-b border-gray-200 py-2"
              >
                <span className="whitespace-nowrap">
                  {group?.name ?? "N/A"}
                </span>
                <div className="items-start hidden md:block">
                  {program?.eliminationDate?.map(
                    (date: any, dateIndex: number) =>
                      group?.id === date.key && (
                        <React.Fragment key={dateIndex}>
                          <p className="text-sm text-gray-600 whitespace-nowrap mb-2">
                            {toDate(date.value)}
                          </p>
                          <div className="bg-blue-100 text-blue-600 px-2 py-0.5 rounded-full text-xs font-semibold mb-1">
                            <span>
                              {program?.eliminationVenue?.[dateIndex]?.value
                                ?.name ?? ""}
                            </span>
                          </div>
                        </React.Fragment>
                      )
                  )}
                </div>
                <div className="items-start hidden md:block">
                  {program?.eliminationStartingTime?.map(
                    (time: any, timeIndex: number) =>
                      group?.id === time.key && (
                        <React.Fragment key={timeIndex}>
                          <p className="text-xs">START TIME</p>
                          <div className="w-24 flex  items-end font-semibold">
                            <span className="text-lg mr-1">
                              {toTime(time.value).split(" ")[0]}
                            </span>
                            <span className="text-xs text-blue-700 mb-1">
                              {toTime(time.value).split(" ")[1]}
                            </span>
                          </div>
                        </React.Fragment>
                      )
                  )}
                </div>
                <div className="hidden md:block">
                  {program?.eliminationEndingTime?.map(
                    (time: any, endTimeIndex: number) =>
                      group?.id === time.key && (
                        <React.Fragment key={endTimeIndex}>
                          <p className="text-xs">END TIME</p>
                          <div className="w-24 flex items-end font-semibold">
                            <span className="text-lg mr-1">
                              {toTime(time.value).split(" ")[0]}
                            </span>
                            <span className="text-xs text-blue-700 mb-1">
                              {toTime(time.value).split(" ")[1]}
                            </span>
                          </div>
                        </React.Fragment>
                      )
                  )}
                </div>
                <div className="relative">
                  <div
                    className={` w-24 h-16 px-2 py-3 text-center rounded-lg text-xs font-semibold 
                                        ${program?.eliminationRegistrationStatus?.find(
                      (item: any) =>
                        item?.key === group?.id
                    )?.value === "Program_Ended"
                        ? "bg-red-200 text-red-600"
                        : program?.eliminationRegistrationStatus?.find(
                          (item: any) =>
                            item?.key === group?.id
                        )?.value === "Program_Started"
                          ? "bg-green-200 text-green-600"
                          : program?.eliminationRegistrationStatus?.find(
                            (item: any) =>
                              item?.key === group?.id
                          )?.value ===
                            "Registration_Completed"
                            ? "bg-blue-200 text-blue-600"
                            : program?.eliminationRegistrationStatus?.find(
                              (item: any) =>
                                item?.key === group?.id
                            )?.value ===
                              "Registration_Started"
                              ? "bg-orange-200 text-orange-600"
                              : "bg-gray-200 text-gray-600"
                      }`}
                    onClick={() => toggleStatusOpen(group.id)}
                  >
                    <i
                      className={`text-lg leading-none
                                        ${program?.eliminationRegistrationStatus?.find(
                        (item: any) =>
                          item?.key === group?.id
                      )?.value === "Program_Ended"
                          ? "mgc_certificate_line"
                          : program?.eliminationRegistrationStatus?.find(
                            (item: any) =>
                              item?.key === group?.id
                          )?.value === "Program_Started"
                            ? "mgc_check_circle_line"
                            : program?.eliminationRegistrationStatus?.find(
                              (item: any) =>
                                item?.key === group?.id
                            )?.value ===
                              "Registration_Completed"
                              ? "mgc_lock_line"
                              : program?.eliminationRegistrationStatus?.find(
                                (item: any) =>
                                  item?.key === group?.id
                              )?.value ===
                                "Registration_Started"
                                ? "mgc_alert_diamond_line"
                                : "mgc_close_circle_line"
                        }
                                    `}
                    ></i>

                    <br />
                    {program?.eliminationRegistrationStatus
                      ?.find((item: any) => item?.key === group?.id)
                      ?.value.split("_")
                      .join(" ") || "Not Started"}
                  </div>

                  {activeGroupId === group.id && (
                    <div
                      ref={dropdownRef}
                      className="absolute bg-white rounded-xl z-20"
                    >
                      {program?.eliminationRegistrationStatus?.find(
                        (item: any) => item?.key === group?.id
                      )?.value === "Program_Ended" ||
                        program?.eliminationRegistrationStatus?.find(
                          (item: any) => item?.key === group?.id
                        )?.value === "Registration_Completed" ||
                        program?.eliminationRegistrationStatus?.find(
                          (item: any) => item?.key === group?.id
                        )?.value === "Program_Started" ? (
                        <>
                          <div
                            className="px-4 py-3 border-b hover:bg-gray-300 rounded-t-xl text-center cursor-pointer"
                            onClick={async () =>
                              handleUpdate(group.id, "Registration_Completed")
                            }
                          >
                            Registration Completed
                          </div>
                          <div
                            className="px-4 py-3 border-b hover:bg-gray-300 text-center cursor-pointer"
                            onClick={async () =>
                              handleUpdate(group.id, "Program_Started")
                            }
                          >
                            Program Started
                          </div>
                          <div
                            className="px-4 py-3 border-b hover:bg-gray-300 text-center rounded-b-xl cursor-pointer"
                            onClick={() =>
                              handleUpdate(group.id, "Program_Ended")
                            }
                          >
                            Program Ended
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            className="px-4 py-3 border-b hover:bg-gray-300 text-center rounded-t-xl cursor-pointer"
                            onClick={() =>
                              handleUpdate(group.id, "Not_Started")
                            }
                          >
                            Not Started
                          </div>
                          <div
                            className="px-4 py-3 border-b hover:bg-gray-300 text-center rounded-b-xl cursor-pointer"
                            onClick={() =>
                              handleUpdate(group.id, "Registration_Started")
                            }
                          >
                            Registration Started
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
            ))
          ) : (
            <div className="flex items-center justify-between gap-2">
              <div className="items-start hidden md:block">
                {program?.eliminationDate?.map(
                  (date: any, dateIndex: number) =>
                    groupId === date.key && (
                      <React.Fragment key={dateIndex}>
                        <p className="text-sm text-gray-600 whitespace-nowrap mb-2">
                          {toDate(date.value)}
                        </p>
                        <div className="bg-blue-100 text-blue-600 px-2 py-0.5 rounded-full text-xs font-semibold mb-1">
                          <span>
                            {program?.eliminationVenue?.[dateIndex]?.value
                              ?.name ?? "N/A"}
                          </span>
                        </div>
                      </React.Fragment>
                    )
                )}
              </div>
              <div className="hidden md:block items-start">
                {program?.eliminationStartingTime?.map(
                  (time: any, timeIndex: number) =>
                    groupId === time.key && (
                      <React.Fragment key={timeIndex}>
                        <p className="text-xs">START TIME</p>
                        <div className="w-24 flex items-end font-semibold">
                          <span className="text-lg mr-1">
                            {toTime(time.value).split(" ")[0]}
                          </span>
                          <span className="text-xs text-blue-700 mb-1">
                            {toTime(time.value).split(" ")[1]}
                          </span>
                        </div>
                      </React.Fragment>
                    )
                )}
              </div>
              <div className="hidden md:block items-start">
                {program?.eliminationEndingTime?.map(
                  (time: any, endTimeIndex: number) =>
                    groupId === time.key && (
                      <React.Fragment key={endTimeIndex}>
                        <p className="text-xs">END TIME</p>
                        <div className="w-24 flex  items-end font-semibold">
                          <span className="text-lg mr-1">
                            {toTime(time.value).split(" ")[0]}
                          </span>
                          <span className="text-xs text-blue-700 mb-1">
                            {toTime(time.value).split(" ")[1]}
                          </span>
                        </div>
                      </React.Fragment>
                    )
                )}
              </div>
              <div className="relative">
                <div
                  className={`w-24 h-16 px-2 py-3 text-center rounded-lg text-xs font-semibold 
                                        ${program?.eliminationRegistrationStatus?.find(
                    (item: any) => item?.key === groupId
                  )?.value === "Program_Ended"
                      ? "bg-red-200 text-red-600"
                      : program?.eliminationRegistrationStatus?.find(
                        (item: any) =>
                          item?.key === groupId
                      )?.value === "Program_Started"
                        ? "bg-green-200 text-green-600"
                        : program?.eliminationRegistrationStatus?.find(
                          (item: any) =>
                            item?.key === groupId
                        )?.value ===
                          "Registration_Completed"
                          ? "bg-blue-200 text-blue-600"
                          : program?.eliminationRegistrationStatus?.find(
                            (item: any) =>
                              item?.key === groupId
                          )?.value ===
                            "Registration_Started"
                            ? "bg-orange-200 text-orange-600"
                            : "bg-gray-200 text-gray-600"
                    }`}
                  onClick={() => toggleStatusOpen(groupId || "")}
                >
                  <i
                    className={`text-lg leading-none
                                        ${program?.eliminationRegistrationStatus?.find(
                      (item: any) => item?.key === groupId
                    )?.value === "Program_Ended"
                        ? "mgc_certificate_line"
                        : program?.eliminationRegistrationStatus?.find(
                          (item: any) =>
                            item?.key === groupId
                        )?.value === "Program_Started"
                          ? "mgc_check_circle_line"
                          : program?.eliminationRegistrationStatus?.find(
                            (item: any) =>
                              item?.key === groupId
                          )?.value ===
                            "Registration_Completed"
                            ? "mgc_lock_line"
                            : program?.eliminationRegistrationStatus?.find(
                              (item: any) =>
                                item?.key === groupId
                            )?.value ===
                              "Registration_Started"
                              ? "mgc_alert_diamond_line"
                              : "mgc_close_circle_line"
                      }
                                    `}
                  ></i>

                  <br />
                  {program?.eliminationRegistrationStatus
                    ?.find((item: any) => item?.key === groupId)
                    ?.value.split("_")
                    .join(" ") || "Not Started"}
                </div>
                {activeGroupId === groupId && (
                  <div
                    ref={dropdownRef}
                    className="absolute bg-white rounded-xl z-20"
                  >
                    {program?.eliminationRegistrationStatus?.find(
                      (item: any) => item?.key === groupId
                    )?.value === "Program_Ended" ||
                      program?.eliminationRegistrationStatus?.find(
                        (item: any) => item?.key === groupId
                      )?.value === "Registration_Completed" ||
                      program?.eliminationRegistrationStatus?.find(
                        (item: any) => item?.key === groupId
                      )?.value === "Program_Started" ? (
                      <>
                        <div
                          className="px-4 py-3 border-b hover:bg-gray-300 rounded-t-xl text-center cursor-pointer"
                          onClick={() =>
                            handleUpdate(groupId, "Registration_Completed")
                          }
                        >
                          Registration Completed
                        </div>
                        <div
                          className="px-4 py-3 border-b hover:bg-gray-300 text-center  cursor-pointer"
                          onClick={() =>
                            handleUpdate(groupId, "Program_Started")
                          }
                        >
                          Program Started
                        </div>
                        <div
                          className="px-4 py-3 border-b hover:bg-gray-300 rounded-b-xl text-center  cursor-pointer"
                          onClick={() => handleUpdate(groupId, "Program_Ended")}
                        >
                          Progam Ended
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className="px-4 py-3 border-b hover:bg-gray-300 rounded-t-xl text-center cursor-pointer"
                          onClick={() => handleUpdate(groupId, "Not_Started")}
                        >
                          Not Started
                        </div>
                        <div
                          className="px-4 py-3 border-b hover:bg-gray-300 rounded-b-xl text-center cursor-pointer"
                          onClick={() =>
                            handleUpdate(groupId, "Registration_Started")
                          }
                        >
                          Registration Started
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        {eliminationRegistrationStatus === "Registration_Started" && groupId && (
          <img
            src="img/spinner.png"
            className="w-16 cursor-pointer rounded-full ml-3"
            onClick={() => setIsModalOpen(true)}
          />
        )}
      </div>
      <div className="w-screen md:w-full overflow-x-scroll">
        <table className="w-full rounded-3xl overflow-hidden">
          <thead>
            <tr className="bg-gray-200 text-gray-700">
              <th className="p-5 text-center">Sl.No</th>
              <th className="p-5 text-center">Chest.No</th>
              <th className="p-5 text-center">Candidates</th>
              <th className="p-5 text-center">Institution</th>
              <th className="p-5 text-center">Code Letter</th>
              <th className="p-5 text-center">Attendance</th>
            </tr>
          </thead>
          <tbody>
            {participants?.map((participant: any, index: number) => (
              <CodeLetterStudent
                key={index}
                serialNo={index + 1}
                chestNumber={participant?.candidate[0]?.chestNo}
                candidateName={participant?.candidate[0]?.name}
                candidateId={participant?.id}
                institution={participant?.candidate[0]?.institution?.shortName}
                codeLetter={participant?.codeLetter}
                attendanceStatus={participant?.isPresent}
                eliminationRegistrationStatus={
                  eliminationRegistrationStatus === "Registration_Started"
                }
                options={options}
                programId={program.id}
                groupId={groupId}
                clusterId={clusterId}
              />
            ))}
          </tbody>
        </table>
      </div>

      {isModalOpen && (
        <Modal
          title="Spin Code Letter"
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          onClose={() => setIsModalOpen(false)}
        >
          <div className="flex flex-col justify-center items-center px-2 text-gray-700">
            <div className="w-full px-2">
              <label className="text-gray-600 mt-1 inline-block">
                Select Candidate
              </label>
              <select
                value={participationId}
                onChange={(e) => setParticipationId(e.target.value)}
                className="text-sm m-1 ml-0 rounded-lg min-w-56 w-full border-2 border-gray-300 p-1 inline-block"
              >
                {toSpin?.map((participant: any, index: number) => (
                  <option key={index} value={participant?.id}>
                    {participant?.candidate[0]?.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="w-full px-2 flex flex-col items-center justify-center">
              <label className="text-gray-600 mt-1 inline-block">
                Code Letter
              </label>
            </div>
          </div>
          {eliminationRegistrationStatus && (
            <div className="flex flex-row items-center justify-center p-2 gap-4">
              <CodeLetterSpinner
                options={options}
                participationId={participationId}
                programId={program.id}
                groupId={groupId}
                clusterId={clusterId}
              />
            </div>
          )}
        </Modal>
      )}
    </>
  );
};

export default CodeLetterContent;
