import React, { useState } from "react";
import Logo from "./Logo";
import SearchModal, { SearchButton } from "./SearchModal";
import NotificationDropdown from "./NotificationDropdown";
import ProfileDropdown from "./ProfileDropdown";
import MenuToggleButton from "./MenuToggleButton";
import FullscreenToggleButton from "./FullscreenToggleButton";
import DarkModeToggle from "./DarkModeToggle";
import SectionDropdown from "./SectionDropdown";
import { useAuth } from "../../context/AuthContext";

const Topbar: React.FC = () => {
  const [isSearchOpen, setSearchOpen] = useState(false);
  const { user } = useAuth();

  return (
    <>
      {/* Topbar Start */}
      <header className="app-header flex items-center p-4 gap-3">
        {/* Sidenav Menu Toggle Button */}
        <MenuToggleButton />
        {/* Logo Section */}
        <Logo />

        {/* Topbar Search Modal Button */}
        <SearchButton
          isSearchOpen={isSearchOpen}
          setSearchOpen={setSearchOpen}
        />

        {/* SectionDropdown*/}
        {!user?.role.section && <SectionDropdown />}

        {/* Fullscreen Toggle Button */}
        <FullscreenToggleButton />

        {/* Notification Dropdown */}
        <NotificationDropdown />

        {/* Dark Mode Toggle */}
        <DarkModeToggle />

        {/* Profile Dropdown */}
        <ProfileDropdown />
      </header>
      <SearchModal isOpen={isSearchOpen} />
    </>
  );
};

export default Topbar;
