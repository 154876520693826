import React from "react";
import { useQuery } from "@apollo/client";
import Skeleton from "../UIElements/Skeleton";
import { GET_DEBATE_PROGRAMS } from "../../graphql/queries/program";

interface DebateInputProps {
  debate: string;
  setDebate: (debate: string) => void;
}

const DebateInput: React.FC<DebateInputProps> = ({ debate, setDebate }) => {
  const { data, loading, error } = useQuery(GET_DEBATE_PROGRAMS);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setDebate(e.target.value);
  };

  if (loading) return <Skeleton />;
  if (error) return <p>Error loading debates</p>;

  return (
    <div className="mb-3">
      <label
        htmlFor="debateSelect"
        className="text-gray-800 text-sm font-medium inline-block mb-2"
      >
        Select Debate
      </label>
      <select
        id="debateSelect"
        className="form-select"
        value={debate}
        onChange={handleChange}
        required
      >
        <option value="" disabled>
          Select a Debate
        </option>
        {data?.debateProgram?.map((debate: any) => (
          <option key={debate.id} value={debate.id}>
            {debate.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default DebateInput;
