import { gql } from "@apollo/client";

export const GET_TOPIC_SELECTIONS = gql`
  query TopicSelections {
    topicSelections {
      topic
      program {
        id
        name
      }
      id
      groupA
      groupB
      groupC
    }
  }
`;

export const GET_TOPIC_SELECTIONS_BY_ID = gql`
  query GetTopicById($id: ID!) {
    topicSelection(id: $id) {
      topic
      program {
        id
        name
      }
      id
      groupA
      groupB
      groupC
    }
  }
`;