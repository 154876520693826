import React, { useState, useEffect, useRef } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import toast from "react-hot-toast";
import * as XLSX from "xlsx";

import PageTitle from "../../components/PageTitle";
import ProgramCard from "../../components/item-reg/ProgramCard";
import RegisterItem from "../../components/item-reg/RegisterItem";
import { SUBSCRIPTION } from "../../graphql/queries/itemReg";
import { DELETE_ITEM_REGISTRATION } from "../../graphql/mutations/itemReg";
import { useAuth } from "../../context/AuthContext";
import { UPDATE_REGISTRATION_STATUS } from "../../graphql/mutations/participation";
import Private from "../../context/Private";
import { ROUND } from "../../lib/Const";

// Queries
const GET_ITEM_REGISTRATIONS = gql`
  query ItemRegistrations(
    $search: String
    $institutionId: String
    $categoryId: String
    $programId: String
    $groupId: String
    $status: String
    $offset: Int
    $limit: Int
    $round: String
  ) {
    itemRegistrations(
      search: $search
      institutionId: $institutionId
      categoryId: $categoryId
      programId: $programId
      groupId: $groupId
      status: $status
      offset: $offset
      limit: $limit
      round: $round
    ) {
      id
      link
      remarks
      status
      title
      description
      program {
        id
        name
        category {
          id
          name
        }
        programCode
      }
      institution {
        id
        name
        groupId {
          id
          name
        }
      }
      participation {
        id
        candidate {
          name
          photo
          chestNo
        }
      }
      status
      createdAt
    }
  }
`;

const GET_INSTITUTIONS = gql`
  query Institutions {
    institutions {
      id
      name
      groupId {
        id
        name
      }
    }
  }
`;

const GET_REGISTRABLE_PROGRAMS = gql`
  query RegistrablePrograms {
    registrablePrograms {
      id
      name
      category {
        id
        name
      }
      programCode
    }
  }
`;

const GET_GROUPS = gql`
  query Groups {
    groups {
      id
      name
    }
  }
`;

interface ItemRegistration {
  id: string;
  title: string;
  description: string;
  program: {
    id: string;
    name: string;
    category: {
      name: string;
      id: string;
    };
    programCode: string;
  };
  participation: {
    id: string;
  };
  institution: {
    id: string;
    name: string;
    groupId: {
      id: string;
      name: string;
    };
  };
  file: string;
  link: string;
  status: string;
  remarks: string;
  createdAt: string;
}

const ItemReg: React.FC = () => {
  const { user } = useAuth();

  const [itemRegistrations, setItemRegistrations] = useState<
    ItemRegistration[]
  >([]);
  const [searchQuery] = useState("");
  const [selectedItem, setSelectedItem] = useState<ItemRegistration | null>(
    null
  );
  const [selectedStatus, setSelectedStatus] = useState<string>("");
  const [selectedInstitution, setSelectedInstitution] = useState<string>(
    `${user?.institution ? user?.institution?.id : ""}`
  );
  const [selectedCategory] = useState<string>("");
  const [selectedProgram, setSelectedProgram] = useState<string>("");
  const [selectedGroup, setSelectedGroup] = useState<string>("");
  const [isAll, setIsAll] = useState<boolean>(user?.institution ? false : true);
  const [openDropdownId, setOpenDropdownId] = useState<string | null>(null);

  const LIMIT = 12;
  const [hasMore, setHasMore] = useState(true);
  const [IsLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const dropdownRef = useRef<HTMLDivElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);

  const { subscribeToMore, data, loading, error, refetch, fetchMore } =
    useQuery(GET_ITEM_REGISTRATIONS, {
      variables: {
        search: searchQuery,
        institutionId: selectedInstitution,
        categoryId: selectedCategory,
        programId: selectedProgram,
        groupId: selectedGroup,
        status: selectedStatus,
        offset: 0,
        limit: LIMIT,
        round: ROUND === "final" ? "final" : "",
      },
    });
  const { data: downloadData, loading: downloadLoading } = useQuery(
    GET_ITEM_REGISTRATIONS,
    {
      variables: {
        offset: 0,
        limit: null,
        round: ROUND === "final" ? "final" : "",
      },
      fetchPolicy: "cache-and-network",
    }
  );
  const { data: institutionData } = useQuery(GET_INSTITUTIONS);
  const { data: programsData } = useQuery(GET_REGISTRABLE_PROGRAMS);
  const { data: groupData } = useQuery(GET_GROUPS);
  const [deleteItemRegistration] = useMutation(DELETE_ITEM_REGISTRATION);
  const [updateRegistrationStatus] = useMutation(UPDATE_REGISTRATION_STATUS);

  const toggleDropdown = (id: string) => {
    setOpenDropdownId((prev) => (prev === id ? null : id));
  };

  const loadMore = async () => {
    setIsLoadingMore(true);
    try {
      await fetchMore({
        variables: {
          search: searchQuery,
          institutionId: selectedInstitution,
          categoryId: selectedCategory,
          status: selectedStatus,
          offset: itemRegistrations.length,
          limit: LIMIT,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (
            !fetchMoreResult ||
            fetchMoreResult.itemRegistrations.length === 0
          ) {
            setHasMore(false);
            return prev;
          }
          setHasMore(true);
          return {
            ...prev,
            itemRegistrations: [
              ...prev.itemRegistrations,
              ...fetchMoreResult.itemRegistrations,
            ],
          };
        },
      });
    } finally {
      setIsLoadingMore(false);
    }
  };

  const filteredOptions = programsData?.registrablePrograms?.filter(
    (option: any) =>
      option?.name?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    if (data && data.itemRegistrations) {
      setItemRegistrations(
        data.itemRegistrations.filter((item: ItemRegistration) =>
          !isAll ? item.institution.id === user?.institution?.id : true
        )
      );
      setIsLoading(false);
      if (data.itemRegistrations.length < LIMIT) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
    }
  }, [data]);

  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const newFeedItem = subscriptionData.data.registrationAdded;
        return {
          itemRegistrations: [newFeedItem, ...prev.itemRegistrations],
        };
      },
    });
    return () => unsubscribe();
  }, [subscribeToMore]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }

    if (isOpen) {
      searchInputRef.current?.focus();
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const handleDownload = () => {
    if (downloadData) {
      const filteredCandidates = downloadData?.itemRegistrations?.filter(
        (item: ItemRegistration) => {
          const matchesSearch = item.program.name
            .toLowerCase()
            .includes(searchTerm.toLowerCase());
          const matchesCategory = selectedCategory
            ? item.program?.category?.id === selectedCategory
            : true;
          const matchesCandidateInstitution = selectedInstitution
            ? item?.institution?.id === selectedInstitution
            : true;
          const matchesStatus = selectedStatus
            ? item?.status === selectedStatus
            : true;

          return (
            matchesSearch &&
            matchesCategory &&
            matchesCandidateInstitution &&
            matchesStatus
          );
        }
      );

      const rows = filteredCandidates?.map((item: ItemRegistration) => ({
        "Program Code": item.program?.programCode,
        "Program Name": item.program?.name,
        Category: item.program?.category?.name,
        "Institution Name": item.institution?.name,
        "Item Title": item.title,
        Description: item.description,
        Link: item.link,
        Status: item.status,
        "Created At": new Date(item.createdAt).toLocaleDateString(),
      }));

      if (!rows || rows.length === 0) {
        console.warn("No data available for download.");
        return;
      }

      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(rows, {
        header: [
          "Program Code",
          "Program Name",
          "Category",
          "Institution Name",
          "Item Title",
          "Description",
          "Link",
          "Status",
          "Created At",
        ],
      });
      XLSX.utils.book_append_sheet(workbook, worksheet, "Item-Registration");

      XLSX.writeFile(workbook, "Item-Registration.xlsx", { compression: true });
    }
  };

  const handleEdit = (item: ItemRegistration) => {
    toggleDropdown(item.id);
    setSelectedItem(item);
  };

  const handleDelete = async (id: string) => {
    toggleDropdown(id);
    try {
      if (confirm("Are you sure you want to delete the Registration?")) {
        await deleteItemRegistration({ variables: { id } });
        setItemRegistrations(
          itemRegistrations.filter((item) => item.id !== id)
        );
        toast.success("Item deleted successfully");
        let participationId = itemRegistrations.filter(
          (item) => item.id === id
        )[0]?.participation?.id;
        await updateRegistrationStatus({
          variables: {
            id: participationId,
            input: {
              isRegistered: false,
            },
          },
        });
      }
    } catch (error) {
      console.error("Error deleting item registration:", error);
    }
  };

  const handleSave = () => {
    setSelectedItem(null);
    refetch();
  };

  const handleCancel = () => {
    setSelectedItem(null);
  };

  if (error) return <p>Error: {error.message}</p>;

  return (
    <>
      <PageTitle pagetitle="Item Registration" subtitle="Registration" />
      <div className="w-full">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          <Private
            permissions={["create-item-registration"]}
            element={
              <RegisterItem
                selectedItem={selectedItem}
                onSave={handleSave}
                onCancel={handleCancel}
                refetch={refetch}
              />
            }
          />
          <div className="p-6 bg-white dark:bg-slate-800 rounded-lg col-span-2">
            <div className="flex justify-between align-center">
              <h2 className="text-base font-medium mb-4">Registered Items</h2>
              {user?.institution && (
                <h2 className="text-sm font-medium mb-4">
                  <a
                    className={`cursor-pointer mr-2 ${
                      isAll && "p-2 bg-gray-600 text-white rounded-md"
                    }`}
                    onClick={() => {
                      setIsAll(true);
                      setSelectedInstitution("");
                    }}
                  >
                    All
                  </a>
                  <a
                    className={`cursor-pointer ml-2  ${
                      !isAll && "p-2 bg-gray-600 text-white rounded-md"
                    }`}
                    onClick={() => {
                      setIsAll(false);
                      setSelectedInstitution(user?.institution?.id);
                    }}
                  >
                    Your Institution
                  </a>
                </h2>
              )}
            </div>
            <div className="flex flex-col sm:flex-row gap-2 mb-7">
              <div className="relative" ref={dropdownRef}>
                <div className="w-full">
                  <input
                    type="text"
                    value={searchTerm}
                    ref={searchInputRef}
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                    }}
                    onClick={() => {
                      setSearchTerm("");
                      setSelectedProgram("");
                      setIsOpen(true);
                    }}
                    placeholder="Select Program"
                    className="h-12 p-2 w-full border-2 border-gray-300 rounded-lg bg-transparent text-gray-900 placeholder-gray-500 dark:placeholder-gray-300 dark:text-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 dark:focus:border-blue-300 dark:focus:ring-blue-500 transition duration-200 ease-in-out sm:text-sm"
                  />
                </div>
                {isOpen && !selectedItem && (
                  <div className="absolute top-full left-0 w-full mt-2 bg-white border rounded shadow-lg z-10">
                    <ul className="max-h-48 overflow-y-auto">
                      {filteredOptions?.map((option: any, index: any) => (
                        <li
                          key={index}
                          onClick={() => {
                            setSelectedProgram(option.id);
                            setIsOpen(false);
                            setSearchTerm(
                              `${option.name} | ${option.category?.name}`
                            );
                          }}
                          className="px-4 py-2 cursor-pointer hover:bg-gray-500 hover:text-white"
                        >
                          {`${option.name} | ${option.category?.name}`}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>

              <Private
                permissions={["create-topic-selection"]}
                element={
                  <div className="flex-grow hidden sm:block">
                    <select
                      required
                      value={selectedInstitution}
                      onChange={(e) => {
                        setSelectedInstitution(e.target.value);
                        setIsLoading(true);
                      }}
                      className="h-12 p-2 w-full border-2 border-gray-300 rounded-lg bg-transparent text-gray-900 placeholder-gray-500 dark:placeholder-gray-300 dark:text-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 dark:focus:border-blue-300 dark:focus:ring-blue-500 transition duration-200 ease-in-out sm:text-sm"
                    >
                      <option value="">Select Institution</option>
                      {institutionData?.institutions?.map(
                        (institution: any) => (
                          <option key={institution.id} value={institution.id}>
                            {institution.name}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                }
              />
              <Private
                permissions={["create-topic-selection"]}
                element={
                  <div className="flex-grow hidden sm:block">
                    <select
                      required
                      value={selectedGroup}
                      onChange={(e) => {
                        setSelectedGroup(e.target.value);
                        setIsLoading(true);
                      }}
                      className="h-12 p-2 w-full border-2 border-gray-300 rounded-lg bg-transparent text-gray-900 placeholder-gray-500 dark:placeholder-gray-300 dark:text-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 dark:focus:border-blue-300 dark:focus:ring-blue-500 transition duration-200 ease-in-out sm:text-sm"
                    >
                      <option value="">Select Group</option>
                      {groupData?.groups?.map((group: any) => (
                        <option key={group.id} value={group.id}>
                          {group.name}
                        </option>
                      ))}
                    </select>
                  </div>
                }
              />
              {(!user?.institution || (user?.institution && !isAll)) && (
                <div className="flex-grow hidden sm:block">
                  <select
                    required
                    value={selectedStatus}
                    onChange={(e) => {
                      const value = e.target.value;
                      setSelectedStatus(value);
                      setIsLoading(true);
                      refetch();
                    }}
                    className="h-12 p-2 w-full border-2 border-gray-300 rounded-lg bg-transparent text-gray-900 placeholder-gray-500 dark:placeholder-gray-300 dark:text-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 dark:focus:border-blue-300 dark:focus:ring-blue-500 transition duration-200 ease-in-out sm:text-sm"
                  >
                    <option value="">Select Status</option>
                    <option value="APPROVED">Approved</option>
                    <option value="REJECTED">Rejected</option>
                    <option value="PENDING">Pending</option>
                  </select>
                </div>
              )}
              <Private
                permissions={["show-candidates"]}
                element={
                  <button
                    onClick={handleDownload}
                    disabled={downloadLoading}
                    className={`btn  inline-flex items-center justify-center text-sm font-medium   ${
                      downloadLoading
                        ? "bg-gray-400 text-gray-500 cursor-not-allowed"
                        : "bg-green-300 text-green-700 hover:text-white hover:bg-green-500"
                    } `}
                  >
                    {downloadLoading ? (
                      <i className="mgc_loading_4_line text-xl animate-spin"></i>
                    ) : (
                      <i className="mgc_arrow_down_circle_line text-xl"></i>
                    )}{" "}
                  </button>
                }
              />
            </div>

            {loading || isLoading ? (
              <div className="grid place-items-center">
                <button
                  type="button"
                  className="btn bg-transparent border-gray-300 dark:border-gray-700"
                >
                  <i className="mgc_loading_4_line me-2 animate-spin"></i>
                  <span>Loading</span>
                </button>
              </div>
            ) : (
              <div>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                  {itemRegistrations.map((item) => {
                    return (
                      <ProgramCard
                        key={item.id}
                        item={item}
                        onEdit={() => handleEdit(item)}
                        onDelete={() => handleDelete(item.id)}
                        refetch={refetch}
                        isDropdownOpen={openDropdownId === item.id}
                        toggleDropdown={toggleDropdown}
                      />
                    );
                  })}
                </div>
                {hasMore && (
                  <div className="flex justify-center">
                    <button
                      type="button"
                      className="mt-10 mb-10 btn bg-transparent border-gray-300 dark:border-gray-700"
                      onClick={async () => {
                        setIsLoadingMore(true);
                        (await hasMore) && (await loadMore());
                        setIsLoadingMore(false);
                      }}
                    >
                      {IsLoadingMore && (
                        <i className="mgc_loading_4_line me-2 animate-spin"></i>
                      )}
                      <span>{IsLoadingMore ? "Loading..." : "Load More"}</span>
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ItemReg;
