import { useQuery } from "@apollo/client";
import PageTitle from "../../../components/PageTitle";
import TabComponent from "../../../components/UIElements/Tab";
import Skeleton from "../../../components/UIElements/Skeleton";
import { GET_GROUPS } from "../../../graphql/queries/group";
import MarkEntryDetails from "./MarkEntryDetails";
import { GET_PROGRAM_BY_ID } from "../../../graphql/queries/program";
import { useParams } from "react-router-dom";

interface group {
  id: string;
  name: string;
}

function MarkEntry() {
  const { data, loading, error } = useQuery(GET_GROUPS);

  const { id: programId } = useParams();

  const {
    data: program,
    loading: programLoading,
    error: programError,
    refetch,    
  } = useQuery(GET_PROGRAM_BY_ID, {
    variables: {
      id: programId,
    },
  });

  // Define tabs based on the fetched data
  const tabs = program?.program?.isGroupwiseResult
    ? data?.groups?.map((group: group, index: number) => ({
        id: group.id,
        label: group.name,
        content: (
          <MarkEntryDetails groupId={group.id} program={program?.program} index={index} refetch={refetch}/>
        ),
      })) || []
    : [
        {
          id: "default",
          label: "Mark Entry",
          content: <MarkEntryDetails program={program?.program} index={0}  refetch={refetch}/>,
        },
      ];

  return (
    <>
      <PageTitle pagetitle="Mark Entry" subtitle="Elimination" />
      {(loading || programLoading) && <Skeleton />}
      {(error || programError) && (
        <p>Failed to load Group {error?.message || programError?.message}</p>
      )}
      {!loading && !error && !programError && !programLoading && (
        <TabComponent tabs={tabs} />
      )}
    </>
  );
}

export default MarkEntry;
