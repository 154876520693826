import React, { useEffect, useState } from "react";

type LayoutConfig = {
  width: string;
  position: string;
};

export type Config = {
  direction: string;
  theme: string;
  layout: LayoutConfig;
  topbar: { color: string };
  menu: { color: string };
  sidenav: { view: string };
};

const ThemeConfig: React.FC = () => {
  const [config, setConfig] = useState<Config>({
    direction: "ltr",
    theme: "light",
    layout: {
      width: "default",
      position: "fixed",
    },
    topbar: {
      color: "light",
    },
    menu: {
      color: "light",
    },
    sidenav: {
      view: "default",
    },
  });

  useEffect(() => {
    const savedConfig = sessionStorage.getItem("__CONFIG__");
    const html = document.documentElement;
    savedConfig
      ? ""
      : sessionStorage.setItem("__CONFIG__", JSON.stringify(config));

    let updatedConfig: Config = { ...config };

    updatedConfig.direction = html.getAttribute("dir") || config.direction;
    updatedConfig.theme = html.getAttribute("data-mode") || config.theme;
    updatedConfig.layout.width =
      html.getAttribute("data-layout-width") || config.layout.width;
    updatedConfig.layout.position =
      html.getAttribute("data-layout-position") || config.layout.position;
    updatedConfig.topbar.color =
      html.getAttribute("data-topbar-color") || config.topbar.color;
    updatedConfig.menu.color =
      html.getAttribute("data-menu-color") || config.menu.color;
    updatedConfig.sidenav.view =
      html.getAttribute("data-sidenav-view") || config.sidenav.view;

    if (savedConfig) {
      updatedConfig = { ...updatedConfig, ...JSON.parse(savedConfig) };
    }

    setConfig(updatedConfig);
    applyConfigToHtml(updatedConfig);

    // Adjust sidenav view based on window width
    const handleResize = () => {
      const isMobile = window.innerWidth <= 1140;
      html.setAttribute(
        "data-sidenav-view",
        isMobile ? "mobile" : updatedConfig.sidenav.view
      );
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const applyConfigToHtml = (config: Config) => {
    const html = document.documentElement;

    html.setAttribute("dir", config.direction);
    html.setAttribute("data-mode", config.theme);
    html.setAttribute("data-layout-width", config.layout.width);
    html.setAttribute("data-layout-position", config.layout.position);
    html.setAttribute("data-topbar-color", config.topbar.color);
    html.setAttribute("data-menu-color", config.menu.color);
    html.setAttribute("data-sidenav-view", config.sidenav.view);
  };

  return null;
};

export default ThemeConfig;
