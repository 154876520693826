import React from 'react';

interface cardProps {
    data: any
}

const MoreDetails: React.FC<cardProps> = ({ data }) => {
    return (
        <>
            <div className="flex items-center mb-4">
                <i className="mgc_mail_line mr-2"></i>
                <div>
                    <p className="text-sm font-semibold">Email</p>
                    <p className="text-xs text-purple-500">{data.email}</p>
                </div>
            </div>
            <div className="flex items-center">
                <i className="mgc_building_1_line mr-2"></i>
                <div>
                    <p className="text-sm font-semibold">Place</p>
                    <p className="text-xs text-purple-500">{data.place}</p>
                </div>
            </div>
        </ >
    );
};

export default MoreDetails;
