import { gql } from "@apollo/client";

export const GET_GROUPS = gql`
  query Groups {
    groups {
      id
      name
      section {
        id
        name
      }
    }
  }
`;