import { gql } from "@apollo/client";

export const GET_GROUPS = gql`
  query Groups {
    groups {
      id
      name
      section {
        id
        name
      }
    }
  }
`;

export const GET_GROUP_BY_ID = gql`
  query Group($id: ID!) {
    group(id: $id) {
      id
      name
      section {
        id
        name
      }
    }
  }
`;
