import React from "react";

interface InputTextProps {
  label: string;
  id: string;
  value: string;
  required?: boolean;
  className?: string;
  disabled?: boolean;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputText: React.FC<InputTextProps> = ({
  label,
  id,
  value,
  required,
  className,
  handleInputChange,
  disabled,
}) => {
  return (
    <div className={className}>
      <label htmlFor={id} className="block text-gray-700 text-sm mb-1">
        {label}
      </label>
      <input
        type="text"
        id={id}
        name={id}
        value={value}
        required={required}
        onChange={handleInputChange}
        disabled={disabled}
        className="w-full p-2 border border-gray-300 rounded-md"
      />
    </div>
  );
};

export default InputText;
