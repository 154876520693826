import { gql } from "@apollo/client";

export const GET_PARTICIPATIONS = gql`
  query GetParticipations {
    participations {
      id
      candidate {
        id
        name
        photo
        chestNo
        dob
        email
        admissionNo
        class {
          name
        }
        gender
        category {
          name
        }
      }
      program {
        id
        name
        isItemRegistrable
        category {
          id
          name
        }
        programCategory {
          id
          name
        }
        skill {
          id
          name
        }
        mode
        programCode
      }
      institution {
        id
        name
        shortName
        groupId {
          id
          name
        }
        cluster {
          id
          name
        }
      }

      codeLetter
      is_selected
      position {
        id
        name
      }
      grade {
        id
        name
      }
      deletedAt
    }
  }
`;

export const GET_REGISTRATION_PARTICIPATIONS = gql`
  query GetitemParticipations {
    ItemRegistrableParticipations {
      id
      candidate {
        id
        name
        photo
        chestNo
      }
      program {
        id
        name
        isItemRegistrable
        category {
          id
          name
        }
        programCode
      }
      institution {
        id
        name
      }
    }
  }
`;

export const GET_FINAL_REGISTRATION_PARTICIPATIONS = gql`
  query FinalItemRegistrableParticipations {
    finalItemRegistrableParticipations {
      id
      candidate {
        id
        name
        photo
        chestNo
      }
      program {
        id
        name
        isItemRegistrable
        category {
          id
          name
        }
        programCode
      }
      institution {
        id
        name
      }
    }
  }
`;

export const GET_INSTITUTION_PARTICIPANTS = gql`
  query GetInstitutionParticipants($programId: ID!, $institutionId: ID!) {
    institutionParticipants(
      programId: $programId
      institutionId: $institutionId
    ) {
      id
      candidate {
        id
        name
        photo
        admissionNo
        chestNo
        category {
          id
          name
        }
        class {
          id
          name
        }
        institution {
          id
          name
        }
      }
      program {
        id
        name
      }
      institution {
        id
        name
      }

      codeLetter
      is_selected
      deletedAt
    }
  }
`;

export const GET_PROGRAM_PARTICIPANTS = gql`
  query GetProgramParticipants($programId: ID!, $groupId: ID, $clusterId: ID) {
    programParticipants(
      programId: $programId
      groupId: $groupId
      clusterId: $clusterId
    ) {
      id
      codeLetter
      candidate {
        id
        name
        photo
        admissionNo
        chestNo
        gender
        dob
        email
        category {
          id
          name
        }
        class {
          id
          name
        }
        institution {
          id
          name
          shortName
        }
      }
      program {
        id
        name
        programCode
        eliminationDate {
          key
          value
        }
        eliminationEndingTime {
          key
          value
        }
        eliminationStartingTime {
          key
          value
        }
        eliminationVenue {
          key
          value {
            id
            name
            place
            host {
              id
              name
              place
            }
          }
        }
      }
      institution {
        id
        name
        shortName
        cluster {
          id
          name
        }
        groupId {
          id
          name
        }
      }
      codeLetter
      is_selected
      isPresent
      deletedAt
    }
  }
`;

export const GET_PARTICIPANTS_BY_ID = gql`
  query Participation($id: ID!) {
    participation(id: $id) {
      id
      candidate {
        id
        name
        photo
        admissionNo
        chestNo
        category {
          id
          name
        }
        class {
          id
          name
        }
        institution {
          id
          name
        }
      }
      program {
        id
        name
      }
      institution {
        id
        name
      }
      isRegistered
      codeLetter
      is_selected
      deletedAt
    }
  }
`;

export const SELECTED_PARTICIPATION = gql`
  query SelectedParticipations($input: SelectedParticipationsInput!) {
    selectedParticipations(input: $input) {
      id
      candidate {
        name
        chestNo
        id
        category {
          name
          id
        }
      }
      institution {
        id
        shortName
        name
        groupId {
          name
          id
        }
      }
      is_selected
      eliminationGrade {
        id
        name
        percentage
      }
    }
  }
`;

export const SELECTED_PARTICIPATION_FROM_MANY_PROGRAM = gql`
  query SelectedParticipationsFromManyProgram(
    $input: SelectedParticipationsFromManyProgramInput!
  ) {
    selectedParticipationsFromManyProgram(input: $input) {
      program {
        programCode
        name
        category {
          name
        }
      }
      selectedParticipations {
        id
        eliminationGrade {
          id
          name
          percentage
        }
        is_selected
        candidate {
          name
          chestNo
          id
          category {
            name
            id
          }
        }
        institution {
          id
          shortName
          name
          groupId {
            name
            id
          }
        }
      }
    }
  }
`;

export const GET_CANDIDATE_PARTICIPANTIONS = gql`
  query CandidateParticipants($candidateId: ID!) {
    candidateParticipants(candidateId: $candidateId) {
      codeLetter
      isPresent
      candidate {
        id
        name
        chestNo
        dob
        category {
          id
          name
        }
      }
      institution {
        id
        name
        groupId {
          id
          name
        }
      }
      program {
        id
        name
        programCode
        programCategory {
          id
          name
        }
        skill {
          id
          name
        }
        eliminationRegistrationStatus {
          key
          value
        }
      }
      is_selected
    }
  }
`;
