
import { gql } from "@apollo/client";

export const GET_NEWS = gql`
query News {
    news {
        id
        title
        description
        date
        photo
        time
        status
        description
    }
}
`;
