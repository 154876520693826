import React, { useEffect, useState } from "react";
import RoleInput from "./RoleInput";
import GroupInput from "./GroupInput";
import SectionInput from "./SectionInput";
import CategoryInput from "./CategoryInput";
import InstitutionInput from "./InstitutionInput";
import { CREATE_USER, UPDATE_USER } from "../../graphql/mutations/admin";
import { useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import HostInput from "./HostInput";
import ClusterInput from "./ClusterInput";
import DebateInput from "./DebateInput";

interface AddAdminProps {
  editingAdmin?: {
    id: string;
    username: string;
    role: {
      id: string;
      name: string;
      section: boolean;
      group: boolean;
      category: boolean;
      institution: boolean;
      host: boolean;
      cluster: boolean;
      debate: boolean;
    };
    institution: {
      id: string;
      name: string;
    };
    category: {
      id: string;
      name: string;
    };
    section: {
      id: string;
      name: string;
    };
    group: {
      id: string;
      name: string;
    };
    host: {
      id: string;
      name: string;
    };
    cluster: {
      id: string;
      name: string;
    };
    debate: {
      id: string;
      name: string;
    };
  };
  onSaved: () => void;
}

const AddAdmin: React.FC<AddAdminProps> = ({ editingAdmin, onSaved }) => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [role, setRole] = useState<string>("");
  const [institution, setInstitution] = useState<boolean>(false);
  const [selectedInstitution, setSelectedInstitution] = useState<string>("");
  const [category, setCategory] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [section, setSection] = useState<boolean>(false);
  const [selectedSection, setSelectedSection] = useState<string>("");
  const [group, setGroup] = useState<boolean>(false);
  const [selectedGroup, setSelectedGroup] = useState<string>("");
  const [host, setHost] = useState<boolean>(false);
  const [selectedHost, setSelectedHost] = useState<string>("");
  const [cluster, setCluster] = useState<boolean>(false);
  const [selectedCluster, setSelectedCluster] = useState<string>("");
  const [debate, setDebate] = useState<boolean>(false);
  const [selectedDebate, setSelectedDebate] = useState<string>("");

  const [Register, { loading: registerLoading }] = useMutation(CREATE_USER, {
    onCompleted: () => onSaved(),
  });
  const [updateUser, { loading: updateLoading }] = useMutation(UPDATE_USER, {
    onCompleted: () => onSaved(),
  });

  useEffect(() => {
    if (editingAdmin) {
      setUsername(editingAdmin.username);
      setRole(editingAdmin.role.id);
      setSection(editingAdmin.role.section);
      setSelectedSection(editingAdmin.section ? editingAdmin.section.id : "");
      setGroup(editingAdmin.role.group);
      setSelectedGroup(editingAdmin.group ? editingAdmin.group.id : "");
      setCategory(editingAdmin.role.category);
      setSelectedCategory(
        editingAdmin.category ? editingAdmin.category.id : ""
      );
      setInstitution(editingAdmin.role.institution);
      setSelectedInstitution(
        editingAdmin.institution ? editingAdmin.institution.id : ""
      );
      setHost(editingAdmin.role.host);
      setSelectedHost(editingAdmin.host ? editingAdmin.host.id : "");
      setCluster(editingAdmin.role.cluster);
      setSelectedCluster(editingAdmin.cluster ? editingAdmin.cluster.id : "");
      setDebate(editingAdmin.role.debate);
      setSelectedDebate(editingAdmin.debate ? editingAdmin.debate.id : "");
    }
  }, [editingAdmin]);

  const clearInput = () => {
    setUsername("");
    setPassword("");
    setRole("");
    setSection(false);
    setSelectedSection("");
    setGroup(false);
    setSelectedGroup("");
    setCategory(false);
    setSelectedCategory("");
    setInstitution(false);
    setSelectedInstitution("");
    setHost(false);
    setSelectedHost("");
    setCluster(false);
    setSelectedCluster("");
    setDebate(false);
    setSelectedDebate("");
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const variables = {
      username,
      password: editingAdmin ? undefined : password,
      role,
      section: selectedSection || null,
      group: selectedGroup || null,
      category: selectedCategory || null,
      institution: selectedInstitution || null,
      host: selectedHost || null,
      cluster: selectedCluster || null,
      debate: selectedDebate || null,
    };

    if (editingAdmin) {
      try {
        await updateUser({ variables: { id: editingAdmin.id, ...variables } });
        toast.success("Admin updated!");
        clearInput();
      } catch (error) {
        console.error("Failed to update Admin:", error);
        toast.error("Failed to update Admin.");
      }
    } else {
      try {
        await Register({ variables });
        toast.success("Admin created!");
        clearInput();
      } catch (error) {
        console.error("Failed to create Admin:", error);
        toast.error("Failed to create Admin.");
      }
    }
  };

  return (
    <div className="card">
      <div className="card-header">
        <div className="flex justify-between items-center">
          <h4 className="card-title">
            {editingAdmin ? "Edit Admin" : "Add Admin"}
          </h4>
        </div>
      </div>
      <div className="p-6">
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label
              htmlFor="exampleInputUsername"
              className="text-gray-800 text-sm font-medium inline-block mb-2"
            >
              Username
            </label>
            <input
              type="text"
              className="form-input"
              id="exampleInputUsername"
              placeholder="Enter Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>

          {!editingAdmin && (
            <div className="mb-3">
              <label
                htmlFor="exampleInputPassword"
                className="text-gray-800 text-sm font-medium inline-block mb-2"
              >
                Password
              </label>
              <input
                type="text"
                className="form-input"
                id="exampleInputPassword"
                placeholder="Enter Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
          )}
          <RoleInput
            role={role}
            setRole={setRole}
            group={setGroup}
            institution={setInstitution}
            category={setCategory}
            section={setSection}
            host={setHost}
            cluster={setCluster}
            debate={setDebate}
          />
          {section && (
            <SectionInput
              section={selectedSection}
              setSection={setSelectedSection}
            />
          )}
          {group && (
            <GroupInput group={selectedGroup} setGroup={setSelectedGroup} />
          )}
          {category && (
            <CategoryInput
              category={selectedCategory}
              setCategory={setSelectedCategory}
            />
          )}
          {institution && (
            <InstitutionInput
              institution={selectedInstitution}
              setInstitution={setSelectedInstitution}
            />
          )}

          {host && <HostInput host={selectedHost} setHost={setSelectedHost} />}
          {cluster && (
            <ClusterInput
              cluster={selectedCluster}
              setCluster={setSelectedCluster}
            />
          )}
          {debate && (
            <DebateInput
              debate={selectedDebate}
              setDebate={setSelectedDebate}
            />
          )}
          <button
            type="submit"
            className="btn bg-primary text-white mb-6 float-right"
            disabled={editingAdmin ? updateLoading : registerLoading}
          >
            {editingAdmin
              ? updateLoading
                ? "Updating..."
                : "Update"
              : registerLoading
              ? "Adding..."
              : "Submit"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddAdmin;
