import { gql } from "@apollo/client";

export const CREATE_CLASS = gql`
  mutation CreateClass($name: String!, $section: ID!, $category: ID!) {
    createClass(input: { name: $name, section: $section, category: $category }) {
      id
      name
    }
  }
`;

export const UPDATE_CLASS = gql`
  mutation UpdateClass($id: ID!, $name: String!, $section: ID!) {
    updateClass(id: $id, input: { name: $name, section: $section }) {
      id
      name
    }
  }
`;

export const DELETE_CLASS = gql`
  mutation DeleteClass($id: ID!) {
    deleteClass(id: $id)
  }
`;
