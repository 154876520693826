import React from "react";
import AddButton from "./AddButton";

interface CardStructureProps<T> {
    Heading: string;
    addButton: string;
    addButtonOnCLick: () => void;
    data: T[];
    renderCard: (item: T) => React.ReactNode;
}

const CardStructure = <T,>({
    Heading,
    addButton,
    addButtonOnCLick,
    data = [],
    renderCard,
}: CardStructureProps<T>) => {
    // const renderCard = 

    return (
        <div className="bg-white p-4 shadow rounded-lg h-fit-content overflow-hidden">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-base">{Heading}</h2>
                <AddButton text={addButton} onClick={addButtonOnCLick} />
            </div>

            <div className="space-y-4 bg-gray-100 h-[350px] rounded-xl no-scrollbar overflow-auto px-2 py-4">
                {data.map((group, index) => (
                    <div key={index}>{renderCard(group)}</div>
                ))}
            </div>

            {/* <div className="apex-charts my-8">
                <ReactApexChart
                    id="Groups"
                    options={chartOptions}
                    series={chartSeries}
                    type="donut"
                    height={350}
                />
            </div> */}
        </div>
    );
};

export default CardStructure;
