import React, { useEffect, useRef, useState } from "react";
import Modal from "../UIElements/Model";
import { useMutation } from "@apollo/client";
import { UPDATE_ITEM_REGISTRATION } from "../../graphql/mutations/itemReg";
import toast from "react-hot-toast";
import Private from "../../context/Private";
import { useAuth } from "../../context/AuthContext";

interface ProgramCardProps {
    item: {
        id: string;
        title: string;
        description: string;
        program: {
            id: string;
            name: string;
            category: {
                name: string;
                id: string;
            };
            programCode: string;
        };
        participation: {
            id: string;
            // candidate: {
            //     name:string;
            //     id:string;
            // }
        };
        institution: {
            id: string;
            name: string;
            groupId: {
                id: string;
                name: string;
            };
        };
        file: string;
        link: string;
        status: string;
        remarks: string;
        createdAt: string;
    };
    onEdit: () => void;
    onDelete: (id: string) => void;
    refetch: () => void;
    isDropdownOpen: boolean;
    toggleDropdown: (id: string) => void;
}

const ProgramCard: React.FC<ProgramCardProps> = ({
    item,
    onEdit,
    onDelete,
    refetch,
    isDropdownOpen,
    toggleDropdown,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const actionModelRef = useRef<HTMLDivElement>(null);

    const getStatusColor = (status: string) => {
        switch (status) {
            case "PENDING":
                return "bg-yellow-200 text-yellow-600";
            case "APPROVED":
                return "bg-green-200 text-green-600";
            case "REJECTED":
                return "bg-red-200 text-red-600";
            default:
                return "bg-gray-200 text--gray-600";
        }
    };
    const truncate = (input: string) =>
        input?.length > 40 ? `${input.substring(0, 60)}...` : input;

    // create a toggle state
    const [showTruncate] = useState(true);

    const [remarks, setRemarks] = useState("");
    // const [ setAction] = useState(''); // Can be 'approve' or 'reject'

    const { user } = useAuth();

    const [updateItemReg] = useMutation(UPDATE_ITEM_REGISTRATION);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (
                actionModelRef.current &&
                !actionModelRef.current.contains(event.target as Node)
            ) {
                toggleDropdown(item.id);
            }
        }

        if (isDropdownOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isDropdownOpen]);

    // Handles input for remarks
    const handleRemarksChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRemarks(e.target.value);
    };

    // Handle Approve Action
    const handleApprove = () => {
        // setAction('approve');
        setRemarks(""); // Clear remarks if any
        handleSubmit("APPROVED", "");
    };

    // Handle Reject Action
    const handleReject = () => {
        if (remarks.trim() === "") {
            toast.error("Please provide remarks for rejection");
            return;
        }
        // setAction('reject');
        handleSubmit("REJECTED", remarks);
    };

    // Submit function to pass to parent component
    const handleSubmit = async (status: string, remarks: string) => {
        try {
            await updateItemReg({
                variables: {
                    id: item.id,
                    input: {
                        program: item.program.id,
                        title: item.title,
                        description: item.description,
                        file: item.file,
                        link: item.link,
                        participation: item.participation.id,
                        institution: item.institution.id,
                        status,
                        remarks: remarks || null,
                    },
                },
            });

            toast.success(
                `Item ${status === "APPROVED" ? "approved" : "rejected"} successfully`
            );
            refetch(); // Refetch the data after updating
            setIsOpen(false); // Close modal
        } catch (error) {
            console.error("Error updating item:", error);
            toast.error("Error updating item");
        }
    };
    const handleSave = () => {
        setIsOpen(false);
    };

    return (
        <div className="bg-white shadow-md rounded-lg flex flex-col justify-between">
            <div className="bg-gray-600 p-4 rounded-t-lg flex items-center justify-between">
                <div>
                    <h3 className="text-white text-xs font-semibold">
                        {item?.program?.name.toLocaleUpperCase()}
                    </h3>
                    <p className="text-gray-300 text-xs">
                        {item?.program?.category?.name.toLocaleUpperCase()}
                    </p>
                </div>

                <div className="flex justify-end flex-1 relative">
                    {user?.institution?.id ? (
                        user?.institution?.id === item.institution?.id && (
                            <i
                                className="mgc_more_2_fill text-xl text-white cursor-pointer px-2 py-1 bg-transparent rounded-full"
                                onClick={() => toggleDropdown(item?.id)} // Toggle only the dropdown for this card
                            ></i>
                        )
                    ) : (
                        <i
                            className="mgc_more_2_fill text-xl text-white cursor-pointer px-2 py-1 bg-transparent rounded-full"
                            onClick={() => toggleDropdown(item?.id)} // Toggle only the dropdown for this card
                        ></i>
                    )}
                    {isDropdownOpen && (
                        <div
                            ref={actionModelRef}
                            className="transition-opacity duration-300 mt-2 bg-white shadow-lg border rounded-lg p-2 border-gray-200 dark:border-gray-700 dark:bg-gray-800 absolute top-10 right-3 w-32 z-10"
                        >
                            <ul>
                                <li>
                                    <button
                                        onClick={() => {
                                            setIsOpen(true);
                                            toggleDropdown(item.id);
                                        }}
                                        className="block w-full px-4 py-2 text-left hover:bg-gray-100"
                                    >
                                        View
                                    </button>
                                </li>
                                <Private
                                    permissions={["update-item-registration"]}
                                    element={
                                        // (item?.status === "PENDING" &&
                                        //     item?.institution?.id === user?.institution?.id) ||
                                        //     !user?.institution ? (
                                        <li>
                                            <button
                                                onClick={onEdit}
                                                className="block w-full px-4 py-2 text-left hover:bg-gray-100"
                                            >
                                                Edit
                                            </button>
                                        </li>}
                                // ) : null}
                                />
                                <Private
                                    permissions={["delete-item-registration"]}
                                    element={
                                        /* {(item?.status === "PENDING" &&
                                            item?.institution?.id === user?.institution?.id) ||
                                            !user?.institution
                                            ? item.program.programCode !== "TW21" &&
                                            item.program.programCode !== "AW18" && ( */
                                        <li>
                                            <button
                                                onClick={() => onDelete(item?.id)}
                                                className="w-full px-4 py-2 text-left text-red-500 hover:bg-gray-100"
                                            >
                                                Delete
                                            </button>
                                        </li>}
                                /* )
                                // : null} */
                                />
                            </ul>
                        </div>
                    )}
                </div>
            </div>

            <div className="p-4 flex flex-col justify-between flex-1">
                <p className="text-white bg-red-500 py-1 px-2 text-xs rounded-md w-fit capitalize">
                    {item?.institution?.groupId?.name?.toLowerCase()}
                </p>
                <div className="flex justify-between flex-1 flex-wrap py-2">
                    {/* <p className='limit-line'>{item.itemTitle}</p> */}
                    <p className="text-xl">
                        {showTruncate ? truncate(item?.title) : item?.title}
                    </p>
                    {/* <button className={`bg-gray-400 text-white px-1 rounded-md text-sm max-h-5 block ${showTruncate ? '' : 'absolute bottom-0 right-0'}`} onClick={() => setShowTruncate(!showTruncate)}>{showTruncate ? <i className='mgc_down_fill'></i> : <i className='mgc_up_fill'></i>}</button> */}
                </div>
                {/* <div className="flex justify-between items-center mt-2"> */}
                <Private
                    permissions={["administration"]}
                    element={
                        <span className="text-gray-500 text-xs">
                            {item?.institution?.name}
                        </span>
                    }
                />
                {user?.institution &&
                    user?.institution?.id === item?.institution?.id && (
                        <span className="text-gray-500 text-xs">
                            {item?.institution?.name}
                        </span>
                    )}

                {/* </div> */}

                {/* Edit and Delete Buttons */}
                {(!user?.institution || (user?.institution &&
                    user?.institution?.id === item?.institution?.id)) &&
                    <div className="flex justify-between mt-4 flex-wrap items-center">
                    <span
                        className={`py-1 rounded-full text-[10px] text-red-500 text-center font-semibold`}
                    >
                        {new Date(item?.createdAt).toLocaleDateString("en-US", {
                            day: "2-digit",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                            hour12: true,
                            weekday: "short",
                        })}
                    </span>
                    <span
                        className={`ml-2 px-2 py-1 inline-flex gap-1 rounded-full text-[10px] text-center font-semibold  ${getStatusColor(
                            item?.status
                        )}`}
                    >
                        {item?.status} 
                        {item?.status === "REJECTED" && 
                            <div className="relative flex items-center justify-center group">
         
                            <button className="bg-red-500 text-white font-bold w-3 h-3 rounded-full text-xs flex items-center justify-center">
                              i
                            </button>
                        
   
                            <span className="absolute bottom-8 left-1/2 transform -translate-x-1/2 w-40 bg-gray-800 text-white text-xs text-center p-2 rounded-md hidden group-hover:block transition-opacity duration-200">
                              {item?.remarks}
                            </span>
                          </div>
                        }
                    </span>
                    <div className="flex justify-end flex-1 relative">

                        {/* <button
                            onClick={() => setIsOpen(true)}
                            className="text-lg pt-0.5 px-1.5 transition-colors duration-200 border-transparent hover:bg-gray-500  hover:bg-opacity-10  text-gray-500 rounded hover:border-gray-500 border"
                        >
                            <i className='mgc_eye_line'></i>
                        </button>
                        {(item?.status === "PENDING" && item?.institution?.id === user?.institution?.id) || !user?.institution ? (
                            <div>
                                <button
                                    onClick={onEdit}
                                    className="text-lg pt-0.5 px-1.5 transition-colors duration-200 border-transparent hover:bg-blue-500 hover:bg-opacity-10 text-blue-500 rounded hover:border-blue-500 border"
                                >
                                    <i className="mgc_edit_line"></i>
                                </button>
                                <button
                                    onClick={() => onDelete(item?.id)}
                                    className="text-lg pt-0.5 px-1.5 transition-colors duration-200 border-transparent hover:bg-red-500 hover:bg-opacity-10 text-red-500 rounded hover:border-red-500 border"
                                >
                                    <i className="mgc_delete_line"></i>
                                </button>
                            </div>
                        ) : null} */}


                    </div>
                </div>}
            </div>

            <Modal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                title={`${item?.program?.name?.toUpperCase()} | ${item?.program?.category?.name.toUpperCase()}`}
                handleSubmit={handleSave}
            >
                <div className="flex flex-col items-center px-2 text-gray-700">
                    {/* <div className="m-2 w-full h-10 border-0 border-b-2 border-gray-400 flex justify-between items-center p-0.5">
                        <h3 className='font-bold text-lg text-black tracking-tight'>{item.program.name.toUpperCase()}</h3>
                        <h3 className='font-medium text-lg tracking-tight'>Category: {item.program.category.name.toUpperCase()}</h3>
                    </div> */}

                    <div className="mb-1 w-full h-10 flex justify-between items-center p-2">
                        <div className="flex justify-start items-center">
                            {/* <img src="" alt="" className="w-8 h-8 rounded-full" /> */}
                            <div className="flex flex-col justify-between items-start m-2">
                                {/* <h6 className="text-sm font-semibold ">{item?.participation?.id}</h6> */}
                                <Private
                                    permissions={["administration"]}
                                    element={
                                        <p className="text-xs ">{item?.institution?.name}</p>
                                    }
                                />
                            </div>
                        </div>
                        <div className="div">
                            <span className="text-lg ">Status: </span>
                            <button
                                className={`px-2 py-1 rounded-lg text-center font-semibold ${getStatusColor(
                                    item.status
                                )}`}
                                disabled
                            >
                                {item?.status}
                            </button>
                        </div>
                    </div>
                    <div className="w-full p-2">
                        <div className="w-full inline-flex items-center justify-between mb-3 h-5">
                            <label htmlFor="" className="text-gray-600 inline-block">
                                Name / Topic / <span className="italic">Kithab</span>
                            </label>
                            <div className="inline-flex items-center">
                                <a
                                    target="_blank"
                                    href={item?.file}
                                    className="px-2 py-1 m-1 rounded-md float-right bg-gray-300 border hover:border-gray-500"
                                >
                                    <i className="mgc_file_line"></i>
                                </a>
                                <a
                                    target="_blank"
                                    href={item?.link}
                                    className="px-2 py-1 m-1 rounded-md float-right bg-gray-300 border hover:border-gray-500"
                                >
                                    <i className="mgc_link_line"></i>
                                </a>
                            </div>
                        </div>
                        <p className="text-justify text-xs p-2 max-h-40 border-2 border-gray-200 rounded-lg overflow-auto shadow-inner shadow-gray-100 ">
                            {item.title}
                        </p>
                    </div>
                    {item?.description && (
                        <div className="w-full p-2">
                            <div className="mb-3 h-5">
                                <label htmlFor="" className="text-gray-600 inline-block">
                                    Description / Lyrics / Author / Context / Portion
                                </label>
                            </div>
                            <p className="text-justify text-xs p-2 max-h-40 border-2 border-gray-200 rounded-lg overflow-auto shadow-inner shadow-gray-100 ">
                                {item.description}
                            </p>
                        </div>
                    )}
                    <div className="w-full h-10 flex flex-col justify-center items-start p-2 mt-7">
                        {item?.remarks && (
                            <div>
                                <label htmlFor="remarks" className=" text-gray-600 mb-3">
                                    Remarks:
                                </label>
                                <p className="w-full text-justify text-xs p-2 max-h-40 border-2 border-gray-200 rounded-lg overflow-auto shadow-inner shadow-gray-100">
                                    {item.remarks}
                                </p>
                            </div>
                        )}
                        <div className="flex justify-between items-center w-full">
                            <Private
                                permissions={["create-topic-selection"]}
                                element={
                                    <input
                                        type="text"
                                        className="text-sm m-1 ml-0 rounded-lg min-w-56 w-full border-2 border-gray-300 overflow-auto p-1 h-9 shadow-inner shadow-gray-100"
                                        value={remarks}
                                        onChange={handleRemarksChange}
                                        // disabled={action === 'approve'} // Disable if approved
                                        placeholder="Remarks"
                                    />
                                }
                            />
                            <Private
                                permissions={["create-topic-selection"]}
                                element={
                                    <div className="flex">
                                        <button
                                            className="border-2 px-3 py-1 rounded-md text-green-600 font-semibold ml-3 border-green-500 bg-green-100 hover:bg-green-500 hover:text-white"
                                            onClick={handleApprove}
                                        >
                                            Approve
                                        </button>
                                        <button
                                            className="border-2 px-3 py-1 rounded-md text-red-600 font-semibold ml-3 border-red-500 bg-red-100 hover:bg-red-500 hover:text-white"
                                            onClick={handleReject}
                                        >
                                            Reject
                                        </button>
                                    </div>
                                }
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default ProgramCard;
