import { gql } from "@apollo/client";

export const CREATE_CATEGORY = gql`
  mutation createCategory(
    $name: String!
    $chest_no_series: String!
    $colorCode: String!
  ) {
    createCategory(
      input: {
        name: $name
        chest_no_series: $chest_no_series
        colorCode: $colorCode
      }
    ) {
      id
      name
      chest_no_series
      colorCode
    }
  }
`;

export const UPDATE_CATEGORY = gql`
  mutation UpdateCategory(
    $id: ID!
    $name: String!
    $chest_no_series: String!
    $colorCode: String!
  ) {
    updateCategory(
      id: $id
      input: {
        name: $name
        chest_no_series: $chest_no_series
        colorCode: $colorCode
      }
    ) {
      id
      name
      chest_no_series
      colorCode
    }
  }
`;

export const DELETE_CATEGORY = gql`
  mutation DeleteCategory($id: ID!) {
    deleteCategory(id: $id)
  }
`;
