import React, { useState } from "react";

interface AddCurbModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (name: string, maximumProgramsCount: number) => void;
}

const AddCurbModal: React.FC<AddCurbModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
}) => {
  const [name, setName] = useState<string>("");
  const [maximumProgramsCount, setMaximumProgramsCount] = useState<
    number | null
  >(null);

  if (!isOpen) return null;

  const clearInput = () => {
    setName("");
    setMaximumProgramsCount(null);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await onSubmit(name, maximumProgramsCount ?? 0);
    clearInput();
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
        <h2 className="text-lg font-semibold mb-4">Add New Curb</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="curbName"
              className="block text-sm font-medium text-gray-700"
            >
              Curb Name
            </label>
            <input
              id="curbName"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="maximumProgramsCount"
              className="block text-sm font-medium text-gray-700"
            >
              Maximum Programs Count
            </label>
            <input
              id="maximumProgramsCount"
              type="number"
              value={maximumProgramsCount ?? ""}
              onChange={(e) =>
                setMaximumProgramsCount(parseInt(e.target.value))
              }
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
              required
            />
          </div>
          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={onClose}
              className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-blue-600 text-white px-4 py-2 rounded-md"
            >
              Add Curb
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddCurbModal;
