import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import Skeleton from "../UIElements/Skeleton";
import DetailsView from "../candidates/DetailsView";
import { GET_PROGRAM_PARTICIPANTS } from "./CardQuery";

interface CardBodyProps {
  concept: string;
  programId: string;
  groupId: string;
  clusterId: string;
}
interface Section {
  id: string;
  name: string;
}

interface Institution {
  id: string;
  name: string;
  shortName: string;
  groupId: {
    id: string;
    name: string;
  };
}

interface Class {
  id: string;
  name: string;
}

interface Category {
  id: string;
  name: string;
  colorCode: string;
}

interface Candidate {
  id: string;
  photo: string;
  chestNo: string;
  name: string;
  section: Section;
  institution: Institution;
  admissionNo: string;
  class: Class;
  category: Category;
  gender: string;
  email: string;
  dob: string;
}

interface Participant {
  id: string;
  candidate: Candidate[];
  institution: {
    id: string;
    name: string;
  };
  program: {
    id: string;
    name: string;
    programCode: string;
  };
}

const truncateText = (text: string, wordLimit: number) => {
  const words = text.split(" ");
  if (words.length > wordLimit) {
    return words.slice(0, wordLimit).join(" ") + "...";
  }
  return text;
};

const CardBody: React.FC<CardBodyProps> = ({ concept, programId, groupId, clusterId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [candidate, setCandidate] = useState<Candidate | null>(null);
  const { data, loading, error } = useQuery(GET_PROGRAM_PARTICIPANTS, {
    variables: { programId, groupId, clusterId },
  });

  const participants: Participant[] = data?.programParticipants || [];
  const candidateArray: Candidate[] = participants.flatMap(
    (participant: Participant) => participant.candidate
  );
  const maxAvatars = 4;
  const displayedCandidate = candidateArray.slice(0, maxAvatars);
  const remainingCount = candidateArray.length - maxAvatars;

  return (
    <div className="flex flex-col">
      <div className="py-3 px-6">
        <p className="text-gray-500 text-sm mb-9 overflow-hidden text-ellipsis whitespace-normal limit-line">
          {truncateText(concept, 50)}
        </p>
        {displayedCandidate.length > 0 && (
          <div className="block text-md font-semibold text-gray-700 mb-2">
            Participants:
            <span className="bg-green-500 text-white px-1 rounded ml-1">
              {candidateArray.length}
            </span>
          </div>
        )}
        {displayedCandidate.length === 1 &&
          displayedCandidate.map((candidate) => (
            <div key={candidate.id} className="flex items-center ">
              {/* Candidate Image */}
              <img
                src={candidate.photo}
                alt={candidate.name}
                className="inline-block h-12 w-12 bg-rose-500 dark:bg-yellow-400 rounded-full border-2 border-white dark:border-gray-700 object-cover"
              />

              {/* Candidate Info */}
              <div className="flex-grow pl-2">
                <div
                  className="text-md font-semibold cursor-pointer"
                  onClick={() => {
                    setIsModalOpen(!isModalOpen);
                    setCandidate(candidate);
                  }}
                >
                  {candidate.chestNo} - {candidate.name}
                </div>
                <div className="text-sm text-gray-500">
                  {candidate.institution.name}
                </div>
              </div>
            </div>
          ))}

        {!loading && !error && displayedCandidate.length > 1 && (
          <div className="flex -space-x-2">
            {displayedCandidate.map((candidate) => (
              <div
                className="relative inline-block h-12 w-12 cursor-pointer"
                key={candidate.id}
                onClick={() => {
                  setIsModalOpen(!isModalOpen);
                  setCandidate(candidate);
                }}
              >
                <img
                  className="h-full w-full bg-rose-500 dark:bg-yellow-400 rounded-full border-2 border-white dark:border-gray-700 object-cover"
                  src={`${candidate?.admissionNo === "001" ? candidate?.photo : `https://haris740.github.io/sibaq-image-uploads/profiles/${candidate?.chestNo[0]}000/${candidate?.chestNo}.png`}`}
                  alt={candidate.name}
                />
                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-full opacity-0 hover:opacity-100 transition-opacity">
                  <i className="mgc_eye_2_line text-2xl text-gray-300"></i>
                </div>
              </div>
            ))}
            {remainingCount > 0 && (
              <div>
                <div className="relative inline-flex">
                  <button className="inline-flex items-center justify-center h-12 w-12 rounded-full bg-gray-200 border-2 border-white font-medium text-gray-700 shadow-sm align-middle dark:bg-gray-700 dark:text-gray-200 dark:border-gray-600 transition-all text-sm">
                    <span className="font-medium leading-none">
                      {maxAvatars}+
                    </span>
                  </button>
                </div>
              </div>
            )}
          </div>
        )}

        {error && (
          <p className="text-red-500 text-sm mt-2">
            Failed to load participants. Please try again later.
          </p>
        )}

        {loading && <Skeleton />}
      </div>
      <DetailsView
        isModalOpen={isModalOpen}
        setModalOpen={setIsModalOpen}
        candidate={candidate}
      />
    </div>
  );
};

export default CardBody;
