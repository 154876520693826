import React from "react";

interface ChatItemProps {
    id: string;
    userId?: {
        id: string;
        username: string;
    };
    time: string;
    isPinned?: boolean;
    isUnread?: boolean;
    typingStatus?: string;
    profileImg: string;
    messages?: MessageProps[];
    handleClick: () => void;
}

interface MessageProps {
    time: string;
    content: string;
    isCurrentUser?: boolean;
}

const ChatItem: React.FC<ChatItemProps> = ({
    // id,
    userId,
    time,
    isUnread,
    typingStatus,
    profileImg,
    messages,
    handleClick,
}) => (
    <div className="flex items-center space-x-2 p-2 hover:bg-gray-100 cursor-pointer" onClick={handleClick}>
        {profileImg 
        ? <img src={profileImg} alt={userId?.username} className="w-12 h-12 rounded-full object-cover" />
        : <div className="w-12 h-12 rounded-full bg-gray-300 flex items-center justify-center">{userId?.username?.[0]?.toUpperCase()}</div>}
        <div className="flex-1 flex-col justify-between">
            <div className="flex justify-between items-center">
                <h3 className="font-semibold">{userId?.username}</h3>
                <span className="text-xs font-light text-gray-500">{time}</span>
            </div>
            <p className="text-sm text-gray-600 line-clamp-1">
                {typingStatus ? <em>{typingStatus}</em> : messages ? messages[messages?.length-1]?.content : "No Messages Yet"}
            </p>
        </div>
        {isUnread && <span className="text-green-500">●</span>}
    </div>
);

export default ChatItem;