import React, { useState } from "react";
import { useAuth } from "../../context/AuthContext";
import Modal from "../UIElements/Model";
import { MARK_NOTIFICATION_AS_READ } from "../../graphql/mutations/notifications";
import { useMutation } from "@apollo/client";

type Notification = {
    id: string;
    title: string;
    description: string;
    type: string;
    user: User;
    role: Role;
    status: Status;
    date: string;
    time: string;
    readBy: string[];
};


interface NotificationItemProps {
    notification: Notification 
    setNotification: React.Dispatch<React.SetStateAction<Notification[]>>
}

type User = {
    // Add user properties here
};

type Role = {
    // Add role properties here
};

type Status = {
    // Add status properties here
};





const NotificationDropdownItem: React.FC<NotificationItemProps> = ({notification, setNotification}) => {
    const [isOpen, setIsOpen] = useState(false);

    const { user } = useAuth()

    const formatRelativeTime = (timestamp: string) => {
        // Parse the stringified timestamp to a number
        const targetDate = new Date(Number(timestamp));

        if (isNaN(targetDate.getTime())) {
            return "Invalid date"; // Handle invalid timestamps
        }

        const now = new Date();
        const diffInMs = now.getTime() - targetDate.getTime();
        const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

        if (diffInDays === 0) return "Today";
        if (diffInDays === 1) return "Yesterday";
        if (diffInDays > 1) return `${diffInDays} days ago`;
        if (diffInDays === -1) return "Tomorrow";
        if (diffInDays < -1) return `${Math.abs(diffInDays)} days from now`;

        return targetDate.toLocaleDateString();
    };

    const [markNotificationAsRead] = useMutation(MARK_NOTIFICATION_AS_READ);

    const handleReadNotification = async () => { 
        setIsOpen(true)
        try {
            setNotification(prevNotifications => prevNotifications.map(notification => notification.id === notification.id ? { ...notification, readBy: [...notification.readBy, user?.id] } : notification));
            await markNotificationAsRead({
                variables: { id: notification.id },
            });
        } catch (error) {
            console.error("Failed to mark notification as read", error);
        }
    };


    return (
        <a href="#" className="block mb-4">
            <div className="card-body">
                <div className="flex items-center">
                    <div className="flex-shrink-0">
                        <div className="flex justify-center items-center h-9 w-9 rounded-full bg text-white bg-primary">
                            <i className="mgc_message_3_line text-lg"></i>
                        </div>
                    </div>
                    <div className="flex-grow truncate ms-2" onClick={handleReadNotification}>
                        <h5 className={`text-sm mb-1 ${!notification.readBy.includes(user?.id) && "font-bold"}`}>
                            {notification.title}{" "}
                            <small className="font-normal text-gray-500 ms-1">
                                {formatRelativeTime(notification.date)}
                            </small>
                        </h5>
                        <small className={`noti-item-subtitle text-muted ${!notification.readBy.includes(user?.id) && "font-bold"}`}>
                            {notification.description}
                        </small>
                    </div>
                </div>
                <Modal
                    title={notification.title}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                >
                    <p>{notification.description}</p>
                </Modal>
            </div>
        </a>
    );
};

export default NotificationDropdownItem;
