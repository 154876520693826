import { gql } from "@apollo/client";

export const GET_DEBATES = gql`
  query Debates {
    debates {
      date
      endingTime
      startingTime
      type
      status
      winner
      id
      teamA {
        institution {
          id
          name
          shortName
        }
      }
      teamB {
        institution {
          id
          name
          shortName
        }
      }
      program {
        id
        name
        programCode
      }
    }
  }
`;

export const GET_DEBATE_BY_ID = gql`
  query Debate($id: ID!) {
    debate(id: $id) {
      id
      teamA {
        id
        institution {
          id
          name
          shortName
        }
      }
      teamB {
        id
        institution {
          id
          name
          shortName
        }
      }
      program {
        id
        name
        programCode
      }
      date
      startingTime
      endingTime
      type
      status
      winner
    }
  }
`;

export const GET_DEBATE_BY_PROGRAM = gql`
  query DebateByProgram($programId: ID!) {
    debateByProgram(programId: $programId) {
      id
      teamA {
        id
        institution {
          id
          name
          shortName
        }
        candidate {
          name
          chestNo
          photo
          id
          institution {
            id
            name
            shortName
          }
        }
      }
      teamB {
        id
        institution {
          id
          name
          shortName
        }
        candidate {
          name
          chestNo
          photo
          id
          institution {
            id
            name
            shortName
          }
        }
      }
      date
      startingTime
      endingTime
      type
      status
      winner
      program {
        id
        name
        programCode
      }
    }
  }
`;
