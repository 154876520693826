import { gql } from "@apollo/client";

export const GET_CANDIDATE_BY_ID = gql`
  query Candidate($id: ID!) {
    candidate(id: $id) {
      id
      photo
      chestNo
      email
      name
      section {
        id
        name
      }
      admissionNo
      class {
        name
        id
      }
      category {
        id
        name
      }
      institution {
        id
        name
      }
      dob
      gender
    }
  }
`;

export const GET_CANDIDATES = gql`
  query Candidates(
    $page: Int
    $limit: Int
    $search: String
    $institutionId: ID
    $categoryId: [ID]
    $classId: ID
  ) {
    candidates(
      page: $page
      limit: $limit
      search: $search
      institutionId: $institutionId
      categoryId: $categoryId
      classId: $classId
    ) {
      candidates {
        id
        photo
        chestNo 
        name 
        email 
        admissionNo 
        gender 
        dob 
        section { 
          id
          name
        }
        class { 
          id
          name
        }
        category { 
          id
          name
          colorCode
        }
        institution {
          id 
          name 
          shortName
          groupId {
            id
            name
          }
        }
      }
      totalPages
      totalCandidates
    }
  }
`;

export const GET_INSTITUTIONS = gql`
  query Institutions {
    institutions {
      id
      shortName
    }
  }
`;

export const GET_CATEGORIES = gql`
  query Categories {
    categories {
      id
      name
    }
  }
`;

export const GET_CLASSES = gql`
  query Classes {
    classes {
      id
      name
      category{
      id
      }
    }
  }
`;


