import React from "react";

interface ProgramItem {
  code: string;
  name: string;
  date?: string;
  sign?: string;
}

interface HallTicketProps {
  ticketNumber?: string;
  studentName?: string;
  group?: string;
  course?: string;
  institution?: string;
  dob?: string;
  programs?: ProgramItem[];
  componentRef?: React.RefObject<HTMLDivElement>;
}

const HallTicket: React.FC<HallTicketProps> = ({
  ticketNumber = "",
  studentName = "",
  group = "",
  course = "",
  institution = "",
  dob = "",
  programs = [],
  componentRef,
}) => {
  return (
    <div className="max-w-3xl mx-auto flex flex-col">
      <div
        style={{ backgroundImage: "url(img/valuation-cover.jpg)" }}
        ref={componentRef}
        className="relative h-[297mm] w-[210mm] bg-white p-16 pt-56 shadow-lg rounded-lg text-black bg-cover"
      >
        <div className="text-center text-3xl font-semibold mb-8 font-[Nippo]">
          HALL TICKET
        </div>

        {/* Student Info */}
        <div className="mb-4">
          <div className="flex justify-between items-start">
            <div>
              <div className="text-4xl font-medium mb-2 font-[Nippo]">
                {ticketNumber}
              </div>
              <div className="text-2xl font-bold mb-2 capitalize font-[Metropolis]">
                {studentName.toUpperCase()}
              </div>
              <div className="flex space-x-4 mb-4">
                <span className="bg-gray-200 px-4 py-1 rounded-full">
                  {group.toUpperCase()}
                </span>
                <span className="bg-gray-200 px-4 py-1 rounded-full">
                  {course.toUpperCase()}
                </span>
              </div>
              <div className="mb-2 font-[Cambria]">
                {institution.toUpperCase()}
              </div>
              <div className="text-gray-600 font-[Cambria]">DOB: {dob}</div>
            </div>
            <div className="font-[Cambria] w-32 h-40 rounded-3xl bg-yellow-50 flex items-center justify-center text-sm text-gray-500 text-center p-2">
              Photo attested by institution seal
            </div>
          </div>
        </div>

        {/* Programs Table */}
        <div className="mb-8">
          <div className="rounded-3xl overflow-hidden border border-gray-200">
            <table className=" font-[Metropolis] w-full border-collapse border border-gray-200">
              <thead className="bg-pink-900 text-white">
                <tr>
                  <th className="py-3 px-4 text-left w-24">CODE</th>
                  <th className="py-3 px-4 text-left">PROGRAM</th>
                  <th className="py-3 px-4 text-left w-32">DATE</th>
                  <th className="py-3 px-4 text-left w-32">SIGN</th>
                </tr>
              </thead>
              <tbody>
                {programs.length > 0 ? (
                  programs.map((program, index) => (
                    <tr key={index} className="border-b border-gray-200">
                      <td className="py-3 px-4 border-r border-gray-300">
                        {program.code}
                      </td>
                      <td className="py-3 px-4 border-r border-gray-300">
                        {program.name}
                      </td>
                      <td className="py-3 px-4 border-r border-gray-300">
                        {program.date}
                      </td>
                      <td className="py-3 px-4">{program.sign}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={4}
                      className="py-3 px-4 text-center text-gray-500"
                    >
                      No programs available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div className="absolute left-0 bottom-16 w-full px-16">
          <div className="w-full flex justify-between items-end mb-8">
            <ol
              style={{ listStyleType: "decimal" }}
              className="text-sm space-y-2 font-[Cambria]"
            >
              <li>
                {" "}
                Candidates must carry their hall tickets for all programs.
              </li>
              <li>
                {" "}
                Arrive at the venue at least 30 minutes before the program
                starts.
              </li>
              <li> Only approved tools are allowed during the program.</li>
              <li>
                {" "}
                Adhere to all SIBAQ rules, including those announced later.
              </li>
            </ol>

            <div className="ml-4">
              <img
                src={`https://haris740.github.io/sibaq-image-uploads/qr-codes/sibaq.inqr.png`}
                alt="QR Code"
                className="w-32 h-32 object-contain"
              />
            </div>
          </div>

          <div className="mb-1">
            <div className="text-sm font-[Cambria]">
              Principal's Signature.................................
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HallTicket;
