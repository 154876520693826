import React from "react";
import PageTitle from "../../components/PageTitle";
import Groups from "../../components/General/Groups";
import ProgramCategory from "../../components/General/ProgramCategory";
import Skill from "../../components/General/Skill";
import Category from "../../components/General/Category";
import Sections from "../../components/General/Sections";
import Position from "../../components/General/Position";
import Class from "../../components/General/Class";
import Clusters from "../../components/General/Clusters";
import Host from "../../components/General/Host";
import Grade from "../../components/General/Grade";

const General: React.FC = () => {
  return (
    <>
      <PageTitle pagetitle={"General"} subtitle={"Settings"} />
      <div className="w-full">
        <div className="p-6 bg-gray-100 min-h-screen">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
            <Sections />
            <Position />
            <Grade />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-6">
            <Groups />
            <ProgramCategory />
            <Skill />
            <Category />
            <Class />
            <Clusters />
            <Host />
          </div>
        </div>
      </div>
    </>
  );
};

export default General;
