import React from 'react';

interface MessageProps {
    timestamp: string;
    content: string;
    sender: {
        id: string;
        username: string;
    };
    userId: {
        id: string;
        username: string;
    };
}

const MessageBubble: React.FC<MessageProps> = ({
    timestamp,
    content,
    sender,
    userId,
}) => {

    return(
    <div className={`flex ${sender?.id === userId?.id ? "justify-start" : "justify-end"}`}>
        {/* {!isCurrentUser && profileImg && (
            <img src={profileImg} alt={sender} className="w-8 h-8 rounded-full mr-2" />
        )} */}
        <div>
            <div className="text-xs text-gray-500 m-1">
                {new Date(Number(timestamp)).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  })}</div>
            <div
                className={`${sender?.id === userId?.id ? "bg-white text-black border border-gray-300" : "bg-blue-600 text-white" 
                    } p-3 rounded-lg max-w-md`}
            >
                <p>{content}</p>
            </div>
        </div>
    </div>
    )
};

export default MessageBubble;