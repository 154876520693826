import React, { useState } from "react";
import PageTitle from "../../components/PageTitle";
import NotificationItem from "../../components/notifications/NotificationItem";
import { useMutation, useQuery } from "@apollo/client";
import {
    CREATE_NOTIFICATION,
    UPDATE_NOTIFICATION,
} from "../../graphql/mutations/notifications";
import toast from "react-hot-toast";
import { GET_ROLES } from "../../graphql/queries/role";
import { GET_USERS } from "../../graphql/queries/admin";
import { GET_NOTIFICATIONS } from "../../graphql/queries/notification";

const Notifications: React.FC = () => {
    const [editingId, setEditingId] = useState("");
    const [activeTab, setActiveTab] = useState("All Notifications");
    const [formData, setFormData] = useState({
        title: "",
        description: "",
        type: "",
        user: "",
        role: "",
        status: "published",
        date: "",
        time: "",
        target: "role"
    });

    const {
        data: getNotifications,
        refetch,
    } = useQuery(GET_NOTIFICATIONS);
    const { data: rolesData } = useQuery(GET_ROLES);
    const { data: usersData } = useQuery(GET_USERS)
    const [createNotification] = useMutation(CREATE_NOTIFICATION);
    const [updateNotification] = useMutation(UPDATE_NOTIFICATION);

    const notifications = getNotifications?.notifications || [];

    const filteredNotifications = notifications.filter((item: any) => {
        if (activeTab === "All Notifications") return true;
        if (activeTab === "Public") {
            return item.type === "public";
        }
        if (activeTab === "Private") {
            return item.type === "private";
        }
        return false;
    });

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        try {
            if (editingId) {
                await updateNotification({
                    variables: {
                        id: editingId,
                        input: {
                            title: formData.title,
                            description: formData.description,
                            date: formData.date,
                            user: formData.user,
                            type: formData.type,
                            status: formData.status,
                            role: formData.role,
                        },
                    },
                });
            } else {
                await createNotification({
                    variables: {
                        input: {
                            title: formData.title,
                            description: formData.description,
                            date: formData.date,
                            user: formData.user,
                            type: formData.type,
                            status: formData.status,
                            role: formData.role,
                            // status,
                        },
                    },
                });
            }
            setEditingId("");
            toast.success("Notification Successfully added");
        } catch (error) {
            console.error(error)
            toast.error("Something went wrong!")
        }

        refetch();
        setFormData({
            title: "",
            description: "",
            type: "",
            user: "",
            role: "",
            status: "",
            date: "",
            time: "",
            target: ""
        });
    };

    const handleEdit = (notification: any) => {
        setFormData({
            title: notification.title,
            description: notification.description,
            type: notification.type,
            user: notification.user?.id,
            role: notification.role?.id,
            status: notification.status,
            date: new Date(Number(notification.date)).toISOString().split('T')[0],
            time: notification.time,
            target: notification.user?.id ? "user" : "role"
        });
        setEditingId(notification.id);
    };

    return (
        <>
            <PageTitle pagetitle="Notifications" subtitle="Notifications" />
            <div className="grid lg:grid-cols-5 gap-6 lg:gap-11 p-4 lg:p-6 w-full max-w-7xl mx-auto">
                <div className="bg-white p-6 lg:p-8 rounded-lg shadow-lg lg:col-span-3 w-full">
                    <h2 className="text-2xl font-bold text-center mb-6">
                        {editingId ? "Edit" : "Add"} Notification
                    </h2>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2">
                                Title
                            </label>
                            <input
                                type="text"
                                placeholder="Enter Title"
                                value={formData.title}
                                onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-green-500"
                            />
                        </div>

                        <div className="mb-4 grid grid-cols-1 sm:grid-cols-2 gap-4">
                            <div>
                                <label className="block text-gray-700 text-sm font-bold mb-2">
                                    Date
                                </label>
                                <input
                                    type="date"
                                    value={formData.date}
                                    onChange={(e) => setFormData({ ...formData, date: e.target.value })}
                                    className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-green-500"
                                />
                            </div>

                            <div>
                                <label className="block text-gray-700 text-sm font-bold mb-2">
                                    Select Type
                                </label>
                                <div className="relative">
                                    <select
                                        value={formData.type}
                                        onChange={(e) => setFormData({ ...formData, type: e.target.value })}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-green-500 pr-10"
                                    >
                                        <option value="">Select Type</option>
                                        <option value="public">Public</option>
                                        <option value="private">Private</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        {/* Conditional rendering of role and user selection based on the type */}
                        <div
                            className={`mb-4 ${formData.type === "private" ? "grid" : "hidden"
                                } grid-cols-1 sm:grid-cols-2 gap-4`}
                        >
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2">
                                    Select Target
                                </label>
                                <div className="relative">
                                    <select
                                        value={formData.target}
                                        onChange={(e) => {
                                            setFormData({ ...formData, target: e.target.value, user: "", role: "" })
                                        }}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-green-500 pr-10"
                                    >
                                        <option value="">Select Target</option>
                                        <option value="role">Role</option>
                                        <option value="user">User</option>
                                    </select>
                                </div>
                            </div>

                            <div className={`${formData.target === "role" ? "block" : "hidden"}`}>
                                <label className="block text-gray-700 text-sm font-bold mb-2">
                                    Select Role
                                </label>
                                <div className="relative">
                                    <select
                                        value={formData.role}
                                        onChange={(e) => setFormData({ ...formData, role: e.target.value })}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-green-500 pr-10"
                                    >
                                        <option value="">Select Role</option>
                                        {rolesData?.roles.map((item: any) => (
                                            <option value={item.id} >{item.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className={`${formData.target === "user" ? "block" : "hidden"}`}>
                                <label className="block text-gray-700 text-sm font-bold mb-2">
                                    Select User
                                </label>
                                <div className="relative">
                                    <select
                                        value={formData.user}
                                        onChange={(e) => setFormData({ ...formData, user: e.target.value })}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-green-500 pr-10"
                                    >
                                        <option value="">Select User</option>
                                        {usersData?.users.map((item: any) => (
                                            <option value={item.id}>{item.username}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2">
                                Message
                            </label>
                            <textarea
                                placeholder="Type Message"
                                value={formData.description}
                                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-green-500"
                            ></textarea>
                        </div>
                        <div>
                            <label className="block text-gray-700 text-sm font-bold mb-2">
                                Select Status
                            </label>
                            <div className="relative">
                                <select
                                    value={formData.status}
                                    onChange={(e) => setFormData({ ...formData, status: e.target.value })}
                                    className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-green-500 pr-10"
                                >
                                    <option value="">Select Status</option>
                                    <option value="published">Published</option>
                                    <option value="draft">Draft</option>
                                </select>
                            </div>
                        </div>


                        <div className="flex justify-center mt-5">
                            <button
                                type="submit"
                                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                </div>

                <div className="w-full lg:col-span-2 h-full bg-white shadow-md rounded-lg p-4 lg:p-6">
                    <div className="flex items-center justify-between mb-4">
                        <h2 className="text-xl font-bold">Notifications</h2>
                        <input
                            type="text"
                            placeholder="Search Notifications"
                            className="border-none text-right rounded-lg py-2 px-3 text-sm outline-none focus:outline-none"
                        />
                    </div>

                    <div className="w-full flex justify-between items-center mb-4 border-b border-gray-200">
                        <div className="flex items-center border-b border-gray-200">
                            {["All Notifications", "Public", "Private"].map((tab) => (
                                <button
                                    key={tab}
                                    onClick={() => setActiveTab(tab)}
                                    className={`px-4 py-2 text-sm font-semibold focus:outline-none ${activeTab === tab
                                        ? "border-b-2 border-blue-500 text-black"
                                        : "text-gray-500"
                                        }`}
                                >
                                    {tab}
                                </button>
                            ))}
                        </div>
                    </div>

                    {filteredNotifications.map((notification: any) => (
                        <NotificationItem
                            key={notification.id}
                            id={notification.id}
                            title={notification.title}
                            message={notification.description}
                            timeAgo={notification.date}
                            user={notification.user}
                            role={notification.role}
                            imageUrl="via.placeholder.com/40"
                            onEdit={() => handleEdit(notification)}
                            refetch={refetch}
                        />
                    ))}

                    <div className="flex justify-center mt-4">
                        <button className="text-blue-500 font-semibold">Load more</button>
                    </div>
                </div>
            </div>


        </>
    );
};

export default Notifications;
