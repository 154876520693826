import { gql } from "@apollo/client";

export const CREATE_DEBATE = gql`
  mutation CreateDebate($input: CreateDebateInput!) {
    createDebate(input: $input) {
      id
    }
  }
`;

export const UPDATE_DEBATE = gql`
  mutation UpdateDebate($id: ID!, $input: UpdateDebateInput!) {
    updateDebate(id: $id, input: $input) {
      id
    }
  }
`;

export const DELETE_DEBATE = gql`
  mutation DeleteDebate($id: ID!) {
    deleteDebate(id: $id)
  }
`;
