import React from "react";
import { Link } from "react-router-dom";
import Private from "../../context/Private";
import JudgeSheet from "./JudgeSheet";
import { useReactToPrint } from "react-to-print";
interface IJudges {
    id: string;
    name: string;
    host: {
        id: string;
        name: string;
        place: string;
        group: {
            id: string;
            name: string
          }
    };
    email: string;
    phoneNumber: string;
}
interface JudgeItemProp {
    judge: IJudges;
    handleEdit: () => void;
    handleDeleteButton: (id: string) => void;
}

const JudgeItem: React.FC<JudgeItemProp> = ({
    judge,
    handleEdit,
    handleDeleteButton,
    // handleView,
}) => {
    const componentRef = React.useRef<HTMLDivElement>(null);
    const handlePrint = useReactToPrint({
        contentRef: componentRef
    });

    const handleDelete = async () => {
        handleDeleteButton(judge.id);
    };

    return (
        <tr key={judge?.id}>

            <td className="whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900 dark:text-gray-200">
                {judge?.name}
            </td>
            <td className="whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900 dark:text-gray-200">
                {judge?.host?.name} | {judge?.host?.place}
            </td>
            {/* <td className="whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900 dark:text-gray-200">
                {judge?.email}
            </td> */}
            <td className="whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900 dark:text-gray-200">
                {judge?.phoneNumber}
            </td>


            <td className="whitespace-nowrap py-4 px-3 text-center text-sm font-medium">
                <Private
                    permissions={[""]}
                    element={
                        <Link to="" className="mr-2">
                            <button
                                type="button"
                                className=""
                                onClick={() => handlePrint()}
                            ><i className="mgc_print_line text-lg"></i></button>
                            <JudgeSheet ref={componentRef}
                                judge={judge}
                            />
                        </Link>
                    }
                />
                <Private
                    permissions={["update-judge"]}
                    element={
                        <Link to="" className="me-0.5">
                            <i className="mgc_edit_line text-lg" onClick={handleEdit}></i>
                        </Link>
                    }
                />
                <Private
                    permissions={["delete-judge"]}
                    element={
                        <a href="#" className="ms-0.5">
                            <i className="mgc_delete_line text-xl" onClick={handleDelete}></i>
                        </a>
                    }
                />
            </td>
        </tr>
    );
};

export default JudgeItem;
