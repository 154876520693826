import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { GET_PROGRAM_PARTICIPANTS } from "../../../graphql/queries/participation";
import TeamInput from "./TeamInput";
import Modal from "../../UIElements/Model";
import toast from "react-hot-toast";
import Select from "../../ui-elements/Select";
import {
  CREATE_DEBATE,
  UPDATE_DEBATE,
} from "../../../graphql/mutations/debate";
import {
  GET_DEBATE_BY_ID,
  GET_DEBATE_BY_PROGRAM,
} from "../../../graphql/queries/debate";

// Type for the candidate data structure
interface Candidate {
  id: string;
  chestNo: string;
  name: string;
  photo: string;
  institution: {
    id: string;
    name: string;
    shortName: string;
  };
}

interface AddCompetitionProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  programId: string;
  editDebateId?: string;
}

const AddCompetition: React.FC<AddCompetitionProps> = ({
  isOpen,
  setIsOpen,
  programId,
  editDebateId,
}) => {
  const [teamOne, setTeamOne] = useState<(Candidate | null)[]>(
    Array(2).fill(null)
  );
  const [teamTwo, setTeamTwo] = useState<(Candidate | null)[]>(
    Array(2).fill(null)
  );
  const [teamOneSearch, setTeamOneSearch] = useState<string[]>([]);
  const [teamTwoSearch, setTeamTwoSearch] = useState<string[]>([]);
  const [filteredTeamOne, setFilteredTeamOne] = useState<Candidate[][]>([]);
  const [filteredTeamTwo, setFilteredTeamTwo] = useState<Candidate[][]>([]);
  const [date, setDate] = useState("");
  const [startingTime, setStartingTime] = useState("");
  const [endingTime, setEndingTime] = useState("");
  const [type, setType] = useState("");
  const [loading] = useState(false);

  const [createDebate] = useMutation(CREATE_DEBATE, {
    refetchQueries: [
      { query: GET_DEBATE_BY_PROGRAM, variables: { programId } },
    ],
  });

  const [updateDebate] = useMutation(UPDATE_DEBATE, {
    refetchQueries: [
      { query: GET_DEBATE_BY_PROGRAM, variables: { programId } },
    ],
  });

  const clearInput = () => {
    setTeamOne(Array(2).fill(null));
    setTeamTwo(Array(2).fill(null));
    setTeamOneSearch([]);
    setTeamTwoSearch([]);
    setDate("");
    setStartingTime("");
    setEndingTime("");
    setType("");
  };

  const {
    data: participantsData,
    loading: participantsLoading,
    error: participantsError,
  } = useQuery(GET_PROGRAM_PARTICIPANTS, {
    variables: { programId },
    skip: !programId,
  });

  const { data: debatesData } = useQuery(GET_DEBATE_BY_ID, {
    variables: { id: editDebateId },
    skip: !editDebateId,
  });

  useEffect(() => {
    if (editDebateId && debatesData) {
      console.log(debatesData);
      setTeamOne([
        debatesData.debate?.teamA?.[0] || null,
        debatesData.debate?.teamA?.[1] || null,
      ]);
      setTeamTwo([
        debatesData.debate?.teamB?.[0] || null,
        debatesData.debate?.teamB?.[1] || null,
      ]);
      setDate(
        new Date(Number(debatesData.debate?.date)).toISOString().split("T")[0]
      );
      setStartingTime(debatesData.debate?.startingTime);
      setEndingTime(debatesData.debate?.endingTime);
      setType(debatesData.debate?.type);
    }
  }, [editDebateId, debatesData, isOpen]);

  useEffect(() => {
    if (participantsData && participantsData.programParticipants) {
      const filterParticipants = (searchTerms: string[]) => {
        return searchTerms.map((term) =>
          participantsData.programParticipants.filter(
            (participant: Candidate) =>
              participant.institution?.name
                .toLowerCase()
                .includes(term?.toLowerCase()) ||
              participant.institution?.shortName
                .toLowerCase()
                .includes(term?.toLowerCase())
          )
        );
      };

      setFilteredTeamOne(filterParticipants(teamOneSearch));
      setFilteredTeamTwo(filterParticipants(teamTwoSearch));
    }
  }, [participantsData, teamOneSearch, teamTwoSearch]);

  const handleParticipantSelect = (
    participant: Candidate | null,
    index: number,
    setTeam: React.Dispatch<React.SetStateAction<(Candidate | null)[]>>
  ) => {
    setTeam((prev) =>
      prev.map((item, idx) => (idx === index ? participant : item))
    );
  };

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Ensure both teams have at least one participant before submitting
    if (
      teamOne.filter((participant) => participant !== null).length === 0 ||
      teamTwo.filter((participant) => participant !== null).length === 0
    ) {
      toast.error("Both teams must have at least one participant.");
      return;
    }

    const teamA = teamOne
      .slice(0, 2) // Ensure only the first two members are included
      .map((member) => member?.id) // Extract IDs
      .filter((id) => id); // Remove any null or undefined IDs

    const teamB = teamTwo
      .slice(0, 2)
      .map((member) => member?.id)
      .filter((id) => id);

    console.log("teamA", teamA);
    console.log("teamB", teamB);

    console.log("teamOne", teamOne);
    console.log("teamTwo", teamTwo);

    const variables = {
      input: {
        date,
        endingTime,
        startingTime,
        teamA,
        teamB,
        program: programId,
        type,
        status: "pending",
      },
    };

    if (editDebateId) {
      try {
        await updateDebate({ variables: { id: editDebateId, ...variables } });
        toast.success("Competition updated successfully!");
        clearInput();
        setIsOpen(false);
      } catch (error) {
        console.error("Failed to update Competition:", error);
        toast.error("Failed to update Competition.");
      }
    } else {
      try {
        await createDebate({ variables });
        toast.success("Competition created successfully!");
        clearInput();
        setIsOpen(false);
      } catch (error) {
        console.error("Failed to create Competition:", error);
        toast.error("Failed to create Competition.");
      }
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      title="Add New Competition"
      setIsOpen={setIsOpen}
      onClose={clearInput}
      handleSubmit={handleFormSubmit}
      loading={loading}
      buttonText={"Submit"}
    >
      <div className="flex items-center gap-3">
        <TeamInput
          team={teamOne}
          teamName="Team A"
          search={teamOneSearch}
          setSearch={setTeamOneSearch}
          filteredParticipants={filteredTeamOne}
          handleParticipantSelect={(participant, index) =>
            handleParticipantSelect(participant, index, setTeamOne)
          }
          double
          loading={participantsLoading}
          error={participantsError}
        />
        <div className="text-3xl font-semibold">v/s</div>
        <TeamInput
          team={teamTwo}
          teamName="Team B"
          search={teamTwoSearch}
          setSearch={setTeamTwoSearch}
          filteredParticipants={filteredTeamTwo}
          handleParticipantSelect={(participant, index) =>
            handleParticipantSelect(participant, index, setTeamTwo)
          }
          double
          loading={participantsLoading}
          error={participantsError}
        />
      </div>
      <div className="grid grid-cols-3 gap-2 mb-3">
        <div>
          <label htmlFor="date" className="block text-sm text-gray-700 mb-1">
            Date
          </label>
          <input
            type="date"
            id="date"
            name="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </div>
        <div>
          <label
            htmlFor="startingTime"
            className="block text-sm text-gray-700 mb-1"
          >
            Starting Time
          </label>
          <input
            type="time"
            id="startingTime"
            name="startingTime"
            value={startingTime}
            onChange={(e) => setStartingTime(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </div>
        <div>
          <label
            htmlFor="endingTime"
            className="block text-sm text-gray-700 mb-1"
          >
            Ending Time
          </label>
          <input
            type="time"
            id="endingTime"
            name="endingTime"
            value={endingTime}
            onChange={(e) => setEndingTime(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </div>
      </div>
      <Select
        label="Type"
        value={type}
        options={["Elimination", "Pre Quarter", "Quarter", "Semi Final"]}
        flex="col"
        onChange={(e) => setType(e.target.value)}
      />
    </Modal>
  );
};

export default AddCompetition;
