import { gql } from "@apollo/client";

// Create Program Mutation
export const CREATE_PROGRAM = gql`
  mutation CreateProgram($input: CreateProgramInput!) {
    createProgram(input: $input) {
      programCode
      name
      concept
      duration
      noOfCandidates
      tools
      mode
      type
      isRegistrable
      isGroupwiseResult
      isDebate
      isItemRegistrable
      isProposalSubmission
      isStarred
      # eliminationResultStatus
      # finalResultStatus
      # maxSelection
      # status
      # date
      # startingTime
      # endingTime
      # eliminationVenue
      # finalVenue
    }
  }
`;

export const UPDATE_PROGRAM = gql`
  mutation UpdateProgram($id: ID!, $input: UpdateProgramInput!) {
    updateProgram(id: $id, input: $input) {
      programCode
      name
      concept
      duration
      noOfCandidates
      tools
    }
  }
`;

export const UPDATE_SCHEDULE = gql`
  mutation UpdateEliminationSchedule(
    $input: UpdateEliminationScheduleInput!
    $programId: ID!
  ) {
    updateEliminationSchedule(input: $input, programId: $programId) {
      name
    }
  }
`;

// Delete Program Mutation
export const DELETE_PROGRAM = gql`
  mutation DeleteProgram($id: ID!) {
    deleteProgram(id: $id)
  }
`;

export const UPDATE_ELIMINATION_REGISTRATION_STATUS = gql`
  mutation UpdateEliminationRegistrationStatus($programId: ID!, $input: UpdateEliminationRegistrationStatusInput!) {
    updateEliminationRegistrationStatus(programId: $programId, input: $input) {
      id
      name
    }
  }
`;