import { gql } from "@apollo/client";

export const GET_CLUSTERS = gql`
  query Clusters {
    clusters {
      id
      name
      section {
        id
        name
      }
    }
  }
`;

export const GET_CLUSTER_BY_ID = gql`
  query Cluster($id: ID!) {
    cluster(id: $id) {
      id
      name
      section {
        id
        name
      }
    }
  }
`;
