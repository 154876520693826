import React, { useState, useRef, useEffect } from "react";
import { useAuth } from "../../context/AuthContext";
import { useQuery } from "@apollo/client";
import { GET_SECTIONS } from "../../graphql/queries/section";
import Skeleton from "../UIElements/Skeleton";
import { useSection } from "../../apolloClient";

interface Section {
  id: string;
  name: string;
}

const SectionDropdown: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const { user, setUser } = useAuth();
  const { setSectionId } = useSection();

  // GraphQL query to fetch sections
  const { data, loading, error } = useQuery(GET_SECTIONS);

  // Toggle dropdown visibility
  const toggleDropdown = () => setIsOpen(!isOpen);

  // Close dropdown when clicking outside
  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  // Handle section selection
  const handleSection = (e: React.MouseEvent, index: number) => {
    e.preventDefault();
    setIsOpen(false);
    setIndex(index);
    const selectedsection = data.sections[index];
    setUser((prevUser: any) => ({
      ...prevUser,
      section: selectedsection,
    }));

    localStorage.setItem("section", selectedsection.id);
    setSectionId(selectedsection.id);
    // window.location.reload();
  };

  useEffect(() => {
    // Initialize the selected section based on the user's section
    if (
      !user?.section &&
      data?.sections?.length &&
      !localStorage.getItem("section")
    ) {
      const firstSection = data.sections[0];
      setUser((prevUser: any) => ({
        ...prevUser,
        section: firstSection,
      }));

      setSectionId(firstSection.id);
      localStorage.setItem("section", firstSection.id);
    }

    if (data) {
      const sectionId = localStorage.getItem("section");
      setIndex(sectionId === data["sections"][0].id ? 0 : 1);
    }

    // Add event listener for clicks outside the dropdown
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [user, data]);

  if (loading) return <Skeleton />;
  if (error) return null;

  return (
    <div className="relative">
      <button
        type="button"
        className="nav-link px-3 py-1 fc-dropdown relative overflow-hidden bg-gray-200 dark:bg-gray-700"
        onClick={toggleDropdown}
      >
        <span className="flex items-center justify-center">
          {data?.sections[index].name}
        </span>
      </button>

      <div
        ref={dropdownRef}
        className={`absolute -left-[25px] mt-2 ${
          isOpen ? "block" : "hidden"
        } w-30 z-50 transition-[margin,opacity] duration-300 bg-white dark:bg-gray-800 shadow-lg border border-gray-200 dark:border-gray-700 rounded-lg p-2`}
      >
        {data?.sections.map((section: Section, index: number) => (
          <a
            key={section.id}
            href="#"
            onClick={(e) => handleSection(e, index)}
            className="flex items-center gap-2.5 py-2 px-3 cursor-pointer rounded-md text-sm text-gray-800 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"
          >
            {/* <img
              src={index === 0 ? "/img/general.png" : "/img/niics.png"}
              alt={`${section.name}-icon`}
              className="h-4"
            /> */}
            <span className="align-middle">{section.name}</span>
          </a>
        ))}
      </div>
    </div>
  );
};

export default SectionDropdown;
