import React from "react";

interface SinglePrintProps {
  programCode: string;
  programName: string;
  programCategory: string;
  results: any;
}

const SinglePrint: React.FC<SinglePrintProps> = ({
  programCode,
  programName,
  programCategory,
  results,
}) => {
  // Sort results: selected first, then unselected
  const sortedResults = [...results]?.sort((a: any, b: any) => {
    if (a.is_selected === b.is_selected) {
      // If both are selected/unselected, optionally sort by eliminationGrade percentage
      const gradeA = a.eliminationGrade?.percentage || 0;
      const gradeB = b.eliminationGrade?.percentage || 0;
      return gradeB - gradeA; // Descending order by percentage
    }
    return b.is_selected - a.is_selected; // Selected first
  });

  return (
    <div className="overflow-x-auto mb-8 mx-14">
      <table className="min-w-full border-collapse border border-gray-200 text-center">
        <thead className="bg-gray-200">
          <tr>
            <th
              className="border border-black py-1 text-white bg-black"
              colSpan={2}
            >
              {programCode}
            </th>
            <th
              className="border border-black py-1 text-white bg-black"
              colSpan={5}
            >
              {programName} - {programCategory}
            </th>
          </tr>
          <tr>
            <th className="border border-black py-1 text-red-600">CHEST NO</th>
            <th className="border border-black py-1 text-red-600">CANDIDATE</th>
            <th className="border border-black py-1 text-red-600">
              INSTITUTION
            </th>
            <th className="border border-black py-1 text-red-600">CATEGORY</th>
            <th className="border border-black py-1 text-red-600">STATUS</th>
            <th className="border border-black py-1 text-red-600">GROUP</th>
          </tr>
        </thead>
        <tbody>
          {sortedResults?.map((result: any, index: number) => (
            <tr key={index} className={`bg-transparent`}>
              <td className="border border-black py-1 font-semibold">
                {result?.candidate[0]?.chestNo}
              </td>
              <td className="border border-black py-1 font-bold">
                {result?.candidate?.length > 1
                  ? `${result?.candidate[0]?.name} & Team`
                  : result?.candidate[0]?.name}
              </td>
              <td className="border border-black py-1">
                {result?.institution?.shortName}
              </td>
              <td className="border border-black py-1">
                {result?.candidate[0]?.category?.name}
              </td>
              <td className="border border-black py-1">
                {result?.is_selected
                  ? "SELECTED"
                  : result?.eliminationGrade?.name}
              </td>
              <td className="border border-black py-1">
                {result?.institution?.groupId?.name}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SinglePrint;
