import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { ELIMINATION_RESULT_COUNT_BY_STATUS_WITH_CATEGORY } from "../../../../graphql/queries/elimination";
import { useQuery } from "@apollo/client";
import Skeleton from "../../../UIElements/Skeleton";

const ChartSection: React.FC = () => {
  const [barSeries, setBarSeries] = useState([]);
  const { data, loading, error } = useQuery(
    ELIMINATION_RESULT_COUNT_BY_STATUS_WITH_CATEGORY,
    { fetchPolicy: "network-only" }
  );

  const barOptions = {
    chart: { type: "bar" as const },
    plotOptions: {
      bar: { horizontal: false, columnWidth: "70%" },
    },
    xaxis: {
      categories: [
        "Not_Entered",
        "Entering",
        "Prepared",
        "To_Publish",
        "Published",
        "Announced",
      ],
    },
    dataLabels: { enabled: false },
  };

  useEffect(() => {
    if (data?.eliminationResultCountByStatusWithCategory) {
        setBarSeries(data.eliminationResultCountByStatusWithCategory);
    }
  }, [data]);

  //   const pieOptions = {
  //     labels: ["Bootcamp", "Course"],
  //     legend: { position: "bottom" as const },
  //   };

  //   const pieSeries = [819, 489];

  if (loading) {
    return <Skeleton />;
  }

  if (error) {
    return <p>Failed to fatech data {error.message}</p>;
  }

  return (
    <div className="md:col-span-2 lg:col-span-4 bg-white shadow-md rounded-lg p-4">
      <h3 className="font-bold text-lg mb-2">Category Base Result Graph</h3>
      <ReactApexChart
        options={barOptions}
        series={barSeries}
        type="bar"
        height={250}
      />
    </div>
    // <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4">

    //  <div className="col-span-1 bg-white shadow-md rounded-lg p-4">
    //     <h3 className="font-bold text-lg mb-2">Total</h3>
    //     <ReactApexChart
    //         options={pieOptions}
    //         series={pieSeries}
    //         type="pie"
    //         height={300}
    //     />
    // </div>
    // </div>
  );
};

export default ChartSection;
