import React from "react";

interface RegPrintProps {
    data?: any;
    category?: any;
    isCluster?: boolean;
    isGroup?: boolean;
    printRef?: React.Ref<HTMLDivElement>;
}

const RegPrint: React.FC<RegPrintProps> = ({ data, category, isCluster, isGroup, printRef }) => {
    return (
        <div
            ref={printRef}
            style={{ printColorAdjust: "exact", WebkitPrintColorAdjust: "exact", backgroundImage: "url('img/valuation-cover.jpg')" }}
            className="w-[210mm] h-[297mm] pt-52 mx-auto px-12 flex flex-col font-serif text-black bg-contain"
        >
            <div className="flex items-center justify-end">
                {isCluster ? (
                    <>
                        <div className="px-12 py-1 font-semibold text-md !bg-black text-white">CLUSTER</div>
                        <div className="px-12 py-1 font-semibold text-md bg-transparent">{data[0]?.institution?.cluster?.name}</div>
                    </>
                ) : isGroup && (
                    <>
                        <div className="px-12 py-1 font-semibold text-md !bg-black text-white">GROUP</div>
                        <div className="px-12 py-1 font-semibold text-md bg-transparent">{data[0]?.institution?.groupId?.name}</div>
                    </>
                )}
            </div>
            <div className="flex items-center justify-start">
                <div className="pr-16 py-4 font-semibold text-md">Venue: {data[0]?.eliminationVenue?.name}</div>
                <div className="pr-16 py-4 font-semibold text-md">{data[0]?.eliminationDate} {data[0]?.eliminationStartingTime} - {data[0]?.eliminationEndingTime}</div>
            </div>

            <table className="border-collapse border-2 border-black">
                <thead>
                    <tr className="text-center">
                        <th className="border-2 border-black">{data[0]?.program?.programCode}</th>
                        <th className="border-2 border-black" colSpan={2}>{data[0]?.program?.name}</th>
                        <th className="border-2 border-black" colSpan={3}>{category}</th>
                    </tr>
                    <tr>
                        <th className="border-2 border-black">Si.No</th>
                        <th className="border-2 border-black">Chest No</th>
                        <th className="border-2 border-black">Candidate</th>
                        <th className="border-2 border-black">Institution</th>
                        <th className="border-2 border-black">Code Letter</th>
                        <th className="border-2 border-black">Sign</th>
                    </tr>
                </thead>
                <tbody>
                    {data?.map((data: any, si: number) => {
                        return (
                            data?.candidate?.map((candidate: any, index: number) => {
                                if (!candidate) return null;
                                return (
                                    <tr key={index}>
                                        <td className="border border-black px-1 text-center">{si + 1}</td>
                                        <td className="border border-black px-1 text-center">{candidate?.chestNo}</td>
                                        <td className="border border-black px-1">{candidate?.name}</td>
                                        <td className="border border-black px-1">{candidate?.institution?.shortName}</td>
                                        <td className="border border-black px-1 text-center">{data?.codeLetter}</td>
                                        <td className="border border-black px-1"></td>
                                    </tr>
                                );
                            })
                        );
                    })}
                </tbody>
            </table>
            <div className="mt-36 flex justify-end font-semibold">
                Sign of Invigilator
            </div>
        </div>
    )
}

export default RegPrint;