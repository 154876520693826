import { gql } from "@apollo/client";

export const GET_PROGRAM_PARTICIPANTS = gql`
  query GetProgramParticipants($programId: ID!, $groupId: ID, $clusterId: ID) {
    programParticipants(
      programId: $programId
      groupId: $groupId
      clusterId: $clusterId
    ) {
      id
      candidate {
        id
        name
        photo
        admissionNo
        chestNo
        gender
        dob
        email
        category {
          id
          name
          colorCode
        }
        class {
          id
          name
        }
        institution {
          id
          name
          shortName
        }
      }
      program {
        id
        name
        programCode
      }
      institution {
        id
        name
      }
    }
  }
`;
