import { useQuery } from "@apollo/client";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";
import PageTitle from "../../components/PageTitle";
import Card from "../../components/programs/Card";
import Private from "../../context/Private";
import Pagination from "../../components/UIElements/Pagination";
import FilterModel from "../../components/UIElements/FilterModel";
import { useAuth } from "../../context/AuthContext";
import { GET_CATEGORIES, GET_CLUSTERS, GET_GROUPS, GET_PARTICIPATIONS, GET_PROGRAM_CATEGORIES, GET_PROGRAMS, GET_SKILLS } from "./programQuery";

const Programs: React.FC = () => {
  const [page, setPage] = useState<number>(0);
  const limit = 9;

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedProgramCategory, setSelectedProgramCategory] = useState("");
  const [selectedSkill, setSelectedSkill] = useState("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [group, setGroup] = useState<string>("");
  const [cluster, setCluster] = useState<string>("");

  const { user } = useAuth();

  const dropdownRef = useRef<HTMLDivElement>(null);

  const { data: programsData, error, loading, refetch } = useQuery(GET_PROGRAMS, {
    variables: {
      page,
      limit,
      search: searchTerm,
      programCategoryId: selectedProgramCategory,
      categoryId: selectedCategory,
      skillId: selectedSkill,
    },
    fetchPolicy: "cache-and-network",
  });

  const handlePageChange = (newPage: number) => {
    setPage(newPage - 1);
    refetch({ page: newPage - 1, limit });
  };

  const { data: participationData } = useQuery(GET_PARTICIPATIONS);
  const { data: categoryData } = useQuery(GET_CATEGORIES);
  const { data: programCategoryData } = useQuery(GET_PROGRAM_CATEGORIES);
  const { data: skillData } = useQuery(GET_SKILLS);
  const { data: groupData } = useQuery(GET_GROUPS);
  const { data: clusterData } = useQuery(GET_CLUSTERS);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  if (error) {
    return <p>{error.message}</p>;
  }

  const handleDownload = () => {
    // flatten object like this {id: 1, title:'', category: ''};
    const rows = programsData?.programs?.programs?.map((program: any) => ({
      programCode: program.programCode,
      name: program.name,
      concept: program.concept,
      duration: program.duration,
      skill: program.skill?.name,
      noOfCandidates: program.noOfCandidates,
      tools: program.tools,
      section: program.section?.name,
      category: program.category?.name,
      programCategory: program.programCategory?.name,
      mode: program.mode,
      type: program.type,
      curbGroup: program.curbGroup?.name,
      isRegistrable: program.isRegistrable ? "Yes" : "No",
      isItemRegistrable: program.isItemRegistrable ? "Yes" : "No",
      isProposalSubmission: program.isProposalSubmission ? "Yes" : "No",
      isStarred: program.isStarred ? "Yes" : "No",
      publishTimeElimination: program.publishTimeElimination,
      eliminationResultStatus: program.eliminationResultStatus,
      publishTimeFinal: program.publishTimeFinal,
      finalResultStatus: program.finalResultStatus,
      maxSelection: program.maxSelection,
      status: program.status,
      finalDate: program.finalDate,
      finalStartingTime: program.finalStartingTime,
      finalEndingTime: program.finalEndingTime,
      finalVenue: program.finalVenue?.name,
      eliminationDate: program.eliminationDate,
      eliminationStartingTime: program.eliminationStartingTime,
      eliminationEndingTime: program.eliminationEndingTime,
      eliminationVenue: program.eliminationVenue?.name,
    }));

    // create workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(rows);

    XLSX.utils.book_append_sheet(workbook, worksheet, "Programs");

    XLSX.writeFile(workbook, "Programs.xlsx", { compression: true });
  };

  const handleDownloadParticipation = () => {
    // flatten object like this {id: 1, title:'', category: ''};
    const filteredParticipation = participationData?.participations?.filter(
      (participation: {
        program: {
          name: string;
          category: {
            id: string;
            name: string;
          };
          programCategory: {
            id: string;
            name: string;
          };
          skill: {
            id: string;
            name: string;
          };
        };
        institution: {
          groupId: {
            id: string;
            name: string;
          };
          cluster: {
            id: string;
            name: string;
          }
        }
      }) => {
        const matchesSearch = participation?.program?.name
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
        const matchesCategory = selectedCategory
          ? participation?.program?.category?.id === selectedCategory
          : true;
        const matchesProgramCategory = selectedProgramCategory
          ? participation?.program?.programCategory?.id === selectedProgramCategory
          : true;
        const matchesSkill = selectedSkill
          ? participation?.program?.skill?.id === selectedSkill
          : true;
        const matchesGroup = group
          ? participation?.institution?.groupId?.id === group
          : true;
        const matchesCluster = cluster
          ? participation?.institution?.cluster?.id === cluster
          : true;


        return (
          matchesSearch &&
          matchesCategory &&
          matchesProgramCategory &&
          matchesSkill &&
          matchesGroup &&
          matchesCluster
        );
      }
    );


    const rows = filteredParticipation?.map((participation: any) => {
      return participation.candidate?.map((candidate: any) => ({
        programCode: participation.program?.programCode,
        program_name: participation.program?.name,
        category: participation.program?.category?.name,
        programCategory: participation.program?.programCategory?.name,
        institution: participation.institution?.name,
        chess_no: candidate.chestNo,
        candidate_name: candidate.name,
      }));
    }).flat();

    if (!rows) {
      return
    }

    // create workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(rows);

    XLSX.utils.book_append_sheet(workbook, worksheet, "Participation");

    XLSX.writeFile(workbook, "Participation.xlsx", { compression: true });
  };

  const filterConfig = [
    ...(user.institution ? [] : [{
      field: 'group',
      label: 'Group',
      type: 'select',
      options: groupData?.groups,
    }]),
    ...(user.institution ? [] : [{
      field: 'cluster',
      label: 'Cluster',
      type: 'select',
      options: clusterData?.clusters,
    }]),
    {
      field: 'category',
      label: 'Category',
      type: 'select',
      options: categoryData?.categories,
    },
    {
      field: 'programCategory',
      label: 'Program Category',
      type: 'select',
      options: programCategoryData?.programCategories,
    },
    {
      field: 'skill',
      label: 'Skill',
      type: 'select',
      options: skillData?.skills,
    },
  ]

  const handleApplyFilters = (filterValue: any) => {
    setSelectedCategory(filterValue?.category)
    setSelectedProgramCategory(filterValue?.programCategory)
    setSelectedSkill(filterValue?.skill)
    setGroup(filterValue?.group)
    setCluster(filterValue?.cluster)
  }

  return (
    <>
      <PageTitle pagetitle={"Programs"} subtitle={"Programs"} />

      {/* Responsive form controls */}
      <div className="flex flex-col items-center sm:flex-row justify-between gap-2 mb-7">
        <div>
          <div className="flex flex-grow relative">
            <div className="pointer-events-none absolute top-3.5 left-4 text-gray-900 text-opacity-40 dark:text-gray-200">
              <i className="mgc_search_line text-xl"></i>
            </div>
            <input
              type="search"
              className="h-12 w-full border rounded-lg bg-transparent pl-11 text-gray-900 placeholder-gray-500 dark:placeholder-gray-300 dark:text-gray-200 focus:ring-0 sm:text-sm"
              placeholder="Search Programs..."
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value)
                setPage(0);
              }}
            />
          </div>
        </div>
        <div className="flex items-center gap-1">
          <FilterModel onApplyFilters={handleApplyFilters} filters={filterConfig} />

          <Private
            permissions={["create-program"]}
            element={
              <div>
                <Link
                  to="/programs/add"
                  className="btn h-full w-full bg-danger/20 inline-flex items-center justify-center text-sm font-medium text-danger hover:text-white hover:bg-danger"
                >
                  <i className="mgc_add_circle_line me-3 text-xl"></i> Add Program
                </Link>
              </div>
            }
          />

          <Private
            permissions={["show-program"]}
            element={
              <div className="relative" ref={dropdownRef}>
                <button
                  onClick={() => setIsOpen(!isOpen)}
                  className="btn w-full h-full bg-green-300 inline-flex items-center justify-center text-sm font-medium text-green-700 hover:text-white hover:bg-green-500"
                >
                  <i className="mgc_arrow_down_circle_line me-2 text-xl"></i>{" "}
                  Download
                </button>
                {isOpen && <div className="absolute top-full left-0 w-full bg-white border rounded shadow-lg z-10">
                  <div className="overflow-y-auto">
                    <button onClick={handleDownload} className="btn w-full h-full inline-flex items-center cursor-pointer hover:bg-blue-500 hover:text-white">
                      <i className="mgc_arrow_down_circle_line me-2 text-xl"></i>{" "}
                      Programs
                    </button>
                    <button onClick={handleDownloadParticipation} className="btn w-full h-full inline-flex items-center cursor-pointer hover:bg-blue-500 hover:text-white">
                      <i className="mgc_arrow_down_circle_line me-2 text-xl"></i>{" "}
                      Participation
                    </button>
                  </div>
                </div>}
              </div>
            }
          />
        </div>
      </div>

      {/* Responsive program cards */}
      {loading ?
        <div className="flex justify-center items-center">
          <button
            type="button"
            className="btn bg-transparent w-fit border-gray-300 dark:border-gray-700"
          >
            <i className="mgc_loading_4_line me-2 animate-spin"></i>
            <span>Loading</span>
          </button>
        </div>
        :
        <div className="flex flex-auto flex-col mt-6">
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6">
            {programsData?.programs?.programs?.map((program: any) => (
              <Card
                programId={program.id}
                key={program.id}
                name={program.name}
                status={program.status}
                statusType={program.type}
                concept={program.concept}
                programCode={program.programCode}
                duration={program.duration}
                category={program?.category}
                noOfCandidates={program?.noOfCandidates}
                skill={program.skill?.name}
                programCategory={program.programCategory?.name}
                isRegistrable={program.isRegistrable}
                groupId={group}
                clusterId={cluster}
              />
            ))}
          </div>
          <Pagination
            totalPages={programsData?.programs?.totalPages}
            page={page + 1}
            onPageChange={handlePageChange}
            className="my-8"
          />
          {/* Load More button */}
          {/* <div className="text-center mt-6">
          <button
            type="button"
            className="btn bg-transparent border-gray-300 dark:border-gray-700"
          >
            <i className="mgc_loading_4_line me-2 animate-spin"></i>
            <span>Load More</span>
          </button>
        </div> */}
        </div>
      }
    </>
  );
};

export default Programs;
