import React from "react";
import { useQuery } from "@apollo/client";
import { GET_CLUSTERS } from "../../graphql/queries/cluster";
import Skeleton from "../UIElements/Skeleton";

interface ClusterInputProps {
  cluster: string;
  setCluster: (cluster: string) => void;
}

const ClusterInput: React.FC<ClusterInputProps> = ({ cluster, setCluster }) => {
  const { data, loading, error } = useQuery(GET_CLUSTERS);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCluster(e.target.value);
  };

  if (loading) return <Skeleton />;
  if (error) return <p>Error loading clusters</p>;

  return (
    <div className="mb-3">
      <label
        htmlFor="clusterSelect"
        className="text-gray-800 text-sm font-medium inline-block mb-2"
      >
        Select Cluster
      </label>
      <select
        id="clusterSelect"
        className="form-select"
        value={cluster}
        onChange={handleChange}
        required
      >
        <option value="" disabled>
          Select a Cluster
        </option>
        {data.clusters.map((cluster: any) => (
          <option key={cluster.id} value={cluster.id}>
            {cluster.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default ClusterInput;
