import { useQuery } from "@apollo/client";
import React from "react";
import { GET_GROUPS } from "../../../../graphql/queries/group";
import Skeleton from "../../../UIElements/Skeleton";
import StatusCount from "./StatusCount";

interface StatCardProps {
  title: string;
  color: string;
}

const StatusCountCard: React.FC<StatCardProps> = ({ title, color }) => {
  const { data: groups, loading, error } = useQuery(GET_GROUPS);

  if (loading) {
    return <Skeleton />;
  }

  if (error) {
    return <p>Failed to fatech data {error.message}</p>;
  }

  return (
    <div
      className={`flex items-center justify-between  shadow-md rounded-lg p-4 bg-${color}-300 text-white`}
    >
      <div className="">
        <div className="flex items-center space-x-3 text-xs">
          <h4 className={`font-bold text-lg text-${color}-700 text-center`}>
            {title}
          </h4>
        </div>

        {groups?.groups?.map((group: any, index: number) => (
          <StatusCount
            groupId={group.id}
            key={index}
            color={color}
            groupName={group.name}
            status={title}
            subtitle={groups?.groups?.length === 1}
          />
        ))}

        {/* {groups?.groups?.length > 1 && (
          <StatusCount
            groupId={"group"}
            color={color}
            groupName={"X - Category"}
            status={title}
            subtitle={false}
          />
        )} */}
        
      </div>
    </div>
  );
};

export default StatusCountCard;
