import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { UPDATE_SCHEDULE } from "../../graphql/mutations/program";
import Modal from "../UIElements/Model";
import toast from "react-hot-toast";
import { GET_VENUES } from "../../graphql/queries/venue";

type EditData = Partial<{
  eliminationDate: string;
  eliminationStartingTime: string;
  eliminationEndingTime: string;
  eliminationVenue: string;
  finalDate: string;
  finalStartingTime: string;
  finalEndingTime: string;
  finalVenue: string;
}>;

// AddScheduleProps
interface AddScheduleProps {
  programId: string;
  groupId: string;
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isElimination: boolean;
  refetch: () => void;
  editData: EditData;
}

const AddSchedule: React.FC<AddScheduleProps> = ({
  programId,
  groupId,
  isModalOpen,
  setIsModalOpen,
  isElimination,
  refetch,
  editData,
}) => {
  const [updateSchedule] = useMutation(UPDATE_SCHEDULE);

  const { data } = useQuery(GET_VENUES);

  const [formData, setFormData] = useState({
    date: "",
    startTime: "",
    endTime: "",
    venue: "",
  });

  let filteredVenue = data?.venues.filter(
    (venue: any) => venue?.host?.group?.id === groupId
  );

  function formatTimestampToTime(timestamp: number, format = "HH:mm:ss") {
    // Convert the timestamp to a Date object
    const date = new Date(timestamp);

    // Extract hours, minutes, seconds, and milliseconds
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const milliseconds = String(date.getMilliseconds()).padStart(3, "0");

    // Return the time in the requested format
    switch (format) {
      case "HH:mm":
        return `${hours}:${minutes}`;
      case "HH:mm:ss":
        return `${hours}:${minutes}:${seconds}`;
      case "HH:mm:ss.SSS":
        return `${hours}:${minutes}:${seconds}.${milliseconds}`;
      default:
        throw new Error(
          "Invalid format. Use 'HH:mm', 'HH:mm:ss', or 'HH:mm:ss.SSS'."
        );
    }
  }

  useEffect(() => {
    setFormData({
      date: isElimination
        ? editData?.eliminationDate
          ? new Date(Number(editData?.eliminationDate))
              .toISOString()
              .split("T")[0]
          : ""
        : editData?.finalDate
        ? new Date(Number(editData?.finalDate)).toISOString().split("T")[0]
        : "",
      startTime: isElimination
        ? editData.eliminationStartingTime
          ? formatTimestampToTime(Number(editData.eliminationStartingTime))
          : ""
        : editData.finalStartingTime
        ? formatTimestampToTime(Number(editData.finalStartingTime))
        : "",
      endTime: isElimination
        ? editData.eliminationEndingTime
          ? formatTimestampToTime(Number(editData.eliminationEndingTime))
          : ""
        : editData.finalEndingTime
        ? formatTimestampToTime(Number(editData.eliminationEndingTime))
        : "",
      venue: isElimination
        ? editData.eliminationVenue ?? ""
        : editData.finalVenue ?? "",
    });
  }, [editData]);

  const clearForm = () => {
    setFormData({
      date: "",
      startTime: "",
      endTime: "",
      venue: "",
    });
  };

  const handleProgramScheduleSubmit = async () => {
    const updateLoading = toast.loading("Updating program schedule...");
    try {
      // toast error when any field of formData is empty
      if (
        !formData.date ||
        !formData.startTime ||
        !formData.endTime ||
        !formData.venue
      ) {
        toast.error("Please fill all fields.");
        return;
      }

      // const form = e.target as HTMLFormElement;
      // const formdata = new FormData(form);
      // const date = formdata.get("date") as string;
      // const startTime = formdata.get("startTime") as string;
      // const endTime = formdata.get("endTime") as string;
      const { date, startTime, endTime, venue } = formData;

      // const startDateTime = `${date}T${startTime}`;
      // const endDateTime = `${date}T${endTime}`;

      // const updatedProgram: any = {
      //   skill: skill?.id,
      //   category: category?.id,
      //   programCategory: programCategory?.id,
      // };

      // if (selectedRound === "elimination") {
      //   updatedProgram.eliminationStartTime = startDateTime;
      //   updatedProgram.eliminationEndTime = endDateTime;
      // } else if (selectedRound === "final") {
      //   updatedProgram.finalStartTime = startDateTime;
      //   updatedProgram.finalEndTime = endDateTime;
      // } else {
      //   toast.error("Invalid round selection.");
      //   return;
      // }
      let inputData;
      if (isElimination) {
        inputData = {
          eliminationDate: [
            {
              key: groupId,
              value: new Date(date).getTime(),
            },
          ],
          eliminationStartingTime: [
            {
              key: groupId,
              value: new Date(`${date}T${startTime}`).getTime(),
            },
          ],
          eliminationEndingTime: [
            {
              key: groupId,
              value: new Date(`${date}T${endTime}`).getTime(),
            },
          ],
          eliminationVenue: [
            {
              key: groupId,
              value: venue,
            },
          ],
        };
      } else {
        inputData = {
          finalDate: [
            {
              key: groupId,
              value: new Date(date).getTime(),
            },
          ],
          finalStartingTime: [
            {
              key: groupId,
              value: new Date(`${date}T${startTime}`).getTime(),
            },
          ],
          finalEndingTime: [
            {
              key: groupId,
              value: new Date(`${date}T${endTime}`).getTime(),
            },
          ],
          finalVenue: [
            {
              key: groupId,
              value: venue,
            },
          ],
        };
      }

      await updateSchedule({
        variables: { programId: programId, input: inputData },
      });
      refetch();
      toast.remove(updateLoading);
      toast.success("Program schedule updated successfully!");
      setIsModalOpen(false);
      clearForm();
    } catch (error) {
      toast.remove(updateLoading);
      console.error("Error updating program schedule:", error);
      toast.error("Error updating program schedule.");
    }
  };

  return (
    <>
      {isModalOpen ? (
        <Modal
          title="Add Schedule to Program"
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          handleSubmit={handleProgramScheduleSubmit}
          onClose={() => {
            setFormData({
              date: "",
              startTime: "",
              endTime: "",
              venue: "",
            });
          }}
          buttonText="Add Schedule"
        >
          <form
            className="flex flex-col gap-2 justify-center items-center px-2 text-gray-700"
            onSubmit={(e) => e.preventDefault()}
          >
            {/* <div className="w-full px-2">
              <label className="block text-gray-600 text-sm mt-1">
                Select Round
              </label>
              <select
                id="round"
                name="round"
                value={selectedRound}
                onChange={(e) => setSelectedRound(e.target.value)}
                className="text-sm m-1 ml-0 rounded-lg min-w-56 w-full border-2 border-gray-300 p-2 inline-block"
              >
                <option value="">Select Round</option>
                <option value="elimination">Elimination Round</option>
                <option value="final">Final Round</option>
              </select> 
            </div> */}

            <div className="w-full px-2">
              <label className="text-gray-800 text-sm capitalize font-medium inline-block mb-1">
                Select Date
              </label>
              <input
                type="date"
                name="date"
                value={formData.date}
                onChange={(e) =>
                  setFormData({ ...formData, date: e.target.value })
                }
                id="date"
                className="form-input"
              />
            </div>

            <div className="w-full px-2">
              <label className="text-gray-800 text-sm capitalize font-medium inline-block mb-1">
                Start Time
              </label>
              <input
                type="time"
                name="startTime"
                value={formData.startTime}
                onChange={(e) =>
                  setFormData({ ...formData, startTime: e.target.value })
                }
                id="startTime"
                className="form-input"
              />
            </div>

            <div className="w-full px-2">
              <label className="text-gray-800 text-sm capitalize font-medium inline-block mb-1">
                End Time
              </label>
              <input
                type="time"
                name="endTime"
                value={formData.endTime}
                onChange={(e) =>
                  setFormData({ ...formData, endTime: e.target.value })
                }
                id="endTime"
                className="form-input"
              />
            </div>
            <div className="w-full px-2">
            <label className="text-gray-800 text-sm capitalize font-medium inline-block mb-1">
                Venue
              </label>
              <select
                value={formData.venue ?? ""}
                onChange={(e) =>
                  setFormData({ ...formData, venue: e.target.value })
                }
                className="form-select capitalize"
                id="venue"
              >
                <option className="" value="">
                  Select Venue
                </option>
                {filteredVenue?.map((option: any) => {
                    return (
                      <option
                        key={option.id}
                        className="capitalize"
                        value={option.id}
                      >
                        {option.name} | {option.host?.place}
                      </option>
                    );
                })}
              </select>
            </div>
          </form>
        </Modal>
      ) : (
        ""
      )}
    </>
  );
};

export default AddSchedule;
