// src/notificationQueries.ts 
import { gql } from "@apollo/client";

export const GET_NOTIFICATIONS = gql`
  query Notifications {
    notifications {
      id
      title
      description
      date
      user {
        id
        username
      }
      role {
        id
        name
      }
      type
      readBy
    }
  }
`;

export const GET_PUBLISHED_NOTIFICATIONS = gql`
  query PublishedNotifications {
    publishedNotifications {
      id
      title
      description
      date
      user {
        id
        username
      }
      role {
        id
        name
      }
      type
      readBy
    }
  }
`;

export const SUBSCRIPTION_NOTIFICATION = gql`
  subscription {
    notificationAdded {
      id
      title
      description
      type
      user {
        username
      }
      status
      date
    }
  }
`;

export const GET_NOTIFICATION_BY_ID = gql`
  query GetNotificationById($id: ID!) {
    notification(id: $id) {
      id
      title
      description
      type
      user {
        id
        name
      }
      role {
        id
        name
      }
      status
      date
      time
      deletedAt
    }
  }
`;

export const NOTIFICATIONS_STATISTICS = gql`
  query NotificationsStatistics {
    notificationsStatistics {
      publishedCount
      draftCount
    }
  }
`;
