import { gql } from "@apollo/client";

export const GET_PROGRAM_BY_ID = gql`
  query GetProgramById($id: ID!) {
    program(id: $id) {
      id
      name
      programCode
      concept
      duration
      skill {
        id
        name
      }
      noOfCandidates
      maxSelection
      tools
      category {
        id
        name
      }
      programCategory {
        id
        name
      }
      mode
      type
      isRegistrable
      isGroupwiseResult
      isDebate
      isItemRegistrable
      isProposalSubmission
      isStarred
      section {
        id
        name
      }
    }
  }
`;

export const GET_SKILLS = gql`
  query GetSkills {
    skills {
      id
      name
    }
  }
`;

export const GET_CATEGORIES = gql`
  query Categories {
    categories {
      id
      name
    }
  }
`;

export const GET_PROGRAM_CATEGORIES = gql`
  query ProgramCategories {
    programCategories {
      id
      name
    }
  }
`;

export const GET_SECTIONS = gql`
  query GetSection {
    sections {
      id
      name
    }
  }
`;

