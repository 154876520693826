import React from 'react';

interface CoordinatorProps {
    name?: string;
    phone?: string;
}

const Coordinator: React.FC<CoordinatorProps> = ({ name, phone }) => {
    return (
        <div className="flex items-center space-x-3 sm:space-x-4 py-2">
            <div className="h-7 w-7 sm:h-9 sm:w-9 bg-green-500 rounded-lg"></div>
            <div>
                <h4 className="text-xs font-semibold">{name}</h4>
                <p className="text-xs text-gray-500">{phone}</p>
            </div>
        </div>
    );
};

export default Coordinator;
