import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import { DELETE_NOTIFICATION } from '../../graphql/mutations/notifications';
import toast from 'react-hot-toast';
import DeleteModal from '../UIElements/DeleteModal';

interface NotificationItemProps {
  id: string;
  title: string;
  message: string;
  timeAgo: string;
  imageUrl: string;
  user: {
    id: string;
    username: string;
  };
  role: {
    id: string;
    name: string;
  };
  onEdit: () => void;
  refetch: () => void;
}

const NotificationItem: React.FC<NotificationItemProps> = ({ id, title, timeAgo, imageUrl, user, role, onEdit, refetch }) => {
  const [isOpen, setOpen] = useState(false)

  const [deleteNotification] = useMutation(DELETE_NOTIFICATION);

  const onDelete = async () => {
    try {
      let loadingToast = toast.loading("Deleting notification...");
      await deleteNotification({ variables: { id } });
      toast.dismiss(loadingToast);
      refetch();
      toast.success("Notification successfully deleted");
    } catch (error) {
      console.error(error);
      toast.error("Failed to delete notification");
    }
  };


  return (
    <div className="flex items-start p-4 border-b border-gray-200 w-full">
      <div className="relative">  
        <img
          src={imageUrl}
          alt="Notification"
          className="w-10 h-10 rounded-full"
        />
        <span className="absolute top-0 left-0 bg-blue-500 w-3 h-3 rounded-full"></span>
      </div>
      <div className="w-full">
        <div className="ml-4 flex justify-between">
          <h4 className="font-bold text-sm">{title}</h4>
          <div className="flex gap-1">
            <i className='mgc_edit_line cursor-pointer' onClick={onEdit}></i>
            <i className='mgc_delete_line cursor-pointer' onClick={()=>setOpen(true)}></i>
          </div>
        </div>
        <div className="ml-4 flex justify-between">
          <p className="text-gray-500 text-[10px] truncate">{user?.username} {role?.name}</p>
          <div className="text-xs text-gray-400">{new Date(Number(timeAgo)).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' })}</div>
        </div>

      <DeleteModal isOpen={isOpen} setIsOpen={setOpen} handleDelete={onDelete}/>

      </div>
    </div>
  );
};

export default NotificationItem;
