import { gql } from "@apollo/client";

export const CREATE_GALLERY = gql`
  mutation CreateGallery($input: CreateGalleryInput!) {
    createGallery(input: $input) {
      id
    }
  }
`;

export const UPDATE_GALLERY = gql`
  mutation UpdateGallery($id: ID!, $input: UpdateGalleryInput!) {
    updateGallery(id: $id, input: $input) {
      id
    }
  }
`;

export const DELETE_GALLERY = gql`
mutation DeleteGallery($id: ID!) {
  deleteGallery(id: $id)
}
`;
