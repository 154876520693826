import { gql } from "@apollo/client";

export const UPDATE_POSITION = gql`
  mutation UpdatePosition($id: ID!, $input: UpdatePositionInput) {
    updatePosition(id: $id, input: $input) {
      name
      category
      mark
    }
  }
`;

export const UPDATE_GRADE = gql`
  mutation UpdateGrade($id: ID!, $input: UpdateGradeInput) {
    updateGrade(id: $id, input: $input) {
      name
      category
      percentage
      mark
    }
  }
`;
