import React, { useState } from "react";
import Nav from "./Nav";
import Table from "./Table";

interface DebateDetailsProps {
  program: {
    id: string;
    name: string;
  };
}

const DebateDetails: React.FC<DebateDetailsProps> = ({ program }) => {
  const [debateId, setDebateId] = useState("");
  const [compatitionModel, setCompatitionModel] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");

  return (
    <>
      <Nav
        programId={program.id}
        debateId={debateId}
        isOpen={compatitionModel}
        setIsOpen={setCompatitionModel}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />
      <Table
        programId={program.id}
        setDebateId={setDebateId}
        setIsOpen={setCompatitionModel}
        searchTerm={searchTerm}
      />
    </>
  );
};

export default DebateDetails;
