import { ChangeEvent, FC } from "react";

interface InputProps {
  label: string;
  placeholder?: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  className?: string;
}

const Input: FC<InputProps> = ({
  label,
  placeholder,
  value,
  onChange,
  className,
}) => {
  return (
    <div className="grid grid-cols-4 items-center gap-6">
      <label
        htmlFor={label}
        className="text-gray-800 text-sm capitalize font-medium inline-block mb-2"
      >
        {label}
      </label>
      <div className="md:col-span-3">
        <input
          id={label}
          type="text"
          className={`form-input ${className}`}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default Input;
