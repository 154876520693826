interface ProgramHeaderProps {
  program: {
    programCode: string;
    skill: { name: string };
    section: { name: string };
    programCategory: { name: string };
    category: { name: string };
    name: string;
    status: string;
  };
}

function ProgramDetailsHeader({ program }: ProgramHeaderProps) {
  return (
    <div className="bg-white shadow-md rounded-lg p-6 my-6">
      {/* Header with Program Name and Status */}
      <div className="flex justify-between items-center border-b border-gray-200 pb-4 mb-4">
        <h1 className="text-xl font-bold uppercase text-gray-800">
          {program?.name}
        </h1>
        {/* <span
          className={`px-3 py-1 rounded-full bg-blue-300 text-blue-900`}
        >
          {program?.status || "Pending"}
        </span> */}
      </div>

      {/* Program Details */}
      <div className="grid sm:grid-cols-5 grid-cols-1 gap-4">
        {/* Program Code */}
        <div className="flex items-center">
          <i className="mgc_grid_line text-3xl text-gray-500 mr-3"></i>
          <div>
            <h3 className="text-lg font-semibold">{program?.programCode}</h3>
            <small className="text-gray-600 text-sm">Program Code</small>
          </div>
        </div>

        {/* Section */}
        <div className="flex items-center">
          <i className="mgc_group_line text-3xl text-gray-500 mr-3"></i>
          <div>
            <h3 className="text-lg font-semibold">{program?.section?.name}</h3>
            <small className="text-gray-600 text-sm">Section</small>
          </div>
        </div>

        {/* Skill */}
        <div className="flex items-center">
          <i className="mgc_bulb_line text-3xl text-gray-500 mr-3"></i>
          <div>
            <h3 className="text-lg font-semibold">{program?.skill?.name}</h3>
            <small className="text-gray-600 text-sm">Skill</small>
          </div>
        </div>

        {/* Program Category */}
        <div className="flex items-center">
          <i className="mgc_book_line text-3xl text-gray-500 mr-3"></i>
          <div>
            <h3 className="text-lg font-semibold">
              {program?.programCategory?.name}
            </h3>
            <small className="text-gray-600 text-sm">Program Category</small>
          </div>
        </div>
        <div className="flex items-center">
          <i className="mgc_user_3_line text-3xl text-gray-500 mr-3"></i>
          <div>
            <h3 className="text-lg font-semibold">
              {program?.category?.name}
            </h3>
            <small className="text-gray-600 text-sm">Category</small>
          </div>
        </div>
      </div>
      
    </div>
  );
}

export default ProgramDetailsHeader;
