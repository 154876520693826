import React from "react";
import { Link } from "react-router-dom";
import Private from "../../context/Private";

interface VenueItemProp {
    venue: any;
    handleEdit: () => void;
    handleDeleteButton: (id: string) => void;
}

const VenueItem: React.FC<VenueItemProp> = ({
    venue,
    handleEdit,
    handleDeleteButton,
    // handleView,
}) => {
    const handleDelete = async () => {
        handleDeleteButton(venue.id);
    };

    return (
        <tr key={venue?.id}>

            <td className="whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900 dark:text-gray-200">
                {venue?.name}
            </td>
            <td className="whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900 dark:text-gray-200">
                {venue?.host?.name}
            </td>
            <td className="whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900 dark:text-gray-200">
                {venue?.place}
            </td>

            <td className="whitespace-nowrap py-4 px-3 text-center text-sm font-medium">
                <Private
                    permissions={["update-venue"]}
                    element={
                        <Link to="" className="me-0.5">
                            <i className="mgc_edit_line text-lg" onClick={handleEdit}></i>
                        </Link>
                    }
                />
                <Private
                    permissions={["delete-venue"]}
                    element={
                        <a href="#" className="ms-0.5">
                            <i className="mgc_delete_line text-xl" onClick={handleDelete}></i>
                        </a>
                    }
                />
            </td>
        </tr>
    );
};

export default VenueItem;
