import { gql } from '@apollo/client';

export const CREATE_CANDIDATE = gql`
  mutation CreateCandidate($input: CreateCandidateInput!) {
    createCandidate(input: $input) {
      id
      chestNo
      photo
      name
      admissionNo
      dob
      gender
      createdAt
      deletedAt
      updatedAt
    }
  }
`;

export const UPDATE_CANDIDATE = gql`
  mutation UpdateCandidate($id: ID!, $input: UpdateCandidateInput!) {
    updateCandidate(id: $id, input: $input) {
      chestNo
      photo
      name
      admissionNo
      dob
      gender
      createdAt
      deletedAt
      updatedAt
    }
  }
`;

export const DELETE_CANDIDATE = gql`
  mutation DeleteCandidate($id: ID!) {
    deleteCandidate(id: $id)
  }
`;
