import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { LOGIN_MUTATION } from "../../graphql/mutations/user";
import toast from "react-hot-toast";
import { useAuth } from "../../context/AuthContext";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";

const Login: React.FC = () => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState(false);
  const [loginMutation, { loading }] = useMutation(LOGIN_MUTATION);
  const { login } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  // Google reCAPTCHA v3 hook
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const trimmedUsername = username.trim();
    const trimmedPassword = password.trim();

    if (!trimmedUsername || !trimmedPassword) {
      toast.error("Username and password are required.");
      return;
    }

    // Check if executeRecaptcha is available
    if (!executeRecaptcha) {
      toast.error("reCAPTCHA service is not available. Please try again.");
      return;
    }

    // Execute reCAPTCHA verification
    const recaptchaToken = await executeRecaptcha("login");

    if (!recaptchaToken) {
      toast.error("reCAPTCHA verification failed. Please try again.");
      return;
    }

    try {
      const { data } = await loginMutation({
        variables: {
          username: trimmedUsername,
          password: trimmedPassword,
          recaptchaToken,
        },
      });
      if (data) {
        const { token, user } = data.login;

        login(token, user);
        toast.success("Login successful!");

        const params = new URLSearchParams(location.search);
        const redirectPath = params.get("redirect") || "/";
        navigate(redirectPath);
      }
    } catch (error: any) {
      const errorMessage =
        error.graphQLErrors && error.graphQLErrors[0]?.message
          ? error.graphQLErrors[0]?.message
          : "Failed to login";
      toast.error(errorMessage);
    }
  };

  return (
    <div className="min-h-screen w-screen bg-[#F2F6F9] flex items-center justify-center px-5 md:p-10">
      <div className="max-w-4xl md:min-w-[44rem] 2xl:w-2/5 md:w-[55%] w-full grid grid-cols-1 gap-4 md:grid-cols-2 bg-white rounded-[1.7rem] shadow-lg px-3 py-10 md:p-10">
        <div
          className="rounded-[1.6rem] hidden md:flex bg-cover bg-no-repeat bg-center justify-center py-12 md:pt-20"
          style={{ backgroundImage: `url('/img/darulhuda.jpg')` }}
        >
          <p className="w-4/5 sm:w-3/5 text-white text-3xl font-semibold tracking-widest">
            Festival of Cultures
          </p>
        </div>
        <div className="px-3 2xl:pt-6">
          <a href="/" className="logo-box">
            {/* Brand Logo */}
            <img src="/img/logo-dark.png" className="logo-lg h-6" alt="Logo" />
          </a>
          <div className="mt-3 mb-10 2xl:mb-16">
            <p className="text-3xl text-center font-bold text-black">
              Welcome Back
            </p>
            <p className="text-center text-sm">Please login to your account</p>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <input
                id="LoggingUsername"
                name="username"
                className="form-input bg-[#F4F8FB] border-none text-sm py-3"
                type="text"
                aria-placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Enter your username"
                required
              />
            </div>

            <div className="mb-4 relative">
              <input
                id="loggingPassword"
                className="form-input bg-[#F4F8FB] border-none text-sm py-3 w-full"
                type={showPassword ? "text" : "password"}
                aria-placeholder="Password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter your password"
                required
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="absolute inset-y-0 right-3 flex items-center text-gray-600"
              >
                {showPassword ? (
                  <i className="mgc_eye_close_line text-xl"></i>
                ) : (
                  <i className="mgc_eye_2_line text-xl"></i>
                )}
              </button>
            </div>

            <div className="flex justify-center">
              <button
                className="btn w-full py-3 rounded-lg text-sm text-white bg-[#EB3252] hover:bg-[#e32c4a]"
                type="submit"
                disabled={loading}
              >
                {loading ? "Logging in..." : "Login"}
              </button>
            </div>
            <a href="https://sibaq.in/">
              <p className="text-sm text-center py-3 hover:text-gray-700">
                Back to home page
              </p>
            </a>
          </form>
        </div>
      </div>
    </div>
  );
};

function App() {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6Lfia3oqAAAAAFAKeGockbD3qJ6hh-EZUznyW_YF">
      <Login />
      {/* Other components */}
    </GoogleReCaptchaProvider>
  );
}

export default App;
