import React from "react";
import PageTitle from "../../components/PageTitle";
import ProgramForm from "../../components/programs/ProgramForm";

const AddProgram: React.FC = () => {
  return (
    <>
      <PageTitle pagetitle={"Add Programs"} subtitle={"Programs"} />

      <ProgramForm />
    </>
  );
};

export default AddProgram;
