import { gql } from "@apollo/client";

export const GET_SECTIONS = gql`
  query GetSection {
    sections {
      id
      name
      year
      chestNoPrefix
    }
  }
`;


