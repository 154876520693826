import React, { useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle";
import JudgeNav from "../../components/judgement/JudgeNav";
import JudgeCard from "../../components/judgement/JudgeCard";
import { gql, useMutation, useQuery } from "@apollo/client";
import Modal from "../../components/UIElements/Model";
import toast from "react-hot-toast";
import { UPDATE_PROGRAM } from "../../graphql/mutations/program";

const GET_PROGRAMS = gql`
  query GetPrograms(
    $page: Int
    $limit: Int
    $search: String
    $programCategoryId: ID
    $categoryId: ID
    $skillId: ID
  ) {
    programs(
      page: $page
      limit: $limit
      search: $search
      programCategoryId: $programCategoryId
      categoryId: $categoryId
      skillId: $skillId
    ) {
      programs {
        category {
          id
          name
        }
        eliminationJudge {
          id
          name
          email
          phoneNumber
          host {
            id
            name
            place
            group {
              id
              name
            }
          }
        }
        finalJudge {
          id
          name
          email
          phoneNumber
          host {
            id
            name
            place
            group {
              id
              name
            }
          }
        }
        id
        name
        noOfCandidates
        programCode
        skill {
          id
          name
        }
      }
      totalPages
      totalPrograms
    }
  }
`;

const GET_JUDGEMENT = gql`
  query Judges {
    judges {
      id
      name
      host {
        id
        name
        place
      }
    }
  }
`;

interface IJudges {
  id: string;
  name: string;
  place: string;
  host: {
    name: string;
    id: string;
    place: string;
    group: {
      id: string;
      name: string
    }
  };
  email: string;
  phoneNumber: string;
}

interface Program {
  id: string;
  name: string;
  programCode: string;
  skill: {
    id: string;
    name: string;
  };
  category: {
    id: string;
    name: string;
  };
  eliminationJudge: IJudges[];
  eliminationStartingTime: string;
  finalDate: string;
  eliminationDate: string;
  eliminationVenue: {
    id: string;
    name: string;
  };
  finalJudge: IJudges[];
  finalStartingTime: string;
  finalVenue: {
    id: string;
    name: string;
  };
  programCategory: {
    id: string;
    name: string;
  }
}

const JudgementProgram: React.FC = () => {
  const { data: judgesData } = useQuery(GET_JUDGEMENT);
  const { data: programData, loading, error, refetch } = useQuery(GET_PROGRAMS);

  const [updateProgram] = useMutation(UPDATE_PROGRAM);

  // State variables
  const [programList, setProgramList] = useState<Program[]>([]);
  const [judgeList, setJudgeList] = useState<IJudges[]>([]);
  const [filteredProgramData, setFilteredProgramData] = useState<Program[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedJudge, setSelectedJudge] = useState<IJudges | null>(null);
  const [selectedProgram, setSelectedProgram] = useState<Program | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRound, setSelectedRound] = useState<string>("");

  const [formProgramData, setFormProgramData] = useState({
    eliminationJudge: [] as string[],
    finalJudge: [] as string[],
  });

  // Effect to load program data
  useEffect(() => {
    if (programData && programData.programs?.programs) {
      setProgramList(programData.programs?.programs);
    }
  }, [programData]);

  useEffect(() => {
    if (judgesData && judgesData.judges) {
      setJudgeList(judgesData.judges);
    }
  }, [judgesData]);

  // Set program data when a program is selected
  // Initialize form data with selectedProgram (if available)
  useEffect(() => {
    if (selectedProgram) {
      setFormProgramData({
        eliminationJudge: selectedProgram.eliminationJudge?.map((judge) => judge.id) ?? [],
        finalJudge: selectedProgram.finalJudge?.map((judge) => judge.id) ?? [],
      });
    }
  }, [selectedProgram]);

  // Handle search
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    const filteredData = programList.filter((program) =>      
      program.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
      program.programCode.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredProgramData(filteredData);
  };

  // Clear form data
  const clearForm = () => {
    setIsModalOpen(false);
    setSelectedJudge(null);
  };

  // Handle judge selection change
  const handleJudgeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newJudgeId = e.target.value;
    const filteredData = judgeList?.filter(judge => judge.id === newJudgeId)[0];
    setSelectedJudge(filteredData);

    if (selectedRound === "elimination") {
      setFormProgramData((prev) => ({
        ...prev,
        eliminationJudge: [...(prev?.eliminationJudge ?? []), newJudgeId],
      }));
    } else if (selectedRound === "final") {
      setFormProgramData((prev) => ({
        ...prev,
        finalJudge: [...(prev?.finalJudge ?? []), newJudgeId],
      }));
    }
  };

  // Add judge to a program
  const handleAddJudge = (program: Program): void => {
    clearForm();
    setIsModalOpen(true);
    setSelectedProgram(program);
  };

  // Handle form submission
  const handleProgramJudgeSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const { skill, category, programCategory, id } = selectedProgram || {};
      const updatedProgram = {
        skill: skill?.id,
        category: category?.id,
        programCategory: programCategory?.id,
        eliminationJudge: formProgramData?.eliminationJudge ?? [],
        finalJudge: formProgramData?.finalJudge ?? [],
      };
      const updateLoading = toast.loading("Adding judge to the program");
      await updateProgram({ variables: { id, input: updatedProgram } });
      refetch();
      toast.remove(updateLoading);
      toast.success("Judge added to the program successfully!");
      clearForm();
    } catch (error) {
      console.error("Error adding judge to the program:", error);
      toast.error("Error adding judge to the program.");
    }
  };

  const removeJudge = async (programId: string, judgeId: string, round: string) => {
    const confirmRemoval = confirm('Are you sure you want to remove the judge from the program?');
    if (confirmRemoval) {
      try {
        // Find the program in the list
        const program = programList.find((program) => program.id === programId);
        if (!program) throw new Error('Program not found');

        // Prepare the updated judge list based on the round
        const updatedProgram = {
          skill: program.skill?.id,
          category: program.category?.id,
          programCategory: program.programCategory?.id,
          eliminationJudge: program.eliminationJudge?.map(j => j.id),
          finalJudge: program.finalJudge?.map(j => j.id),
        };

        if (round === 'eliminationJudge') {
          updatedProgram.eliminationJudge = updatedProgram.eliminationJudge.filter((id) => id !== judgeId);
        } else if (round === 'finalJudge') {
          updatedProgram.finalJudge = updatedProgram.finalJudge.filter((id) => id !== judgeId);
        } else {
          throw new Error('Invalid round specified');
        }

        // Trigger the loading state
        const updateLoading = toast.loading('Removing judge from the program');

        // Call the mutation with the updated data
        await updateProgram({
          variables: { id: programId, input: updatedProgram },
        });

        // Refetch the updated data
        refetch();
        toast.remove(updateLoading);
        toast.success("Judge removed from the program successfully!");
      } catch (error) {
        console.error("Error removing judge from the program:", error);
        toast.error("Error removing judge from the program.");
      }
    }
  };


  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <>
      <PageTitle pagetitle="Judgement" subtitle="Judgement" />
      <JudgeNav
        handleSearch={handleSearch}
        searchQuery={searchQuery}
        setFilteredProgramData={setFilteredProgramData}
        programList={programList}
        // handleCreate={handleCreate}
        judgesData={judgesData}
      />
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 justify-items-center">
        {filteredProgramData?.map((programData) => (
          <JudgeCard
            key={programData.id}
            programData={programData}
            handleAddJudge={handleAddJudge}
            removeJudge={removeJudge}
          />
        ))}
      </div>
      {isModalOpen ?
        (<Modal
          title="Add Judge to Program"
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          handleSubmit={handleProgramJudgeSubmit} // Call the correct submit function
        >
          <form
            className="flex flex-col justify-center items-center px-2 text-gray-700"
            onSubmit={handleProgramJudgeSubmit}
          >
            {/* Select Round */}
            <div className="w-full px-2">
              <label className="block text-gray-600 text-sm mt-1">Select Round</label>
              <select
                id="round"
                name="round"
                value={selectedRound}
                onChange={(e) => setSelectedRound(e.target.value)} // Set the round
                className="text-sm m-1 ml-0 rounded-lg min-w-56 w-full border-2 border-gray-300 p-2 inline-block"
              >
                <option value="">Select Round</option>
                <option value="elimination">Elimination Round</option>
                <option value="final">Final Round</option>
              </select>
            </div>

            {/* Select Judge */}
            <div className="w-full px-2">
              <label className="block text-gray-600 text-sm mt-1">Select Judge</label>
              <select
                id="name"
                name="judge"
                value={selectedJudge?.id}
                onChange={(e) => handleJudgeChange(e)}
                className="text-sm m-1 ml-0 rounded-lg min-w-56 w-full border-2 border-gray-300 p-2 inline-block"
              >
                <option value="">Judge | Host</option>
                {judgesData?.judges?.map((judge: any) => (
                  <option key={judge?.id} value={judge?.id}>
                    {judge?.name} | {judge?.host?.name}
                  </option>
                ))}
              </select>
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className="bg-green-500 text-white py-2 px-4 m-4 rounded-md w-full"
            >
              Add Judge
            </button>
          </form>
        </Modal>


        ) : ("")}
    </>
  );
};

export default JudgementProgram;
