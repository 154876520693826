import React, { useState, useEffect } from "react";
import SupportChat from "./support/SupportChat";

const BackToTopButton: React.FC = () => {
  const [isChatOpen, setIsChatOpen] = useState(false);

  useEffect(() => {
    const backToTopButton = document.querySelector(
      '[data-toggle="back-to-top"]'
    ) as HTMLElement;
    // const supportChatButton = document.querySelector(
    //   '[data-toggle="support-chat-button"]'
    // ) as HTMLElement;

    const handleScroll = () => {
      if (window.pageYOffset > 72) {
        backToTopButton.classList.add("flex");
        backToTopButton.classList.remove("hidden");
      } else {
        backToTopButton.classList.remove("flex");
        backToTopButton.classList.add("hidden");
      }
    };

    const handleClick = (e: Event) => {
      e.preventDefault();
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

    window.addEventListener("scroll", handleScroll);
    backToTopButton.addEventListener("click", handleClick);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      backToTopButton.removeEventListener("click", handleClick);
    };
  }, []);

  return (
    <div className="h-fit-content w-fit-content fixed bottom-3 end-3 flex flex-col items-end gap-3 z-20">
      <div data-toggle="support-chat" className={`${isChatOpen ?  "block" : "hidden"}`}>
        <SupportChat isChatOpen={isChatOpen} />
      </div>
      <button
        data-toggle="support-chat-button"
        className="flex h-10 w-10 items-center justify-center rounded-full bottom-16 end-3 p-2.5 bg-primary cursor-pointer shadow-lg text-white"
        onClick={()=>{setIsChatOpen(!isChatOpen)}}
      >
        <i className={`${isChatOpen ? "mgc_close_line" : "mgc_chat_1_line"} text-lg`}></i>
        <div className={`absolute w-3 h-3 top-0 right-0 rounded-full bg-green-700 border-2 border-white ${isChatOpen && "top-[512px]"}`}></div>
      </button>
      <button
        data-toggle="back-to-top"
        className="hidden h-10 w-10 items-center justify-center rounded-full bottom-3 end-3 p-2.5 bg-primary cursor-pointer shadow-lg text-white"
      >
        <i className="mgc_arrow_up_line text-lg"></i>
      </button>
    </div>
  );
};

export default BackToTopButton;
