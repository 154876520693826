import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { GET_PROGRAMS } from '../../graphql/queries/program';

interface IJudges {
  id: string;
  name: string;
  host: {
    name: string;
    id: string;
  };
}
interface Program {
  id: string;
  name: string;
  programCode: string;
  skill: {
    id: string;
    name: string;
  };
  category: {
    id: string;
    name: string;
  };
  eliminationJudge: IJudges[];
  eliminationStartingTime: string;
  finalDate: string;
  eliminationDate: string;
  eliminationVenue: {
    id: string;
    name: string;
  };
  finalJudge: IJudges[];
  finalStartingTime: string;
  finalVenue: {
    id: string;
    name: string;
  };
  programCategory: {
    id: string;
    name: string;
  }
}
interface JudgeSheetProps {
  judge: IJudges;
}

const JudgeSheet = React.forwardRef<HTMLDivElement, JudgeSheetProps>((props, ref) => {

  const { judge } = props;
  // console.log(programId);

  const { data: programData } = useQuery(GET_PROGRAMS);
  const [programList, setProgramList] = useState<Program>();

  useEffect(() => {
    // console.log(programData);
    if (judge) {
      setProgramList(programData?.program?.map((program: Program) => {
        program.eliminationJudge?.map((ejudge: IJudges) => {
          ejudge.id == judge.id
        })
      }));
      console.log(programList, programData?.program);
    }
  }, [programData, programList]);
  return (
    <div
      className="judge-sheet max-w-4xl mx-auto px-12 bg-white h-auto font-cambria"
      ref={ref}  // Now the ref is correctly typed as HTMLDivElement
      style={{ display: "none" }}  >
      <div className="h-screen w-screen -ml-12 bg-white relative nippo-font">
        <img src="/img/judge_front_cover.jpg" alt="back_cover" className="w-full h-full" />
        <div className="text-center bg-white text-black h-28 pt-20 w-full absolute bottom-20 " style={{ fontFamily: 'Nippo', fontWeight: 'normal'}}>
          {judge?.name}
        </div>
      </div>

      {/* Scoring Table */}
      <div className="w-full p-14 pb-0 add-margin">
        <div className=" text-black">
          <h3 className="font-bold uppercase py-2 text-xl text-white bg-black rounded-lg mx-auto w-2/6 my-8 text-center">GENERAL RULES</h3>
          <ol className="text-justify list-decimal text-xl/6 pl-3">
            <li className="my-1">All doubts related to the judgment of the contests should be clarified only by discussing with the concerned controllers.  </li>
            <li className="my-1"> To ensure fair judgment, jury members are not allowed to discuss the results with each other. Accordingly, each evaluation is required separately. </li>
            <li className="my-1"> In order to prevent further disputes, jury members are strictly advised to refrain from post-contest commentaries. </li>
            <li className="my-1">All contestants are evaluated out of 100 marks.</li>
            <li className="my-1">Prior to judging any contest, jury members are expected to carefully read the Concept Note and make evaluation accordingly. </li>
            <li className="my-1">Outfits are not to be considered for giving special marks. </li>
            <li className="my-1"> Jury members are advised to keep the confidentiality of the results, and never disclose or declare it at the venue. </li>
          </ol>
        </div>
        <div className="text-black pt-10">
          <h3 className="font-bold upperca  se py-2 text-xl text-white bg-black rounded-lg mx-auto w-2/6 mb-8 text-center">CONCEPT NOTE</h3>
          <div className="text-justify pb-10">
            <h5 className="font-semibold uppercase py-2 text-lg text-red-600 rounded-lg">BD23.Song MLM - Bidaya</h5>
            <div className="flex flex-col space-y-3 text-lg/6">
              <p className="">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sint, vel amet! Nesciunt expedita, cumque nisi, omnis nam molestias aut excepturi itaque rerum neque adipisci. Exercitationem laudantium quia in ut nobis!</p>
              <div className="">
                <p>Category: W (Stage programs having elimination at UG and final at DH)</p>
                <p>Time:  4 minutes  </p>
                <p>Skill: Presentation </p>
                <p>No. of Candidates: 2</p>
              </div>
            </div>
          </div>
          <div className="text-justify pb-10">
            <h5 className="font-semibold uppercase py-2 text-lg text-red-600 rounded-lg">BD23.Song MLM - Bidaya</h5>
            <div className="flex flex-col space-y-3 text-lg/6">
              <p className="">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sint, vel amet! Nesciunt expedita, cumque nisi, omnis nam molestias aut excepturi itaque rerum neque adipisci. Exercitationem laudantium quia in ut nobis!</p>
              <div className="">
                <p>Category: W (Stage programs having elimination at UG and final at DH)</p>
                <p>Time:  4 minutes  </p>
                <p>Skill: Presentation </p>
                <p>No. of Candidates: 2</p>
              </div>
            </div>
          </div>
          <div className="text-justify pb-10">
            <h5 className="font-semibold uppercase py-2 text-lg text-red-600 rounded-lg">BD23.Song MLM - Bidaya</h5>
            <div className="flex flex-col space-y-3 text-lg/6">
              <p className="">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sint, vel amet! Nesciunt expedita, cumque nisi, omnis nam molestias aut excepturi itaque rerum neque adipisci. Exercitationem laudantium quia in ut nobis!</p>
              <div className="">
                <p>Category: W (Stage programs having elimination at UG and final at DH)</p>
                <p>Time:  4 minutes  </p>
                <p>Skill: Presentation </p>
                <p>No. of Candidates: 2</p>
              </div>
            </div>
          </div>
          <div className="text-justify pb-10">
            <h5 className="font-semibold uppercase py-2 text-lg text-red-600 rounded-lg">BD23.Song MLM - Bidaya</h5>
            <div className="flex flex-col space-y-3 text-lg/6">
              <p className="">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sint, vel amet! Nesciunt expedita, cumque nisi, omnis nam molestias aut excepturi itaque rerum neque adipisci. Exercitationem laudantium quia in ut nobis!</p>
              <div className="">
                <p>Category: W (Stage programs having elimination at UG and final at DH)</p>
                <p>Time:  4 minutes  </p>
                <p>Skill: Presentation </p>
                <p>No. of Candidates: 2</p>
              </div>
            </div>
          </div>

        </div>
      </div>

      {/* Schedule Table */}
      <div className=" text-black break-after-page w-full mx-3 break-inside-avoid py-10">
        <h3 className="font-bold uppercase py-2 text-xl text-white bg-black rounded-lg mx-auto w-1/4 mb-8 text-center">SCHEDULE</h3>
        <table className="text-center text-lg border border-black w-full">
          <thead>
            <tr className='bg-black text-white font-semibold '>
              <td className="p-2">SI.NO</td>
              <td className="p-2">PROGRAM</td>
              <td className="p-2">CATEGORY</td>
              <td className="p-2">DATE</td>
              <td className="p-2">TIME</td>
            </tr>
          </thead>
          <tbody>
            <tr className=''>
              <td className="border border-black">1</td>
              <td className="border border-black">CONVERSATION MLM</td>
              <td className="border border-black">BIDAYA</td>
              <td className="border border-black">Nov 29, Wed</td>
              <td className="border border-black">10:30 PM - 12:30 PM</td>
            </tr>
            <tr className=''>
              <td className="border border-black">1</td>
              <td className="border border-black">CONVERSATION MLM</td>
              <td className="border border-black">BIDAYA</td>
              <td className="border border-black">Nov 29, Wed</td>
              <td className="border border-black">10:30 PM - 12:30 PM</td>
            </tr>
            <tr className=''>
              <td className="border border-black">1</td>
              <td className="border border-black">CONVERSATION MLM</td>
              <td className="border border-black">BIDAYA</td>
              <td className="border border-black">Nov 29, Wed</td>
              <td className="border border-black">10:30 PM - 12:30 PM</td>
            </tr>
            <tr className=''>
              <td className="border border-black">1</td>
              <td className="border border-black">CONVERSATION MLM</td>
              <td className="border border-black">BIDAYA</td>
              <td className="border border-black">Nov 29, Wed</td>
              <td className="border border-black">10:30 PM - 12:30 PM</td>
            </tr>
            <tr className=''>
              <td className="border border-black">1</td>
              <td className="border border-black">CONVERSATION MLM</td>
              <td className="border border-black">BIDAYA</td>
              <td className="border border-black">Nov 29, Wed</td>
              <td className="border border-black">10:30 PM - 12:30 PM</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Feedback Section */}
      <div className="h-screen py-16 w-full flex flex-col justify-between items-center feedback">
        <h3 className="font-bold mb-2 text-4xl">FEEDBACK</h3>
        <div className='border-0 border-b border-gray-600 w-full'>
          <div className='border-0 border-y border-gray-200 h-10 w-full text-white my-10'> </div>
          <div className='border-0 border-y border-gray-200 h-10 w-full text-white my-10'> </div>
          <div className='border-0 border-y border-gray-200 h-10 w-full text-white my-10'>  </div>
          <div className='border-0 border-y border-gray-200 h-10 w-full text-white my-10'>  </div>
          <div className='border-0 border-y border-gray-200 h-10 w-full text-white my-10'>  </div>
          <div className='border-0 border-y border-gray-200 h-10 w-full text-white my-10'>  </div>
          <div className='border-0 border-y border-gray-200 h-10 w-full text-white my-10'>  </div>
          <div className='border-0 border-y border-gray-200 h-10 w-full text-white my-10'>  </div>
          <div className='border-0 border-y border-gray-200 h-10 w-full text-white my-10'>  </div>
          <div className='border-0 border-y border-gray-200 h-10 w-full text-white my-10'>  </div>
          <div className='border-0 border-y border-gray-200 h-10 w-full text-white my-10'>  </div>
        </div>
      </div>
      <div className="h-screen w-screen -ml-12 bg-white last-page">
        <img src="/img/back_cover.jpg" alt="back_cover" className="w-full h-full" />
      </div>

      {/* Print Button - Only visible on screen, not in print */}
      <div className="print:hidden mt-8 text-center">
        <button
          onClick={() => window.print()}
          className="bg-purple-800 text-white px-6 py-2 rounded hover:bg-purple-900"
        >
          Print Sheet
        </button>
      </div>
    </div>
  );
});

export default JudgeSheet;
