import React, { useState } from 'react';
import { useReactToPrint } from "react-to-print";
import { useQuery } from '@apollo/client';

import HallTicket from '../../components/candidates/HallTicket';
import { GET_CANDIDATE_PARTICIPANTIONS } from '../../graphql/queries/participation';
import { GET_CANDIDATE_BY_CHESTNO } from '../../graphql/queries/candidates';
import Skeleton from '../../components/UIElements/Skeleton';

interface ProgramItem {
  code: string;
  name: string;
  date?: string;
  sign?: string;
}

interface HallTicketProps {
  ticketNumber?: string;
  studentName?: string;
  group?: string;
  course?: string;
  institution?: string;
  dob?: string;
  programs?: ProgramItem[];
}

interface Candidate {
  id: string;
  name: string;
  chestNo: string;
  dob: string;
  category: {
    id: string;
    name: string;
  }
  institution: {
    id: string;
    name: string;
    groupId: {
      id: string;
      name: string;
    }
  }
};

interface Participation {
  id: string;
  candidate: {
    id: string;
    name: string;
    chestNo: string;
    dob: string;
    category: {
      id: string;
      name: string;
    }
  };
  institution: {
    id: string;
    name: string;
    groupId: {
      id: string;
      name: string;
    }
  }
  program: {
    programCode: string;
    skill: {
      id: string;
      name: string;
    };
    name: string;
    id: string;
    programCategory: {
      id: string;
      name: string;
    };
  };
}

const HallTicketPage: React.FC<HallTicketProps> = () => {
  const [chestNo, setChestNo] = useState("");
  const [participations, setParticipation] = useState<Participation[]>([]);
  const [candidate, setCandidate] = useState<Candidate>();
  const [loading, setLoading] = useState(false);

  const componentRef = React.useRef(null);

  const handlePrint = useReactToPrint({
    contentRef: componentRef
  });

  const { data: candidateData, loading: candidateLoading } = useQuery(GET_CANDIDATE_BY_CHESTNO, {
    variables: { chestNo: chestNo },
    skip: chestNo === "",
  });
  const { data: participationData, loading: participationLoading } = useQuery(GET_CANDIDATE_PARTICIPANTIONS, {
    variables: { candidateId: candidateData?.candidateByChestNo?.id?.toString() },
    skip: !candidateData,
  });

  const candidatePrograms = () => {
    setLoading(true);
    if (participationData && participationData.candidateParticipants) {
      setCandidate(candidateData?.candidateByChestNo)
      setParticipation(participationData.candidateParticipants);
    } else {
      console.log("Participation Can't fetch");
    }
    setLoading(false);
  };

  const handlePrintClick = async () => {
    await candidatePrograms();
    handlePrint();
  };

  return (
    <div className="gap-2">
      <div className="flex items-center justify-center gap-3 my-4 ">
        <input
          type="text"
          placeholder="Search by Chest Number"
          className="border-2 border-gray-300 bg-white h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none"
          onChange={(e) => setChestNo(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter")
              !loading && !participationLoading && !candidateLoading  && handlePrintClick();
          }}
        />
        <button
          type="button"
          disabled={loading || participationLoading || candidateLoading}
          className={`px-4 py-2 font-bold rounded transition duration-300 ${loading || participationLoading || candidateLoading
              ? 'bg-gray-400 text-gray-700 cursor-not-allowed'
              : 'bg-blue-500 text-white hover:bg-blue-700'
            }`}
          onClick={handlePrintClick}
        >
          {loading || participationLoading || candidateLoading ? "Loading Data..." : "Print Hall Ticket"}
        </button>
      </div>
      {loading ?
        <Skeleton />
        : <HallTicket
          ticketNumber={candidate?.chestNo}
          studentName={candidate?.name}
          group={candidate?.institution?.groupId?.name}
          institution={candidate?.institution?.name}
          course={candidate?.category?.name}
          dob={candidate?.dob ? new Date(Number(candidate.dob)).toLocaleDateString() : ''}
          programs={participations.map(participation => ({ code: participation.program?.programCode, name: participation.program?.name }))}
          componentRef={componentRef}
        />
      }
    </div>
  )
}

export default HallTicketPage;