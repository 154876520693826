import { gql, useQuery } from "@apollo/client";
import React, { useEffect, useRef, useState } from "react";
import Skeleton from "../../../UIElements/Skeleton";

const ELIMINATION_RESULT_COUNT_BY_STATUS_WITH_INSITUTION = gql`
  query EliminationResultCountByStatusWithInsitution(
    $group: ID
    $categories: [ID]
  ) {
    eliminationResultCountByStatusWithInsitution(
      group: $group
      categories: $categories
    ) {
      shortName
      fullName
      photo
      scores {
        Entering
        Prepared
        To_Publish
        Published
        Announced
      }
    }
  }
`;

const GET_GROUPS = gql`
  query Groups {
    groups {
      id
      name
    }
  }
`;

const GET_CATEGORIES = gql`
  query Categories {
    categories {
      id
      name
    }
  }
`;

const Leaderboard: React.FC = () => {
  const [selectedGroup, setSelectedGroup] = useState("");
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const { data, loading, error } = useQuery(
    ELIMINATION_RESULT_COUNT_BY_STATUS_WITH_INSITUTION,
    {
      variables: {
        group: selectedGroup,
        categories: selectedCategories,
      },
      fetchPolicy: "network-only",
    }
  );

  const { data: groupData } = useQuery(GET_GROUPS);
  const { data: categoryData } = useQuery(GET_CATEGORIES);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleCategoriesCheckbox = (categoryId: string) => {
    setSelectedCategories((prevSelected) => {
      if (prevSelected.includes(categoryId)) {
        return prevSelected.filter((id) => id !== categoryId);
      } else {
        return [...prevSelected, categoryId];
      }
    });
  };

  if (error) {
    return <p>Failed to fatech data {error.message}</p>;
  }

  return (
    <div className="p-4 bg-white rounded-lg shadow-lg">
      <div className="flex justify-between mb-3">
        <h2 className="text-center text-lg font-bold text-gray-700 mb-4">
          Leaderboard
        </h2>
        <div className="flex">
          {groupData?.groups?.length > 1 && (
            <div className="w-max mr-2">
              <select
                required
                value={selectedGroup}
                onChange={(e) => {
                  setSelectedGroup(e.target.value);
                }}
                className="h-12 p-2 w-full border-2 border-gray-300 rounded-lg bg-transparent text-gray-900 placeholder-gray-500 dark:placeholder-gray-300 dark:text-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 dark:focus:border-blue-300 dark:focus:ring-blue-500 transition duration-200 ease-in-out sm:text-sm"
              >
                <option value="">Select Group</option>
                {groupData?.groups?.map((group: any) => (
                  <option key={group.id} value={group.id}>
                    {group.name}
                  </option>
                ))}
              </select>
            </div>
          )}
          <div className="relative" ref={dropdownRef}>
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="btn h-full bg-blue-300 inline-flex items-center justify-center text-sm font-medium text-blue-700 hover:text-white hover:bg-blue-500"
            >
              <i className="mgc_filter_line text-xl"></i>
            </button>
            {isOpen && (
              <div className="absolute py-5 bg-white right-0 shadow-2xl rounded">
                {categoryData?.categories?.map(
                  (category: any, index: number) => (
                    <div
                      key={index}
                      className="flex items-center px-3 py-1 cursor-pointer"
                    >
                      <input
                        type="checkbox"
                        id={`category-${category.id}`}
                        checked={selectedCategories.includes(category.id)}
                        onChange={() => handleCategoriesCheckbox(category.id)}
                        className="mx-2 focus:ring-transparent focus:outline-none"
                      />
                      <label
                        htmlFor={`category-${category.id}`}
                        className="text-md font-semibold"
                      >
                        {category.name}
                      </label>
                    </div>
                  )
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <table className="w-full border-collapse border border-gray-200">
        <thead>
          <tr className="bg-gray-100">
            <th className="text-left px-6 py-2 font-bold text-gray-800">
              Institution
            </th>
            <th className="text-center font-bold text-gray-800 hidden md:table-cell">
              Entering
            </th>
            <th className="text-center font-bold text-gray-800 hidden md:table-cell">
              Prepared
            </th>
            <th className="text-center font-bold text-gray-800 hidden md:table-cell">
              To_Publish
            </th>
            <th className="text-center font-bold text-gray-800 hidden md:table-cell">
              Published
            </th>
            <th className="text-center font-bold text-gray-800 hidden md:table-cell">
              Announced
            </th>
            <th className="text-center font-bold text-gray-800">Total</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <Skeleton />
          ) : (
            data?.eliminationResultCountByStatusWithInsitution &&
            [...data.eliminationResultCountByStatusWithInsitution] 
              .sort((a: any, b: any) => {
                const totalA =
                  (a.scores?.Entering ?? 0) +
                  (a.scores?.Prepared ?? 0) +
                  (a.scores?.To_Publish ?? 0) +
                  (a.scores?.Published ?? 0) +
                  (a.scores?.Announced ?? 0);

                const totalB =
                  (b.scores?.Entering ?? 0) +
                  (b.scores?.Prepared ?? 0) +
                  (b.scores?.To_Publish ?? 0) +
                  (b.scores?.Published ?? 0) +
                  (b.scores?.Announced ?? 0);

                return totalB - totalA; // Sort in descending order
              })
              .map((item: any, index: number) => (
                <tr
                  key={index}
                  className="bg-white hover:bg-gray-50 border-b border-gray-200"
                >
                  <td className="px-4 py-2 text-left flex items-center gap-4">
                    <div className="w-10 h-10 bg-gray-300 rounded-full overflow-hidden">
                      {item.photo ? (
                        <img
                          src={item.photo}
                          alt={item.fullName || "Institution Logo"}
                          className="w-full h-full object-cover"
                        />
                      ) : null}
                    </div>
                    <div>
                      <p className="font-bold text-gray-800">
                        {item.shortName}
                      </p>
                      <p className="text-xs text-gray-600">{item.fullName}</p>
                    </div>
                  </td>{" "}
                  <td className="text-center hidden md:table-cell">
                    {item.scores?.Entering ?? "N/A"}
                  </td>
                  <td className="text-center hidden md:table-cell">
                    {item.scores?.Prepared ?? "N/A"}
                  </td>
                  <td className="text-center hidden md:table-cell">
                    {item.scores?.To_Publish ?? "N/A"}
                  </td>
                  <td className="text-center hidden md:table-cell">
                    {item.scores?.Published ?? "N/A"}
                  </td>
                  <td className="text-center hidden md:table-cell">
                    {item.scores?.Announced ?? "N/A"}
                  </td>
                  <td className="text-center">
                    {item?.scores?.Entering +
                      item?.scores?.Prepared +
                      item?.scores?.To_Publish +
                      item?.scores?.Published +
                      item?.scores?.Announced}
                  </td>
                </tr>
              ))
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Leaderboard;
