// types.ts
interface PaginationProps {
  totalPages: number;
  page?: number;
  onPageChange?: (page: number) => void;
  className?: string;
}

// Pagination.tsx
import React, { useState, FormEvent, ChangeEvent, useEffect } from "react";

const Pagination: React.FC<PaginationProps> = ({
  totalPages,
  page = 1,
  onPageChange,
  className = "",
}) => {
  const [currentPage, setCurrentPage] = useState<number>(page);
  const [goToPage, setGoToPage] = useState<string>("");

  useEffect(() => {
    setCurrentPage(page);
  }, [page]);

  const handlePageChange = (page: number): void => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
      onPageChange?.(page);
    }
  };

  const handleGoToPage = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    const pageNumber = parseInt(goToPage);
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
      onPageChange?.(pageNumber);
      setGoToPage("");
    }
  };

  const renderPageNumbers = (): JSX.Element[] => {
    const pages: JSX.Element[] = [];
    let startPage = Math.max(1, currentPage - 2);
    let endPage = Math.min(totalPages, startPage + 4);

    if (endPage - startPage < 4) {
      startPage = Math.max(1, endPage - 4);
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`w-8 h-8 mx-1 rounded-full flex items-center justify-center
              ${
                currentPage === i
                  ? "bg-blue-600 text-white"
                  : "text-gray-600 hover:bg-gray-100"
              }`}
          aria-label={`Go to page ${i}`}
          aria-current={currentPage === i ? "page" : undefined}
        >
          {i}
        </button>
      );
    }
    return pages;
  };

  return (
    <div
      className={`flex items-center justify-center space-x-2 p-4 ${className}`}
    >
      {/* Previous button */}
      <button
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className="w-8 h-8 flex items-center justify-center text-gray-600 disabled:opacity-50 disabled:cursor-not-allowed"
        aria-label="Previous page"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
            clipRule="evenodd"
          />
        </svg>
      </button>

      {/* First page */}
      {currentPage > 4 && (
        <>
          <button
            onClick={() => handlePageChange(1)}
            className="w-8 h-8 mx-1 rounded-full flex items-center justify-center text-gray-600 hover:bg-gray-100"
            aria-label="Go to first page"
          >
            1
          </button>
          <span className="mx-1" aria-hidden="true">
            ...
          </span>
        </>
      )}

      {/* Page numbers */}
      {renderPageNumbers()}

      {/* Last page */}
      {currentPage < totalPages - 3 && (
        <>
          <span className="mx-1" aria-hidden="true">
            ...
          </span>
          <button
            onClick={() => handlePageChange(totalPages)}
            className="w-8 h-8 mx-1 rounded-full flex items-center justify-center text-gray-600 hover:bg-gray-100"
            aria-label={`Go to last page, page ${totalPages}`}
          >
            {totalPages}
          </button>
        </>
      )}

      {/* Next button */}
      <button
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className="w-8 h-8 flex items-center justify-center text-gray-600 disabled:opacity-50 disabled:cursor-not-allowed"
        aria-label="Next page"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
            clipRule="evenodd"
          />
        </svg>
      </button>

      {/* Go to page input */}
      <form
        onSubmit={handleGoToPage}
        className=" items-center ml-4 hidden sm:flex"
      >
        <span className="text-gray-600 mr-2">Go to page</span>
        <input
          type="number"
          min={1}
          max={totalPages}
          value={goToPage}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setGoToPage(e.target.value)
          }
          className="w-16 h-8 px-2 border border-gray-300 rounded-md"
          aria-label="Go to page number"
        />
        <button
          type="submit"
          className="ml-2 px-3 h-8 bg-blue-600 text-white rounded-md hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed"
          disabled={!goToPage}
        >
          Go
        </button>
      </form>
    </div>
  );
};

export default Pagination;
