import { gql } from "@apollo/client";

export const GET_ITEM_REGISTRATIONS = gql`
  query ItemRegistrations($search: String, $institutionId: String, $categoryId: String, $programId: String, $groupId: String, $status: String, $offset: Int, $limit: Int) {
    itemRegistrations(search: $search,institutionId: $institutionId, categoryId: $categoryId, programId: $programId, groupId: $groupId, status: $status, offset: $offset, limit: $limit) {
      id
      title
      description
      program {
        id
        name
        category{
          id
          name
        }
        programCode
      }
      participation {
        id 
      }  
      institution {
        id
        name
        groupId {
          id
          name
        }
      }
      file
      link
      status
      remarks
      createdAt
    }
  }
`;

export const SUBSCRIPTION = gql`
  subscription {
    registrationAdded {
      id
      title
      description
      program {
        id
        name
        category{
          id
          name
        }
        programCode
      }
      participation {
        id 
      }  
      institution {
        id
        name
        groupId {
          id
          name
        }
      }
      file
      link
      status
      remarks
      createdAt
    }
  }
`;
