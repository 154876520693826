import React, { useEffect, useRef } from "react";
import { DELETE_GALLERY } from "../../graphql/mutations/gallery";
import { useMutation } from "@apollo/client";
import toast from "react-hot-toast";

interface Gallery {
  id: string;
  title: string;
  date: string;
  photo: string;
  time: string;
  status: string;
}

interface ProgramCardProps {
  image: Gallery;
  onEdit: (gallery: Gallery) => void;
  onView: (gallery: Gallery) => void;
  onStatus: (gallery: Gallery) => void;
  isDropdownOpen: boolean;
  toggleDropdown: (id: string) => void;
  refetch: any;
}

const ProgramCard: React.FC<ProgramCardProps> = ({
  image,
  onEdit,
  onView,
  onStatus,
  refetch,
  isDropdownOpen,
  toggleDropdown,
}) => {
  const [deleteGallery] = useMutation(DELETE_GALLERY);
  const actionModelRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(e: MouseEvent) {
      if (
        actionModelRef.current &&
        !actionModelRef.current.contains(e?.target as Node)
      ) {
        toggleDropdown(image?.id);
      }
    }

    if (isDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen]);

  const getStatusColor = (status: string) => {
    switch (status) {
      case "published":
        return "bg-green-200 text-green-600";
      case "draft":
        return "bg-blue-100 text-blue-600";
      default:
        return "bg-gray-200 text--gray-600";
    }
  };

  const handleDelete = async (id: string) => {
    toggleDropdown(id);
    if (confirm("Are you sure you want to delete the image?")) {
      const loadToast = toast.loading("Deleting....");
      try {
        await deleteGallery({ variables: { id } });
        toast.dismiss(loadToast);
        toast.success("Gallery deleted successfully");
        refetch();
      } catch (error) {
        console.error("Error deleting image:", error);
        toast.dismiss(loadToast);
        toast.error("Failed to delete image");
      }
    }
  };
  return (
    <div className="relative border border-gray-300 rounded-lg shadow-md bg-white min-w-48 max-w-xs">
      <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <div
          style={{ backgroundImage: "url(" + image.photo + ")" }}
          className="w-full h-36 bg-cover flex items-start justify-end p-2 relative shadow-sm shadow-gray-100"
        >
          <div className="flex justify-between m-2 flex-wrap items-center w-full">
            <span
              className={`ml-2 px-2 py-1 rounded-full text-[10px] text-center font-semibold  ${getStatusColor(
                image?.status
              )}`}
            >
              {image?.status?.toUpperCase()}
            </span>
            <i
              className="mgc_more_2_fill text-lg text-black cursor-pointer bg-gray-200 shadow-gray-100 shadow-sm px-2 py-0.5 bg-opacity-30 rounded-full"
              onClick={() => toggleDropdown(image.id)} // Toggle only the dropdown for this card
            ></i>

          </div>
          {isDropdownOpen && (
            <div
              ref={actionModelRef}
              className="transition-opacity duration-300 mt-2 bg-white shadow-lg border rounded-lg p-2 border-gray-200 dark:border-gray-700 dark:bg-gray-800 absolute top-2 right-10 w-32 z-10"
            >
              <ul>
                <li>
                  <button
                    onClick={() => onView(image)}
                    className="block w-full px-4 py-2 text-left hover:bg-gray-100"
                  >
                    View
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => onEdit(image)}
                    className="block w-full px-4 py-2 text-left hover:bg-gray-100"
                  >
                    Edit
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => onStatus(image)}
                    className="block w-full px-4 py-2 text-left hover:bg-gray-100"
                  >
                    {image.status == "published"? "Draft":"Publish"}
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => handleDelete(image.id)}
                    className="w-full px-4 py-2 text-left text-red-500 hover:bg-gray-100"
                  >
                    Delete
                  </button>
                </li>
              </ul>
            </div>
          )}
        </div>
        <div className="px-4 py-2">
          <h3 className="font-semibold text-gray-800 text-sm">{image.title}</h3>
          <div className="text-gray-500 text-xs flex items-center gap-1">
            <i className="mgc_calendar_line text-lg mb-0.5"></i>
            <p>
              {image.date
                ? new Date(Number(image.date)).toLocaleDateString('en-GB')
                : ""}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgramCard;
