// src/components/UIElements/DetailsModal.tsx

import React, { useRef, useState } from "react";
import DetailsModal from "../UIElements/DetailsModel";
import { useQuery } from "@apollo/client";
import Skeleton from "../UIElements/Skeleton";
import { Link } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import HallTicket from "./HallTicket";
import { Candidate } from "./CandidatesTable";
import { GET_CANDIDATE_PARTICIPANTIONS } from "./queryCandidates";

interface DetailsViewProps {
  isModalOpen: boolean;
  setModalOpen: (isModalOpen: boolean) => void;
  candidate: Candidate | null;
}
const DetailsView: React.FC<DetailsViewProps> = ({
  isModalOpen,
  setModalOpen,
  candidate,
}) => {
  const [isPersonalDetails, setIsPersonalDetails] = useState(true);
  const componentRef = useRef<HTMLDivElement>(null);

  const toggleCandidateDetailSwitch = (bool: boolean) => {
    setIsPersonalDetails(bool);
  };

  const { data, loading, error } = useQuery(GET_CANDIDATE_PARTICIPANTIONS, {
    variables: { candidateId: candidate?.id },
    skip: !candidate,
  });

  const handlePrint = useReactToPrint({
    contentRef: componentRef,
  });

  return (
    <>
      <DetailsModal
        title="Candidate Details"
        isOpen={isModalOpen}
        setIsOpen={setModalOpen}
      >
        {/* <div className="w-full h-52 sm:h-32 bg-red-200 rounded-xl dark:bg-blue-800"></div> */}
        <img
          className="w-full h-52 sm:h-32 rounded-xl"
          src="./img/bg.jpg"
          alt="bg"
        />
        <div className="bg-red-50 dark:bg-blue-50 opacity-95  border-2 border-white p-5 w-11/12 -mt-36 sm:-mt-14 mx-auto rounded-xl flex flex-col sm:flex-row justify-between items-center ">
          <div className="flex justify-between items-center flex-col sm:flex-row w-full">
            <div className="w-40 sm:w-16 h-40 sm:h-16 bg-orange-600 rounded-xl p-0.5">
              <img
                className="inline-block h-full w-full bg-rose-500 dark:bg-yellow-400 rounded-xl p-0.5 border border-pink-300 dark:border-gray-700 object-cover"
                src={`${
                  candidate?.admissionNo === "001"
                    ? candidate?.photo
                    : `https://haris740.github.io/sibaq-image-uploads/profiles/${candidate?.chestNo[0]}000/${candidate?.chestNo}.png`
                }`}
                alt={candidate?.name}
              />
            </div>
            <div className="sm:flex justify-between sm:flex-row-reverse w-10/12 ">
              <div className="flex flex-col justify-center items-center sm:items-center">
                <p className=" hidden sm:block text-black text-xs font-light">
                  Chest Number
                </p>
                <h3 className="text-black text-2xl font-bold mt-1">
                  {candidate?.chestNo}
                </h3>
              </div>
              <div className="flex flex-col justify-center items-center sm:items-start">
                <h3 className="text-green-500 text-lg font-semibold">
                  {candidate?.name}
                </h3>
                <p className="text-black text-xs">
                  {candidate?.institution?.name}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white w-4/5 sm:w-3/5 mx-auto rounded-xl flex flex-col">
          <div
            className="inline-flex rounded-md shadow-inner mt-2 w-full"
            role="group"
          >
            <button
              type="button"
              onClick={() => {
                toggleCandidateDetailSwitch(true);
              }}
              className={`px-0.5 py-0.5 text-xs sm:text-md font-medium border-orange-500 border-2 w-1/2 ${
                isPersonalDetails
                  ? "z-10 bg-orange-500 text-white dark:border-white dark:text-white dark:bg-orange-200"
                  : "text-orange-500 bg-white border hover:bg-orange-500 hover:text-white"
              }`}
            >
              PERSONAL DETAILS
            </button>
            <button
              type="button"
              onClick={() => {
                toggleCandidateDetailSwitch(false);
              }}
              className={`px-0.5 py-0.5 text-xs sm:text-md font-medium border-orange-500 border-2 w-1/2  ${
                isPersonalDetails
                  ? "text-orange-500 bg-white border hover:bg-orange-500 hover:text-white"
                  : "z-10 bg-orange-500 text-white dark:border-white dark:text-white dark:bg-orange-200"
              }`}
            >
              PROGRAMS
            </button>
          </div>
        </div>
        <div className="w-full h-32 p-4 scroll-auto mb-4">
          {isPersonalDetails ? (
            <div className="w-full flex flex-col text-black dark:text-white text-nowrap text-center text-sm justify-around h-full px-3 sm:px-12 pt-3 space-y-2 sm:space-y-5">
              <div className="flex justify-center sm:justify-start space-y-2 sm:space-y-0 sm:space-x-16 flex-col sm:flex-row">
                <div className="font-light flex justify-between">
                  UG Admission No:{" "}
                  <strong className="font-semibold pl-1">
                    {candidate?.admissionNo}
                  </strong>
                </div>
                <div className="font-light flex justify-between">
                  Category:{" "}
                  <strong className="font-semibold pl-1">
                    {candidate?.category?.name}
                  </strong>
                </div>
              </div>
              <div className="flex justify-center sm:justify-start space-y-2 sm:space-y-0 sm:space-x-14 flex-col sm:flex-row">
                <div className="font-light flex justify-between">
                  Date of Birth:{" "}
                  <strong className="font-semibold pl-1">
                    {candidate?.dob
                      ? new Date(Number(candidate?.dob)).toLocaleDateString()
                      : ""}
                  </strong>
                </div>
                <div className="font-light flex justify-between">
                  Class:{" "}
                  <strong className="font-semibold pl-1">
                    {candidate?.class?.name}
                  </strong>
                </div>
                <div className="font-light flex justify-between">
                  Gender:{" "}
                  <strong className="font-semibold pl-1">
                    {candidate?.gender}
                  </strong>
                </div>
              </div>
              <div className="text-center sm:text-start font-light flex-row flex">
                Email:{" "}
                <strong className="font-semibold pl-1">
                  {candidate?.email}
                </strong>
              </div>
              <div className="w-full grid place-items-center">
                <button
                  onClick={() => handlePrint()}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-xl"
                >
                  Print Hall Ticket
                </button>
              </div>
            </div>
          ) : (
            // <div className="w-full flex">

            <div className="overflow-y-auto relative w-full no-scrollbar">
              <table className="w-full divide-y divide-gray-300 dark:divide-gray-700">
                <thead className="bg-slate-300 bg-opacity-20 border-t dark:bg-slate-800 divide-gray-300 dark:border-gray-700">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 ps-4 pe-3 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
                    >
                      SI No.
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
                    >
                      Code
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
                    >
                      Code Letter
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
                    >
                      Presence
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900 dark:text-gray-200"
                    >
                      Program Status
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
                    >
                      Category
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900 dark:text-gray-200"
                    >
                      Skill
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                  {loading ? (
                    <tr>
                      <td colSpan={100} className="pt-3">
                        <Skeleton />
                      </td>
                    </tr>
                  ) : error ? (
                    <tr>
                      <td colSpan={100} className="pt-3">
                        Error fetching candidates: {error.message}
                      </td>
                    </tr>
                  ) : data?.candidateParticipants?.length ? (
                    data?.candidateParticipants?.map(
                      (participation: any, index: number) => (
                        <tr key={participation?.id}>
                          <td className="whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900 dark:text-gray-200">
                            {index + 1}
                          </td>

                          <td className="whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900 dark:text-gray-200">
                            {participation?.program?.programCode}
                          </td>
                          <td className="whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900 dark:text-gray-200">
                            <Link
                              to={`/programs/${participation?.program?.id}`}
                            >
                              {participation?.program?.name}
                            </Link>
                          </td>
                          <td className="whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900 dark:text-gray-200">
                            <p
                              className={`${
                                participation?.codeLetter
                                  ? "bg-green-500 w-min px-2  py-1 rounded text-white font-semibold"
                                  : ""
                              } `}
                            >
                              {" "}
                              {participation?.codeLetter || "N/A"}
                            </p>
                          </td>
                          <td className="whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900 dark:text-gray-200">
                            {participation?.isPresent === true
                              ? "Present"
                              : "Absent"}
                          </td>
                          <td className="whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900 dark:text-gray-200">
                            {participation?.program?.eliminationRegistrationStatus?.find(
                              (item: any) =>
                                item.key ==
                                participation?.institution?.groupId?.id
                            )?.value ?? "Not Started"}
                          </td>
                          <td className="whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900 dark:text-gray-200">
                            {participation?.program?.programCategory?.name}
                          </td>
                          <td className="whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900 dark:text-gray-200">
                            {participation?.program?.skill?.name}
                          </td>
                        </tr>
                      )
                    )
                  ) : (
                    <tr>
                      <td colSpan={100} className="text-center py-4">
                        No participants available.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </DetailsModal>
      <div className="hidden">
        <HallTicket
          ticketNumber={candidate?.chestNo}
          studentName={candidate?.name}
          group={candidate?.institution?.groupId?.name}
          institution={candidate?.institution?.name}
          course={candidate?.category?.name}
          dob={
            candidate?.dob
              ? new Date(Number(candidate.dob)).toLocaleDateString()
              : ""
          }
          programs={data?.candidateParticipants?.map((participation: any) => ({
            code: participation.program?.programCode,
            name: participation.program?.name,
          }))}
          componentRef={componentRef}
        />
      </div>
    </>
  );
};

export default DetailsView;
