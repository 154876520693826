import React, { useEffect, useState } from "react";
import { Config } from "../../ThemeConfig";

const DarkModeToggle: React.FC = () => {
  const [isDarkMode, setDarkMode] = useState(false);

  // Toggle Dark Mode
  const toggleDarkMode = () => {
    setDarkMode(!isDarkMode);
    document.documentElement.setAttribute(
      "data-mode",
      !isDarkMode ? "dark" : "light"
    );
    let savedConfigString = sessionStorage.getItem("__CONFIG__");
    let savedConfig: Config;

    if (savedConfigString) {
      savedConfig = JSON.parse(savedConfigString) as Config;
      savedConfig.theme = !isDarkMode ? "dark" : "light";
      sessionStorage.setItem("__CONFIG__", JSON.stringify(savedConfig));
    }
  };

  useEffect(() => {
    const mode = document.documentElement.getAttribute("data-mode");
    setDarkMode(mode == "dark" ? true : false);
  }, []);

  return (
    // {/* Light/Dark Toggle Button */}
    <div className="flex">
      <button
        id="light-dark-mode"
        type="button"
        className="nav-link p-2"
        onClick={toggleDarkMode}
      >
        <span className="sr-only">Light/Dark Mode</span>
        <span className="flex items-center justify-center h-6 w-6">
          <i
            className={
              isDarkMode ? "mgc_sun_line text-2xl" : "mgc_moon_line text-2xl"
            }
          ></i>
        </span>
      </button>
    </div>
  );
};

export default DarkModeToggle;
