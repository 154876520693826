import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { GET_PROGRAM_BY_ID } from '../../graphql/queries/program';
import { GET_PROGRAM_PARTICIPANTS } from '../../graphql/queries/participation';

export interface IJudges {
  id: string;
  name: string;
  place: string;
  host: {
    name: string;
    id: string;
    place: string;
    group: {
      id: string;
      name: string
    }
  };
  email: string;
  phoneNumber: string;
}
interface Program {
  id: string;
  name: string;
  programCode: string;
  concept: string;
  duration: number;
  noOfCandidates: number;
  skill: {
    id: string;
    name: string;
  };
  category: {
    id: string;
    name: string;
  };
  eliminationJudge: IJudges[];
  eliminationStartingTime: string;
  finalDate: string;
  eliminationDate: string;
  eliminationVenue: {
    id: string;
    name: string;
  };
  finalJudge: IJudges[];
  finalStartingTime: string;
  finalVenue: {
    id: string;
    name: string;
  };
  programCategory: {
    id: string;
    name: string;
    details: string;
  }
}
interface JudgementSheetProps {
  judge: IJudges;
  programId: string;
  round: string;
}

const JudgementSheet = React.forwardRef<HTMLDivElement, JudgementSheetProps>((props, ref) => {

  const { judge, programId } = props;
  // console.log(programId);
  const { data: programData } = useQuery(GET_PROGRAM_BY_ID, { variables: { id: programId } });
  const { data: participantsData } = useQuery(GET_PROGRAM_PARTICIPANTS, { variables: { programId: programId, groupId: judge?.host?.group?.id } });
  const [programList, setProgramList] = useState<Program>();

  useEffect(() => {
    if (programId) {
      setProgramList(programData?.program);
    }
  }, [programData, programId, programList, participantsData]);

  return (
    <div
      className="judgement-sheet font-cambria max-w-4xl mx-auto px-16 bg-white h-auto" ref={ref} style={{ display: "none" }}  >
      <div className="h-screen flex flex-col justify-start relative w-screen text-black">
        <img src="img/valuation-cover.jpg" alt="" className="h-full w-full -ml-16" />
        {/* Title and Program Details */}
        <h1 className="text-2xl font-extrabold -ml-16 w-full text-center absolute top-64">{programList?.programCode}<span className="mx-2">•</span>{programList?.name}<span className="mx-2">•</span>{programList?.category?.name}</h1>
        {/* Program Description */}
        <div className="text-lg/6 mb-6 text-justify h-fit absolute top-[20rem] w-5/6 ml-2 p-3 ">
          <p className="whitespace-break-spaces">
            {programList?.concept}
          </p>
          <div className="text-left w-4/5 mx-auto mt-6 h-1/6 text-lg">
            <p className="font-semibold">Time: {programList?.duration} Minutes</p>
            <p className="font-semibold ">Skill: {programList?.skill?.name} </p>
            <p className="font-semibold">No. of Candidates: {programList?.noOfCandidates} </p>
          </div>
        </div>
      </div>
      {/* Scoring Table */}
      <div className="h-screen w-screen -ml-16 py-10 px-8 flex justify-around items-center space-x-3">
        <div style={{ backgroundImage: "url(/img/valuation-cover.jpg)" }} className="flex flex-col-reverse justify-center items-center h-full w-32">
          <div className="w-48 flex"><img src="/img/logo.png" alt="" className="block w-full transform mt-52 -rotate-90" /></div>
          <div className="flex justify-start items-end text-black font-semibold h-72">
            <div className="bg-black text-white py-0 px-0.5 h-16 w-8 rounded flex justify-center items-center"><span className='text-xl -rotate-90 whitespace-nowrap'>{programList?.programCode}</span></div>
            <div className="text-3xl font-bold -rotate-90 whitespace-nowrap tracking-wide w-8">{programList?.name} - <span className="font-medium">{programList?.category?.name}</span></div>
          </div>
        </div>
        <div className="flex h-full w-fit justify-center items-center text-black border-2 border-black">
          <div className="flex flex-col-reverse h-full w-12">
            {/* Table Header */}
            <div className="border border-gray-500 p-2 flex justify-center items-center h-32 font-semibold"><span className='text-lg -rotate-90 whitespace-nowrap'>SI.No.</span></div>
            <div className="border border-gray-500 p-2 flex justify-center items-center h-32 font-semibold"><span className='text-lg -rotate-90 whitespace-nowrap'>Chest No.</span></div>
            <div className="border border-gray-500 p-2 flex justify-center items-center h-32 font-semibold"><span className='text-lg -rotate-90 whitespace-nowrap'>Mark 1</span></div>
            <div className="border border-gray-500 p-2 flex justify-center items-center h-32 font-semibold"><span className='text-lg -rotate-90 whitespace-nowrap'>Mark 2</span></div>
            <div className="border border-gray-500 p-2 flex justify-center items-center h-32 font-semibold"><span className='text-lg -rotate-90 whitespace-nowrap'>Mark 3</span></div>
            <div className="border border-gray-500 p-2 flex justify-center items-center h-72 font-semibold"><span className='text-lg -rotate-90 whitespace-nowrap'>Total (/100)</span></div>
            <div className="border border-gray-500 p-2 flex justify-center items-center h-72 font-semibold"><span className='text-lg -rotate-90 whitespace-nowrap'>Remarks</span></div>
          </div>
          {/* {participantsData?.programParticipants?.map((_participant: any, index: any) =>
            <div className="flex flex-col-reverse h-full w-12">
              <div className="border border-gray-400  p-2 flex justify-center items-center h-32 "><span className='text-xl -rotate-90 whitespace-nowrap'>{index + 1}</span></div>
              <div className="border border-gray-400 p-2 flex justify-center items-center h-32 "><span className='text-xl -rotate-90 whitespace-nowrap'>{String.fromCharCode(65 + index)}</span></div>
              <div className="border border-gray-400 p-2 flex justify-center items-center h-32 "><span className='text-xl -rotate-90 whitespace-nowrap'></span></div>
              <div className="border border-gray-400 p-2 flex justify-center items-center h-32 "><span className='text-xl -rotate-90 whitespace-nowrap'></span></div>
              <div className="border border-gray-400 p-2 flex justify-center items-center h-32 "><span className='text-xl -rotate-90 whitespace-nowrap'></span></div>
              <div className="border border-gray-400 p-2 flex justify-center items-center h-72 "><span className='text-xl -rotate-90 whitespace-nowrap'></span></div>
              <div className="border border-gray-400 p-2 flex justify-center items-center h-72 "><span className='text-xl -rotate-90 whitespace-nowrap'></span></div>
            </div>

          )} */}
          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((item: number) => (
            <div className="flex flex-col-reverse h-full w-12">
              <div className="border border-gray-400  p-2 flex justify-center items-center h-32 "><span className='text-xl -rotate-90 whitespace-nowrap'>{item + 1}</span></div>
              <div className="border border-gray-400 p-2 flex justify-center items-center h-32 "><span className='text-xl -rotate-90 whitespace-nowrap'>{String.fromCharCode(65 + item)}</span></div>
              <div className="border border-gray-400 p-2 flex justify-center items-center h-32 "><span className='text-xl -rotate-90 whitespace-nowrap'></span></div>
              <div className="border border-gray-400 p-2 flex justify-center items-center h-32 "><span className='text-xl -rotate-90 whitespace-nowrap'></span></div>
              <div className="border border-gray-400 p-2 flex justify-center items-center h-32 "><span className='text-xl -rotate-90 whitespace-nowrap'></span></div>
              <div className="border border-gray-400 p-2 flex justify-center items-center h-72 "><span className='text-xl -rotate-90 whitespace-nowrap'></span></div>
              <div className="border border-gray-400 p-2 flex justify-center items-center h-72 "><span className='text-xl -rotate-90 whitespace-nowrap'></span></div>
            </div>
          ))}
        </div>
        <div className='h-1/2 text-right flex justify-center items-center -mt-[32rem] w-8'>
          <p className="font-bold -rotate-90 whitespace-nowrap">Name and Sign of the Judge: ____________________________________________________________</p>
        </div>
      </div>
      {/* Feedback Section */}
      <div className="h-screen py-16 w-full flex flex-col justify-between items-center">
        <h3 className="font-bold mb-2 text-4xl">FEEDBACK</h3>
        <div className='border-0 border-y border-gray-200  w-full'>
          <div className='border-0 border-y border-gray-200 h-10 w-full text-white my-10'> </div>
          <div className='border-0 border-y border-gray-200 h-10 w-full text-white my-10'> </div>
          <div className='border-0 border-y border-gray-200 h-10 w-full text-white my-10'>  </div>
          <div className='border-0 border-y border-gray-200 h-10 w-full text-white my-10'>  </div>
          <div className='border-0 border-y border-gray-200 h-10 w-full text-white my-10'>  </div>
          <div className='border-0 border-y border-gray-200 h-10 w-full text-white my-10'>  </div>
          <div className='border-0 border-y border-gray-200 h-10 w-full text-white my-10'>  </div>
          <div className='border-0 border-y border-gray-200 h-10 w-full text-white my-10'>  </div>
          <div className='border-0 border-y border-gray-200 h-10 w-full text-white my-10'>  </div>
          <div className='border-0 border-y border-gray-200 h-10 w-full text-white my-10'>  </div>
          <div className='border-0 border-y border-gray-200 h-10 w-full text-white my-10'>  </div>
          <div className='border-0 border-y border-gray-200 h-10 w-full text-white my-10'>  </div>
        </div>
      </div>
      <div className="h-screen w-screen -m-16 bg-white">
        <img src="/img/back_cover.jpg" alt="back_cover" className="w-full h-full" />
      </div>

      {/* Print Button - Only visible on screen, not in print */}
      <div className="print:hidden mt-8 text-center">
        <button
          onClick={() => window.print()}
          className="bg-purple-800 text-white px-6 py-2 rounded hover:bg-purple-900"
        >
          Print Sheet
        </button>
      </div>
    </div>
  );
});

export default JudgementSheet;
