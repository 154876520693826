import React, { useEffect, useRef, useState } from "react";
import PageTitle from "../../components/PageTitle";
import { gql, useMutation, useQuery } from "@apollo/client";
import CodeLetterContent from "../../components/code-letter-reg/CodeLetterContent";
import RegPrint from "../../components/code-letter-reg/RegPrint";
import { useReactToPrint } from "react-to-print";
import { UPDATE_ELIMINATION_REGISTRATION_STATUS } from "../../graphql/mutations/program";
import Private from "../../context/Private";
import toast from "react-hot-toast";

const GET_PROGRAMS = gql`
  query GetPrograms(
    $page: Int
    $limit: Int
    $search: String
    $programCategoryId: ID
    $categoryId: ID
    $skillId: ID
  ) {
    programs(
      page: $page
      limit: $limit
      search: $search
      programCategoryId: $programCategoryId
      categoryId: $categoryId
      skillId: $skillId
    ) {
      programs {
        category {
          id
          name
        }
        eliminationVenue {
          key
          value {
            id
            name
          }
        }
        eliminationDate {
          key
          value
        }
        eliminationEndingTime {
          key
          value
        }
        eliminationStartingTime {
          key
          value
        }
        finalDate
        finalEndingTime
        finalResultStatus
        finalStartingTime
        finalVenue {
          id
          name
        }
        id
        name
        programCategory {
          id
          name
        }
        programCode
        status
        skill {
          id
          name
        }
      }
      totalPages
      totalPrograms
    }
  }
`;

const GET_PROGRAM_BY_ID = gql`
  query GetProgramById($id: ID!) {
    program(id: $id) {
      id
      name
      programCode
      category {
        id
        name
      }
      eliminationRegistrationStatus {
        key
        value
      }
      eliminationDate {
        key
        value
      }
      eliminationEndingTime {
        key
        value
      }
      eliminationStartingTime {
        key
        value
      }
      eliminationVenue {
        key
        value {
          id
          name
        }
      }
    }
  }
`;

const GET_CATEGORIES = gql`
  query Categories {
    categories {
      id
      name
    }
  }
`;

const GET_PROGRAM_CATEGORIES = gql`
  query ProgramCategories {
    programCategories {
      id
      name
    }
  }
`;

const GET_SKILLS = gql`
  query GetSkills {
    skills {
      id
      name
    }
  }
`;

const GET_VENUES = gql`
  query GetVenues {
    venues {
      id
      name
      place
      host {
        id
        name
        place 
      }
    }
  }
`;

const GET_GROUPS = gql`
  query Groups {
    groups {
      id
      name
    }
  }
`;

const GET_CLUSTERS = gql`
  query Clusters {
    clusters {
      id
      name
    }
  }
`;

const GET_PROGRAM_PARTICIPANTS = gql`
  query GetProgramParticipants($programId: ID!, $groupId: ID, $clusterId: ID) {
    programParticipants(
      programId: $programId
      groupId: $groupId
      clusterId: $clusterId
    ) {
      id
      codeLetter
      candidate {
        id
        name
        photo
        admissionNo
        chestNo
        category {
          id
          name
        }
        institution {
          id
          name
          shortName
        }
      }
      codeLetter
      isPresent
    }
  }
`;

const CodeLetterReg: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedProgramCategory, setSelectedProgramCategory] = useState("");
  const [selectedSkill, setSelectedSkill] = useState("");
  const [selectedVenue, setSelectedVenue] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedGroup, setSelectedGroup] = useState("");
  const [selectedCluster, setSelectedCluster] = useState("");
  const [selectedData, setSelectedData] = useState<any>();
  const [filteredParticipants, setFilteredParticipants] = useState<any>([]);
  const [eliminationRegistrationStatus, setEliminationRegistrationStatus] =
    useState<string>("");
  const [groupDisable, setGroupDisable] = useState(false);
  const [clusterDisable, setClusterDisable] = useState(false);

  const printRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    contentRef: printRef,
    documentTitle: selectedData?.programCode,
  });

  const { data: programsData, error, loading } = useQuery(GET_PROGRAMS);
  const { data: categoryData } = useQuery(GET_CATEGORIES);
  const { data: programCategoryData } = useQuery(GET_PROGRAM_CATEGORIES);
  const { data: skillData } = useQuery(GET_SKILLS);
  const { data: venueData } = useQuery(GET_VENUES);
  const { data: groupData } = useQuery(GET_GROUPS);
  const { data: clusterData } = useQuery(GET_CLUSTERS);
  const { data: participantsData } = useQuery(GET_PROGRAM_PARTICIPANTS, {
    skip: !selectedData || (!selectedGroup && !selectedCluster),
    variables: selectedData
      ? {
        programId: selectedData.id,
        groupId: selectedGroup || null,
        clusterId: selectedCluster || null,
      }
      : {},
    fetchPolicy: "network-only",
  });
  const [updateEliminationRegistrationStatus] = useMutation(
    UPDATE_ELIMINATION_REGISTRATION_STATUS,
    {
      refetchQueries: [
        {
          query: GET_PROGRAM_BY_ID,
          variables: {
            id: selectedData?.id,
          },
        },
      ],
    }
  );

  useEffect(() => {
    setFilteredParticipants(participantsData?.programParticipants);
  }, [participantsData]);

  useEffect(() => {
    if (groupData?.groups?.length === 1) {
      setSelectedGroup(groupData.groups[0].id);
      setGroupDisable(true);
    }
  }, [groupData]);

  useEffect(() => {
    if (clusterData?.clusters?.length === 1) {
      setSelectedCluster(clusterData.clusters[0].id);
      setClusterDisable(true);
    }
  }, [clusterData]);

  if (loading) return <p>Loading...</p>;

  if (error) return <p>Error: {error.message}</p>;

  const filteredPrograms = programsData?.programs?.programs?.filter(
    (program: any) => {
      const matchesSearch =
        program.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        program.programCode.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesCategory = selectedCategory
        ? program?.category?.id === selectedCategory
        : true;
      const matchesProgramCategory = selectedProgramCategory
        ? program?.programCategory?.id === selectedProgramCategory
        : true;
      const matchesSkill = selectedSkill
        ? program?.skill?.id === selectedSkill
        : true;

      return (
        matchesSearch &&
        matchesCategory &&
        matchesProgramCategory &&
        matchesSkill
      );
    }
  );

  const selectProgram = (data: any) => {
    setSelectedData(data);
    setSearchTerm("");
  };

  const handleUpdate = async (groupId: string, value: string) => {
    const updateLoading = toast.loading("Updating status...");
    try {
      await updateEliminationRegistrationStatus({
        variables: {
          programId: selectedData.id,
          input: {
            eliminationRegistrationStatus: [
              {
                key: groupId,
                value: value,
              },
            ],
          },
        },
      });
      toast.success(
        `${value === "Registration_Completed" ? "Finalized" : "Enable edit"
        } successfully!`
      );
      toast.remove(updateLoading);
    } catch (error: any) {
      console.log(error);
      toast.remove(updateLoading);
      toast.error("Failed to update status");
    }
  };

  return (
    <>
      <PageTitle pagetitle="Code Letter Registration" subtitle="Registration" />
      <div className="flex flex-col sm:flex-row gap-2 mb-7">
        <div className="relative w-full md:w-1/3">
          <div className="w-full flex relative">
            <div className="pointer-events-none absolute top-3.5 left-4 text-gray-900 text-opacity-40 dark:text-gray-200">
              <i className="mgc_search_line text-xl"></i>
            </div>
            <input
              type="search"
              className="h-12 w-full border rounded-lg bg-transparent pl-11 text-gray-900 placeholder-gray-500 dark:placeholder-gray-300 dark:text-gray-200 focus:ring-0 sm:text-sm"
              placeholder="Search Programs..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          {searchTerm && (
            <div className="absolute w-full max-h-56 rounded-xl z-20 overflow-hidden bg-white shadow-xl overflow-y-scroll">
              {filteredPrograms.map((filteredProgram: any, index: number) => (
                <div
                  key={index}
                  className="px-3 py-1 border-b cursor-pointer hover:bg-gray-200"
                  onClick={() => selectProgram(filteredProgram)}
                >
                  <p className="text-md font-semibold">
                    {filteredProgram.programCode} - {filteredProgram.name}
                  </p>
                  <p className="text-xs">{filteredProgram.category.name}</p>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="w-1/4 hidden sm:block">
          <select
            required
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            className="h-12 p-2 w-full border-2 border-gray-300 rounded-lg bg-transparent text-gray-900 placeholder-gray-500 dark:placeholder-gray-300 dark:text-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 dark:focus:border-blue-300 dark:focus:ring-blue-500 transition duration-200 ease-in-out sm:text-sm"
          >
            <option value="">Select Category</option>
            {categoryData?.categories?.map((category: any) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
        </div>

        <div className="w-1/4 hidden sm:block">
          <select
            required
            value={selectedProgramCategory}
            onChange={(e) => setSelectedProgramCategory(e.target.value)}
            className="h-12 p-2 w-full border-2 border-gray-300 rounded-lg bg-transparent text-gray-900 placeholder-gray-500 dark:placeholder-gray-300 dark:text-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 dark:focus:border-blue-300 dark:focus:ring-blue-500 transition duration-200 ease-in-out sm:text-sm"
          >
            <option value="">Select Program Category</option>
            {programCategoryData?.programCategories?.map((category: any) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
        </div>

        <div className="w-1/4 hidden sm:block">
          <select
            required
            value={selectedSkill}
            onChange={(e) => setSelectedSkill(e.target.value)}
            className="h-12 p-2 w-full border-2 border-gray-300 rounded-lg bg-transparent text-gray-900 placeholder-gray-500 dark:placeholder-gray-300 dark:text-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 dark:focus:border-blue-300 dark:focus:ring-blue-500 transition duration-200 ease-in-out sm:text-sm"
          >
            <option value="">Select Skill</option>
            {skillData?.skills?.map((skill: any) => (
              <option key={skill.id} value={skill.id}>
                {skill.name}
              </option>
            ))}
          </select>
        </div>

        <div className="w-1/4 hidden sm:block">
          <select
            required
            value={selectedVenue}
            onChange={(e) => setSelectedVenue(e.target.value)}
            className="h-12 p-2 w-full border-2 border-gray-300 rounded-lg bg-transparent text-gray-900 placeholder-gray-500 dark:placeholder-gray-300 dark:text-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 dark:focus:border-blue-300 dark:focus:ring-blue-500 transition duration-200 ease-in-out sm:text-sm"
          >
            <option value="">Select Venue</option>
            {venueData?.venues?.map((venue: any) => (
              <option key={venue.id} value={venue.id}>
                {venue?.name} - {venue?.host?.place}
              </option>
            ))}
          </select>
        </div>

        <div className="w-1/4 hidden sm:block">
          <input
            type="date"
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
            className="h-12 p-2 w-full border-2 border-gray-300 rounded-lg bg-transparent text-gray-900 placeholder-gray-500 dark:placeholder-gray-300 dark:text-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 dark:focus:border-blue-300 dark:focus:ring-blue-500 transition duration-200 ease-in-out sm:text-sm"
          />
        </div>
      </div>

      <div className="w-full flex items-center justify-between">
        <div className="w-2/3 md:w-1/3 flex gap-1 md:gap-8">
          {!selectedCluster && (
            <div className="w-1/2">
              <select
                required
                value={selectedGroup}
                onChange={(e) => {
                  setSelectedGroup(e.target.value);
                  setSelectedCluster("");
                }}
                disabled={groupDisable}
                className="h-12 p-2 w-full border-2 border-gray-300 rounded-lg bg-transparent text-gray-900 placeholder-gray-500 dark:placeholder-gray-300 dark:text-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 dark:focus:border-blue-300 dark:focus:ring-blue-500 transition duration-200 ease-in-out sm:text-sm"
              >
                <option value="">Select Group</option>
                {groupData?.groups?.map((group: any) => (
                  <option key={group.id} value={group.id}>
                    {group.name}
                  </option>
                ))}
              </select>
            </div>
          )}

          {!selectedGroup && (
            <div className="w-1/2">
              <select
                required
                value={selectedCluster}
                onChange={(e) => {
                  setSelectedCluster(e.target.value);
                  setSelectedCategory("");
                }}
                disabled={clusterDisable}
                className="h-12 p-2 w-full border-2 border-gray-300 rounded-lg bg-transparent text-gray-900 placeholder-gray-500 dark:placeholder-gray-300 dark:text-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 dark:focus:border-blue-300 dark:focus:ring-blue-500 transition duration-200 ease-in-out sm:text-sm"
              >
                <option value="">Select Cluster</option>
                {clusterData?.clusters?.map((cluster: any) => (
                  <option key={cluster.id} value={cluster.id}>
                    {cluster.name}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>
        <div>
          {filteredParticipants && (
            <button
              onClick={() => handlePrint()}
              disabled={!filteredParticipants}
              className={`px-10 md:px-16 py-3 rounded-xl font-semibold ${filteredParticipants
                  ? "bg-green-600 hover:bg-green-700 text-white"
                  : "bg-gray-400 text-gray-700"
                }`}
            >
              Print
            </button>
          )}
        </div>
      </div>

      {selectedData && (
        <div className="mb-24">
          <CodeLetterContent
            participants={filteredParticipants}
            programId={selectedData.id}
            groupId={selectedGroup}
            clusterId={selectedCluster}
            eliminationRegistrationStatus={eliminationRegistrationStatus}
            setEliminationRegistrationStatus={setEliminationRegistrationStatus}
          />
        </div>
      )}

      <div className="w-full">
        <div id="print-section" className="hidden">
          {filteredParticipants && (
            <RegPrint
              printRef={printRef}
              data={filteredParticipants}
              category={selectedData?.category?.name}
              isCluster={Boolean(
                selectedCluster?.length && selectedCluster?.length > 0
              )}
              isGroup={Boolean(
                selectedGroup?.length && selectedGroup?.length > 0
              )}
            />
          )}
        </div>
      </div>
      {eliminationRegistrationStatus === "Registration_Completed" &&
        selectedGroup &&
        selectedData && (
          <Private
            roles={["administration"]}
            element={
              <div className="fixed left-0 bottom-0 w-full flex items-center justify-end p-4 pr-24 bg-white shadow-[4.0px_-8.0px_16.0px_rgba(0,0,0,0.3)]">
                <button
                  className="bg-blue-300 hover:bg-blue-500 text-blue-700 hover:text-white font-bold py-3 px-8 rounded"
                  onClick={() =>
                    handleUpdate(selectedGroup, "Registration_Started")
                  }
                >
                  Edit
                </button>
              </div>
            }
          />
        )}
      {eliminationRegistrationStatus === "Registration_Started" &&
        selectedGroup &&
        selectedData && (
          <div className="fixed left-0 bottom-0 w-full flex items-center justify-end p-4 pr-24 bg-white shadow-[4.0px_-8.0px_16.0px_rgba(0,0,0,0.3)]">
            <button
              className="bg-green-300 hover:bg-green-500 text-green-700 hover:text-white font-bold py-3 px-8 rounded"
              onClick={() =>
                handleUpdate(selectedGroup, "Registration_Completed")
              }
            >
              Finalize
            </button>
          </div>
        )}
    </>
  );
};

export default CodeLetterReg;
