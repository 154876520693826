import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useRef, useState } from "react";
import { UPDATE_CODE_LETTER } from "../../graphql/mutations/codeletter";
import { GET_PROGRAM_CODE_LETTERS } from "../../graphql/queries/codeletter";
import toast from "react-hot-toast";
import { GET_PROGRAM_PARTICIPANTS } from "../../graphql/queries/participation";

interface CodeLetterSpinnerProps {
  options: string[];
  participationId: string;
  programId: string;
  groupId?: string;
  clusterId?: string;
}

const CodeLetterSpinner: React.FC<CodeLetterSpinnerProps> = ({
  options: initialOptions,
  participationId,
  programId,
  groupId,
  clusterId,
}) => {
  const [options, setOptions] = useState(initialOptions);
  const [selectedOption, setSelectedOption] = useState("");

  const { data: codeLetters } = useQuery(GET_PROGRAM_CODE_LETTERS, {
    skip: !programId,
    variables: {
      programId: programId,
      groupId: groupId || null,
      clusterId: clusterId || null,
    },
  });

  
  const [updateCodeLetter, { error: updateError }] = useMutation(
    UPDATE_CODE_LETTER,
    {
      refetchQueries: [
        {
          query: GET_PROGRAM_PARTICIPANTS,
          variables: {
            programId: programId,
            groupId: groupId || null,
            clusterId: clusterId || null,
          },
        },
      ],
    }
  );
  

  let startAngle = 0;
  const arc = Math.PI / (options.length / 2);

  let spinTime = 0;
  let spinTimeTotal = 0;

  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const ctxRef = useRef<CanvasRenderingContext2D | null>(null);

  useEffect(() => {
    if (codeLetters?.programParticipants) {
      const filteredOptions = options.filter(
        (option) =>
          !codeLetters.programParticipants.some(
            (participant: any) => participant?.codeLetter === option
          )
      );
      setOptions(filteredOptions);
    }
  }, [codeLetters]);

  useEffect(() => {
    drawRouletteWheel();
    const spinButton = document.getElementById("spin");
    spinButton?.addEventListener("click", spin);
    return () => {
      spinButton?.removeEventListener("click", spin);
    };
  }, [options]);

  useEffect(() => {
    if (selectedOption) {
      const updateLoading = toast.loading("Updating Code Letter...");
      try {
        updateCodeLetter({
          variables: {
            updateCodeLetterId: participationId,
            input: {
              codeLetter: selectedOption,
              isPresent: true
            }
          }

        
        });
        toast.dismiss(updateLoading);
        toast.success("Code Letter Updated");
      } catch {
        console.log("Error:" + updateError);
        toast.dismiss(updateLoading);
        toast.error("Error Updating CodeLetter");
      }
    }
  }, [selectedOption]);

  const byte2Hex = (n: number): string => {
    const nybHexString = "0123456789ABCDEF";
    return nybHexString.charAt((n >> 4) & 0x0f) + nybHexString.charAt(n & 0x0f);
  };

  const RGB2Color = (r: number, g: number, b: number): string => {
    return `#${byte2Hex(r)}${byte2Hex(g)}${byte2Hex(b)}`;
  };

  const getColor = (item: number, maxitem: number): string => {
    const phase = 0;
    const center = 128;
    const width = 127;
    const frequency = (Math.PI * 2) / maxitem;

    const red = Math.sin(frequency * item + 2 + phase) * width + center / 2;
    const green = Math.sin(frequency * item + 0 + phase) * width + center / 2;
    const blue = Math.sin(frequency * item + 4 + phase) * width + center / 2;

    return RGB2Color(red, green, blue);
  };

  const drawRouletteWheel = () => {
    const canvas = canvasRef.current;
    if (canvas && canvas.getContext) {
      ctxRef.current = canvas.getContext("2d");
      if (ctxRef.current) {
        const ctx = ctxRef.current;
        const outsideRadius = 100;
        const textRadius = 80;
        const insideRadius = 0;

        ctx.clearRect(0, 0, 250, 250);

        ctx.strokeStyle = "black";
        ctx.lineWidth = 2;

        ctx.font = "bold 12px Helvetica, Arial";

        for (let i = 0; i < options.length; i++) {
          const angle = startAngle + i * arc;
          ctx.fillStyle = getColor(i, options.length);

          ctx.beginPath();
          ctx.arc(125, 125, outsideRadius, angle, angle + arc, false);
          ctx.arc(125, 125, insideRadius, angle + arc, angle, true);
          ctx.stroke();
          ctx.fill();

          ctx.save();
          ctx.shadowOffsetX = -1;
          ctx.shadowOffsetY = -1;
          ctx.shadowBlur = 0;
          ctx.shadowColor = "rgb(220,220,220)";
          ctx.fillStyle = "black";
          ctx.translate(
            125 + Math.cos(angle + arc / 2) * textRadius,
            125 + Math.sin(angle + arc / 2) * textRadius
          );
          ctx.rotate(angle + arc / 2 + Math.PI / 2);
          const text = options[i];
          ctx.fillText(text, -ctx.measureText(text).width / 2, 0);
          ctx.restore();
        }

        ctx.fillStyle = "black";
        ctx.beginPath();
        ctx.moveTo(125 - 4, 125 - (outsideRadius + 5));
        ctx.lineTo(125 + 4, 125 - (outsideRadius + 5));
        ctx.lineTo(125 + 4, 125 - (outsideRadius - 5));
        ctx.lineTo(125 + 9, 125 - (outsideRadius - 5));
        ctx.lineTo(125 + 0, 125 - (outsideRadius - 13));
        ctx.lineTo(125 - 9, 125 - (outsideRadius - 5));
        ctx.lineTo(125 - 4, 125 - (outsideRadius - 5));
        ctx.lineTo(125 - 4, 125 - (outsideRadius + 5));
        ctx.fill();
      }
    }
  };

  const spin = () => {
    const spinAngleStart = Math.random() * 10 + 10;
    spinTime = 0;
    spinTimeTotal = Math.random() * 3 + 4 * 1000;
    rotateWheel(spinAngleStart);
  };

  const rotateWheel = (spinAngleStart: number) => {
    spinTime += 10;
    if (spinTime >= spinTimeTotal) {
      stopRotateWheel();
      return;
    }
    const spinAngle =
      spinAngleStart - easeOut(spinTime, 0, spinAngleStart, spinTimeTotal);
    startAngle += (spinAngle * Math.PI) / 180;
    drawRouletteWheel();
    setTimeout(() => rotateWheel(spinAngleStart), 5);
  };

  const stopRotateWheel = () => {
    const degrees = (startAngle * 180) / Math.PI + 90;
    const arcd = (arc * 180) / Math.PI;
    const index = Math.floor((360 - (degrees % 360)) / arcd);
    const ctx = ctxRef.current;
    if (ctx) {
      const selected = options[index];
      setSelectedOption(selected);
      setOptions((prevOptions) =>
        prevOptions.filter((opt) => opt !== selected)
      );

      ctx.save();
      ctx.font = "bold 50px Helvetica, Arial";
      ctx.fillStyle = "white";
      ctx.strokeStyle = "black";
      ctx.fillText(
        selected,
        125 - ctx.measureText(selected).width / 2,
        125 + 10
      );
      ctx.strokeText(
        selected,
        125 - ctx.measureText(selected).width / 2,
        125 + 10
      );
      ctx.restore();
    }
  };

  const easeOut = (t: number, b: number, c: number, d: number): number => {
    const ts = (t /= d) * t;
    const tc = ts * t;
    return b + c * (tc + -3 * ts + 3 * t);
  };

  return (
    <div className="flex flex-col items-center overflow-hidden">
      <canvas ref={canvasRef} id="canvas" width="250" height="250"></canvas>
      <input
        type="button"
        value="spin"
        className="absolute translate-x-[-50%] translate-y-[-33%] top-[50%] left-[50%] w-16 h-16 bg-red-600 text-white rounded-full cursor-pointer border-b-4 border-red-800 active:border-b-0"
        id="spin"
      />
      <input
        type="text"
        name="codeLetter"
        id="codeLetter"
        disabled
        value={selectedOption}
        className="w-24 bg-green-700 text-white text-center rounded-lg font-semibold"
      />
    </div>
  );
};

export default CodeLetterSpinner;
