import { gql } from "@apollo/client";
export const GET_ITEM_REG_PROGRAMS = gql`
  query Programs {
    programs {
      isItemRegistrable
      name
      id
      category {
        id
        name
      }
    }
  }
`;
export const GET_PROGRAMS = gql`
  query GetPrograms(
    $page: Int
    $limit: Int
    $search: String
    $programCategoryId: ID
    $categoryId: ID
    $skillId: ID
  ) {
    programs(
      page: $page
      limit: $limit
      search: $search
      programCategoryId: $programCategoryId
      categoryId: $categoryId
      skillId: $skillId
    ) {
      programs {
        category {
          id
          name
        }
        concept
        duration
        eliminationResultStatus {
          key
          value
        }
        eliminationRegistrationStatus {
          key
          value
        }
        publishTimeElimination {
          key
          value
        }
        eliminationVenue {
          key
          value {
            id
            name
          }
        }
        tools
        eliminationDate {
          key
          value
        }
        eliminationEndingTime {
          key
          value
        }
        eliminationStartingTime {
          key
          value
        }
        eliminationJudge {
          id
          name
          email
          phoneNumber
          host {
            id
            name
            place
            group {
              id
              name
            }
          }
        }
        finalJudge {
          id
          name
          email
          phoneNumber
          host {
            id
            name
            place
            group {
              id
              name
            }
          }
        }
        finalDate
        finalEndingTime
        finalResultStatus
        finalStartingTime
        finalVenue {
          id
          name
        }
        id
        isItemRegistrable
        isProposalSubmission
        isRegistrable
        isGroupwiseResult
        isDebate
        isStarred
        maxSelection
        mode
        name
        noOfCandidates
        programCategory {
          id
          name
        }
        programCode
        publishTimeFinal
        section {
          id
          name
        }
        status
        type
        skill {
          id
          name
        }
      }
      totalPages
      totalPrograms
    }
  }
`;
export const GET_PROGRAM_BY_ID = gql`
  query GetProgramById($id: ID!) {
    program(id: $id) {
      id
      name
      programCode
      concept
      duration
      skill {
        id
        name
      }
      noOfCandidates
      maxSelection
      tools
      category {
        id
        name
      }
      programCategory {
        id
        name
      }
      eliminationRegistrationStatus {
        key
        value
      }
      eliminationDate {
        key
        value
      }
      eliminationEndingTime {
        key
        value
      }
      eliminationStartingTime {
        key
        value
      }
      eliminationVenue {
        key
        value {
          id
          name
        }
      }
      mode
      type
      isRegistrable
      isGroupwiseResult
      eliminationResultStatus {
        key
        value
      }
      publishTimeElimination {
        key
        value
      }
      isDebate
      isItemRegistrable
      isProposalSubmission
      isStarred
      section {
        id
        name
      }
    }
  }
`;
export const PROGRAMS_HAVING = gql`
  query programsHaving {
    programsHaving {
      categoryName
      count
    }
  }
`;
export const PROGRAMS_HAVING_CATEGORIES = gql`
  query programsHavingCategories {
    programsHavingWithCategories {
      category
      finalAtDH
      eliminationAtUG
      offStageAtCluster
    }
  }
`;
export const GET_REGISTRABLE_PROGRAMS = gql`
  query registrablePrograms {
    registrablePrograms {
      category {
        id
        name
      }
      id
      programCategory {
        id
        name
      }
      name
      programCode
      section {
        id
        name
      }
    }
  }
`;

export const GET_DEBATE_PROGRAMS = gql`
  query DebateProgram {
    debateProgram {
      id
      name
    }
  }
`;
