import React, { useState } from 'react';
import Modal from "../UIElements/Model";
import { useMutation } from '@apollo/client';
import { UPDATE_PROPOSAL_SUBMISSION } from "../../graphql/mutations/propSub";
import toast from 'react-hot-toast';

interface ProgramCardProps {
    proposal: {
        id: string;
        proposalText: string;
        program: {
            id: string;
            name: string;
            category: {
                name: string;
                id: string;
            }
        };
        participation: {
            id: string;
            // candidate: {
            //     name:string;
            //     id:string;
            // }

        };
        institution: {
            id: string;
            name: string;
        };
        file: string;
        link: string;
        status: string;
        remarks: string;
    };
    onEdit: () => void;
    onDelete: (id: string) => void;
    refetch: () => void;

}



const ProgramCard: React.FC<ProgramCardProps> = ({ proposal, onEdit, onDelete, refetch }) => {
    const [isOpen, setIsOpen] = useState(false)
    const getStatusColor = (status: string) => {
        switch (status) {
            case 'PENDING':
                return 'bg-yellow-200 text-yellow-600';
            case 'APPROVED':
                return 'bg-green-200 text-green-600';
            case 'REJECTED':
                return 'bg-red-200 text-red-600';
            default:
                return 'bg-gray-200 text--gray-600';
        }
    };
    const truncate = (input: string) =>
        input?.length > 40 ? `${input?.substring(0, 60)}...` : input;

    // create a toggle state
    const [showTruncate] = useState(true);

    const [remarks, setRemarks] = useState('');
    const [action, setAction] = useState(''); // Can be 'approve' or 'reject'

    const [updateProposalSubmit] = useMutation(UPDATE_PROPOSAL_SUBMISSION);

    // Handles input for remarks
    const handleRemarksChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRemarks(e.target.value);
    };

    // Handle Approve Action
    const handleApprove = () => {
        setAction('approve');
        setRemarks(''); // Clear remarks if any
        handleSubmit('APPROVED', '');
    };

    // Handle Reject Action
    const handleReject = () => {
        if (remarks.trim() === '') {
            toast.error('Please provide remarks for rejection');
            return;
        }
        setAction('reject');
        handleSubmit('REJECTED', remarks);
    };

    // Submit function to pass to parent component
    const handleSubmit = async (status: string, remarks: string) => {
        try {
            await updateProposalSubmit({
                variables: {
                    id: proposal.id,
                    input: {
                        program: proposal.program.id,
                        proposalText: proposal.proposalText,
                        file: proposal.file,
                        link: proposal.link,
                        participation: proposal.participation.id,
                        institution: proposal.institution.id,
                        status,
                        remarks: remarks || null,
                    }
                }
            });

            toast.success(`Proposal ${status === 'APPROVED' ? 'approved' : 'rejected'} successfully`);
            refetch(); // Refetch the data after updating
            setIsOpen(false); // Close modal
        } catch (error) {
            console.error('Error updating proposal:', error);
            toast.error('Error updating proposal');
        }
    };
    const handleSave = () => {
        setIsOpen(false)
    }

    return (
        <div className="bg-white shadow-md rounded-lg flex flex-col justify-between">
            <div className="bg-gray-600 p-4 rounded-t-lg">
                <h3 className="text-white text-xs font-semibold">{proposal?.program?.name.toLocaleUpperCase()}</h3>
                <p className="text-gray-300 text-xs">{proposal?.program?.category?.name.toLocaleUpperCase()}</p>
            </div>

            <div className="p-4 flex flex-col justify-between flex-1">
                <div className="flex justify-between flex-1 flex-wrap py-2">
                    {/* <p className='limit-line'>{proposal.proposalText}</p> */}
                    <p className=''>{showTruncate ? truncate(proposal?.proposalText) : proposal?.proposalText}</p>
                    {/* <button className={`bg-gray-400 text-white px-1 rounded-md text-sm max-h-5 block ${showTruncate ? '' : 'absolute bottom-0 right-0'}`} onClick={() => setShowTruncate(!showTruncate)}>{showTruncate ? <i className='mgc_down_fill'></i> : <i className='mgc_up_fill'></i>}</button> */}
                </div>
                {/* <div className="flex justify-between proposals-center mt-2"> */}
                <span className="text-gray-500 text-xs">{proposal?.institution?.name}</span>

                {/* </div> */}


                {/* Edit and Delete Buttons */}
                <div className="flex justify-between mt-4 flex-wrap proposals-center">
                    <span className={`px-2 py-1 rounded-full text-xs text-center font-semibold  ${getStatusColor(proposal.status)}`}>
                        {proposal?.status}
                    </span>
                    <div className="flex justify-end flex-1">
                        <button
                            onClick={() => setIsOpen(true)}
                            className="text-lg pt-0.5 px-1.5 transition-colors duration-200 border-transparent hover:bg-gray-500  hover:bg-opacity-10  text-gray-500 rounded hover:border-gray-500 border"
                        >
                            <i className='mgc_eye_line'></i>
                        </button>
                        <button
                            onClick={onEdit}
                            className="text-lg pt-0.5 px-1.5 transition-colors duration-200 border-transparent hover:bg-blue-500  hover:bg-opacity-10  text-blue-500 rounded hover:border-blue-500 border"
                        >
                            <i className='mgc_edit_line'></i>
                        </button>
                        <button
                            onClick={() => onDelete(proposal?.id)}
                            className="text-lg pt-0.5 px-1.5 transition-colors duration-200 border-transparent hover:bg-red-500 hover:bg-opacity-10 text-red-500 rounded  hover:border-red-500 border"
                        >
                            <i className='mgc_delete_line'></i>
                        </button>
                    </div>
                </div>
            </div>

            <Modal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                title={`${proposal?.program?.name.toUpperCase()} | ${proposal?.program?.category?.name.toUpperCase()}`}
                handleSubmit={handleSave}
            >
                <div className="flex flex-col justify-center proposals-center px-2 text-gray-700">
                    {/* <div className="m-2 w-full h-10 border-0 border-b-2 border-gray-400 flex justify-between proposals-center p-0.5">
                        <h3 className='font-bold text-lg text-black tracking-tight'>{proposal.program.name.toUpperCase()}</h3>
                        <h3 className='font-medium text-lg tracking-tight'>Category: {proposal.program.category.name.toUpperCase()}</h3>
                    </div> */}

                    <div className="mb-1 w-full h-10 flex justify-between proposals-center p-2">
                        <div className="flex justify-start proposals-center">
                            <img src="" alt="" className="w-8 h-8 rounded-full" />
                            <div className="flex flex-col justify-between proposals-start m-2">
                            <h6 className="text-sm font-semibold ">{proposal?.participation?.id}</h6>
                            <p className="text-xs ">{proposal?.institution?.name}</p>
                            </div>
                        </div>
                        <div className="div">
                            <span className="text-lg ">Status: </span>
                            <button className={`px-2 py-1 rounded-lg text-center font-semibold ${getStatusColor(proposal.status)}`} disabled>
                                {proposal.status}
                            </button>
                        </div>
                    </div>
                    <div className="w-full p-2">
                        <div className="mb-5 h-5">
                            <label htmlFor="" className='text-gray-600 mt-3 inline-block'>Lyrics:</label>
                            <a target='_blank' href={proposal.file} className="px-2 py-1 m-1 rounded-md float-right bg-gray-300 border hover:border-gray-500"><i className="mgc_file_line"></i></a>
                            <a target='_blank' href={proposal.link} className="px-2 py-1 m-1 rounded-md float-right bg-gray-300 border hover:border-gray-500"><i className="mgc_link_line"></i></a>
                        </div>
                        <p className="text-justify text-xs p-2 max-h-40 border-2 border-gray-200 rounded-lg overflow-auto shadow-inner shadow-gray-100 ">{proposal.proposalText}</p>
                    </div>
                    <div className="w-full h-10 flex justify-between proposals-center p-2 mt-7">
                        <div className="flex flex-col justify-center proposals-start w-full">
                            <label htmlFor="remarks" className=" text-gray-600">Remarks:</label>
                            <input
                                type="text"
                                className="text-sm m-1 ml-0 rounded-lg min-w-56 w-full border-2 border-gray-300 overflow-auto p-1 h-9 shadow-inner shadow-gray-100"
                                value={remarks}
                                onChange={handleRemarksChange}
                                disabled={action === 'approve'} // Disable if approved
                                placeholder="Add remarks if rejecting"
                            />
                        </div>
                        <div className="flex justify-end proposals-start mt-4 pt-0.5 ">
                            <button className="border-2 px-3 py-1 rounded-md text-green-600 font-semibold ml-3 border-green-500 bg-green-100 hover:bg-green-500 hover:text-white" onClick={handleApprove}>Approve</button>
                            <button className="border-2 px-3 py-1 rounded-md text-red-600 font-semibold ml-3 border-red-500 bg-red-100 hover:bg-red-500 hover:text-white" onClick={handleReject}>Reject</button>
                        </div>
                    </div>
                </div>

            </Modal>
        </div>

    );
};

export default ProgramCard;


