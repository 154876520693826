import React from "react";

interface HeaderProps {
  program: any;
}

const Header: React.FC<HeaderProps> = ({ program }) => {
  return (
    <div className="flex items-center justify-between pb-10">
      <div className="w-1/2 flex flex-col">
        <h3 className="text-xl text-black font-semibold">{program?.programCode} - {program?.name}</h3>
        <p className="text-sm text-gray-600">{program?.category?.name}</p>
      </div>
    </div>
  );
};

export default Header;
