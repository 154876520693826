import React from "react";
import GroupCard from "../../components/institution/GroupCard";
import PageTitle from "../../components/PageTitle";
import { useQuery } from "@apollo/client";
import { GET_GROUPS } from "./group";

const Institution: React.FC = () => {
  const { data: groupsData } = useQuery(GET_GROUPS);

  return (
    <>
      <PageTitle pagetitle={"Institution"} subtitle={"Institution"} />
      <div className="grid 2xl:grid-cols-4 md:grid-cols-3 gap-6 mt-6">
        {groupsData?.groups.map((group: any) => (
          <GroupCard
            key={group.id}
            title={group.name}
            id={group.id}
            section={group.section}
          />
        ))}
      </div>
    </>
  );
};

export default Institution;
