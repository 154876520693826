import React from "react";

import { useQuery } from "@apollo/client";
import PositionCardStructure from "./PositionCardStructure";
import Skeleton from "../UIElements/Skeleton";
import { GET_POSITIONS } from "../../graphql/queries/position";

const Position: React.FC = () => {

  // QUERY
  const {
    data: positionData,
    loading: positionLoading,
    error: positionError,
  } = useQuery(GET_POSITIONS);

  if (positionLoading) return <Skeleton />;

  if (positionError)
    return <p>Error loading positions: {positionError.message}</p>;

  return (
    <div className="bg-white p-4 rounded-lg">
      <div className="flex justify-between items-center mb-2">
        <h2 className="text-xl">Position</h2>
      </div>
      <div className="space-y-4 bg-gray-100 h-[250px] rounded-xl no-scrollbar overflow-auto px-3 py-4">
        <PositionCardStructure
          positions={positionData.positions}
          category="individual"
        />
        <PositionCardStructure
          positions={positionData.positions}
          category="group"
        />
        <PositionCardStructure
          positions={positionData.positions}
          category="kulliyyah"
        />
      </div>
    </div>
  );
};

export default Position;
