import { useQuery } from "@apollo/client";
import React, { useEffect, useRef, useState } from "react";
import { GET_GROUPS } from "../../../graphql/queries/group";
import { useReactToPrint } from "react-to-print";
import ProgramTableRow from "./ProgramTableRow";

interface ProgramTableProps {
  programs: any;
  groupId: string;
  setGroupId: (value: string) => void;
}

const ProgramTable: React.FC<ProgramTableProps> = ({
  programs,
  groupId,
  setGroupId,
}) => {
  const [programsData, setProgramsData] = useState<any[]>([]);
  const [printData, setPrintData] = useState(null);
  const [docName, setDocName] = useState("");

  const { data } = useQuery(GET_GROUPS);

  useEffect(() => {
    if (data?.groups && data?.groups?.length === 1) {
      setGroupId(data?.groups[0]?.id);
      console.log(data?.groups?.length);
    }
  }, [data]);

  const singleRef = useRef<any>(null);
  const singlePrint = useReactToPrint({
    contentRef: singleRef,
    documentTitle: docName,
    pageStyle: `
      @media print {
        @page {
          size: A4;
          margin: 0;
        }
        body {
          margin: 0;
          padding: 0;
          -webkit-print-color-adjust: exact; 
          print-color-adjust: exact;
        }
      }
    `,
  });

  useEffect(() => {
    if (printData) {
      singlePrint();
      setPrintData(null);
      setDocName("");
    }
  }, [printData]);

  useEffect(() => {
    if (programs) {
      setProgramsData(programs);
    }
  }, [programs]);

  return (
    <div className="overflow-x-auto no-scrollbar">
      <table className="min-w-full border border-gray-300 divide-y divide-gray-200">
        <thead className="bg-gray-100">
          <tr>
            <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">
              Program Code
            </th>
            <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">
              Program Name
            </th>
            <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">
              Category
            </th>

            <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">
              Result Status
            </th>
            <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">
              Updated Time
            </th>
            <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">
              Action
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {programsData?.map((program: any, index: number) => (
            <ProgramTableRow
              key={index}
              program={program}
              groupId={groupId}
              setGroupId={setGroupId}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProgramTable;
