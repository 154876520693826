import { useMutation, useQuery } from "@apollo/client";
import { GET_DEBATE_BY_PROGRAM } from "../../../graphql/queries/debate";
import Skeleton from "../../UIElements/Skeleton";
import {
  DELETE_DEBATE,
  UPDATE_DEBATE,
} from "../../../graphql/mutations/debate";
import toast from "react-hot-toast";
import Private from "../../../context/Private";
import Modal from "../../UIElements/Model";
import { useEffect, useState } from "react";

interface TableProps {
  programId: string;
  setDebateId: (id: string) => void;
  setIsOpen: (value: boolean) => void;
  searchTerm: string;
}

const Table: React.FC<TableProps> = ({
  programId,
  setDebateId,
  setIsOpen,
  searchTerm,
}) => {
  const [deleteModel, setDeleteModel] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string>("");
  const [competitions, setCompetitions] = useState<any[]>([]);

  const [candidates, setCandidates] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);


  const { data, loading, error, refetch } = useQuery(GET_DEBATE_BY_PROGRAM, {
    variables: { programId },
  });
  const [deleteDebate, { loading: deleteLoading }] = useMutation(DELETE_DEBATE);

  const [updateDebate] = useMutation(UPDATE_DEBATE);

  useEffect(() => {
    if (data?.debateByProgram) {
      setCompetitions(data.debateByProgram);
    }
  }, [data]);

  // Filter data based on searchTerm
  const filteredCompetitions = searchTerm
    ? competitions.filter(
        (competition) =>
          competition.teamA.some(
            (member: any) =>
              member?.institution?.name
                ?.toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
              member?.institution?.shortName
                ?.toLowerCase()
                .includes(searchTerm.toLowerCase())
          ) ||
          competition.teamB.some(
            (member: any) =>
              member?.institution?.name
                ?.toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
              member?.institution?.shortName
                ?.toLowerCase()
                .includes(searchTerm.toLowerCase())
          )
      )
    : competitions;

  const statusOptions = ["pending", "registration", "started", "ended"];
  const winnerOptions = ["", "teamA", "teamB"];

  const handleUpdateField = async (id: string, field: string, value: any) => {
    try {
      await updateDebate({ variables: { id, input: { [field]: value } } });
      toast.success(`Competition ${field} updated successfully!`);
      refetch();
    } catch (error) {
      console.error(`Failed to update Competition ${field}:`, error);
      toast.error(`Failed to update Competition ${field}.`);
    }
  };

  const handleEdit = (id: string) => {
    setDebateId(id);
    setIsOpen(true);
  };

  const handleDeleteConfirm = (id: string) => {
    setDeleteModel(true);
    setDeleteId(id);
  };

  const handleDelete = async () => {
    try {
      await deleteDebate({ variables: { id: deleteId } });
      toast.success("Debate deleted successfully!");
      setDeleteModel(false);
      refetch();
    } catch (error) {
      console.error("Failed to delete the debate", error);
      toast.error("Failed to delete the Debate");
    }
  };

  return (
    <div className="relative overflow-x-auto no-scrollbar">
      <table className="w-full divide-y divide-gray-300 dark:divide-gray-700">
        <thead className="bg-slate-300 bg-opacity-20 border-t dark:bg-slate-800 divide-gray-300 dark:border-gray-700">
          <tr>
            <th className="py-3.5 ps-4 pe-3 text-sm text-center font-semibold text-gray-900 dark:text-gray-200">
              Sl. No.
            </th>
            <th className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900 dark:text-gray-200">
              Teams
            </th>
            <th className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900 dark:text-gray-200">
              Date & Time
            </th>
            <th className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900 dark:text-gray-200">
              Type
            </th>
            <th className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900 dark:text-gray-200">
              Status
            </th>
            <th className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900 dark:text-gray-200">
              Winner
            </th>
            <th className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900 dark:text-gray-200">
              Action
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
          {loading ? (
            <tr>
              <td colSpan={7} className="pt-3">
                <Skeleton />
              </td>
            </tr>
          ) : error ? (
            <tr>
              <td colSpan={7} className="pt-3">
                Error fetching competitions: {error.message}
              </td>
            </tr>
          ) : filteredCompetitions.length ? (
            filteredCompetitions.map((competition: any, index: number) => (
              <tr key={competition.id}>
                <td className="py-4 px-4 text-sm text-gray-700 dark:text-gray-300 text-center">
                  {index + 1}
                </td>
                <td className="py-4 px-4 text-sm text-gray-700 dark:text-gray-300 text-center">
                  {competition.teamA
                    ?.map((team: any, index: number) => (
                      <span
                        key={`teamA-${index}`}
                        className="cursor-pointer font-semibold"
                        onClick={() => {
                          setIsModalOpen(true);
                          setCandidates(team.candidate);
                        }}
                      >
                        {team.institution?.shortName}
                      </span>
                    ))
                    .reduce((prev: any, curr: any) => [prev, " & ", curr])}
                  <br />
                  <span className="text-2xl font-bold">vs</span>
                  <br />
                  {competition.teamB
                    ?.map((team: any, index: number) => (
                      <span
                        key={`teamB-${index}`}
                        className="cursor-pointer font-semibold"
                        onClick={() => {
                          setIsModalOpen(true);
                          setCandidates(team.candidate);
                        }}
                      >
                        {team.institution?.shortName}
                      </span>
                    ))
                    .reduce((prev: any, curr: any) => [prev, " & ", curr])}
                </td>
                <td className="py-4 px-4 text-sm text-gray-700 dark:text-gray-300 text-center">
                  {competition.date
                    ? new Date(Number(competition.date)).toLocaleDateString()
                    : "No Date Available"}
                  <br />
                  {competition.startingTime || "N/A"} -{" "}
                  {competition.endingTime || "N/A"}
                </td>
                <td className="py-4 px-4 text-sm text-gray-700 dark:text-gray-300 text-center">
                  <div className="inline-flex items-center gap-1.5 py-1 px-3 rounded text-xs font-medium bg-primary/25 text-primary">
                    {" "}
                    {competition.type}
                  </div>
                </td>
                <td className="py-4 px-4 text-sm text-gray-700 dark:text-gray-300">
                  <select
                    className="pr-6 pl-2 py-1 text-xs cursor-pointer font-semibold rounded border-none focus:ring-transparent bg-red-300 text-red-700"
                    value={competition.status || "pending"}
                    onChange={(e) =>
                      handleUpdateField(
                        competition.id,
                        "status",
                        e.target.value
                      )
                    }
                  >
                    {statusOptions.map((status) => (
                      <option key={status} value={status} className="bg-white">
                        {status.charAt(0).toUpperCase() + status.slice(1)}
                      </option>
                    ))}
                  </select>
                </td>
                <td className="py-4 px-4 text-sm text-gray-700 dark:text-gray-300">
                  <select
                    className="pr-6 pl-2 py-1 text-xs cursor-pointer font-semibold rounded border-none focus:ring-transparent bg-green-300 text-green-700"
                    value={competition.winner || ""}
                    onChange={(e) =>
                      handleUpdateField(
                        competition.id,
                        "winner",
                        e.target.value
                      )
                    }
                  >
                    {winnerOptions.map((winner) => (
                      <option key={winner} value={winner} className="bg-white">
                        {winner || "Not Selected"}
                      </option>
                    ))}
                  </select>
                </td>
                <td className="py-4 px-4  text-center text-sm">
                  <Private
                    permissions={["update-debate"]}
                    element={
                      <div className="me-0.5 cursor-pointer">
                        <i
                          className="mgc_edit_line text-lg"
                          onClick={() => handleEdit(competition.id)}
                        ></i>
                      </div>
                    }
                  />
                  <Private
                    permissions={["delete-debate"]}
                    element={
                      <div className="ms-0.5 cursor-pointer">
                        <i
                          className="mgc_delete_line text-xl"
                          onClick={() => handleDeleteConfirm(competition.id)}
                        ></i>
                      </div>
                    }
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={7} className="py-4 text-center">
                No competitions available.
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <Modal
        setIsOpen={setDeleteModel}
        isOpen={deleteModel}
        title="Delete Confirmation"
        handleSubmit={handleDelete}
        loading={deleteLoading}
        danger={true}
        buttonText="Delete"
      >
        <p className="text-center bg-red-100 text-red-800 rounded-md border border-red-600 p-3">
          Are you sure want to delete this Competition?
        </p>
      </Modal>

      <Modal setIsOpen={setIsModalOpen} isOpen={isModalOpen} title="Candidates">
        {candidates.map((candidate: any) => (
          <div className="flex items-center justify-between border border-gray-300 rounded-md p-2 m-2">
            {/* Candidate Image */}
            <img
              src={candidate.photo}
              alt={candidate.name}
              className="inline-block h-12 w-12 bg-blue-200 rounded-full border-2 border-white dark:border-gray-700 mr-4"
            />

            {/* Candidate Info */}
            <div className="flex-grow">
              <div className="text-lg font-semibold">
                {candidate.chestNo}-{candidate.name}
              </div>
              <div className="text-sm text-gray-500">
                {candidate.institution.name}
              </div>
            </div>
          </div>
        ))}
      </Modal>
    </div>
  );
};

export default Table;
