import React, { Key, useState } from "react";
import Judge from "./Judge";
import { IJudges } from "./JudgementSheet";

interface Program {
  id: string;
  name: string;
  programCode: string;
  skill: {
    id: string;
    name: string;
  }
  category: {
    id: string;
    name: string;
  };
  eliminationJudge: IJudges[];
  eliminationStartingTime: string;
  finalDate: string;
  eliminationDate: string;
  eliminationVenue: {
    id: string;
    name: string;
  };
  finalJudge: IJudges[];
  finalStartingTime: string;
  finalVenue: {
    id: string;
    name: string;
  };
  programCategory: {
    id: string;
    name: string;
  }
}

interface JudgeCardProps {
  programData: Program;
  handleAddJudge: (program: Program) => void;
  removeJudge: ((programId: string, judgeId: string, round: string) => any);
}



const JudgeCard: React.FC<JudgeCardProps> = ({ programData, handleAddJudge, removeJudge
  // onEdit, onView, refetch, isDropdownOpen, toggleDropdown 
}) => {
  const [isEliminationSwitch, setIsEliminationSwitch] = useState(true);
  const toggleRoundSwitch = ((bool: boolean) => {
    setIsEliminationSwitch(bool)
  })


  return (
    <div className="bg-white p-4 rounded-3xl shadow-md w-full max-w-md flex flex-col justify-between">
      <div className="flex justify-between items-start mt-1">
        <h3 className="text-lg font-semibold text-gray-800">{programData?.name}{programData?.programCode}</h3>



        <div className="flex flex-col items-end" >
          <span className="text-gray-500 text-sm font-medium">{programData?.category?.name}</span>
          <div className="inline-flex rounded-md shadow-inner mt-1" role="group">

            <button type="button" onClick={() => { toggleRoundSwitch(true) }} className={`px-2 py-1 text-xs font-medium rounded-s-lg  ${isEliminationSwitch ? "z-10 bg-gray-200 text-black dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:bg-gray-700" : "text-gray-600 bg-transparent border border-gray-200 hover:bg-gray-200 hover:text-black"}`}>
              Elimination
            </button>

            <button type="button" onClick={() => { toggleRoundSwitch(false) }} className={`px-2 py-1 text-xs font-medium rounded-e-lg ${isEliminationSwitch ? "text-gray-600 bg-transparent border border-gray-200 hover:bg-gray-200 hover:text-black" : " z-10 bg-gray-200 text-black dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:bg-gray-700"}`}>
              Final
            </button>
          </div>
        </div>



      </div>
      {isEliminationSwitch ? (
        <div className="flex flex-col justify-start flex-1">
          {programData?.eliminationJudge?.map((judge?: any, index?: Key) => (
            <div className="">
              <Judge
                key={index}
                judge={judge}
                removeJudge={removeJudge}
                programId={programData.id}
                round='eliminationJudge'
              />
            </div>

          ))}
        </div>) : (

        <div className="flex flex-col justify-start flex-1">
          {/* <h1>Fin Judge:</h1> */}
          {programData?.finalJudge?.map((judge: IJudges, index?: any) => (
            <div className="">
              <Judge
                key={index}
                judge={judge}
                removeJudge={removeJudge}
                programId={programData.id}
                round="finalJudge"
              />
            </div>
          ))}
        </div>)}

      <div className="flex justify-between items-center mt-4">
        {isEliminationSwitch ? (
          <div className="text-gray-500 text-sm">
            {/* <span>{programData?.eliminationVenue?.name}</span>
            <span className="mx-2">•</span>
            <span>{programData?.eliminationDate}</span>
            <span className="mx-2">•</span>
            <span>{programData?.eliminationStartingTime}{programData?.skill?.name}</span> */}
          </div>) : (
          <div className="text-gray-500 text-sm">
            {/* <span>{programData?.finalVenue?.name}</span>
            <span className="mx-2">•</span>
            <span>{programData?.finalDate}</span>
            <span className="mx-2">•</span>
            <span>{programData?.finalStartingTime}{programData?.skill?.name}</span> */}
          </div>
        )}

        <button className="flex items-center text-sm font-medium text-blue-500 hover:underline" onClick={() => handleAddJudge(programData)}>
          <i className="mgc_add_circle_line mr-1"></i> Add judge
        </button>
      </div>
    </div>
  );
};

export default JudgeCard;
