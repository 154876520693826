import React, { useEffect, useRef, useState } from "react";

type FilterModelProps = {
  onApplyFilters: (filterValues: any) => void;
  filters: { label: string; options: any[]; field: string; type: string }[];
};

const FilterModel: React.FC<FilterModelProps> = ({
  onApplyFilters,
  filters = [],
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filterValues, setFilterValues] = useState<any>({});
  const filterModalRef = useRef<HTMLDivElement>(null);

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        filterModalRef.current &&
        !filterModalRef.current.contains(event.target as Node)
      ) {
        setIsModalOpen(false);
      }
    }

    if (isModalOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isModalOpen]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFilterValues((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleApplyFilters = () => {
    onApplyFilters(filterValues);
    toggleModal();
  };

  return (
    <div className="flex flex-col h-full relative bg-gray-100">
      {/* Filter Button */}
      <button
        onClick={toggleModal}
        className="btn w-full h-full bg-blue-300 inline-flex items-center justify-center text-sm font-medium text-blue-700 hover:text-white hover:bg-blue-500"
      >
        <i className="mgc_filter_line text-xl mr-2"></i>
        Filter
      </button>

      {/* Modal Overlay */}
      {isModalOpen && (
        <div
          className="fixed top-0 left-0 z-50 w-full h-full bg-gray-900 bg-opacity-50 flex items-center justify-end"
          onClick={() => setIsModalOpen(false)}
        >
          <div
            className="h-full flex flex-col gap-2 p-10 bg-white overflow-y-scroll w-80 md:w-96"
            ref={filterModalRef}
            onClick={(e) => e.stopPropagation()}
          >
            <h1 className="text-xl text-black font-semibold">Filters</h1>
            <hr />

            <div className="space-y-4">
              {filters.map((filter) => (
                <div key={filter.field} className="space-y-1">
                  <label className="block text-gray-700">{filter.label}</label>

                  {filter.type === "select" && (
                    <select
                      name={filter.field}
                      value={filterValues[filter.field] || ""}
                      onChange={handleChange}
                      className="h-12 p-2 w-full border-2 border-gray-300 rounded-lg bg-transparent text-gray-900 placeholder-gray-500 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition duration-200 ease-in-out sm:text-sm"
                    >
                      <option value="">Select {filter.label}</option>
                      {filter.options.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </select>
                  )}

                  {filter.type === "fromToDate" && (
                    <div>
                      <div className="flex items-center justify-between">
                        <label htmlFor="fromDate" className="mr-2">From</label>
                        <input
                          type="datetime-local"
                          name="fromDate"
                          id="fromDate"
                          value={filterValues.fromDate || ""}
                          onChange={handleChange}
                          className="block w-auto float-end mt-1 rounded-md border-gray-300 shadow-sm focus:border-blue-500"
                        />
                      </div>
                      <div className="flex items-center justify-between ">
                        <label htmlFor="toDate" className="mr-2">To</label>
                        <input
                          type="datetime-local"
                          name="toDate"
                          id="toDate"
                          value={filterValues.toDate || ""}
                          onChange={handleChange}
                          className="block w-auto mt-1 rounded-md border-gray-300 shadow-sm focus:border-blue-500"
                        />
                      </div>
                    </div>
                  )}

                  {filter.type === "radio" &&
                    filter.options.map((option) => (
                      <label
                        key={option.value}
                        className="flex items-center space-x-2"
                      >
                        <input
                          type="radio"
                          name={filter.field}
                          value={option.value}
                          checked={filterValues[filter.field] === option.value}
                          onChange={handleChange}
                          className="form-radio text-blue-600"
                        />
                        <span>{option.label}</span>
                      </label>
                    ))}

                  {filter.type === "text" && (
                    <input
                      type="text"
                      name={filter.field}
                      value={filterValues[filter.field] || ""}
                      onChange={handleChange}
                      className="block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-blue-500"
                      placeholder={`Enter ${filter.label}`}
                    />
                  )}
                </div>
              ))}

              <button
                onClick={handleApplyFilters}
                className="w-full mt-4 bg-blue-500 text-white py-2 rounded-md font-semibold hover:bg-blue-600"
              >
                Apply Filters
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterModel;
