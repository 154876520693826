import { gql } from "@apollo/client";

export const GET_INSTITUTIONS = gql`
  query Institutions {
    institutions {
      id
      name
      shortName
      place
      email
      groupId {
        id
        name
        section {
          id
          name
        }
      }
      logo
      photo
      ugCoordinator
      ugCoordinatorPhoneNumber
      category {
        id
        name
      }
      programCategory {
        id
        name
      }
      candidateId {
        id
        name
      }
      deletedAt
    }
  }
`;

export const TOTAL_INSTITUTIONS = gql`
  query totalInstitutions {
    totalInstitutions {
      id
      name
      shortName
    }
  }
`;

export const GET_INSTITUTION_BY_ID = gql`
  query Institution($id: ID!) {
    institution(id: $id) {
      name
      id
      shortName
      email
      groupId {
        id
        name
        section {
          id
          name
        }
      }
      category {
        id
        name
      }
      programCategory {
        id
        name
      }
      candidateId {
        chestNo
        photo
      }
      ugCoordinator
      ugCoordinatorPhoneNumber
    }
  }
`;

export const GET_GROUP_WISE_INSTITUTIONS = gql`
  query GroupWiseInstitution($groupId: ID!) {
    groupWiseInstitution(groupId: $groupId) {
      id
      name
      shortName
      place
      email
      groupId {
        id
        name
      }
      logo
      photo
      ugCoordinator
      ugCoordinatorPhoneNumber
      candidateId {
        id
        name
        category {
          id
          name
        }
      }
      userId {
        id
        username
        role {
          id
          name
        }
      }
      category {
        id
        name
      }

      cluster {
        name
        id
      }
      programCategory {
        id
        name
      }
      deletedAt
    }
  }
`;

export const GET_GROUP_INSTITUTIONS = gql`
  query GroupWiseInstitution($groupId: ID!) {
    groupWiseInstitution(groupId: $groupId) {
      id
      name
    }
  }
`;
