import { useQuery } from "@apollo/client";
import PageTitle from "../../../components/PageTitle";
import DebateDetails from "../../../components/result/debate/DebateDetails";
import TabComponent from "../../../components/UIElements/Tab";
import { GET_DEBATE_PROGRAMS } from "../../../graphql/queries/program";
import Skeleton from "../../../components/UIElements/Skeleton";

interface Program {
  id: string;
  name: string;
}

function Debate() {
  const { data, loading, error } = useQuery(GET_DEBATE_PROGRAMS);

  // Define tabs based on the fetched data
  const tabs = data?.debateProgram?.map((program: Program) => ({
    id: program.id,
    label: program.name,
    content: <DebateDetails program={program} />, 
  })) || [];

  return (
    <>
      <PageTitle pagetitle="Debate Rounds" subtitle="Debate" />
      {loading && <Skeleton />}
      {error && <p>Failed to load debates: {error.message}</p>}
      {!loading && !error && <TabComponent tabs={tabs} />}
    </>
  );
}

export default Debate;
