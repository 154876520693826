import React from 'react';

const CandidateDetails: React.FC = () => {
    return (
        <div className="bg-white rounded-lg shadow-lg p-4 sm:p-6 mt-4">
            <h3 className="text-xs sm:text-sm font-semibold mb-4">Candidates Details</h3>
            <table className="min-w-full table-auto">
                <thead>
                    <tr className="bg-gray-200 text-xs sm:text-sm">
                        <th className="px-2 sm:px-4 py-2">Category</th>
                        <th className="px-2 sm:px-4 py-2">Candidates</th>
                        <th className="px-2 sm:px-4 py-2">Programmes</th>
                    </tr>
                </thead>
                <tbody className="text-xs sm:text-sm text-center font-bold">
                    <tr>
                        <td className="border px-2 sm:px-4 py-2">BIDAYA</td>
                        <td className="border px-2 sm:px-4 py-2">45 Candidates</td>
                        <td className="border px-2 sm:px-4 py-2">50 Programs</td>
                    </tr>
                    <tr>
                        <td className="border px-2 sm:px-4 py-2">BIDAYA</td>
                        <td className="border px-2 sm:px-4 py-2">45 Candidates</td>
                        <td className="border px-2 sm:px-4 py-2">50 Programs</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default CandidateDetails;
