import { gql } from "@apollo/client";

export const CREATE_EVENTS = gql`
  mutation CreateEvent($input: CreateEventInput!) {
  createEvent(input: $input) {
    id
  }
}
`;

export const UPDATE_EVENTS = gql`
  mutation UpdateEvent($id: ID!, $input: UpdateEventInput!) {
  updateEvent(id: $id, input: $input) {
    id
  }
}
`;

export const DELETE_EVENTS = gql`
mutation DeleteEvent($id: ID!) {
  deleteEvent(id: $id)
}
`;
