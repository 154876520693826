import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { GET_ROLES } from "../../graphql/queries/role";
import { DELETE_ROLE } from "../../graphql/mutations/role";
import toast from "react-hot-toast";
import DeleteModal from "../UIElements/DeleteModal";

interface RoleProps {
  data: {
    roles: {
      id: string;
      name: string;
      permissions: string[];
    }[];
  };
  onRoleEdit: (role: any) => void;
}

const ShowRole: React.FC<RoleProps> = ({ data, onRoleEdit }) => {
  const [deleteModel, setDeleteModel] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string>("");

  const [deleteRole] = useMutation(DELETE_ROLE, {
    refetchQueries: [{ query: GET_ROLES }],
  });
  const [searchTerm, setSearchTerm] = useState("");

  const handleDeleteConfirm = async (id: string) => {
    setDeleteModel(true);
    setDeleteId(id);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleDelete = () => {
    console.log("dlete id", deleteId);

    deleteRole({ variables: { id: deleteId } })
      .then(() => toast.success("Role deleted successfully"))
      .catch((err) => console.error("Failed to delete role:", err));
  };

  const filteredRoles = data?.roles.filter((role: any) =>
    role.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="card">
      <div className="card-header">
        <div className="flex justify-between items-center">
          <h4 className="card-title">Roles</h4>
        </div>
      </div>
      <div className="p-6">
        {/* Search Bar */}
        <div className="overflow-hidden rounded-xl bg-white transition-all dark:bg-slate-800 mb-2">
          <div className="relative">
            <div className="pointer-events-none absolute top-3.5 start-4 text-gray-900 text-opacity-40 dark:text-gray-200">
              <i className="mgc_search_line text-xl"></i>
            </div>
            <input
              type="search"
              className="h-12 w-full border-0 bg-gray-100 ps-11 pe-4 text-gray-900 placeholder-gray-500 dark:placeholder-gray-300 dark:text-gray-200 focus:ring-0 sm:text-sm"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
        </div>

        {/* Display Roles */}
        {filteredRoles?.map((role: any) => (
          <div
            key={role.id}
            className="flex items-center justify-between border border-gray-500 border-dashed p-6 mb-2"
          >
            <p className="text-gray-800 text-base font-medium inline-block">
              {role.name}
            </p>
            <div className="flex">
              <div
                className="flex items-center cursor-pointer py-2 px-4 text-sm rounded text-white bg-primary hover:bg-slate-100 hover:text-slate-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 me-2"
                onClick={() => onRoleEdit(role)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-edit-3 w-4 h-4"
                >
                  <path d="M12 20h9"></path>
                  <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
                </svg>
              </div>
              <div
                className="flex items-center cursor-pointer py-2 px-4 text-sm rounded text-white bg-danger hover:bg-slate-100 hover:text-slate-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                onClick={() => handleDeleteConfirm(role.id)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-trash w-4"
                >
                  <polyline points="3 6 5 6 21 6"></polyline>
                  <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                </svg>
              </div>
            </div>
          </div>
        ))}
        <DeleteModal
          setIsOpen={setDeleteModel}
          isOpen={deleteModel}
          handleDelete={handleDelete}
        />
      </div>
    </div>
  );
};

export default ShowRole;
