import { FC, ChangeEvent } from "react";

interface SelectProps {
  label: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  options: string[];
  valueKey?: string;
  flex?: string;
  disabled?: boolean;
}

const Select: FC<SelectProps> = ({ label, value, onChange, options, flex, valueKey = "name", disabled }) => {
  return (
    <div className={`grid ${flex === "col" ? "grid-cols-1" : "grid-cols-4 gap-6"} items-center`}>
      <label
        htmlFor={label}
        className="text-gray-800 text-sm capitalize font-medium inline-block mb-1"
      >
        {label}
      </label>
      <div className="md:col-span-3">
        <select
          value={value}
          onChange={onChange}
          disabled={disabled}
          className="form-select capitalize"
          id={label}
        >
          <option className="" value="">
            Select {label}
          </option>
          {
            options.map((option: any) => {
              if (typeof option === 'string') {
                return (
                  <option key={option} className="capitalize" value={option}>
                    {option}
                  </option>
                )
              } else {
                return (
                  <option key={option.id} className="capitalize" value={option.id}>
                    {option[valueKey]}
                  </option>
                )
              }
            }
            )}
        </select>
      </div>
    </div>
  );
};

export default Select;
