import { useQuery } from "@apollo/client";
import React, { useState, useRef, useEffect } from "react";


import { GET_PUBLISHED_NOTIFICATIONS, SUBSCRIPTION_NOTIFICATION } from "../../graphql/queries/notification";
import NotificationDropdownItem from "./NotificationDropDownItem";
import { useAuth } from "../../context/AuthContext";

type Notification = {
  id: string;
  title: string;
  description: string;
  type: string;
  user: User;
  role: Role;
  status: Status;
  date: string;
  time: string;
  readBy: string[];
};

type User = {
  // Add user properties here
};

type Role = {
  // Add role properties here
};

type Status = {
  // Add status properties here
};

const NotificationDropdown: React.FC = () => {
  const [isNotificationOpen, setNotificationOpen] = useState(false);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const { user } = useAuth()


  // Toggle notification dropdown
  const toggleNotification = () => setNotificationOpen(!isNotificationOpen);

  const { subscribeToMore, data } = useQuery(GET_PUBLISHED_NOTIFICATIONS)

  // Close dropdown when clicking outside
  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setNotificationOpen(false);
    }
  };

  useEffect(() => {
    if (data && data.publishedNotifications) {
      setNotifications(data.publishedNotifications);
    }
  }, [data]);

  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: SUBSCRIPTION_NOTIFICATION,
      updateQuery: (prev, { subscriptionData }) => {
        console.log(prev);
        
        if (!subscriptionData.data) return prev;
        const newFeedItem = subscriptionData.data.notificationAdded;
        return {
          publishedNotifications: [newFeedItem, ...prev.publishedNotifications]
        };
      }
    });
    return () => unsubscribe();
  }, [subscribeToMore]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className="relative md:flex hidden">
      <button
        type="button"
        data-fc-type="dropdown"
        data-fc-placement="bottom-end"
        className={`nav-link p-2 fc-dropdown ${isNotificationOpen ? "open" : ""
          }`}
        onClick={toggleNotification}
      >
        <span className="sr-only">View notifications</span>
        <span className="flex items-center justify-center h-6 w-6 relative">
          <i className="mgc_notification_line text-2xl"></i>
          {notifications.some(notification => !notification.readBy.includes(user?.id)) && (
            <span className="absolute top-0 right-0 h-3 w-3 bg-red-500 rounded-full"></span>
          )}
        </span>
      </button>
      <div
        ref={dropdownRef}
        className={`fc-dropdown ${isNotificationOpen ? "block" : "hidden"
          } w-80 z-50 mt-2 transition-opacity duration-300 bg-white dark:bg-gray-800 shadow-lg border border-gray-200 dark:border-gray-700 rounded-lg absolute top-[44px] -left-[280px]`}
      >
        <div className="p-2 border-b border-dashed border-gray-200 dark:border-gray-700">
          <div className="flex items-center justify-between">
            <h6 className="text-sm">Notification</h6>
            <a href="#" className="text-gray-500 underline">
              <small>Clear All</small>
            </a>
          </div>
        </div>
        <div className="p-4 h-80 overflow-auto">

          {notifications && notifications.map((item) => (
            <NotificationDropdownItem notification={item} setNotification={setNotifications} />
          ))}

          
        </div>
        <a
          href="#"
          className="p-2 border-t border-dashed border-gray-200 dark:border-gray-700 block text-center text-primary underline font-semibold"
        >
          View All
        </a>
      </div>
    </div>
  );
};

export default NotificationDropdown;
