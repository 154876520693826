import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import PageTitle from "../../components/PageTitle";
import CandidatesTable from "../../components/candidates/CandidatesTable";
import Pagination from "../../components/UIElements/Pagination";
import * as XLSX from "xlsx";
import Private from "../../context/Private";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import JSZip from "jszip";
import { GET_CANDIDATES, GET_CATEGORIES, GET_CLASSES, GET_INSTITUTIONS } from "./query";

const Candidates: React.FC = () => {
  const [page, setPage] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedInstitution, setSelectedInstitution] = useState<null | string>(
    null
  );
  const [selectedCategory, setSelectedCategory] = useState<null | string>(null);
  const [selectedClass, setSelectedClass] = useState<null | string>(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);

  const limit = 10;
  const { user } = useAuth();

  const { data: institutionData } = useQuery(GET_INSTITUTIONS);
  const { data: categoryData } = useQuery(GET_CATEGORIES);
  const { data: classData } = useQuery(GET_CLASSES);

  const { loading, error, data, refetch } = useQuery(GET_CANDIDATES, {
    variables: {
      page,
      limit,
      search: searchTerm,
      institutionId: selectedInstitution,
      categoryId: selectedCategory,
      classId: selectedClass,
    },
    fetchPolicy: "cache-and-network",
  });

  const handlePageChange = (newPage: number) => {
    setPage(newPage - 1);
    refetch({ page: newPage - 1, limit });
  };

  const { data: downloadData, loading: downloadLoading } = useQuery(
    GET_CANDIDATES,
    {
      variables: {
        page: 0,
        limit: null,
      },
      fetchPolicy: "cache-and-network",
    }
  );

  const handleDownload = () => {
    if (downloadData) {
      const filteredCandidates = downloadData?.candidates?.candidates?.filter(
        (candidate: {
          name: string;
          category: {
            id: string;
            name: string;
            colorCode: string;
          };
          institution: {
            id: string;
            name: string;
          };
          class: {
            id: string;
            name: string;
          };
        }) => {
          const matchesSearch = candidate.name
            .toLowerCase()
            .includes(searchTerm.toLowerCase());
          const matchesCategory = selectedCategory
            ? candidate?.category?.id === selectedCategory
            : true;
          const matchesCandidateInstitution = selectedInstitution
            ? candidate?.institution?.id === selectedInstitution
            : true;
          const matchesClass = selectedClass
            ? candidate?.class?.id === selectedClass
            : true;

          return (
            matchesSearch &&
            matchesCategory &&
            matchesCandidateInstitution &&
            matchesClass
          );
        }
      );

      const rows = filteredCandidates?.map(
        (candidate: {
          chestNo: string;
          name: string;
          category: { name: string };
          class: { name: string };
          admissionNo: string;
          institution: { name: string };
          section: { name: string };
          email: string;
          dob: Date;
          gender: String;
        }) => ({
          "Chest No": candidate.chestNo,
          Name: candidate.name,
          Category: candidate.category?.name,
          Class: candidate.class?.name,
          "UG Ad No": candidate.admissionNo,
          DOB: candidate.dob ? new Date(Number(candidate?.dob)).toLocaleDateString() : "",
          Gender: candidate.gender,
          Institution: candidate.institution?.name,
          Section: candidate.section?.name,
          Email: candidate.email,
        })
      );

      if (!rows || rows.length === 0) {
        console.warn("No data available for download.");
        return;
      }

      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(rows, {
        header: [
          "Chest No",
          "Name",
          "Category",
          "Class",
          "UG Ad No",
          "DOB",
          "Gender",
          "Institution",
          "Section",
          "Email",
        ],
      });
      XLSX.utils.book_append_sheet(workbook, worksheet, "Candidates");

      XLSX.writeFile(workbook, "Candidates.xlsx", { compression: true });
    }
  };

  const downloadProfiles = async () => {
    if (downloadData) {
      const filteredCandidates = downloadData?.candidates?.candidates?.filter(
        (candidate: {
          name: string;
          category: {
            id: string;
            name: string;
            colorCode: string;
          };
          institution: {
            id: string;
            name: string;
          };
          class: {
            id: string;
            name: string;
          };
        }) => {
          const matchesSearch = candidate.name
            .toLowerCase()
            .includes(searchTerm.toLowerCase());
          const matchesCategory = selectedCategory
            ? candidate?.category?.id === selectedCategory
            : true;
          const matchesCandidateInstitution = selectedInstitution
            ? candidate?.institution?.id === selectedInstitution
            : true;
          const matchesClass = selectedClass
            ? candidate?.class?.id === selectedClass
            : true;

          return (
            matchesSearch &&
            matchesCategory &&
            matchesCandidateInstitution &&
            matchesClass
          );
        }
      );

      setIsDownloading(true);
      const zip = new JSZip();
      const folder = zip.folder("Candidate_Photos");
      if (!folder) {
        setIsDownloading(false);
        return;
      }
      const totalCandidates = filteredCandidates.length;
      let processedCandidates = 0;
      for (const candidate of filteredCandidates) {
        try {
          const response = await fetch(candidate.photo);
          if (!response.ok) continue;
          const blob = await response.blob();
          folder.file(`${candidate.chestNo}.png`, blob);
        } catch (error) {
          console.error(`Failed to fetch photo for candidate ${candidate.chestNo}:`, error);
        }
        processedCandidates += 1;
        setDownloadProgress((processedCandidates / totalCandidates) * 100);
      }
      const content = await zip.generateAsync({ type: "blob" });
      const url = URL.createObjectURL(content);
      const a = document.createElement("a");
      a.href = url;
      a.download = "Candidate_Photos.zip";
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(a);
      setIsDownloading(false);
      setDownloadProgress(0);
    }
  };

  return (
    <>
      <PageTitle pagetitle="Candidates" subtitle="Candidates" />
      <div className="mt-6">
        <div className="flex flex-col sm:flex-row gap-2 mb-7">
          <div>
            <div className="flex flex-grow relative">
              <div className="pointer-events-none absolute top-3.5 left-4 text-gray-900 text-opacity-40 dark:text-gray-200">
                <i className="mgc_search_line text-xl"></i>
              </div>
              <input
                type="search"
                className="h-12 w-full border rounded-lg bg-transparent pl-11 pr-4 text-gray-900 placeholder-gray-500 dark:placeholder-gray-300 dark:text-gray-200 focus:ring-0 sm:text-sm"
                placeholder="Search Candidates..."
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  setPage(0);
                }}
              />
            </div>
          </div>
          {!user?.institution && (
            <div className="flex-grow hidden sm:block">
              <select
                required
                value={selectedInstitution ?? ""}
                onChange={(e) => {
                  setSelectedInstitution(e.target.value);
                  setPage(0);
                }}
                className="h-12 p-2 w-full border-2 border-gray-300 rounded-lg bg-transparent text-gray-900 placeholder-gray-500 dark:placeholder-gray-300 dark:text-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 dark:focus:border-blue-300 dark:focus:ring-blue-500 transition duration-200 ease-in-out sm:text-sm"
              >
                <option value="">Select Institution</option>
                {institutionData?.institutions?.map((institution: any) => (
                  <option key={institution.id} value={institution.id}>
                    {institution.shortName}
                  </option>
                ))}
              </select>
            </div>
          )}

          <div className="flex-grow hidden sm:block">
            <select
              required
              value={selectedCategory ?? ""}
              onChange={(e) => {
                setSelectedCategory(e.target.value);
                setPage(0);
              }}
              className="h-12 p-2 w-full border-2 border-gray-300 rounded-lg bg-transparent text-gray-900 placeholder-gray-500 dark:placeholder-gray-300 dark:text-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 dark:focus:border-blue-300 dark:focus:ring-blue-500 transition duration-200 ease-in-out sm:text-sm"
            >
              <option value="">Select Category</option>
              {categoryData?.categories?.map((category: any) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>

          <div className="flex-grow hidden sm:block">
            <select
              required
              value={selectedClass ?? ""}
              onChange={(e) => {
                setSelectedClass(e.target.value);
                setPage(0);
              }}
              className="h-12 p-2 w-full border-2 border-gray-300 rounded-lg bg-transparent text-gray-900 placeholder-gray-500 dark:placeholder-gray-300 dark:text-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 dark:focus:border-blue-300 dark:focus:ring-blue-500 transition duration-200 ease-in-out sm:text-sm"
            >
              <option value="">Select Class</option>
              {classData?.classes?.map((item: any) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          <Private
            permissions={["create-candidate"]}
            element={
              <Link
                to="/candidates/add"
                className="btn bg-danger/20 inline-flex items-center justify-center text-sm font-medium text-danger hover:text-white hover:bg-danger"
              >
                <i className="mgc_add_circle_line me-2 text-xl"></i> Add
                Candidate
              </Link>
            }
          />

          <Private
            permissions={["show-candidates"]}
            element={
              <button
                onClick={handleDownload}
                disabled={downloadLoading}
                className={`btn  inline-flex items-center justify-center text-sm font-medium   ${downloadLoading
                  ? "bg-gray-400 text-gray-500 cursor-not-allowed"
                  : "bg-green-300 text-green-700 hover:text-white hover:bg-green-500"
                  } `}
              >
                {!downloadLoading && (
                  <i className="mgc_arrow_down_circle_line me-2 text-xl"></i>
                )}{" "}
                {downloadLoading ? "Loading..." : "Download"}
              </button>
            }
          />

          <Private roles={['administration']} element={
            <button
              onClick={downloadProfiles}
              className={`btn  inline-flex items-center justify-center text-sm font-medium   ${downloadLoading
                ? "bg-gray-400 text-gray-500 cursor-not-allowed"
                : "bg-blue-300 text-blue-700 hover:text-white hover:bg-blue-500"
                } `}
              disabled={isDownloading}
            >
              {isDownloading ? (
                <>
                  <i className="mgc_arrow_down_circle_line me-2 text-xl"></i>
                  Downloading ({Math.round(downloadProgress)}%)
                </>
              ) : (
                downloadLoading ? "Loading..." :
                  <>
                    <i className="mgc_arrow_down_circle_line me-2 text-xl"></i>
                    Download Photos
                  </>
              )}
            </button>
          } />
        </div>

        <CandidatesTable
          refetch={refetch}
          loading={loading}
          error={error}
          candidates={data?.candidates?.candidates}
        />

        <Pagination
          totalPages={data?.candidates?.totalPages}
          page={page + 1}
          onPageChange={handlePageChange}
          className="my-8"
        />
      </div>
    </>
  );
};

export default Candidates;
