import { gql } from "@apollo/client";

// Create Role Mutation
export const UPDATE_TOPIC_SELECTION = gql`
  mutation UpdateTopicSelection(
    $id: ID!
    $input: UpdateTopicSelectionInput
  ) {
    updateTopicSelection(id: $id, input: $input) {
      id
      topic
    }
  }
`;
