import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import Spinner from "../components/UIElements/Spinner";

interface PrivateProps {
  roles?: string[];
  permissions?: string[];
  element: React.ReactElement;
  route?: boolean;
}

const Private: React.FC<PrivateProps> = ({
  roles = [],
  permissions = [],
  element,
  route,
}) => {
  const { isAuthenticated, user } = useAuth();

  if (!user && isAuthenticated) {
    return <Spinner />;
  }

  if (!isAuthenticated) {
    return <Navigate to={`/login?redirect=${location.pathname}`} />;
  }

  if (user.role.name === "administration") {
    return element;
  }

  if (roles.length && !roles.includes(user.role.name)) {
    return route ? <Navigate to="/" /> : null;
  }

  // Split permissions into AND and OR groups
  const andPermissions = permissions.filter((perm) => !perm.startsWith("|"));
  const orPermissions = permissions
    .filter((perm) => perm.startsWith("|"))
    .map((perm) => perm.substring(1)); // Remove the "|" prefix for checking

  const hasAllAndPermissions = (
    userPermissions: { name: string; isActive: boolean }[],
    requiredPermissions: string[]
  ) => {
    return requiredPermissions.every((perm) =>
      userPermissions.some(
        (userPerm) => userPerm.name === perm && userPerm.isActive
      )
    );
  };

  const hasAnyOrPermission = (
    userPermissions: { name: string; isActive: boolean }[],
    requiredPermissions: string[]
  ) => {
    return requiredPermissions.some((perm) =>
      userPermissions.some(
        (userPerm) => userPerm.name === perm && userPerm.isActive
      )
    );
  };

  // Final permission check: AND condition or OR condition must pass
  const hasPermissions =
    (andPermissions.length === 0 || hasAllAndPermissions(user.role.permissions, andPermissions)) &&
    (orPermissions.length === 0 || hasAnyOrPermission(user.role.permissions, orPermissions));

  if (!hasPermissions) {
    return route ? <Navigate to="/" /> : null;
  }

  return element;
};

export default Private;
