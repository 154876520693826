import React, { useEffect, useRef, useState } from 'react';

interface UploadImageProps {
  onFileChange: (file: File | null) => void;
  isEditing?: boolean;
  previewUrl?: any;
  setPreviewUrl: (url: string | ArrayBuffer | null) => void;
  setFormData: (data: any) => void;
  photo: string;
}

const UploadImage: React.FC<UploadImageProps> = ({
  onFileChange,
  isEditing,
  previewUrl,
  setPreviewUrl,
  photo
}) => {

  const [file, setFile] = useState<File | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  // Set local preview when file is selected
  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result as string);
      };
      reader.readAsDataURL(file);
    } else {
      setPreviewUrl(previewUrl);
    }
  }, [file]);

  // Handle file input changes
  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files && e.target.files[0];
    setFile(selectedFile || null);
    onFileChange(selectedFile || null);

    // Reset input value to allow re-selection of the same file
    if (inputRef.current) {
      inputRef.current.value = ''; // Clear the input value
    }
  };

  // Remove selected file and clear preview
  const handleRemoveFile = () => {
    setFile(null);
    setPreviewUrl(null);

    // Reset the input value after removal so the same file can be selected again
    if (inputRef.current) {
      inputRef.current.value = ''; // Clear the input value
    }
  };

  // Trigger file selection
  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  // Handle file selection and generate preview
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    handleFileInputChange(e);

    // Generate a preview for the new selected file
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result); // Preview the selected image
      };
      reader.readAsDataURL(file); // Read file as Data URL for preview
    }
  };

  return (
    <div className="w-full px-2 mt-1 flex flex-col justify-center items-start">
      <label className="text-gray-600 mt-1 mb-2 inline-block">Upload Image:</label>
      {isEditing ? (
        <>
          <div onClick={handleClick} className="flex items-center justify-center text-gray-700 h-32 border border-dashed border-gray-300 rounded-md ">
            <input
              type="file"
              name="photo"
              id="photo"
              ref={inputRef}
              hidden
              required
              onChange={handleFileChange}
              className=" bg-black absolute"
              accept="image/*"
            />
            {typeof (previewUrl) != typeof ('') && photo ? (
              <div className="w-full h-full relative">
              <img src={photo} className="w-full h-full" alt="Preview" style={{ objectFit: 'cover' }} />
              <button
                onClick={handleRemoveFile}
                className="absolute top-2 right-2 bg-red-500 text-white p-1 pb-0.5 rounded-full text-xs"
              >
                <i className="mgc_close_line p-0"></i>
              </button>
            </div>
            ) : typeof (previewUrl) == typeof ('') ? (
              <div className="w-full h-full relative">
                <img src={previewUrl} className="w-full h-full" alt="Preview" style={{ objectFit: 'cover' }} />
                <button
                  onClick={handleRemoveFile}
                  className="absolute top-2 right-2 bg-red-500 text-white p-1 pb-0.5 rounded-full text-xs"
                >
                  <i className="mgc_close_line p-0"></i>
                </button>
              </div>
            ) :
              <p className="dz-message text-center needsclick">
                <i className="mgc_pic_2_line text-6xl mt-2"></i>
                <span className="block mt-2 mx-3">Click here to upload</span>
              </p>
            }
          </div>
        </>
      ) : (
        <>
          {typeof (previewUrl) != typeof ('') ? (
            <div className=" h-32 border-2 border-gray-300 rounded-lg overflow-hidden">
              <img src={photo} alt="Image Preview" className="w-full h-full object-cover" />
            </div>
          ) : typeof (previewUrl) == typeof ('') ? (
            <div className=" h-32 border-2 border-gray-300 rounded-lg overflow-hidden">
              <img src={previewUrl} alt="Image Preview" className="w-full h-full object-cover" />
            </div>
          ) :
            (
              <div className="h-32 flex justify-center items-center border-2 border-dashed border-gray-300 rounded-lg">
                <span className="text-gray-400">No image available</span>
              </div>
            )}
        </>
      )}
    </div>
  );
};

export default UploadImage;
