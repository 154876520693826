import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useRef, useState } from "react";
import Header from "./Header";
import Candidate from "./Candidate";
import Skeleton from "../../UIElements/Skeleton";
import {
  CREATE_ELIMINATION_RESULT,
  UPDATE_ELIMINATION_RESULT,
  UPDATE_ELIMINATION_RESULT_STATUS,
} from "../../../graphql/mutations/elimination";
import toast from "react-hot-toast";
import { SELECT_FROM_ELIMINATION } from "../../../graphql/mutations/participation";
import { useNavigate } from "react-router-dom";
import { PARTICIPATION_WITH_ELIMINATION_RESULT } from "../../../graphql/queries/elimination";
import { GET_GRADES } from "../../../graphql/queries/grade";

interface ScoreRowProps {
  id: string;
  participationId: string;
  codeLetter: string;
  mark1: number;
  mark2: number;
  mark3: number;
  total: number;
  percentage: number;
  remarks: string;
}

interface MarkEntryDetailsProps {
  groupId?: string;
  program: any;
  index: number;
  refetch: () => void;
}

interface Participant {
  _id: string;
  is_selected: boolean;
  codeLetter?: string;
  eliminationResult?: {
    id?: string;
    point1?: number;
    point2?: number;
    point3?: number;
    remarks?: string;
  };
}

const MarkEntryDetails: React.FC<MarkEntryDetailsProps> = ({
  groupId,
  program,
  index,
  refetch,
}) => {
  const [maxMark, setMaxMark] = useState<number[]>([100, 100, 100]);
  const [scores, setScores] = useState<ScoreRowProps[][]>([]);
  const [selectedCandidates, setSelectedCandidates] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [duplicateTotals, setDuplicateTotals] = useState<
    Record<number, number>
  >({});
  const [resultStatus, setResultStatus] = useState("Not_Entered");
  const [sortOption, setSortOption] = useState<string>("default");
  const [maxTotalMark, setMaxTotalMark] = useState(0);
  const [grade, setGrade] = useState<any[]>([]);

  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);

  const navigate = useNavigate();

  const { data: grades } = useQuery(GET_GRADES);

  const {
    data: participantsData,
    loading: participantsLoading,
    refetch: participantsDataRefetch,
  } = useQuery(PARTICIPATION_WITH_ELIMINATION_RESULT, {
    skip: !program,
    variables: {
      programId: program?.id,
      groupId,
    },
  });

  useEffect(() => {
    if (grades?.grades) {
      if (program.isStarred) {
        setGrade(
          grades?.grades?.filter((item: any) => item.category === "starred")
        );
      } else {
        setGrade(
          grades?.grades?.filter((item: any) => item.category === "unstarred")
        );
      }
    }
  }, [grades]);

  useEffect(() => {
    // Determine maxTotalMark based on which marks are present
    let newMaxTotalMark = 0;
    if (scores[index]?.some((result: any) => result.mark1 > 0)) {
      newMaxTotalMark += maxMark[0]; // Add max for mark1
    }
    if (scores[index]?.some((result: any) => result.mark2 > 0)) {
      newMaxTotalMark += maxMark[1]; // Add max for mark2
    }
    if (scores[index]?.some((result: any) => result.mark3 > 0)) {
      newMaxTotalMark += maxMark[2]; // Add max for mark3
    }

    setMaxTotalMark(newMaxTotalMark);
  }, [participantsData, index, maxMark, scores[index]]);

  useEffect(() => {
    setResultStatus(
      program?.eliminationResultStatus?.find(
        (item: any) => item.key === (groupId || "group")
      )?.value || "Not_Entered"
    );
  }, [program, index]);

  useEffect(() => {
    if (participantsData?.ParticipationWithEliminationResult) {
      const participants = participantsData.ParticipationWithEliminationResult;

      // Set selected candidates
      const selectedIds = participants
        .filter((participant: Participant) => participant.is_selected)
        .map((participant: Participant) => ({
          participationId: participant._id,
        }));
      setSelectedCandidates(selectedIds);

      // Calculate scores
      const newScores = participants.map((participant: Participant) => ({
        id: participant.eliminationResult?.id || "",
        participationId: participant._id,
        codeLetter: participant.codeLetter || "",
        mark1: participant.eliminationResult?.point1 || 0,
        mark2: participant.eliminationResult?.point2 || 0,
        mark3: participant.eliminationResult?.point3 || 0,
        total:
          (participant.eliminationResult?.point1 || 0) +
          (participant.eliminationResult?.point2 || 0) +
          (participant.eliminationResult?.point3 || 0),
        percentage: Math.round(
          (((participant.eliminationResult?.point1 || 0) +
            (participant.eliminationResult?.point2 || 0) +
            (participant.eliminationResult?.point3 || 0)) /
            maxTotalMark) *
            100
        ),
        remarks: participant.eliminationResult?.remarks || "",
      }));

      setScores((prevScores) => {
        const updatedScores = [...prevScores];
        updatedScores[index] = newScores;
        return updatedScores;
      });
    }
  }, [participantsData]);

  useEffect(() => {
    if (scores[index]?.length > 0) {
      getSelectedCandidates();
      const newDuplicateTotals = scores[index].reduce<Record<number, number>>(
        (acc, result) => {
          // Calculate duplicate totals for each score's total
          acc[result.total] = (acc[result.total] || 0) + 1;
          return acc;
        },
        {}
      );

      setDuplicateTotals(newDuplicateTotals);
    }
  }, [scores[index]]);

  const [createEliminationResult] = useMutation(CREATE_ELIMINATION_RESULT);
  const [updateEliminationResult] = useMutation(UPDATE_ELIMINATION_RESULT);
  const [UpdateEliminationResultStatus] = useMutation(
    UPDATE_ELIMINATION_RESULT_STATUS
  );
  const [selectFromElimination] = useMutation(SELECT_FROM_ELIMINATION);

  // Process participants and apply sorting
  const processedParticipants =
    participantsData?.ParticipationWithEliminationResult || [];
  const sortedParticipants = [...processedParticipants].sort(
    (a: any, b: any) => {
      if (sortOption === "total") {
        const aScore =
          scores[index]?.find((score) => score.participationId === a._id)
            ?.total || 0;
        const bScore =
          scores[index]?.find((score) => score.participationId === b._id)
            ?.total || 0;

        return bScore - aScore; // Descending by total
      } else {
        // Default sorting: by codeLetter, then chestNumber
        const aCodeLetter = a.codeLetter || "";
        const bCodeLetter = b.codeLetter || "";

        const codeComparison = aCodeLetter.localeCompare(bCodeLetter);
        if (codeComparison !== 0) return codeComparison;

        const aChestNo = a.candidate?.[0]?.chestNo || 0;
        const bChestNo = b.candidate?.[0]?.chestNo || 0;
        return aChestNo - bChestNo; // Ascending by chestNo
      }
    }
  );

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    idx: number
  ) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent default form submission
      const nextInput = inputRefs.current[idx + 3];
      if (nextInput) {
        nextInput.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        nextInput.focus();
      }
    }
  };

  const handleUpdate = async (status: string) => {
    setLoading(true);
    const loadingToast = toast.loading("Adding Result...");
    try {
      for (let i = 0; i < scores[index]?.length; i++) {
        if (scores[index][i].id) {
          await updateEliminationResult({
            variables: {
              id: scores[index][i].id,
              input: {
                participation_id: scores[index][i].participationId,
                point1: Math.round((scores[index][i].mark1 / maxMark[0]) * 100),
                point2: Math.round((scores[index][i].mark2 / maxMark[1]) * 100),
                point3: Math.round((scores[index][i].mark3 / maxMark[2]) * 100),
                remarks: scores[index][i].remarks,
              },
            },
          });
        } else {
          await createEliminationResult({
            variables: {
              input: {
                participation_id: scores[index][i].participationId,
                point1: Math.round((scores[index][i].mark1 / maxMark[0]) * 100),
                point2: Math.round((scores[index][i].mark2 / maxMark[1]) * 100),
                point3: Math.round((scores[index][i].mark3 / maxMark[2]) * 100),
                remarks: scores[index][i].remarks,
              },
            },
          });
        }
      }

      await selectFromElimination({
        variables: {
          input: {
            is_selected: true,
            programId: program.id,
            selectedParticipationId: selectedCandidates.map(
              (p) => p.participationId
            ),
            groupId,
            eliminationGrade: scores[index]?.map((s) => {
              // const getGrade: any = grade?.find(
              //   (g: any) => s.percentage >= g.percentage
              // );

              const getGrade: any = grade
                ?.filter((g: any) => s.percentage >= g.percentage)
                ?.sort((a: any, b: any) => b.percentage - a.percentage)[0];

              return {
                participationId: s.participationId,
                grade: getGrade ? getGrade.id : null,
              };
            }),
          },
        },
      });
      handleUpdateStatus(program.id, groupId ? groupId : "group", status);
      participantsDataRefetch();
      setMaxMark([100, 100, 100]);
      toast.success(
        `Elimination Result ${
          status === "Prepared" ? "prepared" : "added"
        } successfully`
      );
    } catch (error: any) {
      const errorMessage =
        error.graphQLErrors && error.graphQLErrors[0]?.message
          ? error.graphQLErrors[0]?.message
          : "Failed to add selected Teams";
      toast.error(errorMessage);
      console.error("Failed to add selected Teams", error);
    } finally {
      toast.dismiss(loadingToast);
      setLoading(false);
    }
  };

  const handleUpdateStatus = async (
    programId: string,
    groupId: string,
    value: string
  ) => {
    try {
      await UpdateEliminationResultStatus({
        variables: {
          id: programId,
          input: {
            eliminationResultStatus: [
              {
                key: groupId,
                value,
              },
            ],
            publishTimeElimination: [
              {
                key: groupId,
                value: Date.now(),
              },
            ],
          },
        },
      });

      setResultStatus(value);
      refetch();
    } catch (error: any) {
      const errorMessage =
        error.graphQLErrors && error.graphQLErrors[0]?.message
          ? error.graphQLErrors[0]?.message
          : "Failed to update Elimination Result status";
      toast.error(errorMessage);
      console.error("Failed to update Elimination Result status", error);
    }
  };

  const getSelectedCandidates = () => {
    const maxSelection = program.maxSelection;

    // Filter out candidates with a total score of 0
    const validScores = scores[index].filter((score) => score.total > 0);

    // Sort the remaining scores array by total marks in descending order
    const sortedScores = [...validScores].sort((a, b) => b.total - a.total);

    // Get the top candidates based on maxSelection
    const selected = sortedScores.slice(0, maxSelection).map((score) => ({
      participationId: score.participationId,
      codeLetter: score.codeLetter, // Assuming chest number is same as code letter
      name: score.remarks, // Assuming remarks are the candidate name
      totalMarks: score.total,
    }));

    setSelectedCandidates(selected);
  };

  if (participantsLoading) {
    return <Skeleton />;
  }

  return (
    <>
      <Header program={program} />

      <div className="overflow-auto no-scrollbar">
        <table className="min-w-full rounded-3xl overflow-scroll mb-16">
          <thead>
            <tr className="bg-gray-200 text-gray-700">
              <th className="p-5 text-center">Sl.No</th>
              <th className="p-5 text-center">Chest.No</th>
              <th
                className="p-5 text-center w-max cursor-pointer flex items-center"
                onClick={() => setSortOption("default")}
              >
                Code Letter
                {sortOption === "default" && (
                  <i className="mgc_AZ_sort_ascending_letters_line text-xl text-red-400"></i>
                )}
              </th>
              <th className="p-5 text-center">Name</th>
              <th className="p-5 text-center">Mark 1</th>
              <th className="p-5 text-center">Mark 2</th>
              <th className="p-5 text-center">Mark 3</th>
              <th
                className="p-5 text-center cursor-pointer flex items-center"
                onClick={() => setSortOption("total")}
              >
                Total{" "}
                {sortOption === "total" && (
                  <i className="mgc_numbers_09_sort_ascending_line text-xl text-red-400"></i>
                )}
              </th>
              <th className="p-5 text-center">%</th>
              <th className="p-5 text-center">Grade</th>
              <th className="p-5 text-center">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr className="border-b border-gray-200 bg-gray-500 text-white">
              <td className="px-4 py-2 text-center" colSpan={4}>
                Mark Entering in
              </td>
              {maxMark.map((mark, index) => (
                <td className="px-4 py-2 text-center" key={index}>
                  <input
                    type="text"
                    pattern="[0-9]"
                    className="w-full text-center p-1 border rounded text-gray-700"
                    value={mark}
                    onChange={(e) => {
                      const newMaxMark = [...maxMark];
                      newMaxMark[index] = parseInt(e.target.value) || 0;
                      setMaxMark(newMaxMark);
                    }}
                  />
                </td>
              ))}
              <td className="px-4 py-2 text-center font-bold">
                {maxMark.reduce((sum, mark) => sum + (mark || 0), 0)}
              </td>
              <td className="px-4 py-2 text-center font-bold">
                {maxTotalMark}
              </td>
              <td></td>
              <td></td>
            </tr>
            {sortedParticipants.map((participant: any, indx: number) => (
              <Candidate
                key={indx}
                serialNo={indx + 1}
                chestNumber={participant?.candidate?.[0]?.chestNo || "N/A"}
                candidateName={
                  participant?.candidate?.length > 1
                    ? `${participant?.candidate[0]?.name} & Team`
                    : participant?.candidate[0]?.name
                }
                institution={
                  participant?.candidate?.[0]?.institution?.shortName ||
                  "Unknown"
                }
                codeLetter={participant?.codeLetter}
                scores={scores}
                setScores={setScores}
                index={index}
                maxMark={maxMark}
                participationId={participant._id}
                selectedCandidates={selectedCandidates}
                duplicateTotals={duplicateTotals}
                inputRefs={inputRefs}
                handleKeyDown={handleKeyDown}
                inputDisable={
                  sortOption === "total" ||
                  (resultStatus !== "Not_Entered" &&
                    resultStatus !== "Entering")
                }
                maxTotalMark={maxTotalMark}
                grades={grade}
              />
            ))}
          </tbody>
        </table>
      </div>

      <div className="fixed bottom-2 right-10 left-10 xl:left-72 flex justify-between items-center shadow-lg shadow-gray-500 bg-gray-50 p-4 rounded-md">
        {!loading ? (
          <div>
            <button
              className={`btn text-sm font-medium px-4 py-2 rounded transition text-gray-800 hover:text-gray-500`}
              onClick={() => navigate("/result/elimination")}
              disabled={loading}
            >
              <i className="mgc_arrow_left_line bg-blue-500 hover:bg-blue-600 p-2 rounded-full text-white mr-1 font-bold"></i>
              Back
            </button>
          </div>
        ) : (
          <div></div>
        )}

        {resultStatus === "Prepared" && (
          <div>
            <button
              className={`btn text-sm font-medium px-4 py-2 rounded transition ${
                loading
                  ? "bg-gray-300 text-gray-500 cursor-not-allowed" // Disabled state styling
                  : "bg-primary/20 text-primary hover:bg-primary hover:text-white"
              }`}
              onClick={() =>
                handleUpdateStatus(
                  program.id,
                  groupId ? groupId : "group",
                  "Entering"
                )
              }
              disabled={loading} // Disable the button during loading
            >
              {loading ? "Loading.." : "Edit"}
            </button>
          </div>
        )}
        {(resultStatus === "Not_Entered" || resultStatus === "Entering") && (
          <div className="">
            <button
              className={`btn text-sm font-medium px-4 py-2 rounded transition ${
                loading
                  ? "bg-gray-300 text-gray-500 cursor-not-allowed" // Disabled state styling
                  : "bg-primary/20 text-primary hover:bg-primary hover:text-white"
              }`}
              onClick={() => handleUpdate("Entering")}
              disabled={loading} // Disable the button during loading
            >
              {loading ? "Loading.." : "Update"}
            </button>

            <button
              className={`btn text-sm font-medium px-4 py-2 rounded transition ml-2 ${
                loading
                  ? "bg-gray-300 text-gray-500 cursor-not-allowed " // Disabled state styling
                  : "bg-green-300 text-green-700 hover:bg-green-700 hover:text-white"
              }`}
              onClick={() => handleUpdate("Prepared")}
              disabled={loading} // Disable the button during loading
            >
              {loading ? "Loading.." : "Finalize"}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default MarkEntryDetails;
