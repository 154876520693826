import { gql } from "@apollo/client";

export const CREATE_VENUE = gql`
    mutation CreateVenue($input: CreateVenueInput) {
        createVenue(input: $input) {
            id
            name
        }
    }
`;

export const DELETE_VENUE = gql`
    mutation DeleteVenue($deleteVenueId: ID!) {
        deleteVenue(id: $deleteVenueId)
    }
`;

export const UPDATE_VENUE = gql`
    mutation UpdateVenue($updateVenueId: ID!, $input: UpdateVenueInput) {
        updateVenue(id: $updateVenueId, input: $input) {
            id
            name
        }
    }
`;