import { ApolloError } from "@apollo/client";
import Skeleton from "../../UIElements/Skeleton";

// Type for the candidate data structure
interface Candidate {
  id: string;
  chestNo: string;
  name: string;
  photo: string;
  institution: {
    id: string;
    name: string;
    shortName: string;
  };
}

// Component props type
interface TeamInputProps {
  team: (Candidate | null)[];
  teamName: string;
  search: string[];
  setSearch: React.Dispatch<React.SetStateAction<string[]>>;
  filteredParticipants: Candidate[][];
  handleParticipantSelect: (
    participant: Candidate | null,
    index: number
  ) => void;
  double?: boolean;
  loading: boolean;
  error: ApolloError | undefined;
}

const TeamInput: React.FC<TeamInputProps> = ({
  team,
  teamName,
  search,
  setSearch,
  filteredParticipants,
  handleParticipantSelect,
  loading,
  error,
  double,
}) => {
  const handleSearchChange = (value: string, index: number) => {
    const newSearch = [...search];
    newSearch[index] = value;
    setSearch(newSearch);
  };

  const renderParticipantInput = (index: number) => (
    <div className="mt-1 relative" key={index}>
      {team[index] ? (
        <div className="flex items-center justify-between border border-gray-300 rounded-md p-2">
          <div className="flex-grow">
            <div className="text-md font-semibold">
              {team[index].institution.shortName}
            </div>
            <div className="text-sm text-gray-500">
              {team[index].institution.name}
            </div>
          </div>
          <button
            onClick={() => {
              handleParticipantSelect(null, index),
                handleSearchChange("", index);
            }}
            className="ml-4 px-2 py-1 bg-blue-500 text-white rounded-md"
          >
            <i className="mgc_pencil_line"></i>
          </button>
        </div>
      ) : (
        <>
          <input
            type="text"
            value={search[index] || ""}
            onChange={(e) => handleSearchChange(e.target.value, index)}
            placeholder={`Enter institution`}
            className="block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm p-2"
          />

          {search[index] && (
            <div className="absolute z-10 bg-white border border-gray-300 rounded-md shadow-lg mt-1 max-h-40 overflow-y-auto w-full">
              {filteredParticipants[index] &&
              filteredParticipants[index].length > 0 ? (
                filteredParticipants[index].map((participant) => (
                  <div
                    key={participant.id}
                    onClick={() => handleParticipantSelect(participant, index)}
                    className="cursor-pointer px-4 py-2 hover:bg-blue-100 flex items-center"
                  >
                    <div className="flex-grow">
                      <div className="text-md font-semibold">
                        {participant.institution.shortName}
                      </div>
                      <div className="text-sm text-gray-500">
                        {participant.institution.name}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p className="px-4 py-2 text-gray-500">No participants found</p>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );

  return (
    <div className="mb-4">
      <div className="block text-lg font-semibold text-gray-700 text-center">
        {teamName}
      </div>
      {loading ? (
        <Skeleton />
      ) : error ? (
        <p>Error in loading participants</p>
      ) : (
        <>
          {renderParticipantInput(0)}
          {double && renderParticipantInput(1)}
        </>
      )}
    </div>
  );
};

export default TeamInput;
