import { gql } from "@apollo/client";

export const GET_GRADES = gql`
  query Grades {
    grades {
      id
      name
      mark
      category
      percentage
    }
  }
`;
