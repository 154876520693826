import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  CREATE_PARTICIPATION,
  UPDATE_PARTICIPATION,
} from "../../graphql/mutations/participation";
import { GET_CANDIDATES } from "../../graphql/queries/candidates";
import {
  GET_INSTITUTION_PARTICIPANTS,
  GET_PARTICIPANTS_BY_ID,
  GET_PROGRAM_PARTICIPANTS,
} from "../../graphql/queries/participation";
import { useAuth } from "../../context/AuthContext";
import { GET_CATEGORIES } from "../../graphql/queries/category";

interface ParticipationFormProps {
  programId: string; // Pass program ID from card header
  programName: string; // Program name to display on top
  programCode: string; // Program code for display
  category: {
    name: string;
    id: string;
  };
  noOfCandidates: number;
  editParticipationId?: string;
  isRegistrable: boolean;
  closeModal: () => void;
}

const ParticipationForm: React.FC<ParticipationFormProps> = ({
  programId,
  programName,
  programCode,
  noOfCandidates,
  editParticipationId,
  category,
  isRegistrable,
  closeModal,
}) => {
  const [searchChestNo, setSearchChestNo] = useState<string[]>([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [candidateCategory, setCandidateCategory] = useState<
    string | string[] | null
  >(null);
  const [filteredCandidates, setFilteredCandidates] = useState<any[][]>(
    Array.from({ length: noOfCandidates }, () => [])
  );
  const [selectedCandidates, setSelectedCandidates] = useState<any[]>(
    Array.from({ length: noOfCandidates }, () => null)
  );
  const [participationId, setParticipationId] = useState(null);

  const { user } = useAuth();
  const isInstitutionLogin = user?.institution ? true : false;

  const [createParticipation] = useMutation(CREATE_PARTICIPATION);
  const [updateParticipation] = useMutation(UPDATE_PARTICIPATION);

  const { data: categoryData } = useQuery(GET_CATEGORIES);

  const {
    loading: candidatesLoading,
    error: candidatesError,
    data: candidatesData,
  } = useQuery(GET_CANDIDATES, {
    variables: {
      page: 0,
      limit: null,
      categoryId: candidateCategory,
    },
    fetchPolicy: "cache-and-network",
  });

  const {
    data: institutionParticipants,
    loading: loadingInstitutionParticipants,
    error: errorInstitutionParticipants,
    refetch: refetchInstitutionParticipants,
  } = useQuery(GET_INSTITUTION_PARTICIPANTS, {
    variables: { programId, institutionId: user?.institution?.id },
    skip: !isInstitutionLogin,
  });

  const {
    data: participantById,
    loading: loadingParticipantById,
    error: errorParticipantById,
    refetch: refetchParticipantById,
  } = useQuery(GET_PARTICIPANTS_BY_ID, {
    variables: { id: editParticipationId },
    skip: !editParticipationId,
  });

  useEffect(() => {
    if (!candidatesData?.candidates || !category || !categoryData) return;

    if (!isRegistrable) {
      const category = categoryData?.categories.find(
        (category: any) => category.name === "KULLIYYAH"
      );
      const categoryId = category?.id;
      setCandidateCategory(categoryId);
      return;
    }

    if (category.name == "KULLIYYAH") {
      // const aliya = categoryData?.categories.find(
      //   (category: any) => category.name === "ʿᾹLIYAH"
      // );
      // const aliyaId = aliya?.id;
      // const thanawiyyah = categoryData?.categories.find(
      //   (category: any) => category.name === "THĀNAWIYYAH"
      // );
      // const thanawiyyahId = thanawiyyah?.id;
      // setCandidateCategory([aliyaId, thanawiyyahId]);
      // for niics
      const candidateIds = categoryData?.categories
        .filter((category: any) => category.name !== "KULLIYYAH")
        .map((category: any) => category.id);

      setCandidateCategory(candidateIds);
    } else {
      setCandidateCategory(category.id);
    }
  }, [candidatesData, category, categoryData]);

  useEffect(() => {
    const participants = institutionParticipants?.institutionParticipants || [
      participantById?.participation,
    ];
    if (participants?.[0]?.candidate) {
      setSelectedCandidates(participants[0].candidate);
      setParticipationId(participants[0].id);
    }
  }, [institutionParticipants, participantById]);

  const clearInput = () => {
    setSearchChestNo([]);
    setFilteredCandidates([]);
  };

  const handleClose = () => {
    clearInput();
    closeModal();
    setSelectedCandidates([]);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitLoading(true);
    const loadingToast = toast.loading(
      participationId ? "Updating candidate..." : "Adding candidate..."
    );

    if (selectedCandidates.some((candidate) => candidate === null)) {
      toast.error("Please select all candidates.");
      setSubmitLoading(false);
      toast.dismiss(loadingToast);
      return;
    }

    if (category.name == "KULLIYYAH" && isRegistrable) {
      // const aliyaCandidate = selectedCandidates.filter(
      //   (candidate) => candidate.category.name == "ʿᾹLIYAH"
      // );

      // if (!aliyaCandidate.length) {
      //   toast.error(
      //     "KULLIYYAH program must participate at least one candidate from ʿᾹLIYAH"
      //   );
      //   setSubmitLoading(false);
      //   toast.dismiss(loadingToast);
      //   return;
      // }
    }

    // Collect candidate IDs and institutions from selected candidates
    const candidateIDs = selectedCandidates
      .filter((candidate) => candidate)
      .map((candidate) => candidate?.id);
    const institutions = selectedCandidates
      .filter((candidate) => candidate)
      .map((candidate) => candidate?.institution?.id)
      .filter(Boolean);

    // Ensure at least one candidate is selected
    if (candidateIDs.length === 0) {
      toast.error("Please select at least one candidate.");
      setSubmitLoading(false);
      toast.dismiss(loadingToast);
      return;
    }

    const uniqueCandidateIDs = Array.from(new Set(candidateIDs));

    if (uniqueCandidateIDs.length !== candidateIDs.length) {
      toast.error("Duplicate candidates are not allowed.");
      setSubmitLoading(false);
      toast.dismiss(loadingToast);
      return;
    }

    // Check if all institutions are the same
    const uniqueInstitutions = Array.from(new Set(institutions));

    if (uniqueInstitutions.length > 1) {
      toast.error("All candidates must belong to the same institution.");
      setSubmitLoading(false);
      toast.dismiss(loadingToast);
      return;
    }

    if (!uniqueInstitutions.length) {
      toast.error("institution is required");
      setSubmitLoading(false);
      toast.dismiss(loadingToast);
      return;
    }

    if (participationId) {
      try {
        await updateParticipation({
          variables: {
            id: participationId,
            input: {
              candidate: candidateIDs,
              institution: uniqueInstitutions[0],
              program: programId,
            },
          },
          refetchQueries: [
            {
              query: GET_PROGRAM_PARTICIPANTS,
              variables: {
                programId,
              },
            },
          ],
        });
        isInstitutionLogin && (await refetchInstitutionParticipants());
        editParticipationId && (await refetchParticipantById());
        toast.success("Participation Updated Successfully");
        handleClose();
      } catch (error: any) {
        const errorMessage =
          error.graphQLErrors && error.graphQLErrors[0]?.message
            ? error.graphQLErrors[0]?.message
            : "Failed to adding participation";
        toast.error(errorMessage);
        console.error("Failed to updating participation:" + error);
      } finally {
        setSubmitLoading(false);
        toast.dismiss(loadingToast);
      }
    } else {
      try {
        await createParticipation({
          variables: {
            input: {
              candidate: candidateIDs,
              institution: uniqueInstitutions[0],
              program: programId,
            },
          },
          refetchQueries: [
            {
              query: GET_PROGRAM_PARTICIPANTS,
              variables: {
                programId,
              },
            },
          ],
        });
        isInstitutionLogin && (await refetchInstitutionParticipants());
        editParticipationId && (await refetchParticipantById());
        toast.success("Participation Added Successfully");
        handleClose();
      } catch (error: any) {
        const errorMessage =
          error.graphQLErrors && error.graphQLErrors[0]?.message
            ? error.graphQLErrors[0]?.message
            : "Failed to adding participation";
        toast.error(errorMessage);
        console.error("Failed to adding participation:" + error);
      } finally {
        setSubmitLoading(false);
        toast.dismiss(loadingToast);
      }
    }
  };

  const handleSearchChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const chestNo = e.target.value;
    const updatedChestNo = [...searchChestNo];
    updatedChestNo[index] = chestNo;
    setSearchChestNo(updatedChestNo);

    if (candidatesData?.candidates?.candidates && chestNo) {
      const filtered = candidatesData?.candidates?.candidates.filter(
        (cand: any) => cand.chestNo.includes(chestNo)
      );
      const updatedFilteredCandidates = [...filteredCandidates];
      updatedFilteredCandidates[index] = filtered;
      setFilteredCandidates(updatedFilteredCandidates);
    } else {
      setFilteredCandidates([]);
    }
  };

  useEffect(() => {
    if (!candidatesLoading) {
      searchChestNo.forEach((chestNo, index) => {
        if (candidatesData?.candidates?.candidates && chestNo) {
          const filtered = candidatesData?.candidates?.candidates.filter(
            (cand: any) => cand.chestNo.includes(chestNo)
          );
          const updatedFilteredCandidates = [...filteredCandidates];
          updatedFilteredCandidates[index] = filtered;
          setFilteredCandidates(updatedFilteredCandidates);
        } else {
          const updatedFilteredCandidates = [...filteredCandidates];
          updatedFilteredCandidates[index] = [];
          setFilteredCandidates(updatedFilteredCandidates);
        }
      });
    }
  }, [candidatesLoading, candidatesData]);

  const handleCandidateSelect = (candidate: any, index: number) => {
    const updatedCandidates = [...selectedCandidates];
    updatedCandidates[index] = candidate; // Set the selected candidate details
    setSelectedCandidates(updatedCandidates);
    setFilteredCandidates(Array.from({ length: noOfCandidates }, () => [])); // Clear filtered candidates
  };

  const handleEdit = (index: number) => {
    const updatedCandidates = [...selectedCandidates];
    updatedCandidates[index] = null; // Reset to allow editing
    searchChestNo[index] = "";
    setSelectedCandidates(updatedCandidates);
  };

  return (
    <>
      <div className="space-y-6 relative">
        {/* Program Information */}
        <div className="mb-6 bg-blue-100 p-4 rounded-lg shadow-sm">
          <h3 className="text-2xl font-semibold text-gray-700">
            {programName} ({programCode})
          </h3>
          <h5>No of candidates: {noOfCandidates}</h5>
        </div>

        <div className="mb-4">
          <div className="block text-lg font-semibold text-gray-700">
            Candidates
          </div>
          {Array.from({ length: noOfCandidates }).map((_, index) => {
            if (loadingInstitutionParticipants || loadingParticipantById) {
              return "loading...";
            }
            if (errorInstitutionParticipants || errorParticipantById) {
              return "Error in loading participants";
            }
            return (
              <div key={index} className="mt-1 relative">
                {!loadingInstitutionParticipants &&
                !loadingParticipantById &&
                !errorInstitutionParticipants &&
                !errorParticipantById &&
                selectedCandidates[index] ? (
                  // Show selected candidate details or input
                  <div className="flex items-center justify-between border border-gray-300 rounded-md p-2">
                    {/* Candidate Image */}
                    <img
                      src={selectedCandidates[index].photo}
                      alt={selectedCandidates[index].name}
                      className="inline-block h-12 w-12 bg-blue-200 rounded-full border-2 border-white dark:border-gray-700 mr-4"
                    />

                    {/* Candidate Info */}
                    <div className="flex-grow">
                      <div className="text-lg font-semibold">
                        {selectedCandidates[index].chestNo}-
                        {selectedCandidates[index].name}
                      </div>
                      <div className="text-sm text-gray-500">
                        {selectedCandidates[index].institution.name}
                      </div>
                    </div>
                    <button
                      onClick={() => handleEdit(index)}
                      className="ml-4 px-2 py-1 bg-blue-500 text-white rounded-md"
                    >
                      <i className="mgc_pencil_line"></i>
                    </button>
                  </div>
                ) : (
                  <>
                    <input
                      type="text"
                      value={searchChestNo[index] || ""}
                      onChange={(e) => handleSearchChange(e, index)}
                      placeholder="Enter Chest No"
                      className="block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm p-2"
                    />

                    {/* Dropdown menu with filtered candidates or loading/error messages */}
                    {searchChestNo[index] && (
                      <div className="absolute z-10 bg-white border border-gray-300 rounded-md shadow-lg mt-1 max-h-40 overflow-y-auto w-full">
                        {candidatesLoading ? (
                          <p className="px-4 py-2 text-gray-500">Loading...</p>
                        ) : candidatesError ? (
                          <p className="px-4 py-2 text-red-500">
                            Failed to load candidate
                          </p>
                        ) : filteredCandidates[index] &&
                          filteredCandidates[index].length > 0 ? (
                          filteredCandidates[index].map((candidate: any) => (
                            <div
                              key={candidate.id}
                              onClick={() =>
                                handleCandidateSelect(candidate, index)
                              } // Close dropdown on select
                              className="cursor-pointer px-4 py-2 hover:bg-blue-100 flex items-center"
                            >
                              {/* Candidate Image */}
                              <img
                                src={candidate.photo}
                                alt={candidate.name}
                                className="inline-block h-12 w-12 bg-blue-200 rounded-full border-2 border-white dark:border-gray-700 mr-4"
                              />

                              {/* Candidate Info */}
                              <div className="flex-grow">
                                <div className="text-lg font-semibold">
                                  {candidate.chestNo}-{candidate.name}
                                </div>
                                <div className="text-sm text-gray-500">
                                  {candidate.institution.name}
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <p className="px-4 py-2 text-gray-500">
                            No candidates found
                          </p> // Message when no candidates are found
                        )}
                      </div>
                    )}
                  </>
                )}
              </div>
            );
          })}
        </div>
      </div>
      <div className="float-end space-x-4">
        <button
          onClick={handleClose}
          className="mt-4 bg-gray-300 text-white px-4 py-2 rounded-md"
        >
          Cancel
        </button>
        <button
          onClick={handleSubmit}
          className={`mt-4 text-white px-4 py-2 rounded-md ${
            submitLoading
              ? "bg-gray-400 cursor-not-allowed"
              : "bg-blue-500 hover:bg-blue-600"
          }`}
          disabled={submitLoading}
        >
          {submitLoading
            ? participationId
              ? "Updating..."
              : "Adding..."
            : participationId
            ? "Update"
            : "Add"}
        </button>
      </div>
    </>
  );
};

export default ParticipationForm;
