import { useQuery } from "@apollo/client";
import { GET_GROUPS } from "../../graphql/queries/group";
import React, { useEffect, useRef, useState } from "react";

interface ProgramProps { 
  program: {
    concept: string;
    tools: [string];
    duration: string;
    noOfCandidates: string;
    eliminationRegistrationStatus: [{
      key: string;
      value: string;
    }]
    eliminationDate: [{
      key: string;
      value: string;
    }]
    eliminationEndingTime: [{
      key: string;
      value: string;
    }]
    eliminationJudge: [{
      id: string;
      name: string;
    }]
    eliminationResultStatus: [{
      key: string;
      value: string;
    }]
    eliminationStartingTime: [{
      key: string;
      value: string;
    }]
    eliminationVenue: [{
      key: string;
      value: {
        id: string;
        name: string;
      }
    }]
  };
}

function ProgramDetails({ program }: ProgramProps) {

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setActiveGroupId(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const { data: groupData } = useQuery(GET_GROUPS);
  
  const [activeGroupId, setActiveGroupId] = useState<string | null>(null);


  function toDate(date: string) {
    const dateObj = new Date(date);
    const day = dateObj.getDate();
    const month = dateObj.getMonth() + 1;
    const year = dateObj.getFullYear();
    return `${day}-${month}-${year}`;
  }

  function toTime(time: string): string {
    const dateObj = new Date(time);
    let hours = dateObj.getHours();
    const minutes = dateObj.getMinutes();
    const period = hours >= 12 ? "PM" : "AM";

    hours = hours % 12 || 12;

    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");

    return `${formattedHours}:${formattedMinutes} ${period}`;
  }

  function toggleStatusOpen(groupId: string) {
    setActiveGroupId((prev) => (prev === groupId ? null : groupId));
  }

  return (
    <div className="card">
      <div className="p-6">
        <h4 className="text-gray-900 font-semibold mb-3 border-b border-gray-200">
          Concept Note
        </h4>

        <p className="text-gray-600 text-sm my-5">{program.concept}</p>
        <div className="flex justify-between">
          <div>
            <h4 className="text-gray-900 font-semibold mb-3 border-b border-gray-200 mt-4">
              Tools
            </h4>
            <div className="flex space-x-3">
              {program.tools?.length ? (
                program.tools?.map((tool, index) => (
                  <p
                    className="text-blue-800 text-sm uppercase font-semibold"
                    key={index}
                  >
                    {tool}
                  </p>
                ))
              ) : (
                <p className="text-blue-800 text-sm uppercase font-semibold">
                  No Tools
                </p>
              )}
            </div>
          </div>
          <div>
            <h4 className="text-gray-600 font-semibold mb-3 border-b border-gray-200 mt-4">
              Duration
            </h4>
            <p>{program.duration} Minutes </p>
          </div>
          <div>
            <h4 className="text-gray-600 font-semibold mb-3 border-b border-gray-200 mt-4">
              No Of Candidates
            </h4>
            <p>{program.noOfCandidates} Candidates </p>
          </div>
        </div>
      </div>
      <div className="p-5 flex justify-between items-center bg-gray-100">
        <div className="flex flex-col items-start justify-between">
          {groupData?.groups?.map((group: any, groupIndex: number) => (
              <div
                key={groupIndex}
                className="flex items-center justify-center gap-4 border-b border-gray-200 py-2"
              >
                <span className="whitespace-nowrap">
                  {group?.name ?? "N/A"}
                </span>
                <div className="items-start hidden md:block">
                  {program?.eliminationDate?.map(
                    (date: any, dateIndex: number) =>
                      group?.id === date?.key && (
                        <React.Fragment key={dateIndex}>
                          <p className="text-sm text-gray-600 whitespace-nowrap mb-2">
                            {toDate(date?.value)}
                          </p>
                          <div className="bg-blue-100 text-blue-600 px-2 py-0.5 rounded-full text-xs font-semibold mb-1 uppercase text-center">
                            <span>
                              {program?.eliminationVenue?.[dateIndex]?.value
                                ?.name ?? "N/A"}
                            </span>
                          </div>
                        </React.Fragment>
                      )
                  )}
                </div>
                <div className="items-start hidden md:block">
                  {program?.eliminationStartingTime?.map(
                    (time: any, timeIndex: number) =>
                      group?.id === time?.key && (
                        <React.Fragment key={timeIndex}>
                          <p className="text-xs">START TIME</p>
                          <div className="w-24 flex  items-end font-semibold">
                            <span className="text-lg mr-1">
                              {toTime(time?.value).split(" ")[0]}
                            </span>
                            <span className="text-xs text-blue-700 mb-1">
                              {toTime(time?.value).split(" ")[1]}
                            </span>
                          </div>
                        </React.Fragment>
                      )
                  )}
                </div>
                <div className="hidden md:block">
                  {program?.eliminationEndingTime?.map(
                    (time: any, endTimeIndex: number) =>
                      group?.id === time?.key && (
                        <React.Fragment key={endTimeIndex}>
                          <p className="text-xs">END TIME</p>
                          <div className="w-24 flex items-end font-semibold">
                            <span className="text-lg mr-1">
                              {toTime(time?.value).split(" ")[0]}
                            </span>
                            <span className="text-xs text-blue-700 mb-1">
                              {toTime(time?.value).split(" ")[1]}
                            </span>
                          </div>
                        </React.Fragment>
                      )
                  )}
                </div>
                <div className="relative">
                  <div
                    className={` w-24 px-2 py-2 text-center rounded-lg text-xs font-semibold 
                    ${program?.eliminationRegistrationStatus?.find(
                      (item: any) =>
                        item?.key === group?.id
                    )?.value === "Program_Ended"
                        ? "bg-red-200 text-red-600"
                        : program?.eliminationRegistrationStatus?.find(
                          (item: any) =>
                            item?.key === group?.id
                        )?.value === "Program_Started"
                          ? "bg-green-200 text-green-600"
                          : program?.eliminationRegistrationStatus?.find(
                            (item: any) =>
                              item?.key === group?.id
                          )?.value ===
                            "Registration_Completed"
                            ? "bg-blue-200 text-blue-600"
                            : program?.eliminationRegistrationStatus?.find(
                              (item: any) =>
                                item?.key === group?.id
                            )?.value ===
                              "Registration_Started"
                              ? "bg-orange-200 text-orange-600"
                              : "bg-gray-200 text-gray-600"
                      }`}
                    onClick={() => toggleStatusOpen(group.id)}
                  >
                    {program?.eliminationRegistrationStatus
                      ?.find((item: any) => item?.key === group?.id)
                      ?.value.split("_")
                      .join(" ") || "Not Started"}
                  </div>

                  {activeGroupId === group.id && (
                    <div
                      ref={dropdownRef}
                      className="absolute bg-white rounded-xl z-20"
                    >
                      {program?.eliminationRegistrationStatus?.find(
                        (item: any) => item?.key === group?.id
                      )?.value === "Program_Ended" ||
                        program?.eliminationRegistrationStatus?.find(
                          (item: any) => item?.key === group?.id
                        )?.value === "Registration_Completed" ||
                        program?.eliminationRegistrationStatus?.find(
                          (item: any) => item?.key === group?.id
                        )?.value === "Program_Started" ? (
                        <>
                        </>
                      ) : (
                        <>

                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default ProgramDetails;
