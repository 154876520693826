import React, { useEffect, useState } from "react";
import { UPDATE_CODE_LETTER } from "../../graphql/mutations/codeletter";
import { useMutation, useQuery } from "@apollo/client";
import toast from "react-hot-toast";
import { GET_PROGRAM_CODE_LETTERS } from "../../graphql/queries/codeletter";

interface CodeLetterStudentProps {
  serialNo: number;
  chestNumber: string;
  candidateName: string;
  candidateId: string;
  institution: string;
  codeLetter: string;
  attendanceStatus: boolean;
  eliminationRegistrationStatus?: boolean;
  options: string[];
  programId: string;
  groupId?: string;
  clusterId?: string;
}

const CodeLetterStudent: React.FC<CodeLetterStudentProps> = ({
  serialNo,
  chestNumber,
  candidateName,
  candidateId,
  institution,
  codeLetter,
  attendanceStatus,
  eliminationRegistrationStatus,
  options: initialOptions,
  programId,
  groupId,
  clusterId,
}) => {
  const [attendance, setAttendance] = useState(attendanceStatus);
  const [currentCode, setCurrentCode] = useState(codeLetter);
  const [options, setOptions] = useState(initialOptions);

  const { data: codeLetters, refetch } = useQuery(GET_PROGRAM_CODE_LETTERS, {
    skip: !programId,
    variables: {
      programId: programId,
      groupId: groupId || null,
      clusterId: clusterId || null,
    },
  });

  useEffect(() => {
    if (codeLetters?.programParticipants) {
      const filteredOptions = initialOptions.filter(
        (option) =>
          !codeLetters.programParticipants.some(
            (participant: any) => participant?.codeLetter === option
          )
      );

      setOptions(filteredOptions);
    }
  }, [codeLetters]);

  useEffect(() => {
    if (codeLetter) {
      setCurrentCode(codeLetter);
    }
  }, [codeLetter]);

  const [updateCodeLetter] = useMutation(UPDATE_CODE_LETTER);

  const handleAttendanceChange = async (value: string) => {
    setAttendance(value === "Present");
    const updateLoading = toast.loading("Updating status...");
    try {
      await updateCodeLetter({
        variables: {
          updateCodeLetterId: candidateId,
          input: {
            isPresent: value === "Present",
          },
        },
      });
      toast.success("Mark presents successfully!");
      toast.remove(updateLoading);
      refetch();
    } catch (error: any) {
      console.log(error);
      toast.remove(updateLoading);
      toast.error("Failed to Mark presents");
    }
  };

  const handleCodeChange = async (data: string) => {
    const loadingUpdate = toast.loading("Updating CodeLetter...");
    try {
      await updateCodeLetter({
        variables: {
          updateCodeLetterId: candidateId,
          input: {
            codeLetter: data.toUpperCase(),
            isPresent: true,
          },
        },
      });
      toast.dismiss(loadingUpdate);
      setCurrentCode(data);
      toast.success("CodeLetter Updated!");

      refetch();
    } catch (error) {
      console.log("Error: ", error);
      toast.dismiss(loadingUpdate);
      toast.error("Failed to update CodeLetter");
    }
  };

  return (
    <tr className="border-b border-gray-200 bg-white">
      <td className="px-4 py-2 text-center">{serialNo}</td>
      <td className="px-4 py-2 text-center">{chestNumber}</td>
      <td className="px-4 py-2 flex items-center space-x-2">
        <img
          src={`${
            chestNumber &&
            `https://haris740.github.io/sibaq-image-uploads/profiles/${chestNumber[0]}000/${chestNumber}.png`
          }`}
          alt="Profile"
          className="w-8 h-8 rounded-full"
        />
        <div>
          <p className="font-semibold">{candidateName}</p>
        </div>
      </td>
      <td className="px-4 py-2 text-center font-semibold">{institution}</td>
      <td className="px-4 py-2 text-center font-semibold">
        {/* allowed only codeletters included in options array */}
        <input
          type="text"
          name="code-letter"
          id="code-letter"
          maxLength={1}
          value={currentCode}
          onChange={(e) => {
            const value = e.target.value.toUpperCase(); // Ensure uppercase
            if (options.includes(value) || value === "") {
              handleCodeChange(value);
            } else {
              toast.error("This code letter is not allowed");
            }
          }}
          className={`w-10 border-none rounded-lg text-center focus:ring-transparent bg-gray-100 capitalize ${!eliminationRegistrationStatus && "cursor-not-allowed"}`}
          disabled={!eliminationRegistrationStatus}
        />
      </td>
      <td className="px-4 py-2 text-center">
        <select
          className={`px-7 text-xs py-1 text-white font-semibold rounded-lg border-none focus:ring-transparent ${
            attendance ? "bg-green-500" : "bg-red-500"
          } ${!eliminationRegistrationStatus && "cursor-not-allowed"}`}
          onChange={(e) =>
            eliminationRegistrationStatus &&
            handleAttendanceChange(e.target.value)
          }
          value={attendance ? "Present" : "Absent"}
          disabled={!eliminationRegistrationStatus}
        >
          <option value="Present" className="bg-white text-black">
            Present
          </option>
          <option value="Absent" className="bg-white text-black">
            Absent
          </option>
        </select>
      </td>
    </tr>
  );
};

export default CodeLetterStudent;
