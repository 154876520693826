import { gql } from "@apollo/client";

export const CREATE_SKILL = gql`
  mutation CreateSkill($name: String!) {
    createSkill(input: { name: $name }) {
      id
      name
    }
  }
`;

export const UPDATE_SKILL = gql`
  mutation UpdateSkill($id: ID!, $name: String!) {
    updateSkill(id: $id, input: { name: $name }) {
      id
      name
    }
  }
`;

export const DELETE_SKILL = gql`
  mutation DeleteSkill($id: ID!) {
    deleteSkill(id: $id)
  }
`;
