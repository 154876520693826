import { gql } from "@apollo/client";

export const CREATE_NEWS = gql`
  mutation CreateNews($input: CreateNewsInput!) {
  createNews(input: $input) {
    id
    title
    description
    date
    photo
  }
}
`;

export const UPDATE_NEWS = gql`
  mutation UpdateNews($id: ID!, $input: UpdateNewsInput!) {
  updateNews(id: $id, input: $input) {
    title
  }
}
`;

export const DELETE_NEWS = gql`
  mutation DeleteNews($id: ID!) {
  deleteNews(id: $id)
}
`;
