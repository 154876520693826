import React from "react";

interface DeleteCurbModalProps {
    isOpen: boolean;
    curbName: string;
    onClose: () => void;
    onConfirm: () => void;
}

const DeleteCurbModal: React.FC<DeleteCurbModalProps> = ({
    isOpen,
    curbName,
    onClose,
    onConfirm,
}) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
                <h2 className="text-lg font-semibold mb-4">Delete Curb</h2>
                <p>
                    Are you sure you want to delete the curb{" "}
                    <strong>"{curbName}"</strong>? This action cannot be undone.
                </p>
                <div className="flex justify-end space-x-4 mt-4">
                    <button
                        type="button"
                        onClick={onClose}
                        className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md"
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        onClick={onConfirm}
                        className="bg-red-600 text-white px-4 py-2 rounded-md"
                    >
                        Delete
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DeleteCurbModal;
