import React, { Key, useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_GROUPS } from "../../graphql/queries/group";
import AddSchedule from "./AddSchedule";

interface ScheduleCardProps {
  programData: any;
  refetch: () => void;
}

interface Venue {
  id: string;
  name: string;
}

const ScheduleCard: React.FC<ScheduleCardProps> = ({
  programData,
  refetch,
  // onEdit, onView, refetch, isDropdownOpen, toggleDropdown
}) => {
  const { data: groupData } = useQuery(GET_GROUPS);

  const [isEliminationSwitch, setIsEliminationSwitch] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editData, setEditData] = useState({
    eliminationDate: "",
    eliminationStartingTime: "",
    eliminationEndingTime: "",
    eliminationVenue: "",
    finalDate: "",
    finalStartingTime: "",
    finalEndingTime: "",
    finalVenue: "",
  });
  const [groupId, setGroupId] = useState("");

  function arrayToObject<T>(
    array: { key: string; value: T }[]
  ): Record<string, T> {
    return array?.reduce((acc, item) => {
      acc[item.key] = item.value;
      return acc;
    }, {} as Record<string, T>);
  }

  const eliminationDate = arrayToObject<string>(programData.eliminationDate);
  const eliminationStartingTime = arrayToObject<string>(
    programData.eliminationStartingTime
  );
  const eliminationEndingTime = arrayToObject<string>(
    programData.eliminationEndingTime
  );
  const eliminationVenue = arrayToObject<Venue>(programData.eliminationVenue);

  const finalDate = arrayToObject<string>(programData.finalDate);

  const finalStartingTime = arrayToObject<string>(
    programData.finalStartingTime
  );
  const finalEndingTime = arrayToObject<string>(programData.finalEndingTime);
  const finalVenue = arrayToObject<Venue>(programData.finalVenue);

  const toggleRoundSwitch = (bool: boolean) => {
    setIsEliminationSwitch(bool);
  };

  const handleModalOpen = (group: string) => {
    setGroupId(group);
    setIsModalOpen(true);

    if (eliminationDate && eliminationDate[group]) {
      setEditData({
        ...editData,
        eliminationDate: eliminationDate[group] || "",
        eliminationStartingTime: eliminationStartingTime[group] || "",
        eliminationEndingTime: eliminationEndingTime[group] || "",
        eliminationVenue: eliminationVenue[group]
          ? eliminationVenue[group].id
          : "",
      });
    }

    if (finalDate && finalDate[group]) {
      setEditData({
        ...editData,
        finalDate: finalDate[group] || "",
        finalStartingTime: finalStartingTime[group] || "",
        finalEndingTime: finalEndingTime[group] || "",
        finalVenue: finalVenue[group] ? finalVenue[group].id : "",
      });
    }
  };

  return (
    <div className="bg-white p-4 rounded-3xl shadow-md w-full max-w-md flex flex-col justify-between">
      <div className="flex justify-between items-start mt-1">
        <h3 className="text-lg font-semibold text-gray-800">
          {programData?.name} | {programData?.programCode}
        </h3>

        <div className="flex flex-col items-end">
          <span className="text-gray-500 text-sm font-medium">
            {programData?.category?.name}
          </span>
          <div
            className="inline-flex rounded-md shadow-inner mt-1"
            role="group"
          >
            <button
              type="button"
              onClick={() => {
                toggleRoundSwitch(true);
              }}
              className={`px-2 py-1 text-xs font-medium rounded-s-lg  ${
                isEliminationSwitch
                  ? "z-10 bg-gray-200 text-black dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:bg-gray-700"
                  : "text-gray-600 bg-transparent border border-gray-200 hover:bg-gray-200 hover:text-black"
              }`}
            >
              Elimination
            </button>

            {/* <button
              type="button"
              onClick={() => {
                toggleRoundSwitch(false);
              }}
              className={`px-2 py-1 text-xs font-medium rounded-e-lg ${
                isEliminationSwitch
                  ? "text-gray-600 bg-transparent border border-gray-200 hover:bg-gray-200 hover:text-black"
                  : " z-10 bg-gray-200 text-black dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:bg-gray-700"
              }`}
            >
              Final
            </button> */}
          </div>
        </div>
      </div>
      {isEliminationSwitch ? (
        <div className="flex flex-col justify-start flex-1">
          {groupData?.groups?.map((group?: any, index?: Key) => (
            <div key={index} className="">
              <div className="flex justify-between items-center p-2 bg-gray-100 rounded-lg mt-2">
                <div className="w-full">
                  <div className="flex items-center justify-between mb-2">
                    <h4 className="text-sm font-medium text-gray-800">
                      {group?.name}
                    </h4>
                    <i
                      className="mgc_edit_3_line cursor-pointer"
                      onClick={() => handleModalOpen(group.id)}
                    ></i>
                  </div>
                  {eliminationVenue &&
                    eliminationDate &&
                    eliminationStartingTime &&
                    eliminationEndingTime &&
                    eliminationVenue[group.id] &&
                    eliminationDate[group.id] &&
                    eliminationStartingTime[group.id] &&
                    eliminationEndingTime[group.id] && (
                      <div className="w-full flex items-center justify-between">
                        <p className="text-sm text-gray-500">
                          {new Date(
                            eliminationDate[group.id]
                          ).toLocaleDateString("en-GB", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "2-digit",
                          })}{" "}
                          |{" "}
                          <span className="capitalize">
                            {eliminationVenue[group.id]?.name}
                          </span>
                        </p>
                        <p className="text-sm text-gray-500">
                          {new Date(
                            eliminationStartingTime[group.id]
                          ).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                          })}{" "}
                          -{" "}
                          {new Date(
                            eliminationEndingTime[group.id]
                          ).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                        </p>
                      </div>
                    )}
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="flex flex-col justify-start flex-1">
          {groupData?.groups?.map((group?: any, index?: Key) => (
            <div key={index} className="">
              <div className="flex justify-between items-center p-2 bg-gray-100 rounded-lg mt-2">
                <div className="w-full">
                  <h4 className="text-sm font-medium text-gray-800">
                    {group?.name}
                  </h4>
                  {finalVenue &&
                    finalDate &&
                    finalStartingTime &&
                    finalEndingTime &&
                    finalVenue[group.id] &&
                    finalDate[group.id] &&
                    finalStartingTime[group.id] &&
                    finalEndingTime[group.id] && (
                      <div className="w-full flex items-center justify-between">
                        <p className="text-sm text-gray-500">
                          {new Date(finalDate[group.id]).toLocaleDateString(
                            "en-GB",
                            {
                              day: "2-digit",
                              month: "2-digit",
                              year: "2-digit",
                            }
                          )}{" "}
                          |{" "}
                          <span className="capitalize">
                            {finalVenue[group.id]?.name}
                          </span>
                        </p>
                        <p className="text-sm text-gray-500">
                          {new Date(
                            finalStartingTime[group.id]
                          ).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                          })}{" "}
                          -{" "}
                          {new Date(
                            finalEndingTime[group.id]
                          ).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                        </p>
                      </div>
                    )}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      <AddSchedule
        programId={programData.id}
        groupId={groupId}
        isElimination={isEliminationSwitch}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        refetch={refetch}
        editData={editData}
      />
    </div>
  );
};

export default ScheduleCard;
