import React from "react";
import SinglePrint from "./SinglePrint";
import { SELECTED_PARTICIPATION_FROM_MANY_PROGRAM } from "../../../graphql/queries/participation";
import { useQuery } from "@apollo/client";

interface AllPrintProps {
  programIds: string[];
  groupId: string;
}

const AllPrint: React.FC<AllPrintProps> = ({ programIds, groupId }) => {
  const { data, loading, error } = useQuery(
    SELECTED_PARTICIPATION_FROM_MANY_PROGRAM,
    {
      variables: {
        input: {
          programId: programIds,
          groupId,
        },
      },
      skip: programIds.length === 0,
      fetchPolicy: "network-only",
    }
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div>
      {data?.selectedParticipationsFromManyProgram?.map(
        ({ program, selectedParticipations }: any, index: number) =>
          selectedParticipations.length > 0 && (
            <SinglePrint
              key={index}
              programCode={program.programCode}
              programName={program.name}
              programCategory={program.category?.name}
              results={selectedParticipations}
            />
          )
      )}
    </div>
  );
};

export default AllPrint;
