import React, { useEffect, useState } from "react";
import Modal from "../UIElements/Model";
import Input from "../ui-elements/Input";
import { useMutation, useQuery } from "@apollo/client";
import {
  CREATE_PROGRAM_CATEGORY,
  DELETE_PROGRAM_CATEGORY,
  UPDATE_PROGRAM_CATEGORY,
} from "../../graphql/mutations/programCategory";
import Card from "./Card";
import toast from "react-hot-toast";
import CardStructure from "./CardStructure";
import Skeleton from "../UIElements/Skeleton";
import { GET_PROGRAM_CATEGORIES } from "../../graphql/queries/programCategory";
import DeleteModal from "../UIElements/DeleteModal";

const ProgramCategory: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [details, setDetails] = useState<string>("");
  const [editProgramCategory, setEditProgramCategory] = useState<any>(null);
  const [deleteModel, setDeleteModel] = useState<boolean>(false);
  const [deleteProgramCategoryId, setDeleteProgramCategoryId] = useState<string>("");

  useEffect(() => {
    if (editProgramCategory) {
      setName(editProgramCategory.name);
      setDetails(editProgramCategory.details);
    }
  }, [editProgramCategory]);

  // QUERY
  const { data, loading, error, refetch } = useQuery(GET_PROGRAM_CATEGORIES);

  // MUTATION
  const [createProgramCategory, { loading: createLoading }] =
    useMutation(CREATE_PROGRAM_CATEGORY);
  const [updateProgramCategory, { loading: updateLoading }] =
    useMutation(UPDATE_PROGRAM_CATEGORY);
  const [deleteProgramCategory] =
    useMutation(DELETE_PROGRAM_CATEGORY);

  if (loading) return <Skeleton />;

  if (error) return <p>Error loading sections</p>;

  const handleAddProgramCategory = async () => {
    setEditProgramCategory(null);
    clearInput();
    setIsOpen(true);
  };

  const handleEditProgramCategory = async (item: any) => {
    setEditProgramCategory(item);
    setName(item.name);
    setDetails(item.details);
    setIsOpen(true);
  };

  const handleDeleteConfirm = async (id: string) => {
    setDeleteModel(true);
    setDeleteProgramCategoryId(id);
  }

  const handleDelete = async () => {
    try {
      await deleteProgramCategory({ variables: { id: deleteProgramCategoryId } });
      toast.success("Category deleted successfully!");
      setDeleteModel(false);
      clearInput();
      refetch();
    } catch (error) {
      console.error("Failed to delete the category", error);
      toast.error("Failed to delete the category")
    }
  }

  const clearInput = () => {
    setName("");
    setDetails("");
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!name.trim() || !details.trim()) {
      toast.error("Please fill in all fields.");
      return;
    }

    try {
      if (editProgramCategory) {
        await updateProgramCategory({
          variables: {
            id: editProgramCategory.id,
            name: name.trim(),
            details: details.trim(),
          },
        });
        toast.success("Program Category updated successfully!");
      } else {
        await createProgramCategory({
          variables: {
            name: name.trim(),
            details: details.trim(),
          },
        });
        toast.success("Program Category created successfully!");
      }

      setIsOpen(false);
      clearInput();
      refetch();
    } catch (error) {
      console.error(
        editProgramCategory
          ? `Failed to update the program category:`
          : `Failed to create the program category`,
        error
      );
      toast.error(
        editProgramCategory
          ? `Failed to update the program category:`
          : `Failed to create the program category`
      );
    }
  };

  if (loading) return <Skeleton />;

  if (error) return <p>Error loading Program Categories</p>;

  return (
    <>
      <CardStructure
        Heading="Program Categories"
        addButton="Add"
        addButtonOnCLick={handleAddProgramCategory}
        data={data.programCategories}
        renderCard={(item: any) => (
          <Card
            item={item}
            title={item.name}
            subTitle={item.details}
            handleEditButton={handleEditProgramCategory}
            handleDeleteButton={handleDeleteConfirm}
            textClass="text-sm"
          />
        )}
      />
      <Modal
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        title={editProgramCategory ? "Edit Program Category" : "Add Program Category"}
        handleSubmit={handleSubmit}
        loading={editProgramCategory ? updateLoading : createLoading}
      >
        <form className="flex flex-col gap-3" onSubmit={handleSubmit}>
          <Input
            placeholder="Enter Name"
            label="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Input
            placeholder="Enter Details"
            label="details"
            value={details}
            onChange={(e) => setDetails(e.target.value)}
          />
        </form>
      </Modal>
      <DeleteModal
        setIsOpen={setDeleteModel}
        isOpen={deleteModel}
        handleDelete={handleDelete}
      />
    </>
  );
};

export default ProgramCategory;
