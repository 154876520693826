import React from "react";
import { useReactToPrint } from "react-to-print";
import JudgementSheet, { IJudges } from "./JudgementSheet";

interface JudgeProps {
  judge: IJudges;
  removeJudge: ((programId: string, judgeId: string, round: string) => any);
  programId: string;
  round: string;
}

const Judge: React.FC<JudgeProps> = ({ judge, removeJudge, programId, round }) => {
  const componentRef = React.useRef(null);
  const handlePrint = useReactToPrint({
    contentRef: componentRef
  });

  return (
    <div className="flex justify-between items-center p-2 bg-gray-100 rounded-lg mt-2">
      <div className="flex items-center space-x-3">
        <img
          src="https://via.placeholder.com/50"
          alt={judge?.name}
          className="w-9 h-9 rounded-full"
        />
        <div>
          <h4 className="text-sm font-medium text-gray-800">{judge.name}</h4>
          <p className="text-xs text-gray-500">{judge?.host?.name}</p>
        </div>
      </div>
      <div className="flex">
        <button
          type="button"
          className="text-black"
          onClick={() => handlePrint()}
        ><i className="mgc_print_line text-lg text-gray-500 mr-2"></i></button>
        <JudgementSheet ref={componentRef}
          judge={judge}
          programId={programId}
          round={round}
        />
        <i className="mgc_close_line text-md border-2 hover:border-red-500 hover:text-red-700 focus:border-red-300 focus:text-red-400 rounded-full px-1.5 py-1.5" onClick={() => removeJudge(programId, judge?.id, round)}></i>
      </div>
    </div>
  );
};

export default Judge;

