import React from "react";
interface DashboardCardProps {
  count: number;
  label: string;
  avatar: string;
  className: string;
}

const DashboardCard: React.FC<DashboardCardProps> = ({
  count,
  label,
  avatar,
  className,
}) => {
  return (
    <div className="card">
      <div className="p-3">
        <div className="flex justify-between items-start">
          <div
            className={
              "justify-center items-center rounded-full w-16 h-16 hidden md:flex " +
              className
            }
          >
            <i className={avatar + " text-3xl"}></i>
          </div>
          <div>
            <h4 className="text-2xl mb-1 font-bold text-red-500 dark:text-gray-400">
              {count }
            </h4>
            <p className="font-semibold text-xs text-gray-400 truncate dark:text-gray-500">
              {label}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardCard;
