import { gql } from "@apollo/client";

export const START_CHAT = gql`
  mutation StartChat($userId: ID!) {
    startChat(userId: $userId) {
      id
    }
  }
`;

export const SEND_MESSAGE = gql`
mutation SendMessage($chatId: ID!, $senderId: ID!, $content: String!) {
  sendMessage(chatId: $chatId, senderId: $senderId, content: $content) {
    id
  }
}
`
