import React, { useState } from "react";
import PageTitle from "../../components/PageTitle";
import AddRole from "../../components/role/AddRole";
import ShowRole from "../../components/role/ShowRole";
import { useQuery } from "@apollo/client";
import { GET_ROLES } from "../../graphql/queries/role";

const Role: React.FC = () => {
  const [editingRole, setEditingRole] = useState<any>(null);

  const { data, refetch } = useQuery(GET_ROLES);

  const handleRoleSaved = () => {
    setEditingRole(null); // Reset editing state
    refetch();
  };

  const handleEdit = (role: any) => {
    setEditingRole(role);
  };

  return (
    <>
      <PageTitle pagetitle={"Role"} subtitle={"Admin"} />
      <div className="flex flex-col space-y-4">
        <div className="grid md:grid-cols-2 gap-6">
          <AddRole editingRole={editingRole} onRoleSaved={handleRoleSaved} />
          <ShowRole data={data} onRoleEdit={handleEdit} />
        </div>
      </div>
    </>
  );
};

export default Role;
