import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useRef, useState } from "react";
import { SEND_MESSAGE, START_CHAT } from "../../graphql/mutations/support";
import { useAuth } from "../../context/AuthContext";
import toast from "react-hot-toast";
import { GET_CHAT_BY_USERS, MESSAGE_ADDED } from "../../graphql/queries/chat";

interface Message {
  sender: {
    id: string;
  };
  profilePath?: string;
  content: string;
  imageUrl?: string;
  timestamp?: string;
  status?: string;
}

const SupportChat: React.FC<{isChatOpen: boolean}> = ({ isChatOpen }) => {
  const [messages, setMessages] = useState<Message[]>([
    // {
    //   id: 1,
    //   sender: "support",
    //   profilePath: "img/logo-sm.png",
    //   content:
    //     "Hello user, the weather is nice. If you encounter any issues, feel free to ask me. I'm here at your service.",
    // },
    // {
    //   id: 2,
    //   profilePath: "img/user.png",
    //   sender: "user",
    //   content: "I like to know about item registration, when will it start?",
    // },
    // {
    //   id: 3,
    //   profilePath: "img/logo-sm.png",
    //   sender: "support",
    //   content:
    //     "Item registration will start at 12:00 AM  on 2024-11-07. We will inform you  about the details via email.",
    // },
  ]);
  const [showOptions, setShowOptions] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const [isChatExist, setIsChatExist] = useState(false);

  const chatContainerRef = useRef<HTMLDivElement>(null);

  const { user } = useAuth();

  // useMutation OF START_CHAT
  const [startChat] = useMutation(START_CHAT);
  const [sendMessage] = useMutation(SEND_MESSAGE);

  const { data, refetch, subscribeToMore } = useQuery(
    GET_CHAT_BY_USERS,
    {
      variables: {
        userId: user?.id,
      },
    }
  );

  useEffect(() => {
    if (data?.getChatByUser?.id) {
      const unsubscribe = subscribeToMore({
        document: MESSAGE_ADDED,
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData) return prev;

          const newMessage = subscriptionData.data.messageAdded;
          if (newMessage.chatId.id !== data.getChatByUser.id) return prev;
          setMessages((prevMsg) => [...prevMsg, newMessage]);
          return {
            getChatByUser: {
              ...prev.getChatByUser,
              messages: [...prev.getChatByUser?.messages, newMessage],
            },
          };
        },
      });

      // Optionally return unsubscribe function
      return unsubscribe;
    }
  }, [data, subscribeToMore]);

  useEffect(() => {
      if (data?.getChatByUser) {
        setMessages(data.getChatByUser.messages);
          setIsChatExist(true);
      } else {
        setIsChatExist(false);
      }
  }, [data?.getChatByUser]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages, data?.getChatByUser?.messages, isChatExist, isChatOpen]);

  const handleSendMessage = async () => {
    if (newMessage.trim() === "") return;
    try {
      await sendMessage({
        variables: {
          chatId: data?.getChatByUser?.id,
          senderId: user?.id,
          content: newMessage,
        },
      });
      refetch();
    } catch (error) {
      toast.error("Failed to send message");
      console.log(error);
    }
    setNewMessage("");
  };

  const startChatHandler = async () => {
    setIsChatExist(true);
    setShowOptions(false);
    try {
      await startChat({
        variables: {
          userId: user?.id,
        },
      });
      refetch();
    } catch (error) {
      console.log(error);
    }
  };

  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };

  const endChat = () => {
    setMessages([]);
    setShowOptions(false);
  };

  const sendMessageHandler = (e: any) => {
    if (newMessage.trim() === "") return;
    if (e.key === "Enter") {
      handleSendMessage();
    }
  };

  return (
    <div className="w-full max-w-sm min-w-96 h-[450px] mx-auto py-2 pb-3 bg-white shadow-2xl border-1 rounded-3xl rounded-br-none overflow-hidden flex flex-col z-50">
      <div className="flex items-center justify-between border-b p-3 bg-red">
        <div className="flex items-center">
          <div className="relative p mr-2 bg-white rounded-full">
            <img
              src="img/logo-sm.png"
              alt="Sibaq Logo"
              className="w-8 h-8 rounded-full object-cover"
            />
            <div className="absolute bottom-0 right-0 w-3 h-3 border-2 border-red rounded-full bg-green-500"></div>
          </div>
          <span className="font-medium ">SIBĀQ Support</span>
        </div>
        <div className="relative">
          <button onClick={toggleOptions} className="focus:outline-none">
            <i className="mgc_more_1_fill"></i>
          </button>
          {showOptions && (
            <div className="absolute right-0 mt-2 w-40 bg-white border rounded-lg shadow-lg z-10">
              <button
                className="w-full text-left px-4 py-2 text-sm text-red-500 hover:bg-gray-100"
                onClick={endChat}
              >
                <i className="mgc_delete_2_line"> </i>
                Clear Chat
              </button>
            </div>
          )}
        </div>
      </div>

      <div
        className="flex-1 p-4 overflow-y-auto space-y-4"
        ref={chatContainerRef}
      >
        {messages?.map((msg) => (
          <div
            className={`flex ${
              msg.sender?.id !== data.getChatByUser?.userId?.id
                ? "justify-start"
                : "justify-end"
            }`}
          >
            {/* {!isCurrentUser && profileImg && (
              <img src={profileImg} alt={msg.sender} className="w-8 h-8 rounded-full mr-2" />
          )} */}
            <div>
              <div className="text-xs text-gray-500 m-1">
                {new Date(Number(msg.timestamp)).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: true,
                })}
              </div>
              <div
                className={`${
                  msg.sender?.id !== data.getChatByUser?.userId?.id
                    ? "bg-white text-black border border-gray-300"
                    : "bg-blue-600 text-white"
                } p-3 rounded-lg max-w-md`}
              >
                <p>{msg.content}</p>
              </div>
            </div>
          </div>

          // <div
          //   className={`flex w-full ${
          //     msg.sender.id === user.id ? "justify-end" : "justify-start"
          //   }`}
          // >
          //   <div
          //     className={`px-3 max-w-xs min-w-64  py-2 rounded-lg shadow-md ${
          //       msg.sender?.id === user?.id
          //         ? "bg-gray-200 text-black"
          //         : "bg-blue-500 text-white"
          //     }`}
          //   >
          //     <p className="text-sm">{msg.content}</p>

          //     {/* Timestamp & Status */}
          //     <div
          //       className={`flex items-center gap-1 mt-1 justify-end`}
          //     >
          //       <span className={`text-xs ${msg.sender?.id === user?.id ? "text-gray-400" : "text-white"}`}>
          //         {new Date(Number(msg.timestamp)).toLocaleTimeString([], {
          //           hour: "2-digit",
          //           minute: "2-digit",
          //           hour12: true,
          //         })}
          //       </span>
          //       {msg.sender?.id === user?.id && (
          //         <span>
          //           {msg.status === "sent" && (
          //             <i className="mgc_check_line text-gray-400"></i>
          //           )}
          //           {msg.status === "delivered" && (
          //             <i className="mgc_double_check_line text-gray-400"></i>
          //           )}
          //           {msg.status === "read" && (
          //             <i className="mgc_double_check_line text-blue-500"></i>
          //           )}
          //         </span>
          //       )}
          //     </div>
          //   </div>
          // </div>
        ))}
      </div>

      {isChatExist ? (
        <div className="border-t p-4 flex items-center">
          <input
            type="text"
            placeholder="Type a message..."
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyDown={sendMessageHandler}
            className="flex-1 border-none rounded-lg px-4 py-2 focus:outline-none focus:ring-0"
          />
          <button
            onClick={handleSendMessage}
            className={`text-xl text-blue-400 focus:outline-none ${
              newMessage.length > 0 ? "block" : "hidden"
            }`}
          >
            <i className="mgc_send_line"></i>
          </button>
        </div>
      ) : (
        <button
          className="bg-blue-500 text-white text-base w-1/2 mx-auto font-medium rounded-full px-4 py-2 mt-4"
          onClick={startChatHandler}
        >
          Start Chat
        </button>
      )}
    </div>
  );
};

export default SupportChat;
