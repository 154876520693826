import { gql } from "@apollo/client";

export const GET_USERS = gql`
  query GetUsers {
    users {
      id
      username
      institution {
        id
        name
      }
      category {
        id
        name
      }
      section {
        id
        name
      }
      group {
        id
        name
      }
      host {
        id
        name
      }
      cluster {
        id
        name
      }
      debate {
        id
        name
      }
      role {
        id
        name
        section
        group
        category
        institution
        host
        cluster
        debate
      }
    }
  }
`;

export const GET_USER_BY_ID = gql`
  query User($userId: ID!) {
    user(id: $userId) {
      id
      username
    }
  }
`;

export const GET_USER = gql`
  query GetUser {
    getUser {
      id
      username
      role {
        name
        permissions {
          name
          isActive
        }
        section
      }
      section {
        id
        name
      }
      institution {
        id
        name
        shortName
        groupId {
          id
          name
        }
      }
    }
  }
`;
