import React, { useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle";
import CandidateDetails from "../../components/profile/CandidatesDetails";
import Coordinator from "../../components/profile/Coordinator";
import MoreDetails from "../../components/profile/MoreDetails";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { GET_INSTITUTION_BY_ID } from "../../graphql/queries/institution";
import Skeleton from "../../components/UIElements/Skeleton";
import { useAuth } from "../../context/AuthContext";
import ProfileCard from "../../components/profile/ProfileCard";
import EditModal from "../../components/profile/EditModal";
import { UPDATE_INSTITUTION } from "../../graphql/mutations/institution";

const Profile: React.FC = () => {
  const [institutionId, setInstitutionId] = useState("");
  const [isCoordinatorModalOpen, setCoordinatorModalOpen] = useState(false);
  const [isMoreDetailsModalOpen, setMoreDetailsModalOpen] = useState(false);
  const [coordinatorData, setCoordinatorData] = useState({ name: "", phone: "" });
  const [moreDetails, setMoreDetails] = useState({ email: "", place: "" });
  const { user } = useAuth();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      setInstitutionId(id);
    } else if (user?.institution?.id) {
      setInstitutionId(user.institution.id);
    } else {
      setInstitutionId("");
    }
  }, [user, id]);

  const [updateInstitution] = useMutation(UPDATE_INSTITUTION);
  const {
    data: institutionData,
    loading,
    error,
    refetch,
  } = useQuery(GET_INSTITUTION_BY_ID, {
    variables: {
      id: institutionId,
    },
    skip: !institutionId,
  });

  useEffect(() => {
    if (institutionData) {
      setCoordinatorData({
        name: institutionData?.institution?.ugCoordinator || "",
        phone: institutionData?.institution?.ugCoordinatorPhoneNumber || "",
      });
      setMoreDetails({
        email: institutionData?.institution?.email || "",
        place: institutionData?.institution?.place || ""
      });
    }
  }, [institutionData]);

  const handleSaveCoordinator = async () => {
    try {
      await updateInstitution({
        variables: {
          id: institutionId,
          input: {
            ugCoordinator: coordinatorData.name,
            ugCoordinatorPhoneNumber: coordinatorData.phone,
          },
        },
      });
      refetch();
      setCoordinatorModalOpen(false);
    } catch (error) {
      console.error("Error updating coordinator:", error);
    }
  };

  const handleSaveMoreDetails = async () => {
    try {
      await updateInstitution({
        variables: {
          id: institutionId,
          input: {
            email: moreDetails.email,
            place: moreDetails.place
          },
        },
      });
      refetch();
      setMoreDetailsModalOpen(false);
    } catch (error) {
      console.error("Error updating more details:", error);
    }
  };

  if (error) return <p>Error loading institution data</p>;

  return (
    <>
      <PageTitle
        pagetitle={id ? "Institution" : "Profile"}
        subtitle={"Profile"}
      />
      <div className="w-full">
        <div className="container mx-auto p-4 grid grid-cols-1 gap-4 lg:grid-cols-3">
          <div className="lg:col-span-2">
            {loading ? (
              <Skeleton />
            ) : (
              <ProfileCard
                data={institutionId ? institutionData?.institution : user}
                institutionId={institutionId}
              />
            )}

            {loading ? (
              <Skeleton />
            ) : (
              (id || user?.institution) && <CandidateDetails />
            )}
          </div>

          <div>
            {loading ? (
              <Skeleton />
            ) : (
              (id || user?.institution) && (
                <div className="bg-white rounded-lg shadow-lg p-4 lg:p-6">
                  <div className="flex justify-between align-center">
                    <h3 className="text-sm font-semibold mb- mr-6">Coordinators:</h3>
                    <i
                      className="mgc_edit_line cursor-pointer"
                      onClick={() => setCoordinatorModalOpen(true)}
                    ></i>
                  </div>
                  {institutionData?.institution ? (
                    <Coordinator
                      name={institutionData.institution.ugCoordinator || "Coordinator not available"}
                      phone={institutionData.institution.ugCoordinatorPhoneNumber || "Phone not available"}
                    />
                  ) : (
                    <p>No coordinator information available.</p>
                  )}
                </div>
              )
            )}

            {loading ? (
              <Skeleton />
            ) : (
              <div className="bg-white rounded-lg shadow-lg p-4 sm:p-6 mt-4">
                <div className="flex justify-between align-center">
                  <h3 className="text-sm font-semibold mb-2">More Details</h3>
                  <i
                    className="mgc_edit_line cursor-pointer"
                    onClick={() => setMoreDetailsModalOpen(true)}
                  ></i>
                </div>
                {institutionData?.institution ? (
                  <MoreDetails data={institutionData.institution} />
                ) : (
                  <p>No more details available</p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      <EditModal
        isOpen={isCoordinatorModalOpen}
        onClose={() => setCoordinatorModalOpen(false)}
        title="Edit Coordinator Details"
      >
        <form>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Coordinator Name
            </label>
            <input
              type="text"
              value={coordinatorData.name}
              onChange={(e) =>
                setCoordinatorData({ ...coordinatorData, name: e.target.value })
              }
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Phone Number
            </label>
            <input
              type="text"
              value={coordinatorData.phone}
              onChange={(e) =>
                setCoordinatorData({ ...coordinatorData, phone: e.target.value })
              }
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              onClick={handleSaveCoordinator}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Save
            </button>
          </div>
        </form>
      </EditModal>

      <EditModal
        isOpen={isMoreDetailsModalOpen}
        onClose={() => setMoreDetailsModalOpen(false)}
        title="Edit More Details"
      >
        <form>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Email
            </label>
            <input
              type="text"
              value={moreDetails.email}
              onChange={(e) =>
                setMoreDetails({ ...moreDetails, email: e.target.value })
              }
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Place
            </label>
            <input
              type="text"
              value={moreDetails.place}
              onChange={(e) =>
                setMoreDetails({ ...moreDetails, place: e.target.value })
              }
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              onClick={handleSaveMoreDetails}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Save
            </button>
          </div>
        </form>
      </EditModal>
    </>
  );
};

export default Profile;
