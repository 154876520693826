import { gql } from "@apollo/client";

export const GET_CLASSES = gql`
  query Classes {
    classes {
      id
      name
      section {
        id
        name
      }
      category {
        id
        name
      }
    }
  }
`;

export const GET_CLASS_BY_ID = gql`
  query Class($id: ID!) {
    class(id: $id) {
      id
      name
      section {
        id
        name
      }
    }
  }
`;
