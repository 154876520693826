import React from "react";

interface CardProps {
    item: any;
    textClass: string;
    title: string;
    subTitle?: string;
    notDelete?: boolean;
    handleEditButton?: (item: any) => void;
    handleDeleteButton?: (id: string) => void;
}

const Card: React.FC<CardProps> = ({ textClass, title, subTitle, notDelete, handleEditButton = () => { }, handleDeleteButton = () => { }, item }) => {
    const handleEdit = () => {
        handleEditButton(item)
    }

    const handleDelete = () => {
        handleDeleteButton(item.id)
    }

    return (
        <div className="bg-gray-100 p-1 flex justify-between items-center gap-1">
            <div className="flex">
                <div className="w-10 h-10 bg-gray-400 rounded-md mr-3"></div>
                <div>
                    <div className={`${textClass} font-semibold text-gray-700`}>{title}</div>
                    <div className="inline text-xs text-gray-500">{subTitle}</div>
                </div>
            </div>
            <div className="flex items-center">
                <button className="text-gray-400 text-xl">
                    <i className="mgc_edit_3_line mr-2 hover:text-gray-700" onClick={handleEdit}></i>
                    {notDelete ? '' : <i className="mgc_delete_2_line hover:text-gray-700" onClick={handleDelete}></i>}
                </button>
            </div>
        </div>
    )
}

export default Card;