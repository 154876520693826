import React from "react";
import { ELIMINATION_RESULT_COUNT_BY_STATUS } from "../../../../graphql/queries/elimination";
import { useQuery } from "@apollo/client";
import Skeleton from "../../../UIElements/Skeleton";

interface StatusCountProps {
  color: string;
  groupName: string;
  status: string;
  groupId: string;
  subtitle: boolean;
}

const StatusCount: React.FC<StatusCountProps> = ({
  color,
  groupName,
  status,
  groupId,
  subtitle,
}) => {
  const { data, loading, error } = useQuery(
    ELIMINATION_RESULT_COUNT_BY_STATUS,
    { variables: { groupId, status }, fetchPolicy: "network-only" }
  );

  if (loading) {
    return <Skeleton />;
  }

  if (error) {
    return <p>Failed to fatech data {error.message}</p>;
  }

  return (
    <div className="flex items-end space-x-2">
      {!subtitle && (
        <p
          className={`text-md font-medium text-${color}-700 mb-1 font-semibold`}
        >
          {groupName} :
        </p>
      )}
      <h2 className="text-xl font-bold">
        {data?.eliminationResultCountByStatus}
      </h2>
      {subtitle && (
        <p className={`text-md font-medium text-${color}-700 mb-1`}>Programs</p>
      )}
    </div>
  );
};

export default StatusCount;
