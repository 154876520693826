import { gql } from "@apollo/client";

export const CREATE_INSTITUTION = gql`
  mutation CreateInstitution($input: CreateInstitutionInput!) {
    createInstitution(input: $input) {
      id
      name
    }
  }
`;


export const UPDATE_INSTITUTION = gql`
  mutation UpdateInstitution(
    $id: ID!
    $input: UpdateInstitutionInput!
  ) {
    updateInstitution(id: $id, input: $input) {
      name
    }
  }
`;

export const DELETE_INSTITUTION = gql`
  mutation DeleteInstitution($id: ID!) {
    deleteInstitution(id: $id)
  }
`;
