import { gql } from "@apollo/client";

export const GET_CHATS = gql`
  query GetChats {
    getChats {
      userId {
        id
        username
      }
      id
      messages {
        sender {
          id
          username
        }
        content
        timestamp
        status
      }
    }
  }
`;

export const GET_CHAT_BY_USERS = gql`
  query GetChatByUser($userId: ID!) {
    getChatByUser(userId: $userId) {
      id
      userId {
        id
        username
      } 
      messages {
        sender {
          id
          username
        }
        content
        timestamp
        status
      }
    }
  }
`;

export const MESSAGE_ADDED = gql`
  subscription {
    messageAdded {
      id
      sender {  
        id
        username
      }
      chatId {
        id
      }
      content
      timestamp
      status
    }
  }
`;

