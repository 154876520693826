import { gql, useQuery } from "@apollo/client";
import React, { useState } from "react";
import * as XLSX from "xlsx";

import PageTitle from "../../components/PageTitle";
import Card from "../../components/participants/Card";

const GET_CATEGORIES = gql`
  query Categories {
    categories {
      id
      name
    }
  }
`;

const GET_PROGRAM_CATEGORIES = gql`
  query ProgramCategories {
    programCategories {
      id
      name
    }
  }
`;

const GET_INSTITUTIONS = gql`
  query Institutions {
    institutions {
      id
      name
      shortName
      place
      email
      groupId {
        id
        name
      }
      category {
        id
        name
      }
      programCategory {
        id
        name
      }
    }
  }
`;

const GET_PARTICIPATIONS = gql`
  query GetParticipations {
    participations {
      id
      is_selected
      candidate {
        id
        name
        photo
        chestNo
        dob
        email
        admissionNo

        class {
          name
        }
        gender
        category {
          name
        }
      }
      program {
        id
        name
        maxSelection
        category {
          id
          name
        }
        programCategory {
          id
          name
        }
        skill {
          id
          name
        }
        mode
        programCode
      }
      institution {
        id
        name
        shortName
        groupId {
          id
          name
        }
        cluster {
          id
          name
        }
      }
    }
  }
`;

const GET_PROGRAMS = gql`
  query GetPrograms(
    $page: Int
    $limit: Int
    $search: String
    $programCategoryId: ID
    $categoryId: ID
    $skillId: ID
  ) {
    programs(
      page: $page
      limit: $limit
      search: $search
      programCategoryId: $programCategoryId
      categoryId: $categoryId
      skillId: $skillId
    ) {
      programs {
        category {
          id
          name
        }
        id
        mode
        name
        programCategory {
          id
          name
        }
        programCode
        skill {
          id
          name
        }
      }
      totalPages
      totalPrograms
    }
  }
`;

const GET_CLUSTERS = gql`
  query Clusters {
    clusters {
      id
      name
    }
  }
`;

const GET_GROUPS = gql`
  query Groups {
    groups {
      id
      name
    }
  }
`;

const GET_SKILLS = gql`
  query GetSkills {
    skills {
      id
      name
    }
  }
`;

const Participants: React.FC = () => {
  const {
    data: participationData,
    error,
    loading,
  } = useQuery(GET_PARTICIPATIONS);
  const { data: programData } = useQuery(GET_PROGRAMS);
  const { data: institutionData } = useQuery(GET_INSTITUTIONS);
  const { data: categoryData } = useQuery(GET_CATEGORIES);
  const { data: programCategoryData } = useQuery(GET_PROGRAM_CATEGORIES);
  const { data: clusterData } = useQuery(GET_CLUSTERS);
  const { data: groupData } = useQuery(GET_GROUPS);
  const { data: skillData } = useQuery(GET_SKILLS);

  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [isInstitutionFocus, setIsInstitutionFocus] = useState(false);
  const [isProgramFocus, setIsProgramFocus] = useState(false);
  const [searchPrograms, setSearchPrograms] = useState("");
  const [searchInstitutions, setSearchInstitutions] = useState("");
  const [selectedMode, setSelectedMode] = useState("");
  const [selectedPrograms, setSelectedPrograms] = useState<string[]>([]);
  const [selectedInstitutions, setSelectedInstitutions] = useState<string[]>(
    []
  );
  const [selectedProgramNames, setSelectedProgramNames] = useState<string[]>(
    []
  );
  const [selectedInstitutionNames, setSelectedInstitutionNames] = useState<
    string[]
  >([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedProgramCategories, setSelectedProgramCategories] = useState<
    string[]
  >([]);
  const [selectedClusters, setSelectedClusters] = useState<string[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<string[]>([]);
  const [selectedSkills, setSelectedSkills] = useState<string[]>([]);
  const [selectedParticipants, setSelectedParticipants] = useState(false);

  if (loading) {
    return <p>Loading...</p>;
  }
  if (error) {
    return <p>{error.message}</p>;
  }

  const filterParticipants = participationData.participations.filter(
    (participation: any) => {
      const conditions = [
        // Check if selected programs match
        selectedPrograms?.length > 0
          ? selectedPrograms.includes(participation?.program?.id)
          : true,
        // Check if selected institutions match
        selectedInstitutions?.length > 0
          ? selectedInstitutions.includes(participation?.institution?.id)
          : true,
        // Check if selected categories match
        selectedCategories?.length > 0
          ? selectedCategories.includes(participation?.program?.category?.id)
          : true,
        // Check if selected program categories match
        selectedProgramCategories?.length > 0
          ? selectedProgramCategories.includes(
              participation?.program?.programCategory?.id
            )
          : true,
        // Check if selected clusters match
        selectedClusters.length > 0
          ? selectedClusters.includes(participation?.institution?.cluster?.id)
          : true,
        // Check if selected groups match
        selectedGroups.length > 0
          ? selectedGroups.includes(participation?.institution?.groupId?.id)
          : true,
        // Check if mode matches
        selectedMode.length > 0
          ? participation?.program?.mode === selectedMode
          : true,
        // Check if skills match
        selectedSkills.length > 0
          ? selectedSkills.includes(participation?.program?.skill?.id)
          : true,
        // Check if selected participants match
        selectedParticipants
          ? participation?.program?.maxSelection > 0
            ? participation?.is_selected
            : true
          : true,
      ];

      // Return true if all conditions are met
      return conditions.every((condition) => condition);
    }
  );

  const candidatesAvail = new Set<string>(); // Using a Set for uniqueness

  const filteredParticipants = filterParticipants.map((participation: any) => {
    // Create a shallow copy of the participation object
    const updatedParticipation = { ...participation };

    // Filter out candidates and add unique ones
    updatedParticipation.candidate = participation.candidate.filter(
      (candidate: any) => {
        if (candidatesAvail.has(candidate.id)) {
          return false; // Remove if already included
        } else {
          candidatesAvail.add(candidate.id); // Add unique candidate ID
          return true; // Keep in the array
        }
      }
    );

    return updatedParticipation; // Return the updated participation
  });

  const searchedInstitutions = institutionData?.institutions?.filter(
    (institution: any) =>
      institution.name.toLowerCase().includes(searchInstitutions.toLowerCase())
  );
  const searchedPrograms = programData?.programs?.programs?.filter(
    (program: any) =>
      program.name.toLowerCase().includes(searchPrograms.toLowerCase())
  );

  const handleProgramsCheckbox = (programId: string, programName: string) => {
    setSelectedPrograms((prevSelected) => {
      if (prevSelected.includes(programId)) {
        return prevSelected.filter((id) => id !== programId);
      } else {
        return [...prevSelected, programId];
      }
    });
    setSelectedProgramNames((prevSelected) => {
      if (prevSelected.includes(programName)) {
        return prevSelected.filter((name) => name !== programName);
      } else {
        return [...prevSelected, programName];
      }
    });
  };

  const handleInstitutionsCheckbox = (
    institutionId: string,
    institutionName: string
  ) => {
    setSelectedInstitutions((prevSelected) => {
      if (prevSelected.includes(institutionId)) {
        return prevSelected.filter((id) => id !== institutionId);
      } else {
        return [...prevSelected, institutionId];
      }
    });
    setSelectedInstitutionNames((prevSelected) => {
      if (prevSelected.includes(institutionName)) {
        return prevSelected.filter((name) => name !== institutionName);
      } else {
        return [...prevSelected, institutionName];
      }
    });
  };

  const handleCategoriesCheckbox = (categoryId: string) => {
    setSelectedCategories((prevSelected) => {
      if (prevSelected.includes(categoryId)) {
        return prevSelected.filter((id) => id !== categoryId);
      } else {
        return [...prevSelected, categoryId];
      }
    });
  };

  const handleProgramCategoriesCheckbox = (categoryId: string) => {
    setSelectedProgramCategories((prevSelected) => {
      if (prevSelected.includes(categoryId)) {
        return prevSelected.filter((id) => id !== categoryId);
      } else {
        return [...prevSelected, categoryId];
      }
    });
  };

  const handleClustersCheckbox = (clusterId: string) => {
    setSelectedClusters((prevSelected) => {
      if (prevSelected.includes(clusterId)) {
        return prevSelected.filter((id) => id !== clusterId);
      } else {
        return [...prevSelected, clusterId];
      }
    });
  };

  const handleGroupsCheckbox = (groupId: string) => {
    setSelectedGroups((prevSelected) => {
      if (prevSelected.includes(groupId)) {
        return prevSelected.filter((id) => id !== groupId);
      } else {
        return [...prevSelected, groupId];
      }
    });
  };

  const handleSkillsCheckbox = (skillId: string) => {
    setSelectedSkills((prevSelected) => {
      if (prevSelected.includes(skillId)) {
        return prevSelected.filter((id) => id !== skillId);
      } else {
        return [...prevSelected, skillId];
      }
    });
  };

  const deleteProgram = (index: number) => {
    setSelectedPrograms((prevSelected) => {
      return prevSelected.filter((id) => id !== prevSelected[index]);
    });
    setSelectedProgramNames((prevSelected) => {
      return prevSelected.filter((name) => name !== prevSelected[index]);
    });
  };

  const deleteInstitution = (index: number) => {
    setSelectedInstitutions((prevSelected) => {
      return prevSelected.filter((id) => id !== prevSelected[index]);
    });
    setSelectedInstitutionNames((prevSelected) => {
      return prevSelected.filter((name) => name !== prevSelected[index]);
    });
  };

  const exportToExcel = () => {
    const dataToExport = filteredParticipants.flatMap((participation: any) =>
      participation.candidate.map((cand: any) => ({
        ChestNo: cand?.chestNo,
        FullName: cand?.name,
        dob: cand?.dob ? new Date(Number(cand?.dob)).toLocaleDateString() : "",
        email: cand?.email,
        ugAdNo: cand?.admissionNo,
        class: cand?.class?.name,
        gender: cand?.gender,
        Category: participation?.program?.category?.name,
        Institution: participation?.institution?.name,
        InstitutionShortName: participation?.institution?.shortName,
      }))
    );

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Participants");

    XLSX.writeFile(workbook, "Participants.xlsx");
  };

  return (
    <>
      <PageTitle pagetitle={"Participants Count"} subtitle={"Participants"} />
      <div className="flex items-center justify-between mb-4">
        <div className="w-32 flex items-center justify-between">
          <div>
            <div className="h-12 w-full flex items-center justify-center font-semibold border rounded-lg bg-red-600 px-4 py-2 text-white dark:placeholder-gray-300 dark:text-gray-200 focus:ring-0 sm:text-sm">
              Count:{" "}
              {filteredParticipants?.reduce(
                (total: number, participation: any) =>
                  total + participation?.candidate?.length,
                0
              )}
            </div>
          </div>
        </div>
        <div className="w-64 flex items-center justify-between">
          <button
            className="px-8 py-3 bg-yellow-600 text-white font-semibold rounded-lg"
            onClick={() => setIsFilterModalOpen(true)}
          >
            Filters
          </button>
          <button
            className="px-8 py-3 bg-green-600 text-white font-semibold rounded-lg"
            onClick={exportToExcel}
          >
            Download
          </button>
        </div>
      </div>

      {isFilterModalOpen && (
        <div
          className="fixed top-0 left-0 z-50 w-full h-full bg-gray-900 bg-opacity-50 flex items-center justify-end"
          onClick={() => {
            setIsFilterModalOpen(false);
            setIsInstitutionFocus(false);
          }}
        >
          <div
            className="h-full flex flex-col gap-2 p-12 bg-white overflow-y-scroll"
            onClick={(e) => e.stopPropagation()}
          >
            <h1 className="text-xl text-black font-semibold">Filters</h1>
            <hr />

            <div className="w-96 hidden sm:block mt-4">
              <div className="flex items-center px-3 py-1 cursor-pointer">
                <input
                  type="checkbox"
                  id="selectedParticipants"
                  checked={selectedParticipants}
                  onChange={() =>
                    setSelectedParticipants(!selectedParticipants)
                  }
                  className="mx-2 focus:ring-transparent"
                />
                <label
                  htmlFor="selectedParticipants"
                  className="text-md font-semibold"
                >
                  Selected Participants
                </label>
              </div>
            </div>

            <div className="w-96 hidden sm:block mt-4">
              <label htmlFor="select-programs">Select Programs</label>
              {selectedProgramNames.length > 0 && (
                <div className="grid grid-cols-3 gap-1 py-3">
                  {selectedProgramNames.map((name, index) => (
                    <div
                      key={index}
                      className="flex items-center justify-between bg-blue-600 text-white text-[10px] p-1"
                    >
                      <span>{name}</span>
                      <i
                        className="mgc_close_line cursor-pointer"
                        onClick={() => deleteProgram(index)}
                      ></i>
                    </div>
                  ))}
                </div>
              )}
              <div className="flex items-center mt-2">
                <input
                  type="search"
                  placeholder="Select Programs"
                  id="select-programs"
                  value={searchPrograms}
                  onChange={(e) => setSearchPrograms(e.target.value)}
                  onFocus={() => setIsProgramFocus(true)}
                  className="h-12 p-2 w-full border-2 border-gray-300 rounded-lg bg-transparent text-gray-900 placeholder-gray-500 dark:placeholder-gray-300 dark:text-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 dark:focus:border-blue-300 dark:focus:ring-blue-500 transition duration-200 ease-in-out sm:text-sm"
                />
                {isProgramFocus && (
                  <i
                    className="mgc_close_line ml-4 text-red-600 cursor-pointer"
                    onClick={() => setIsProgramFocus(false)}
                  ></i>
                )}
              </div>
              {isProgramFocus && (
                <div className="absolute w-96 max-h-56 rounded-xl overflow-hidden bg-white z-50 shadow-xl overflow-y-scroll">
                  {searchedPrograms?.map((program: any, index: number) => (
                    <div
                      key={index}
                      className="flex items-center px-3 py-1 border-b cursor-pointer hover:bg-gray-200"
                      onClick={() => {
                        handleProgramsCheckbox(program.id, program.name);
                        setIsProgramFocus(false);
                      }}
                    >
                      <input
                        type="checkbox"
                        id={`program-${program.id}`}
                        checked={selectedPrograms.includes(program.id)}
                        onChange={() =>
                          handleProgramsCheckbox(program.id, program.name)
                        }
                        className="mx-2"
                      />
                      <label
                        htmlFor={`institution-${program.id}`}
                        className="text-md font-semibold"
                      >
                        {program.name}
                      </label>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="w-96 hidden sm:block mt-4">
              <label htmlFor="select-institutions">Select Institutions</label>
              {selectedInstitutionNames.length > 0 && (
                <div className="grid grid-cols-3 gap-1 py-3">
                  {selectedInstitutionNames.map((name, index) => (
                    <div
                      key={index}
                      className="flex items-center justify-between bg-blue-600 text-white text-[10px] p-1"
                    >
                      <span>{name}</span>
                      <i
                        className="mgc_close_line cursor-pointer"
                        onClick={() => deleteInstitution(index)}
                      ></i>
                    </div>
                  ))}
                </div>
              )}
              <div className="flex items-center mt-2">
                <input
                  type="search"
                  placeholder="Select Institutions"
                  id="select-institutions"
                  value={searchInstitutions}
                  onChange={(e) => setSearchInstitutions(e.target.value)}
                  onFocus={() => setIsInstitutionFocus(true)}
                  className="h-12 p-2 w-full border-2 border-gray-300 rounded-lg bg-transparent text-gray-900 placeholder-gray-500 dark:placeholder-gray-300 dark:text-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 dark:focus:border-blue-300 dark:focus:ring-blue-500 transition duration-200 ease-in-out sm:text-sm"
                />
                {isInstitutionFocus && (
                  <i
                    className="mgc_close_line ml-4 text-red-600 cursor-pointer"
                    onClick={() => setIsInstitutionFocus(false)}
                  ></i>
                )}
              </div>
              {isInstitutionFocus && (
                <div className="absolute w-96 max-h-56 rounded-xl overflow-hidden bg-white z-50 shadow-xl overflow-y-scroll">
                  {searchedInstitutions?.map(
                    (institution: any, index: number) => (
                      <div
                        key={index}
                        className="flex items-center px-3 py-1 border-b cursor-pointer hover:bg-gray-200"
                        onClick={() => {
                          handleInstitutionsCheckbox(
                            institution.id,
                            institution.shortName
                          );
                          setIsInstitutionFocus(false);
                        }}
                      >
                        <input
                          type="checkbox"
                          id={`institution-${institution.id}`}
                          checked={selectedInstitutions.includes(
                            institution.id
                          )}
                          onChange={() =>
                            handleInstitutionsCheckbox(
                              institution.id,
                              institution.shortName
                            )
                          }
                          className="mx-2"
                        />
                        <label
                          htmlFor={`institution-${institution.id}`}
                          className="text-md font-semibold"
                        >
                          {institution.name}
                        </label>
                      </div>
                    )
                  )}
                </div>
              )}
            </div>

            <div className="w-96 hidden sm:block mt-4">
              <label htmlFor="">Select Categories</label>
              <div className="w-full grid grid-cols-2 mt-2">
                {categoryData?.categories?.map(
                  (category: any, index: number) => (
                    <div
                      key={index}
                      className="flex items-center px-3 py-1 cursor-pointer"
                    >
                      <input
                        type="checkbox"
                        id={`category-${category.id}`}
                        checked={selectedCategories.includes(category.id)}
                        onChange={() => handleCategoriesCheckbox(category.id)}
                        className="mx-2 focus:ring-transparent focus:outline-none"
                      />
                      <label
                        htmlFor={`category-${category.id}`}
                        className="text-md font-semibold"
                      >
                        {category.name}
                      </label>
                    </div>
                  )
                )}
              </div>
            </div>

            <div className="w-96 hidden sm:block mt-4">
              <label htmlFor="">Select Progam Categories</label>
              <div className="w-full grid grid-cols-4 mt-2">
                {programCategoryData?.programCategories?.map(
                  (category: any, index: number) => (
                    <div
                      key={index}
                      className="flex items-center px-3 py-1 cursor-pointer"
                    >
                      <input
                        type="checkbox"
                        id={`category-${category.id}`}
                        checked={selectedProgramCategories.includes(
                          category.id
                        )}
                        onChange={() =>
                          handleProgramCategoriesCheckbox(category.id)
                        }
                        className="mx-2 focus:ring-transparent"
                      />
                      <label
                        htmlFor={`category-${category.id}`}
                        className="text-md font-semibold"
                      >
                        {category.name}
                      </label>
                    </div>
                  )
                )}
              </div>
            </div>

            <div className="w-96 hidden sm:block mt-4">
              <label htmlFor="">Select Clusters</label>
              <div className="w-full grid grid-cols-2 mt-2">
                {clusterData?.clusters?.map((cluster: any, index: number) => (
                  <div
                    key={index}
                    className="flex items-center px-3 py-1 cursor-pointer"
                  >
                    <input
                      type="checkbox"
                      id={`cluster-${cluster.id}`}
                      checked={selectedClusters.includes(cluster.id)}
                      onChange={() => handleClustersCheckbox(cluster.id)}
                      className="mx-2 focus:ring-transparent focus:outline-none"
                    />
                    <label
                      htmlFor={`cluster-${cluster.id}`}
                      className="text-md font-semibold"
                    >
                      {cluster.name}
                    </label>
                  </div>
                ))}
              </div>
            </div>

            <div className="w-96 hidden sm:block mt-4">
              <label htmlFor="">Select Groups</label>
              <div className="w-full grid grid-cols-2 mt-2">
                {groupData?.groups?.map((group: any, index: number) => (
                  <div
                    key={index}
                    className="flex items-center px-3 py-1 cursor-pointer"
                  >
                    <input
                      type="checkbox"
                      id={`group-${group.id}`}
                      checked={selectedGroups.includes(group.id)}
                      onChange={() => handleGroupsCheckbox(group.id)}
                      className="mx-2 focus:ring-transparent focus:outline-none"
                    />
                    <label
                      htmlFor={`group-${group.id}`}
                      className="text-md font-semibold"
                    >
                      {group.name}
                    </label>
                  </div>
                ))}
              </div>
            </div>

            <div className="w-96 hidden sm:block mt-4">
              <label htmlFor="">Select Mode</label>
              <div className="w-full grid grid-cols-3 mt-2">
                <div onClick={() => setSelectedMode("")}>
                  <input
                    type="radio"
                    name="mode"
                    id="both"
                    checked={selectedMode === ""}
                    className="mr-3 focus:ring-transparent"
                  />
                  <label htmlFor="both">Both</label>
                </div>
                <div onClick={() => setSelectedMode("stage")}>
                  <input
                    type="radio"
                    name="mode"
                    id="stage"
                    checked={selectedMode === "stage"}
                    className="mr-3 focus:ring-transparent"
                  />
                  <label htmlFor="stage">Stage</label>
                </div>
                <div onClick={() => setSelectedMode("non_stage")}>
                  <input
                    type="radio"
                    name="mode"
                    id="non-stage"
                    checked={selectedMode === "non_stage"}
                    className="mr-3 focus:ring-transparent"
                  />
                  <label htmlFor="non-stage">Non-Stage</label>
                </div>
              </div>
            </div>

            <div className="w-96 hidden sm:block mt-4">
              <label htmlFor="">Select Skills</label>
              <div className="w-full grid grid-cols-2 mt-2">
                {skillData?.skills?.map((skill: any, index: number) => (
                  <div
                    key={index}
                    className="flex items-center px-3 py-1 cursor-pointer"
                  >
                    <input
                      type="checkbox"
                      id={`skill-${skill.id}`}
                      checked={selectedSkills.includes(skill.id)}
                      onChange={() => handleSkillsCheckbox(skill.id)}
                      className="mx-2 focus:ring-transparent focus:outline-none"
                    />
                    <label
                      htmlFor={`skill-${skill.id}`}
                      className="text-md font-semibold"
                    >
                      {skill.name}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="relative overflow-x-auto">
        <table className="w-full divide-y divide-gray-300 dark:divide-gray-700">
          <thead className="bg-slate-300 bg-opacity-20 border-t dark:bg-slate-800 divide-gray-300 dark:border-gray-700">
            <tr>
              <th
                scope="col"
                className="py-3.5 ps-4 pe-3 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
              >
                Chest No.
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
              >
                Full Name
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
              >
                Institution
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
              >
                Category
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
            {filteredParticipants?.map((participation: any) =>
              participation?.candidate?.map((candidate: any) => (
                <Card
                  participantId={participation?.id}
                  key={`${participation?.id}-${candidate?.id}`}
                  chestNo={candidate?.chestNo}
                  name={candidate?.name}
                  institution={participation?.institution?.name}
                  category={candidate?.category?.name}
                />
              ))
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Participants;
