import React, { useEffect, useRef, useState } from "react";
import { gql, useMutation, useQuery } from '@apollo/client';
import { CREATE_VENUE, UPDATE_VENUE } from "../../graphql/mutations/venue";
import toast from "react-hot-toast";

const GET_HOSTS = gql`
    query Hosts {
        hosts {
            id
            name
            place
        }
    }
`;

interface Host {
    id: string;
    name: string;
    place: string;
}

interface AddVenueProps {
    selectedVenue: any;
    onSave: () => void;
    onCancel: () => void;
    refetch: () => void;
    setSelectedVenue: (venue: any) => void;
}

const AddVenue: React.FC<AddVenueProps> = ({ selectedVenue, onSave, onCancel, refetch, setSelectedVenue }) => {
    const [loadingFile, setLoadingFile] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const dropdownRef = useRef<HTMLDivElement>(null);
    const searchInputRef = useRef<HTMLInputElement>(null);
    const [selectedHost, setselectedHost] = useState<Host | null>(null);

    const [formData, setFormData] = useState({
        name: "",
        place: "",
        host: "",
    });

    const { loading, error, data, refetch: refetchData } = useQuery(GET_HOSTS);
    const [addVenue] = useMutation(CREATE_VENUE);
    const [updateVenue] = useMutation(UPDATE_VENUE);

    const filteredHostOptions = data?.hosts?.filter((host: any) =>
        host?.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        }

        if (isOpen) {
            searchInputRef.current?.focus();
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen]);

    useEffect(() => {
        if (selectedVenue) {
            setFormData({
                name: selectedVenue?.name || '',
                host: selectedVenue?.host?.id || '',
                place: selectedVenue?.place || '',
            },);
        }
    }, [selectedVenue]);

    const handleHostChange = (selectedHostId: string) => {
        const selectedHost = data?.hosts
            .filter((host: Host) => host?.id === selectedHostId);
        setselectedHost(selectedHost[0])
        setFormData({ ...formData, host: selectedHost[0]?.id })
        if (selectedVenue && selectedHostId != "") {
            setSelectedVenue({ ...selectedVenue, host: selectedHost[0] })
        }
    };

    const clearForm = () => {
        onCancel()
        handleHostChange("")
        setFormData({
            name: "",
            host: "",
            place: "",
        });
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            if (!formData.name) {
                toast.error("Fill the name field");
                return;
            }
            if (!formData.host) {
                toast.error("Select a host from host field");
                return;
            }

            if (selectedVenue) {
                const updateloading = toast.loading('Updating The Venue')
                await updateVenue({
                    variables: {
                        updateVenueId: selectedVenue.id,
                        input: {
                            name: formData.name,
                            host: formData.host,
                            place: formData.place,
                        }
                    }
                });
                toast.remove(updateloading)
                toast.success('Venue updated successfully');
            } else {

                const addloading = toast.loading('Creating New Venue')
                await addVenue({
                    variables: {
                        input: {
                            name: formData.name,
                            host: formData.host,
                            place: formData.place,
                        }
                    }
                });
                toast.remove(addloading)
                toast.success('Venue registration successful');

            }
            refetchData();
            onSave();
            refetch();
            clearForm();
        } catch (err) {
            console.error('Error during registration:', err);
            toast.error('Venue registration failed');
        } finally {
            setLoadingFile(false);
        }
    };

    if (error) return <p>Error: {error.message}</p>;

    return (
        <div className="bg-white h-fit p-6 rounded-lg shadow-sm dark:bg-slate-800">
            <div className="flex justify-between flex-1 align-center">
                <h2 className="text-base font-medium mb-4">Add New Venue</h2>
            </div>
            <form className="space-y-4" onSubmit={handleSubmit}>
                <div>
                    <label className="block text-gray-700 text-sm mb-2">Enter Name: </label>
                    <input
                        type="text"
                        className="form-input w-full p-2 border border-gray-300 rounded-md"
                        placeholder="Venue Name"
                        name="name"
                        value={formData.name}
                        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    />
                </div>

                <div>
                    <label className="block text-gray-700 text-sm mb-2">Enter Place: </label>
                    <input
                        type="text"
                        className="form-input w-full p-2 border border-gray-300 rounded-md"
                        placeholder="Venue Place"
                        name="name"
                        value={formData.place}
                        onChange={(e) => setFormData({ ...formData, place: e.target.value })}
                    />
                </div>

                <div>
                    <label className="block text-gray-700 text-sm mb-2">Select Host</label>

                    <div className="relative" ref={dropdownRef}>
                        <div className="w-full">
                            <div className="relative">
                                <input
                                    type="button"
                                    value={selectedVenue?.host ? `${selectedVenue?.host?.name} | ${selectedVenue?.host?.place} ` : selectedHost ? `${selectedHost?.name} | ${selectedHost?.place}` : `${loading ? "Loading Hosts" : "Select Host"}`}
                                    onClick={() => setIsOpen(!isOpen)}
                                    placeholder={loading ? "Loading Hosts" : "Select Host"}
                                    className="form-input text-left cursor-pointer w-full p-2 border border-gray-300 rounded-md"
                                />
                                <span className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                    <i className="mgc_down_fill me-2 text-xl"></i>
                                </span>
                            </div>
                        </div>
                        {isOpen && (
                            <div className="absolute top-full left-0 w-full mt-2 bg-white border rounded shadow-lg z-10">

                                <div className="w-full">
                                    <input
                                        type="text"
                                        value={searchTerm}
                                        ref={searchInputRef}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        placeholder="Search Host"
                                        className="form-input cursor-pointer w-full p-2 border border-gray-300 rounded-md"
                                    />
                                </div>

                                <ul className="max-h-48 overflow-y-auto">
                                    {filteredHostOptions?.map((host: any, index: any) => (
                                        <li
                                            key={index}
                                            onClick={() => {
                                                setIsOpen(false);
                                                setSearchTerm("");
                                                handleHostChange(host?.id);
                                            }}
                                            className="px-4 py-2 cursor-pointer hover:bg-blue-500 hover:text-white"
                                        >
                                            {`${host?.name} | ${host?.place}`}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>

                </div>

                <div className="flex justify-between">
                    <button type="submit" disabled={loadingFile} className={`${"bg-green-500"} disabled:bg-gray-500 disabled:text-white text-white disabled:cursor-not-allowed py-2 px-4 rounded-md w-full`} >
                        {loadingFile ? "Submitting..." : selectedVenue ? "Update Venue" : "Submit"}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default AddVenue;