import { gql } from "@apollo/client";

export const GET_VENUES = gql`
  query GetVenues {
    venues {
      id
      name
      place
      host {
        id
        name
        place
        group {
          id
          name
        } 
      }
    }
  }
`;
