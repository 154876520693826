import React, { useState, useEffect } from "react";

interface ProgramCurb {
  id: string;
  name: string;
  maximumProgramsCount: number;
  programs?: Program[];
}

interface Program {
  id: string;
  programCode: string;
  name: string;
  category: Category;
  skill: Skill;
}

interface Category {
  id: string;
  name: string;
}

interface Skill {
  id: string;
  name: string;
}

interface EditCurbModalProps {
  isOpen: boolean;
  curb: ProgramCurb | null;
  onClose: () => void;
  onSubmit: (name: string, maximumProgramsCount: number) => void;
}

const EditCurbModal: React.FC<EditCurbModalProps> = ({
  isOpen,
  curb,
  onClose,
  onSubmit,
}) => {
  const [name, setName] = useState(curb?.name || "");
  const [maximumProgramsCount, setMaximumProgramsCount] = useState(
    curb?.maximumProgramsCount || null
  );

  useEffect(() => {
    if (curb) {
      setName(curb.name);
      setMaximumProgramsCount(curb.maximumProgramsCount);
    }
  }, [curb]);

  if (!isOpen) return null;

  const clearInput = () => {
    setName("");
    setMaximumProgramsCount(null);
  };

  const handleSave = async (e: React.FormEvent) => {
    e.preventDefault();
    await onSubmit(name, maximumProgramsCount ?? 0);
    onClose();
    clearInput();
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
        <h2 className="text-lg font-semibold mb-4">Edit Curb</h2>
        <form onSubmit={handleSave}>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Name
            </label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Maximum Programs Count
            </label>
            <input
              type="number"
              value={maximumProgramsCount ?? ""}
              onChange={(e) => setMaximumProgramsCount(Number(e.target.value))}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
              required
            />
          </div>
          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={onClose}
              className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-blue-600 text-white px-4 py-2 rounded-md"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditCurbModal;
