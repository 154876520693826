import React from 'react';

interface PageTitleProps {
  pagetitle: string;
  subtitle: string;
}

const PageTitle: React.FC<PageTitleProps> = ({ pagetitle, subtitle }) => {
  return (
    <div className="flex justify-between items-center mb-6">
      <h4 className="text-slate-900 dark:text-slate-200 text-lg font-medium">
        {pagetitle}
      </h4>

      <div className="md:flex hidden items-center gap-2.5 text-sm font-semibold">
        <div className="flex items-center gap-2">
          <a href="#" className="text-sm font-medium text-slate-700 dark:text-slate-400">
            Sibaq
          </a>
        </div>

        <div className="flex items-center gap-2">
          <i className="mgc_right_line text-lg flex-shrink-0 text-slate-400 rtl:rotate-180"></i>
          <a href="#" className="text-sm font-medium text-slate-700 dark:text-slate-400">
            {subtitle}
          </a>
        </div>

        <div className="flex items-center gap-2">
          <i className="mgc_right_line text-lg flex-shrink-0 text-slate-400 rtl:rotate-180"></i>
          <a href="#" className="text-sm font-medium text-slate-700 dark:text-slate-400" aria-current="page">
            {pagetitle}
          </a>
        </div>
      </div>
    </div>
  );
};

export default PageTitle;
