import { gql } from "@apollo/client";

export const CREATE_JUDGES = gql`
  mutation CreateJudge($input: CreateJudgeInput!) {
  createJudge(input: $input) {
    id
    name
  }
}
`;

export const UPDATE_JUDGES = gql`
  mutation UpdateJudge($id: ID!, $input: UpdateJudgeInput!) {
  updateJudge(id: $id, input: $input) {
    id
    name
  }
}
`;

export const DELETE_JUDGES = gql`
mutation DeleteJudge($id: ID!) {
  deleteJudge(id: $id)
}
`;
