import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { Toaster } from "react-hot-toast";
import ThemeConfig from "./ThemeConfig";
import { AuthProvider } from "./context/AuthContext";
import ApolloClientProvider from "./apolloClient";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <ApolloClientProvider>
      <AuthProvider>
        <ThemeConfig />
        <App />
        <Toaster />
      </AuthProvider>
    </ApolloClientProvider>
  </React.StrictMode>
);
