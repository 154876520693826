import { gql } from "@apollo/client";

export const CREATE_ITEM_REGISTRATION = gql`
mutation CreateItemRegistration($input: CreateItemRegistrationInput) {
  createItemRegistration(input: $input) {
    title
    id
  }
}
`;

export const UPDATE_ITEM_REGISTRATION = gql`
mutation UpdateItemRegistration($id: ID!, $input: UpdateItemRegistrationInput) {
  updateItemRegistration(id: $id, input: $input) {
    id
  }
}
`;


export const DELETE_ITEM_REGISTRATION = gql`
  mutation DeleteItemRegistration($id: ID!) {
    deleteItemRegistration(id: $id)
  }
`;

