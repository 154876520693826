import React, { useRef, useState } from "react";

interface AddImageProps {
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  imageUrl?: string;
  isEdit?: boolean;
  setImageUrl: (data: any) => void
}

const AddImage: React.FC<AddImageProps> = ({ handleInputChange, imageUrl, setImageUrl }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState<string | null>(null);

  const handleClick = async () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  }

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    handleInputChange(e);

    if (file) {
      // Validate file type (only png)
      if (file.type !== "image/png") {
        setError("Only PNG images are allowed.");
        setImageUrl(null);
        return;
      }

      // Validate file size (under 500KB)
      const maxSize = 500 * 1024; // 500KB
      if (file.size > maxSize) {
        setError("Image size must be under 500KB.");
        setImageUrl(null);
        return;
      }

      // Reset error if validation passes
      setError(null);

      // Generate a preview for the new selected file
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageUrl(reader.result); // Preview the selected image
      };
      reader.readAsDataURL(file); // Read file as Data URL for preview
    }
  };
  
  return (
    <div className="card p-6">
      <div className="flex justify-between items-center mb-4">
        <label className="card-title" htmlFor="photo">Candidate Image</label>
        <div className="inline-flex items-center justify-center rounded-lg bg-slate-100 w-9 h-9">
          <i className="mgc_add_line"></i>
        </div>
      </div>

      <div onClick={handleClick} className="dropzone flex items-center justify-center text-gray-700 h-min-52 h-fit border border-dashed border-gray-300 rounded-md ">
        <input
          type="file"
          name="photo"
          id="photo"
          ref={inputRef}
          hidden
          required
          onChange={handleFileChange}
          className=" bg-black absolute"
          accept="image/png"
        />
        {imageUrl ?
          <img src={imageUrl} className="w-full h-full" alt="Preview" style={{ objectFit: 'cover' }} />
          :
          <p className="dz-message text-center needsclick">
            <i className="mgc_pic_2_line text-6xl"></i>
            <span className="block mt-2">Click here to upload</span>
          </p>
        }
      </div>
      {error && <p className="text-red-500 mt-2">{error}</p>}
    </div>
  );
};

export default AddImage;
