import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_PROGRAM_PARTICIPANTS,
  SELECTED_PARTICIPATION,
} from "../../../graphql/queries/participation";
import TeamInput from "./TeamInput";
import Modal from "../../UIElements/Model";
import toast from "react-hot-toast";
import { SELECT_FROM_ELIMINATION } from "../../../graphql/mutations/participation";
import Skeleton from "../../UIElements/Skeleton";

// Type for the candidate data structure
interface Candidate {
  id: string;
  chestNo: string;
  name: string;
  photo: string;
  institution: {
    id: string;
    name: string;
    shortName: string;
  };
}

interface AddSelectedProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  programId: string;
  participationId?: string;
}

const AddSelected: React.FC<AddSelectedProps> = ({
  isOpen,
  setIsOpen,
  programId,
  // participationId,
}) => {
  const [teamOne, setTeamOne] = useState<(Candidate | null)[]>(
    Array(1).fill(null)
  );
  const [teamTwo, setTeamTwo] = useState<(Candidate | null)[]>(
    Array(1).fill(null)
  );
  const [teamThree, setTeamThree] = useState<(Candidate | null)[]>(
    Array(1).fill(null)
  );
  const [teamFour, setTeamFour] = useState<(Candidate | null)[]>(
    Array(1).fill(null)
  );
  const [teamOneSearch, setTeamOneSearch] = useState<string[]>([]);
  const [teamTwoSearch, setTeamTwoSearch] = useState<string[]>([]);
  const [teamThreeSearch, setTeamThreeSearch] = useState<string[]>([]);
  const [teamFourSearch, setTeamFourSearch] = useState<string[]>([]);
  const [filteredTeamOne, setFilteredTeamOne] = useState<Candidate[][]>([]);
  const [filteredTeamTwo, setFilteredTeamTwo] = useState<Candidate[][]>([]);
  const [filteredTeamThree, setFilteredTeamThree] = useState<Candidate[][]>([]);
  const [filteredTeamFour, setFilteredTeamFour] = useState<Candidate[][]>([]);
  const [loading, setLoading] = useState(false);

  const clearInput = () => {
    setTeamOne(Array(1).fill(null));
    setTeamTwo(Array(1).fill(null));
    setTeamThree(Array(1).fill(null));
    setTeamFour(Array(1).fill(null));
    setTeamOneSearch([]);
    setTeamTwoSearch([]);
    setTeamThreeSearch([]);
    setTeamFourSearch([]);
  };

  const {
    data: participantsData,
    loading: participantsLoading,
    error: participantsError,
  } = useQuery(GET_PROGRAM_PARTICIPANTS, {
    variables: { programId },
    skip: !programId,
  });

  const {
    data: selectedParticipants,
    loading: selectedParticipantsLoading,
    error: selectedParticipantsError,
    refetch,
  } = useQuery(SELECTED_PARTICIPATION, {
    variables: { input: { programId } },
    skip: !programId,
  });

  const [selectFromElimination] = useMutation(SELECT_FROM_ELIMINATION);

  useEffect(() => {
    // Reset teams when programId changes
    if (programId) {
      setTeamOne(Array(1).fill(null));
      setTeamTwo(Array(1).fill(null));
      setTeamThree(Array(1).fill(null));
      setTeamFour(Array(1).fill(null));
    }
  }, [programId]);

  useEffect(() => {
    // Update teams based on selected participants
    if (
      selectedParticipants?.selectedParticipations &&
      !selectedParticipantsError &&
      !selectedParticipantsLoading
    ) {
      const teams = [setTeamOne, setTeamTwo, setTeamThree, setTeamFour];

      selectedParticipants.selectedParticipations.forEach(
        (participation: Candidate, index: number) => {
          if (index < teams.length) {
            teams[index]([participation]);
          }
        }
      );
    }
  }, [
    selectedParticipants,
    selectedParticipantsError,
    selectedParticipantsLoading,
    isOpen,
  ]);

  useEffect(() => {
    // Filter participants based on search terms
    if (participantsData && participantsData.programParticipants) {
      const filterParticipants = (searchTerms: string[]) => {
        return searchTerms.map((term) =>
          participantsData.programParticipants.filter(
            (participant: Candidate) =>
              participant.institution.name
                .toLowerCase()
                .includes(term.toLowerCase()) ||
              participant.institution.shortName
                .toLowerCase()
                .includes(term.toLowerCase())
          )
        );
      };

      setFilteredTeamOne(filterParticipants(teamOneSearch));
      setFilteredTeamTwo(filterParticipants(teamTwoSearch));
      setFilteredTeamThree(filterParticipants(teamThreeSearch));
      setFilteredTeamFour(filterParticipants(teamFourSearch));
    }
  }, [
    participantsData,
    teamOneSearch,
    teamTwoSearch,
    teamThreeSearch,
    teamFourSearch,
  ]);

  const handleParticipantSelect = (
    participant: Candidate | null,
    index: number,
    setTeam: React.Dispatch<React.SetStateAction<(Candidate | null)[]>>
  ) => {
    setTeam((prev) =>
      prev.map((item, idx) => (idx === index ? participant : item))
    );
  };

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    // Validate all four teams have participants
    const hasEmptyTeam = [teamOne, teamTwo, teamThree, teamFour].some(
      (team) => team.filter((participant) => participant !== null).length === 0
    );

    if (hasEmptyTeam) {
      toast.error("Four teams must have selected.");
      setLoading(false); // Ensure loading is reset
      return;
    }

    try {
      const teamIds = [
        teamOne[0]?.id,
        teamTwo[0]?.id,
        teamThree[0]?.id,
        teamFour[0]?.id,
      ];

      await selectFromElimination({
        variables: {
          input: {
            is_selected: true,
            programId,
            selectedParticipationId: teamIds,
          },
        },
      });

      // Trigger refetch to update the state
      await refetch();

      // Close modal and show success
      setIsOpen(false);
      toast.success("Selected Teams added successfully");
    } catch (error: any) {
      const errorMessage =
        error.graphQLErrors && error.graphQLErrors[0]?.message
          ? error.graphQLErrors[0]?.message
          : "Failed to add selected Teams";
      toast.error(errorMessage);
      console.error("Failed to add selected Teams", error);
    } finally {
      // Reset loading state
      setLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      title="Add Selected Participations"
      setIsOpen={setIsOpen}
      onClose={clearInput}
      handleSubmit={handleFormSubmit}
      loading={loading}
      buttonText={"Submit"}
    >
      <div className="">
        {selectedParticipantsError && (
          <p>
            Failed to load selected participation{" "}
            {selectedParticipantsError.message}
          </p>
        )}
        {selectedParticipantsLoading ? (
          <Skeleton />
        ) : (
          <TeamInput
            team={teamOne}
            teamName=""
            search={teamOneSearch}
            setSearch={setTeamOneSearch}
            filteredParticipants={filteredTeamOne}
            handleParticipantSelect={(participant, index) =>
              handleParticipantSelect(participant, index, setTeamOne)
            }
            loading={participantsLoading}
            error={participantsError}
          />
        )}
        {selectedParticipantsLoading ? (
          <Skeleton />
        ) : (
          <TeamInput
            team={teamTwo}
            teamName=""
            search={teamTwoSearch}
            setSearch={setTeamTwoSearch}
            filteredParticipants={filteredTeamTwo}
            handleParticipantSelect={(participant, index) =>
              handleParticipantSelect(participant, index, setTeamTwo)
            }
            loading={participantsLoading}
            error={participantsError}
          />
        )}
        {selectedParticipantsLoading ? (
          <Skeleton />
        ) : (
          <TeamInput
            team={teamThree}
            teamName=""
            search={teamThreeSearch}
            setSearch={setTeamThreeSearch}
            filteredParticipants={filteredTeamThree}
            handleParticipantSelect={(participant, index) =>
              handleParticipantSelect(participant, index, setTeamThree)
            }
            loading={participantsLoading}
            error={participantsError}
          />
        )}
        {selectedParticipantsLoading ? (
          <Skeleton />
        ) : (
          <TeamInput
            team={teamFour}
            teamName=""
            search={teamFourSearch}
            setSearch={setTeamFourSearch}
            filteredParticipants={filteredTeamFour}
            handleParticipantSelect={(participant, index) =>
              handleParticipantSelect(participant, index, setTeamFour)
            }
            loading={participantsLoading}
            error={participantsError}
          />
        )}
      </div>
    </Modal>
  );
};

export default AddSelected;
