import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";

import PageTitle from "../../components/PageTitle";
// import Card from "../../components/UIElements/Card";
import InstitutionSelection from "../../components/candidates/InstitutionSelection";
import AddImage from "../../components/candidates/AddImage";
import Details from "../../components/candidates/Details";
import {
  CREATE_CANDIDATE,
  UPDATE_CANDIDATE,
} from "../../graphql/mutations/candidates";
import Skeleton from "../../components/UIElements/Skeleton";
import { useAuth } from "../../context/AuthContext";
import { GET_CANDIDATE_BY_ID, GET_CANDIDATES } from "./query";

interface candidateProps {
  isEdit?: boolean;
}

const AddCandidate: React.FC<candidateProps> = ({ isEdit }) => {
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState("");
  const [loadingImage, setLoadingImage] = useState(false);
  const [candidateData, setCandidateData] = useState({
    name: "",
    institution: "",
    admissionNo: "",
    class: "",
    chestNo: "Auto Generate",
    category: "",
    email: "",
    dob: "",
    gender: "",
    section: "",
  });

  const { user } = useAuth();

  const navigate = useNavigate();
  const { id } = useParams();

  // Query candidate data by ID if `isEdit`
  const { data, loading, error, refetch } = useQuery(GET_CANDIDATE_BY_ID, {
    variables: { id },
    skip: !id,
  });

  // Mutation
  const [createCandidate] = useMutation(CREATE_CANDIDATE, {
    refetchQueries: [{ query: GET_CANDIDATES }],
  });
  const [updateCandidate] = useMutation(UPDATE_CANDIDATE);

  useEffect(() => {
    if (id) {
      setCandidateData({
        name: data?.candidate?.name || "",
        admissionNo: data?.candidate?.admissionNo || "",
        dob: data?.candidate?.dob
          ? new Date(Number(data.candidate.dob)).toISOString().split("T")[0]
          : "",
        gender: data?.candidate?.gender || "",
        institution: data?.candidate?.institution?.id || "",
        class: data?.candidate?.class?.id || "",
        email: data?.candidate?.email || "",
        category: data?.candidate?.category?.id || "",
        section: data?.candidate?.section?.id || "",
        chestNo: data?.candidate?.chestNo || "Auto Generate",
      });
      setImageUrl(data?.candidate?.photo || "");
    }
  }, [data, user]);

  // Handle input change
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setCandidateData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle file change
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setImageFile(e.target.files[0]);
    }
  };

  // clearInputs
  const clearInputs = async () => {
    setCandidateData({
      name: "",
      institution: "",
      admissionNo: "",
      class: "",
      category: "",
      email: "",
      dob: "",
      gender: "",
      section: "",
      chestNo: "Auto Generate",
    });
  };

  // Cloudinary Upload Function
  const uploadImage = async (): Promise<string | null> => {
    if (!imageFile) return null;

    const formData = new FormData();
    formData.append("file", imageFile);
    formData.append("upload_preset", import.meta.env.VITE_UPLOAD_PRESET);

    try {
      const response = await fetch(
        `https://api.cloudinary.com/v1_1/${
          import.meta.env.VITE_CLOUD_NAME
        }/image/upload`,
        {
          method: "POST",
          body: formData,
        }
      );

      const data = await response.json();
      if (response.ok) {
        return data.secure_url; // This is the URL of the uploaded image
      } else {
        throw new Error(data.error.message);
      }
    } catch (error) {
      console.log("Image upload error:", error);
      return null;
    }
  };

  const validateForm = (fields: any) => {
    for (const field in fields) {
      // Skip validation for the email field
      if (field === "email") {
        continue; // Skip this iteration
      }

      if (!fields[field]) {
        toast.error(`${field} is required`);
        return false;
      }
    }
    return true;
  };

  // Submit the form
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoadingImage(true);
    const loadingToast = toast.loading(
      isEdit ? "Updating candidate..." : "Adding candidate..."
    );

    const isFormValid = isEdit
      ? validateForm({ ...candidateData })
      : validateForm({ ...candidateData, imageFile });

    if (!isFormValid) {
      setLoadingImage(false);
      toast.dismiss(loadingToast);
      return;
    }

    try {
      let photoUrl = imageFile ? await uploadImage() : imageUrl;

      const variables = {
        input: {
          name: candidateData.name,
          institution: candidateData.institution,
          admissionNo: candidateData.admissionNo,
          class: candidateData.class,
          category: candidateData.category,
          email: candidateData.email,
          dob: candidateData.dob,
          gender: candidateData.gender,
          section: candidateData.section,
          photo: photoUrl,
        },
      };

      if (isEdit) {
        await updateCandidate({ variables: { id, ...variables } });
        refetch();
        toast.success("Candidate updated successfully");
      } else {
        await createCandidate({ variables });
        toast.success("Candidate added successfully");
      }

      navigate("/candidates");
      clearInputs();
    } catch (err) {
      console.log("Error:", err);
      toast.error(
        isEdit ? "Failed to update candidate." : "Failed to add candidate."
      );
    } finally {
      setLoadingImage(false);
      toast.dismiss(loadingToast);
    }
  };

  return (
    <>
      <PageTitle
        pagetitle={`${isEdit ? "Edit" : "Add"} Candidate`}
        subtitle={"Candidates"}
      />
      {error ? (
        <p>Error loading candidate data</p>
      ) : loading ? (
        Skeleton
      ) : (
        <form
          className="grid grid-cols-1 md:grid-cols-4 gap-6 mt-6"
          onSubmit={handleSubmit}
        >
          <div className="col-span-1 flex flex-col gap-6">
            <AddImage
              isEdit={isEdit}
              imageUrl={imageUrl}
              setImageUrl={setImageUrl}
              handleInputChange={handleFileChange}
            />
            <InstitutionSelection
              selectedInstitution={candidateData?.institution}
              setData={setCandidateData}
            />
          </div>
          <Details
            data={candidateData}
            setData={setCandidateData}
            loading={loadingImage}
            handleInputChange={handleInputChange}
            handleSubmit={handleSubmit} // Passing the submit function as a prop
          />
        </form>
      )}
    </>
  );
};

export default AddCandidate;
