import React, { ReactNode } from "react";
import Sidebar from "./components/sidebar/Sidebar";
import Topbar from "./components/topbar/Topbar";
import { useAuth } from "./context/AuthContext";
import { Navigate } from "react-router-dom";
import BackToTopButton from "./components/BackToTopButton";

// Define the prop types for Layout
interface LayoutProps {
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to={`/login?redirect=${location.pathname}`} replace />;
  }

  return ( 
    <div className="flex wrapper h-screen">
      <Sidebar />
      <div className="page-content flex flex-col flex-grow">
        <Topbar />
        <main className="flex-grow overflow-auto p-4 lg:p-6">{children}</main>
        {/* Back to Top Button */}
        <BackToTopButton />
      </div>
    </div>
  );
};

export default Layout;
