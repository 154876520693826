import { gql } from "@apollo/client";

export const CREATE_HOST = gql`
  mutation CreateHost($name: String!, $place: String!, $categories: [ID!]!, $group: ID!) {
    createHost(input: { name: $name, place: $place, categories: $categories, group: $group  }) {
      id
      name
    }
  }
`;

export const UPDATE_HOST = gql`
  mutation UpdateHost($id: ID!, $name: String, $place: String, $categories: [ID], $group: ID) {
    updateHost(id: $id, input: { name: $name, place: $place, categories: $categories, group: $group }) {
      id
      name
    }
  }
`;

export const DELETE_HOST = gql`
  mutation DeleteHost($id: ID!) {
    deleteHost(id: $id)
  }
`;
