import React, { useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle";
import ProgramCard from "../../components/gallery/ProgramCard";
import Uploadgallery from "../../components/gallery/UploadImage";
import { useMutation, useQuery } from "@apollo/client";
import { GET_GALLERY } from "../../graphql/queries/gallery";
import {
  CREATE_GALLERY,
  UPDATE_GALLERY,
} from "../../graphql/mutations/gallery";
import toast from "react-hot-toast";
import Modal from "../../components/UIElements/Model";
import Skeleton from "../../components/UIElements/Skeleton";

// Define interface for the Gallery type
interface Gallery {
  id: string;
  title: string;
  date: string;
  photo: string;
  time: string;
  status: string;
}

const Gallery: React.FC = () => {
  const { data, loading, error, refetch } = useQuery(GET_GALLERY);
  const [createGallery] = useMutation(CREATE_GALLERY);
  const [updateGallery] = useMutation(UPDATE_GALLERY);

  // State variables
  const [selectedGallery, setSelectedGallery] = useState<Gallery | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    date: "",
    photo: "",
    time: "",
    status: "published",
  });
  const [previewUrl, setPreviewUrl] = useState<string | ArrayBuffer | null>(
    null
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [galleryList, setGalleryList] = useState<Gallery[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [openDropdownId, setOpenDropdownId] = useState<string | null>(null);

  // Toggle dropdown for a specific gallery item
  const toggleDropdown = (id: string) => {
    setOpenDropdownId((prev) => (prev === id ? null : id)); // If the same id is clicked, close it, otherwise open
  };

  // Load gallery data into state when fetched
  useEffect(() => {
    if (data?.gallery) {
      setGalleryList(data.gallery);
    }
  }, [data]);

  // Populate form when editing a gallery item
  useEffect(() => {
    if (selectedGallery) {
      setFormData({
        title: selectedGallery.title || "",
        date: selectedGallery.date
          ? new Date(Number(selectedGallery.date)).toISOString().split("T")[0]
          : "",
        photo: selectedGallery.photo || "",
        time: selectedGallery.time || "",
        status: selectedGallery.status || "published",
      });
      setPreviewUrl(selectedGallery?.photo);
    }
  }, [selectedGallery]);

  // Filter gallery based on search query
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const filteredGallery = galleryList.filter((gallery) =>
    gallery.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Clear form data
  const clearForm = () => {
    setFormData({
      title: "",
      date: "",
      photo: "",
      time: "",
      status: "published",
    });
    setPreviewUrl(null);
    setIsModalOpen(false);
    setSelectedGallery(null);
  };

  const handleCreate = () => {
    setIsEditing(true);
    clearForm();
    setIsModalOpen(true);
  };

  const handleStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      status: e.target.value,
    });
  };

  // Handle editing gallery
  const handleEdit = (gallery: Gallery) => {
    setSelectedGallery(gallery);
    setIsEditing(true);
    toggleDropdown(gallery.id);
    setIsModalOpen(true);
  };

  // Handle viewing gallery details
  const handleView = (gallery: Gallery) => {
    setSelectedGallery(gallery);
    setIsEditing(false);
    toggleDropdown(gallery.id);
    setIsModalOpen(true);
  };

  const handleStatusRevert = async (gallery: Gallery) => {
    toggleDropdown(gallery.id);
    const statusLoad = toast.loading("Updating the Data");
    var status = gallery.status === "published" ? "draft" : "published";
    try {
      const input = {
        status: status,
      };
      if (status) {
        await updateGallery({ variables: { id: gallery.id, input: input } });
        toast.remove(statusLoad);
        toast.success("Gallery updated successfully");
      }
      refetch();
      clearForm();
    } catch (error) {
      toast.remove(statusLoad);
      console.error("Error during gallery submission:", error);
      toast.error("Error saving gallery");
    } finally {
      setIsModalOpen(false);
    }
  };

  // Upload file to Cloudinary
  const uploadFile = async (file: string): Promise<string> => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", import.meta.env.VITE_UPLOAD_PRESET);

    try {
      const response = await fetch(
        `https://api.cloudinary.com/v1_1/${
          import.meta.env.VITE_CLOUD_NAME
        }/image/upload`,
        {
          method: "POST",
          body: formData,
        }
      );
      const data = await response.json();
      return data.secure_url;
    } catch (error) {
      console.error("File upload error:", error);
      throw new Error("File upload failed");
    }
  };

  // Handle submission (create gallery as draft or published)
  const handleSubmit = async () => {
    // e.prgalleryDefault();
    if (
      !formData.title ||
      !formData.photo ||
      !formData.date ||
      !formData.time ||
      !formData.status
    ) {
      toast.error("Please fill all the data");
    } else {
      const submitLoading = toast.loading(
        `${selectedGallery ? "Updating" : "Creating"} The Gallery`
      );

      try {
        const fileUrl = formData.photo
          ? await uploadFile(formData.photo)
          : previewUrl;

        const inputData = {
          ...formData,
          photo: fileUrl,
        };

        if (selectedGallery) {
          await updateGallery({
            variables: { id: selectedGallery.id, input: inputData },
          });
          toast.success("Gallery updated successfully");
          toast.remove(submitLoading);
        } else {
          await createGallery({ variables: { input: inputData } });
          toast.remove(submitLoading);
          toast.success("Gallery created successfully");
        }

        refetch();
        clearForm();
      } catch (error) {
        toast.remove(submitLoading);
        console.error("Error during gallery submission:", error);
        toast.error("Error saving gallery");
      } finally {
        setIsModalOpen(false);
      }
    }
  };

  const handleFileChange = (photo: any | null) => {
    setFormData({ ...formData, photo });
  };

  return (
    <>
      <PageTitle pagetitle="Gallery" subtitle="Media" />
      <div className="w-full">
        <div className="flex justify-end items-center mb-4">
          <input
            type="text"
            placeholder="Search registered gallery..."
            className="form-input w-1/2 rounded"
            value={searchQuery}
            onChange={handleSearch}
          />
          <button
            onClick={() => {
              handleCreate();
            }}
            className="bg-green-300 text-green-700 h-full block py-2 px-3 ml-4 hover:text-white rounded hover:bg-green-500"
          >
            + Add gallery
          </button>
        </div>

        <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-3 md:grid-cols-4 sm:gap-4 min-w-48">
          {loading && <Skeleton />}
          {error && <p>Error: {error.message}</p>}
          {filteredGallery.map((gallery) => (
            <ProgramCard
              key={gallery.id}
              image={gallery}
              onEdit={() => handleEdit(gallery)}
              onView={() => handleView(gallery)}
              onStatus={() => handleStatusRevert(gallery)}
              refetch={refetch}
              isDropdownOpen={openDropdownId === gallery.id} // Open dropdown only for the specific card
              toggleDropdown={toggleDropdown}
              // formateDate= {() => formatDate(formData.date)}
            />
          ))}
        </div>
      </div>

      {isModalOpen && (
        <Modal
          title={
            !selectedGallery
              ? "Add Gallery"
              : isEditing
              ? "Edit Gallery"
              : "View Gallery"
          }
          isOpen={isModalOpen}
          setIsOpen={clearForm}
          handleSubmit={handleSubmit}
        >
          <div className="flex flex-col justify-center items-center px-2 text-gray-700">
            <div className="w-full px-2">
              <label className="text-gray-600 mt-1 inline-block">Title:</label>
              <input
                type="text"
                value={formData.title}
                onChange={(e) =>
                  setFormData({ ...formData, title: e.target.value })
                }
                className="text-sm m-1 ml-0 rounded min-w-56 w-full border border-gray-300  inline-block"
                placeholder="Enter title"
                disabled={!isEditing}
              />
            </div>
            <div className="w-full px-2 flex justify-between items-center">
              <div className="w-6/12">
                <label className="text-gray-600 mt-1 inline-block">Date:</label>
                <input
                  type="date"
                  value={formData.date}
                  // value={formatForInput(formData.date)}
                  onChange={(e) =>
                    setFormData({ ...formData, date: e.target.value })
                  }
                  className="text-sm m-1 ml-0 inline-block rounded w-full border border-gray-300 "
                  disabled={!isEditing}
                />
              </div>
              <div className="w-5/12">
                <label className="text-gray-600 mt-1 inline-block">Time:</label>
                <input
                  type="time"
                  value={formData.time}
                  onChange={(e) =>
                    setFormData({ ...formData, time: e.target.value })
                  }
                  className="text-sm m-1 ml-0 inline-block rounded w-full border border-gray-300 "
                  disabled={!isEditing}
                />
              </div>
            </div>
            <div className="w-full px-2 mt-3">
              <label className="text-gray-600 my-1 inline-block">Status:</label>
              <div className="w-full px-2 flex justify-center">
                {/* Pending Status */}
                <div className="flex items-center ps-4 border border-gray-200 rounded dark:border-gray-700 w-1/2 mr-2">
                  <input
                    id="published"
                    type="radio"
                    value="published"
                    checked={formData.status === "published"}
                    onChange={handleStatusChange}
                    name="status"
                    disabled={!isEditing}
                    className="w-3 h-3 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    htmlFor="published"
                    className="w-full py-2 ms-2 text-xs font-medium text-gray-900 dark:text-gray-300"
                  >
                    Published
                  </label>
                </div>

                {/* Draft Status */}
                <div className="flex items-center ps-4 border border-gray-200 rounded dark:border-gray-700 w-1/2">
                  <input
                    id="draft"
                    type="radio"
                    value="draft"
                    checked={formData.status === "draft"}
                    onChange={handleStatusChange}
                    name="status"
                    disabled={!isEditing}
                    className="w-3 h-3 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    htmlFor="draft"
                    className="w-full py-3 ms-2 text-xs font-medium text-gray-900 dark:text-gray-300"
                  >
                    Draft
                  </label>
                </div>
              </div>
            </div>
            <Uploadgallery
              onFileChange={handleFileChange}
              isEditing={isEditing}
              previewUrl={previewUrl}
              setPreviewUrl={setPreviewUrl}
              setFormData={() => setFormData}
              photo={formData?.photo}
            />
          </div>

          {/* {isEditing && (
            <div className="flex flex-row items-center justify-end py-2 px-4">
              <button
                onClick={handleSubmit}
                className="bg-green-500 text-white py-2 px-4 w-28 rounded"
              >
                Save
              </button>
            </div>
          )} */}
        </Modal>
      )}
    </>
  );
};

export default Gallery;
