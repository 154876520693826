import React, { useEffect, useRef, useState } from "react";
import { gql, useMutation, useQuery } from '@apollo/client';
import toast from "react-hot-toast";
import { CREATE_JUDGES, UPDATE_JUDGES } from "../../graphql/mutations/judgement";

const GET_HOSTS = gql`
    query Hosts {
        hosts {
            id
            name
            place
        }
    }
`;

export interface Host {
    id: string;
    name: string;
    place: string;
    group: {
        id: string;
        name: string
    }
}

interface IJudges {
    id: string;
    name: string;
    host: Host;
    email: string;
    phoneNumber: string;
}

interface AddJudgeProps {
    selectedJudge: IJudges | null; // For editing, the selected item will be passed
    setSelectedJudge: (judge: IJudges | null) => void;
    onSave: () => void; // Callback when saving is complete
    onCancel: () => void; // Callback when canceling the edit
    refetch: () => void;
}

const AddJudge: React.FC<AddJudgeProps> = ({ selectedJudge, onSave, onCancel, refetch, setSelectedJudge }) => {
    const [loadingFile, setLoadingFile] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedHost, setselectedHost] = useState<Host | null>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const searchInputRef = useRef<HTMLInputElement>(null);

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        host: "",
        phoneNumber: "",
    });

    const { loading, error, data, refetch: refetchData } = useQuery(GET_HOSTS);

    const filteredHostOptions = data?.hosts?.filter((host: any) =>
        host?.name.toLowerCase().includes(searchTerm.toLowerCase())
    );


    const [createJudge] = useMutation(CREATE_JUDGES);
    const [updateJudge] = useMutation(UPDATE_JUDGES);

    // const { user } = useAuth();


    // Load form data if editing a judge
    const handleHostChange = (selectedHostId: string) => {
        const selectedHost = data?.hosts
            .filter((host: Host) => host?.id === selectedHostId);
        setselectedHost(selectedHost[0])
        setFormData({ ...formData, host: selectedHost[0]?.id })
        if (selectedJudge && selectedHostId != "") {
            setSelectedJudge({ ...selectedJudge, host: selectedHost[0] })
        }

    };

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        }

        if (isOpen) {
            searchInputRef.current?.focus();
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen]);

    useEffect(() => {
        if (selectedJudge) {
            setFormData({
                name: selectedJudge?.name || '',
                host: selectedJudge?.host?.id || '',
                email: selectedJudge?.email || '',
                phoneNumber: selectedJudge?.phoneNumber || '',
            },);
        }
    }, [selectedJudge]);

    const clearForm = () => {
        onCancel()
        handleHostChange("")
        setFormData({
            name: "",
            email: "",
            host: "",
            phoneNumber: "",
        });
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            if (!formData.name) {
                toast.error("Fill the name field");
                return;
            }
            if (!formData.host) {
                toast.error("Select a host from host field");
                return;
            }

            if (selectedJudge) {
                const updateloading = toast.loading('Updating The Judge')
                // Update existing judge
                await updateJudge({
                    variables: {
                        id: selectedJudge.id,
                        input: {
                            name: formData.name,
                            email: formData.email,
                            phoneNumber: formData.phoneNumber,
                            host: formData.host,
                        }
                    }
                });
                toast.remove(updateloading)
                toast.success('Judge updated successfully');
            } else {

                const addloading = toast.loading('Creating New Judge')
                await createJudge({
                    variables: {
                        input: {
                            name: formData.name,
                            email: formData.email,
                            phoneNumber: formData.phoneNumber,
                            host: formData.host,
                        }
                    }
                });
                toast.remove(addloading)
                toast.success('Judge registration successful');

            }
            refetchData();
            onSave();
            refetch();
            clearForm()
        } catch (err) {
            console.error('Error during registration:', err);
            toast.error('Judge registration failed');
        } finally {
            setLoadingFile(false);
        }
    };

    if (error) return <p>Error: {error.message}</p>;

    return (
        <div className="bg-white h-fit p-6 rounded-lg shadow-sm dark:bg-slate-800">
            <div className="flex justify-between flex-1 align-center">
                <h2 className="text-base font-medium mb-4">{selectedJudge ? 'Edit Judge' : 'Register New Judge'}</h2>
                {selectedJudge ? <button className="bg-gray-400 text-white px-2 text-xs rounded-lg max-h-8" onClick={clearForm}>Cancel</button> : ''}
            </div>
            <form className="space-y-4" onSubmit={handleSubmit}>
                <div>
                    <label className="block text-gray-700 text-sm mb-2">Enter Name: </label>
                    <input
                        type="text"
                        className="form-input w-full p-2 border border-gray-300 rounded-md"
                        placeholder="Judge Name"
                        name="name"
                        value={formData.name}
                        onChange={(e) => setFormData({ ...formData, name: e.target.value })}

                    />
                </div>

                <div>
                    <label className="block text-gray-700 text-sm mb-2">Select Host</label>

                    <div className="relative" ref={dropdownRef}>
                        {/* Selected Option Display */}
                        <div className="w-full">
                            <div className="relative">
                                <input
                                    type="button"
                                    value={selectedJudge?.host ? `${selectedJudge?.host?.name} | ${selectedJudge?.host?.place} ` : selectedHost ? `${selectedHost?.name} | ${selectedHost?.place}` : `${loading ? "Loading Hosts" : "Select Host"}`}
                                    onClick={() => setIsOpen(!isOpen)}
                                    placeholder={loading ? "Loading Hosts" : "Select Host"}
                                    className="form-input text-left cursor-pointer w-full p-2 border border-gray-300 rounded-md"
                                />
                                <span className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                    <i className="mgc_down_fill me-2 text-xl"></i>
                                </span>
                            </div>
                        </div>
                        {/* Dropdown Options */}
                        {isOpen && (
                            <div className="absolute top-full left-0 w-full mt-2 bg-white border rounded shadow-lg z-10">

                                <div className="w-full">
                                    <input
                                        type="text"
                                        value={searchTerm}
                                        ref={searchInputRef}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        placeholder="Search Host"
                                        className="form-input cursor-pointer w-full p-2 border border-gray-300 rounded-md"
                                    />
                                </div>

                                {/* Options List */}
                                <ul className="max-h-48 overflow-y-auto">
                                    {filteredHostOptions?.map((host: any, index: any) => (
                                        <li
                                            key={index}
                                            onClick={() => {
                                                setIsOpen(false)
                                                setSearchTerm("");
                                                handleHostChange(host?.id);

                                            }}
                                            className="px-4 py-2 cursor-pointer hover:bg-blue-500 hover:text-white"
                                        >
                                            {`${host?.name} | ${host?.place}`}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>

                </div>

                <div>
                    <label className="block text-gray-700 text-sm mb-2">Enter Email: </label>
                    <input
                        type="text"
                        className="form-input w-full p-2 border border-gray-300 rounded-md"
                        placeholder="Judge Email"
                        name="email"
                        value={formData.email}
                        onChange={(e) => setFormData({ ...formData, email: e.target.value })}

                    />
                </div>

                <div>
                    <label className="block text-gray-700 text-sm mb-2">Enter Phone Number: </label>
                    <input
                        type="text"
                        className="form-input w-full p-2 border border-gray-300 rounded-md"
                        placeholder="Judge Phone Number"
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={(e) => setFormData({ ...formData, phoneNumber: e.target.value })}

                    />
                </div>
                <div className="flex justify-between">
                    <button type="submit" disabled={loadingFile} className={`${selectedJudge ? "bg-blue-500" : "bg-green-500"} disabled:bg-gray-500 disabled:text-white text-white disabled:cursor-not-allowed py-2 px-4 rounded-md w-full`} >
                        {loadingFile ? "Submitting..." : selectedJudge ? "Update Judge" : "Submit"}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default AddJudge;