import React, { useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle";
import ScheduleCard from "../../components/schedule/ScheduleCard";
import { gql, useQuery } from "@apollo/client";
import Pagination from "../../components/UIElements/Pagination";
import { NavLink } from "react-router-dom";
import FilterModel from "../../components/UIElements/FilterModel";

const GET_PROGRAMS = gql`
  query GetPrograms(
    $page: Int
    $limit: Int
    $search: String
    $programCategoryId: ID
    $categoryId: ID
    $skillId: ID
  ) {
    programs(
      page: $page
      limit: $limit
      search: $search
      programCategoryId: $programCategoryId
      categoryId: $categoryId
      skillId: $skillId
    ) {
      programs {
        category {
          id
          name
        }
        eliminationVenue {
          key
          value {
            id
            name
          }
        }
        eliminationDate {
          key
          value
        }
        eliminationEndingTime {
          key
          value
        }
        eliminationStartingTime {
          key
          value
        }
        finalDate
        finalEndingTime
        finalResultStatus
        finalStartingTime
        finalVenue {
          id
          name
        }
        id
        mode
        name
        programCategory {
          id
          name
        }
        programCode
      }
      totalPages
      totalPrograms
    }
  }
`;

const GET_CATEGORIES = gql`
  query Categories {
    categories {
      id
      name
    }
  }
`;

const GET_PROGRAM_CATEGORIES = gql`
  query ProgramCategories {
    programCategories {
      id
      name
    }
  }
`;

interface Program {
  id: string;
  name: string;
  programCode: string;
  skill: {
    id: string;
    name: string;
  };
  category: {
    id: string;
    name: string;
  };
  eliminationStartingTime: string;
  finalDate: string;
  eliminationDate: string;
  eliminationVenue: {
    id: string;
    name: string;
  };
  finalStartingTime: string;
  finalVenue: {
    id: string;
    name: string;
  };
  programCategory: {
    id: string;
    name: string;
  };
}

const Schedule: React.FC = () => {
  const [page, setPage] = useState<number>(0);
  const limit = 9;

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedProgramCategory, setSelectedProgramCategory] = useState("");

  const {
    data: programData,
    error,
    loading,
    refetch,
  } = useQuery(GET_PROGRAMS, {
    variables: {
      page,
      limit,
      search: searchTerm,
      programCategoryId: selectedProgramCategory,
      categoryId: selectedCategory,
    },
    fetchPolicy: "cache-and-network",
  });

  const { data: categoryData } = useQuery(GET_CATEGORIES);
  const { data: programCategoryData } = useQuery(GET_PROGRAM_CATEGORIES);

  const [programList, setProgramList] = useState<Program[]>([]);

  useEffect(() => {
    if (programData && programData.programs?.programs) {
      setProgramList(programData.programs?.programs);
    }
  }, [programData]);

  const handlePageChange = (newPage: number) => {
    setPage(newPage - 1);
    refetch({ page: newPage - 1, limit });
  };

  const filterConfig = [
    {
      field: "category",
      label: "Category",
      type: "select",
      options: categoryData?.categories,
    },
    {
      field: "programCategory",
      label: "Program Category",
      type: "select",
      options: programCategoryData?.programCategories,
    },
  ];

  const handleApplyFilters = (filterValue: any) => {
    setSelectedCategory(filterValue?.category);
    setSelectedProgramCategory(filterValue?.programCategory);
  };
  
  if (error) return <p>Error: {error.message}</p>;

  return (
    <>
      <PageTitle pagetitle="Schedule" subtitle="Schedule" />

      <div className="justify-between flex items-center gap-4 p-4 ">
        <div className="w-1/4 flex items-center">
          <label className="text-gray-600 mr-2">Search</label>
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setPage(0);
            }}
            placeholder="Search.."
            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
          />
        </div>
        <div className="flex gap-2">
          <FilterModel
            onApplyFilters={handleApplyFilters}
            filters={filterConfig}
          />

          <NavLink
            to="/compare-schedules"
            className="w-40 grid place-items-center bg-red-600 text-white px-4 py-2 rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400"
          >
            Compare
          </NavLink>
        </div>
      </div>
      {loading ? (
        <div className="flex justify-center items-center">
          <button
            type="button"
            className="btn bg-transparent w-fit border-gray-300 dark:border-gray-700"
          >
            <i className="mgc_loading_4_line me-2 animate-spin"></i>
            <span>Loading</span>
          </button>
        </div>
      ) : (
        <div className="flex flex-auto flex-col mt-6">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 justify-items-center">
            {programList?.map((programData) => (
              <ScheduleCard
                key={programData.id}
                programData={programData}
                refetch={refetch}
              />
            ))}
          </div>
          <Pagination
            totalPages={programData?.programs?.totalPages}
            page={page + 1}
            onPageChange={handlePageChange}
            className="my-8"
          />
        </div>
      )}
    </>
  );
};

export default Schedule;
