import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { GET_GROUPS } from "../../../graphql/queries/group";
import toast from "react-hot-toast";
import { UPDATE_ELIMINATION_RESULT_STATUS } from "../../../graphql/mutations/elimination";
import { useReactToPrint } from "react-to-print";
import SinglePrint from "./SinglePrint";
import { SELECTED_PARTICIPATION } from "../../../graphql/queries/participation";
import { ELIMINATION_RESULT_TO_PUBLISH_COUNT_BY_INSITUTION } from "../../../pages/result/elimination/EliminationPrograms";

interface ProgramTableProps {
  program: any;
  groupId: string;
  setGroupId: (value: string) => void;
}

const ProgramTableRow: React.FC<ProgramTableProps> = ({
  program,
  groupId,
  setGroupId,
}) => {
  const [programData, setProgramData] = useState<any>({});
  const [printData, setPrintData] = useState(null);
  const [docName, setDocName] = useState("");

  const apolloClient = useApolloClient();

  const { data } = useQuery(GET_GROUPS);
  const [UpdateEliminationResultStatus] = useMutation(
    UPDATE_ELIMINATION_RESULT_STATUS,
    {
      refetchQueries: [
        { query: ELIMINATION_RESULT_TO_PUBLISH_COUNT_BY_INSITUTION },
      ],
    }
  );

  useEffect(() => {
    if (data?.groups && data?.groups?.length === 1) {
      setGroupId(data?.groups[0]?.id);
    }
  }, [data]);

  useEffect(() => {
    if (program) {
      setProgramData(program);
    }
  }, [program]);

  const singleRef = useRef<any>(null);
  const singlePrint = useReactToPrint({
    contentRef: singleRef,
    documentTitle: docName,
    pageStyle: `
      @media print {
        @page {
          size: A4;
          margin: 0;
        }
        body {
          margin: 0;
          padding: 0;
          -webkit-print-color-adjust: exact; 
          print-color-adjust: exact;
        }
      }
    `,
  });

  useEffect(() => {
    if (printData) {
      singlePrint();
      setPrintData(null);
      setDocName("");
    }
  }, [printData]);

  const handlePrint = async (
    programId: string,
    programName: string,
    programCode: string
  ) => {
    try {
      const { data } = await apolloClient.query({
        query: SELECTED_PARTICIPATION,
        variables: { input: { programId, groupId } },
        fetchPolicy: "network-only",
      });

      if (data) {
        setPrintData(data?.selectedParticipations);
        setDocName(`${programCode} - ${programName}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Error fetching printing data");
    }
  };

  const handleUpdate = async (
    programId: string,
    groupId: string,
    value: string
  ) => {
    try {
      await UpdateEliminationResultStatus({
        variables: {
          id: programId,
          input: {
            eliminationResultStatus: [
              {
                key: groupId,
                value,
              },
            ],
            publishTimeElimination: [
              {
                key: groupId,
                value: Date.now(),
              },
            ],
          },
        },
      });

      const updatedProgramData = {
        ...programData, // Spread the existing state to create a new object
        eliminationResultStatus: programData.eliminationResultStatus?.some(
          (status: any) => status.key === groupId
        )
          ? programData.eliminationResultStatus.map(
              (status: any) =>
                status.key === groupId
                  ? { ...status, value } // Update existing status
                  : status // Keep other statuses unchanged
            )
          : [
              ...(programData.eliminationResultStatus || []), // Ensure it's an array if undefined
              { key: groupId, value }, // Add new status if missing
            ],
        publishTimeElimination: programData.publishTimeElimination?.some(
          (time: any) => time.key === groupId
        )
          ? programData.publishTimeElimination.map(
              (time: any) =>
                time.key === groupId
                  ? { ...time, value: Date.now() } // Update existing publish time
                  : time // Keep other times unchanged
            )
          : [
              ...(programData.publishTimeElimination || []), // Ensure it's an array if undefined
              { key: groupId, value: Date.now() }, // Add new publish time if missing
            ],
      };

      setProgramData(updatedProgramData);

      toast.success("Elimination Result status updated successfully");
    } catch (error: any) {
      const errorMessage =
        error.graphQLErrors && error.graphQLErrors[0]?.message
          ? error.graphQLErrors[0]?.message
          : "Failed to update Elimination Result status";
      toast.error(errorMessage);
      console.error("Failed to update Elimination Result status", error);
    }
  };

  const resultStatusOptions = [
    "Prepared",
    "To_Publish",
    "Published",
    "Announced",
  ];

  return (
    <>
      <tr className="hover:bg-gray-100 dark:hover:bg-gray-700">
        <td className="px-4 py-2 text-sm text-gray-700 dark:text-gray-300 font-bold">
          {programData.programCode}
        </td>
        <td className="px-4 py-2 text-sm text-gray-700 dark:text-gray-300 font-extrabold">
          <Link to={`/result/elimination/${programData.id}`}>
            {programData.name}
          </Link>
        </td>
        <td className="px-4 py-2 text-sm text-gray-700 dark:text-gray-300">
          {programData.category?.name}
        </td>
        <td className="px-4 py-2 text-sm text-gray-700 dark:text-gray-300">
          {!programData.isGroupwiseResult &&
            (["Prepared", "To_Publish", "Published", "Announced"].includes(
              programData.eliminationResultStatus?.find(
                (item: any) => item.key === "group"
              )?.value
            ) ? (
              <select
                id="resultStatus"
                className={`m-1 pr-6 pl-2 py-1 text-xs cursor-pointer font-semibold rounded border-none focus:ring-transparent ${
                  programData.eliminationResultStatus?.find(
                    (item: any) => item.key === "group"
                  )?.value === "Announced"
                    ? "bg-green-300 text-green-700"
                    : programData.eliminationResultStatus?.find(
                        (item: any) => item.key === "group"
                      )?.value === "Published"
                    ? "bg-blue-300 text-blue-700"
                    : programData.eliminationResultStatus?.find(
                        (item: any) => item.key === "group"
                      )?.value === "To_Publish"
                    ? "bg-orange-300 text-orange-700"
                    : "bg-violet-300 text-violet-700"
                } `}
                value={
                  programData.eliminationResultStatus?.find(
                    (item: any) => item.key === "group"
                  )?.value || ""
                }
                onChange={(e) =>
                  handleUpdate(programData.id, "group", e.target.value)
                }
              >
                {resultStatusOptions.map((status) => (
                  <option key={status} value={status} className="bg-white">
                    {status || "Not_Entered"}
                  </option>
                ))}
              </select>
            ) : (
              <div
                className={` py-1 px-4 rounded text-xs w-max font-semibold border-none focus:ring-transparent ${
                  programData.eliminationResultStatus?.find(
                    (item: any) => item.key === "group"
                  )?.value === "Entering"
                    ? "bg-pink-300 text-pink-700"
                    : "bg-red-300 text-red-700"
                }`}
              >
                {programData.eliminationResultStatus?.find(
                  (item: any) => item.key === "group"
                )?.value || "Not_Entered"}
              </div>
            ))}

          {programData.isGroupwiseResult &&
            data?.groups?.map((group: any) => (
              <div className="flex items-center" key={group.id}>
                {data?.groups?.length > 1 && (
                  <label htmlFor="resultStatus" className="mr-2">
                    {group.name}
                  </label>
                )}
                {["Prepared", "To_Publish", "Published", "Announced"].includes(
                  programData.eliminationResultStatus?.find(
                    (item: any) => item.key === group.id
                  )?.value
                ) ? (
                  <select
                    id="resultStatus"
                    className={`m-1 pr-6 pl-2 py-1 text-xs cursor-pointer font-semibold rounded border-none focus:ring-transparent ${
                      programData.eliminationResultStatus?.find(
                        (item: any) => item.key === group.id
                      )?.value === "Announced"
                        ? "bg-green-300 text-green-700"
                        : programData.eliminationResultStatus?.find(
                            (item: any) => item.key === group.id
                          )?.value === "Published"
                        ? "bg-blue-300 text-blue-700"
                        : programData.eliminationResultStatus?.find(
                            (item: any) => item.key === group.id
                          )?.value === "To_Publish"
                        ? "bg-orange-300 text-orange-700"
                        : "bg-violet-300 text-violet-700"
                    } `}
                    value={
                      programData.eliminationResultStatus?.find(
                        (item: any) => item.key === group.id
                      )?.value || ""
                    }
                    onChange={(e) =>
                      handleUpdate(programData.id, group.id, e.target.value)
                    }
                  >
                    {resultStatusOptions.map((status) => (
                      <option key={status} value={status} className="bg-white">
                        {status || "Not_Entered"}
                      </option>
                    ))}
                  </select>
                ) : (
                  <div
                    className={`m-1 py-1 px-4 rounded text-xs w-max font-semibold border-none focus:ring-transparent ${
                      programData.eliminationResultStatus?.find(
                        (item: any) => item.key === group.id
                      )?.value === "Entering"
                        ? "bg-pink-300 text-pink-700"
                        : "bg-red-300 text-red-700"
                    } `}
                  >
                    {programData.eliminationResultStatus?.find(
                      (item: any) => item.key === group.id
                    )?.value || "Not_Entered"}
                  </div>
                )}
              </div>
            ))}
        </td>
        <td className="px-4 py-2 text-sm text-gray-700 dark:text-gray-300">
          {!programData.isGroupwiseResult && (
            <div
              className={` py-1 rounded text-xs w-max font-medium  text-gray-800`}
            >
              {new Intl.DateTimeFormat("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              }).format(
                programData.publishTimeElimination?.find(
                  (item: any) => item.key === "group"
                )?.value || new Date()
              )}
            </div>
          )}

          {programData.isGroupwiseResult &&
            data?.groups?.map((group: any) => (
              <div
                className={`m-1 my-2 py-1 rounded w-max text-xs font-medium  text-gray-800`}
              >
                {new Intl.DateTimeFormat("en-GB", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: true,
                }).format(
                  programData.publishTimeElimination?.find(
                    (item: any) => item.key === group.id
                  )?.value || new Date()
                )}
              </div>
            ))}
        </td>
        <td className="px-4 py-2 text-sm text-gray-700 dark:text-gray-300 text-center">
          <i
            className="mgc_print_line text-lg cursor-pointer"
            onClick={() =>
              handlePrint(
                programData.id,
                programData.name,
                programData.programCode
              )
            }
          ></i>
        </td>
      </tr>
      {printData && (
        <div className="hidden">
          <div ref={singleRef}>
            <div
              className="w-[210mm] h-[297mm] mx-auto pt-[280px]"
              style={{
                backgroundImage: "url('/img/valuation-cover.jpg')",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
              }}
            >
              <h3 className="text-xl text-center font-medium font-cambria text-black mb-4">
                SIBAQ'25 ELIMINATION RESULTS
              </h3>
              <h4 className="text-lg text-center font-bold text-black">
                {programData?.programCode} - {programData?.name}
              </h4>
              <div className="flex justify-center">
                <p className="text-sm mb-10 bg-black text-white w-min py-1 px-2 rounded-md">
                  {programData?.category?.name}
                </p>
              </div>
              <SinglePrint
                programCode={programData?.programCode}
                programName={programData?.name}
                programCategory={programData?.category?.name}
                results={printData}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProgramTableRow;
