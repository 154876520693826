import React, { useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle";
import ProgramCard from "../../components/events/ProgramCard";
import Uploadevent from "../../components/events/UploadImage";
import { useMutation, useQuery } from "@apollo/client";
import { GET_EVENTS } from "../../graphql/queries/events";
import { CREATE_EVENTS, UPDATE_EVENTS } from "../../graphql/mutations/events";
import toast from "react-hot-toast";
import Modal from "../../components/UIElements/Model";
import Skeleton from "../../components/UIElements/Skeleton";

// Define interface for the Events type
interface Events {
  id: string;
  title: string;
  description: string;
  date: string;
  photo: string;
  time: string;
  status: string;
}

const Events: React.FC = () => {
  const { data, loading, error, refetch } = useQuery(GET_EVENTS);
  const [createEvents] = useMutation(CREATE_EVENTS);
  const [updateEvents] = useMutation(UPDATE_EVENTS);

  // State variables
  const [selectedEvents, setSelectedEvents] = useState<Events | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    date: "",
    photo: "",
    time: "",
    status: "published",
  });
  const [previewUrl, setPreviewUrl] = useState<string | ArrayBuffer | null>(
    null
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [eventsList, setEventsList] = useState<Events[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [openDropdownId, setOpenDropdownId] = useState<string | null>(null);

  // Toggle dropdown for a specific events item
  const toggleDropdown = (id: string) => {
    setOpenDropdownId((prev) => (prev === id ? null : id)); // If the same id is clicked, close it, otherwise open
  };

  // Load events data into state when fetched
  useEffect(() => {
    if (data?.events) {
      setEventsList(data.events);
    }
  }, [data]);

  // Populate form when editing a events item
  useEffect(() => {
    if (selectedEvents) {
      setFormData({
        title: selectedEvents.title || "",
        description: selectedEvents.description || "",
        date: selectedEvents.date
          ? new Date(Number(selectedEvents.date)).toISOString().split("T")[0]
          : "",
        photo: selectedEvents.photo || "",
        time: selectedEvents.time || "",
        status: selectedEvents.status || "published",
      });
      setPreviewUrl(selectedEvents?.photo);
    }
  }, [selectedEvents]);

  // Filter events based on search query
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const filteredEvents = eventsList.filter(
    (event) =>
      event.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      event.description.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Clear form data
  const clearForm = () => {
    setFormData({
      title: "",
      description: "",
      date: "",
      photo: "",
      time: "",
      status: "published",
    });
    setPreviewUrl(null);
    setIsModalOpen(false);
    setSelectedEvents(null);
  };

  const handleCreate = () => {
    setIsEditing(true);
    clearForm();
    setIsModalOpen(true);
  };

  const handleStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      status: e.target.value,
    });
  };

  // Handle editing events
  const handleEdit = (event: Events) => {
    setSelectedEvents(event);
    setIsEditing(true);
    toggleDropdown(event.id);
    setIsModalOpen(true);
  };

  // Handle viewing events details
  const handleView = (event: Events) => {
    setSelectedEvents(event);
    setIsEditing(false);
    toggleDropdown(event.id);
    setIsModalOpen(true);
  };

  const handleStatusRevert = async (event: Events) => {
    toggleDropdown(event.id);
    const statusLoad = toast.loading("Updating the Event");
    var status = event.status === "published" ? "draft" : "published";
    try {
      const input = {
        status: status,
      };
      if (status) {
        await updateEvents({ variables: { id: event.id, input: input } });
        toast.remove(statusLoad);
        toast.success("Event updated successfully");
      }
      refetch();
      clearForm();
    } catch (error) {
      toast.remove(statusLoad);
      console.error("Error during event submission:", error);
      toast.error("Error saving event");
    } finally {
      setIsModalOpen(false);
    }
  };

  // Upload file to Cloudinary
  const uploadFile = async (file: string): Promise<string> => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", import.meta.env.VITE_UPLOAD_PRESET);

    try {
      const response = await fetch(
        `https://api.cloudinary.com/v1_1/${
          import.meta.env.VITE_CLOUD_NAME
        }/image/upload`,
        {
          method: "POST",
          body: formData,
        }
      );
      const data = await response.json();
      return data.secure_url;
    } catch (error) {
      console.error("File upload error:", error);
      throw new Error("File upload failed");
    }
  };

  // Handle submission (create events as draft or published)
  const handleSubmit = async () => {
    // e.preventDefault();
    if (
      !formData.title ||
      !formData.photo ||
      !formData.description ||
      !formData.date ||
      !formData.time ||
      !formData.status
    ) {
      toast.error("Please fill all the data");
    } else {
      const submitLoading = toast.loading(
        `${selectedEvents ? "Updating" : "Creating"} The Event`
      );

      try {
        const fileUrl = formData.photo
          ? await uploadFile(formData.photo)
          : previewUrl;

        const inputData = {
          ...formData,
          photo: fileUrl,
        };

        if (selectedEvents) {
          await updateEvents({
            variables: { id: selectedEvents.id, input: inputData },
          });
          toast.success("Events updated successfully");
          toast.remove(submitLoading);
        } else {
          await createEvents({ variables: { input: inputData } });
          toast.remove(submitLoading);
          toast.success("Events created successfully");
        }

        refetch();
        clearForm();
      } catch (error) {
        toast.remove(submitLoading);
        console.error("Error during events submission:", error);
        toast.error("Error saving events");
      } finally {
        setIsModalOpen(false);
      }
    }
  };

  const handleFileChange = (photo: any | null) => {
    setFormData({ ...formData, photo });
  };

  return (
    <>
      <PageTitle pagetitle="Events" subtitle="Media" />
      <div className="w-full">
        <div className="flex justify-end items-center mb-4">
          <input
            type="text"
            placeholder="Search registered events..."
            className="form-input w-1/2 rounded"
            value={searchQuery}
            onChange={handleSearch}
          />
          <button
            onClick={() => {
              handleCreate();
            }}
            className="bg-green-300 text-green-700 h-full block py-2 px-3 ml-4 hover:text-white rounded hover:bg-green-500"
          >
            + Add event
          </button>
        </div>

        <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-3 md:grid-cols-4 sm:gap-4 min-w-48">
          {loading && <Skeleton />}
          {error && <p>Error: {error.message}</p>}
          {filteredEvents.map((event) => (
            <ProgramCard
              key={event.id}
              event={event}
              onEdit={() => handleEdit(event)}
              onView={() => handleView(event)}
              onStatus={() => handleStatusRevert(event)}
              refetch={refetch}
              isDropdownOpen={openDropdownId === event.id} // Open dropdown only for the specific card
              toggleDropdown={toggleDropdown}
              // formateDate= {() => formatDate(formData.date)}
            />
          ))}
        </div>
      </div>

      {isModalOpen && (
        <Modal
          title={
            !selectedEvents
              ? "Add Events"
              : isEditing
              ? "Edit Events"
              : "View Events"
          }
          isOpen={isModalOpen}
          setIsOpen={clearForm}
          handleSubmit={handleSubmit}
        >
          <div className="flex flex-col justify-center items-center px-2 text-gray-700">
            <div className="w-full px-2">
              <label className="text-gray-600 mt-1 inline-block">Title:</label>
              <input
                type="text"
                value={formData.title}
                onChange={(e) =>
                  setFormData({ ...formData, title: e.target.value })
                }
                className="text-sm m-1 ml-0 rounded min-w-56 w-full border border-gray-300  inline-block"
                placeholder="Enter title"
                disabled={!isEditing}
              />
            </div>
            <div className="w-full px-2">
              <label className="text-gray-600 mt-1 inline-block">
                Description:
              </label>
              <textarea
                value={formData.description}
                onChange={(e) =>
                  setFormData({ ...formData, description: e.target.value })
                }
                className="text-sm m-1 ml-0 rounded min-w-56 w-full border border-gray-300 p-2"
                placeholder="Enter description"
                disabled={!isEditing}
              />
            </div>
            <div className="w-full px-2 flex justify-between items-center">
              <div className="w-6/12">
                <label className="text-gray-600 mt-1 inline-block">Date:</label>
                <input
                  type="date"
                  value={formData.date}
                  // value={formatForInput(formData.date)}
                  onChange={(e) =>
                    setFormData({ ...formData, date: e.target.value })
                  }
                  className="text-sm m-1 ml-0 inline-block rounded w-full border border-gray-300"
                  disabled={!isEditing}
                />
              </div>
              <div className="w-5/12">
                <label className="text-gray-600 mt-1 inline-block">Time:</label>
                <input
                  type="time"
                  value={formData.time}
                  onChange={(e) =>
                    setFormData({ ...formData, time: e.target.value })
                  }
                  className="text-sm m-1 ml-0 inline-block rounded w-full border border-gray-300"
                  disabled={!isEditing}
                />
              </div>
            </div>
            <div className="w-full px-2 mt-3">
              <label className="text-gray-600 my-1 inline-block">Status:</label>
              <div className="w-full px-2 flex justify-center">
                {/* Pending Status */}
                <div className="flex items-center ps-4 border border-gray-200 rounded dark:border-gray-700 w-1/2 mr-2">
                  <input
                    id="published"
                    type="radio"
                    value="published"
                    checked={formData.status === "published"}
                    onChange={handleStatusChange}
                    name="status"
                    disabled={!isEditing}
                    className="w-3 h-3 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    htmlFor="published"
                    className="w-full py-2 ms-2 text-xs font-medium text-gray-900 dark:text-gray-300"
                  >
                    Published
                  </label>
                </div>

                {/* Draft Status */}
                <div className="flex items-center ps-4 border border-gray-200 rounded dark:border-gray-700 w-1/2">
                  <input
                    id="draft"
                    type="radio"
                    value="draft"
                    checked={formData.status === "draft"}
                    onChange={handleStatusChange}
                    name="status"
                    disabled={!isEditing}
                    className="w-3 h-3 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    htmlFor="draft"
                    className="w-full py-3 ms-2 text-xs font-medium text-gray-900 dark:text-gray-300"
                  >
                    Draft
                  </label>
                </div>
              </div>
            </div>
            <Uploadevent
              onFileChange={handleFileChange}
              isEditing={isEditing}
              previewUrl={previewUrl}
              setPreviewUrl={setPreviewUrl}
              setFormData={() => setFormData}
              photo={formData?.photo}
            />
          </div>

          {/* {isEditing && (
            <div className="flex flex-row items-center justify-end py-2 px-4">
              <button
                onClick={handleSubmit}
                className="bg-green-500 text-white py-2 px-4 w-28 rounded"
              >
                Save
              </button>
            </div>
          )} */}
        </Modal>
      )}
    </>
  );
};

export default Events;
