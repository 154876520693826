import { gql } from "@apollo/client";

export const GET_GROUP_WISE_INSTITUTIONS = gql`
  query GroupWiseInstitution($groupId: ID!) {
    groupWiseInstitution(groupId: $groupId) {
      id
      name
      shortName
      logo
    }
  }
`;

export const GET_ROLES = gql`
  query GetRoles {
    roles {
      id
      name
    }
  }
`;

export const GET_CATEGORIES = gql`
  query Categories {
    categories {
      id
      name
    }
  }
`;

export const GET_PROGRAM_CATEGORIES = gql`
  query ProgramCategories {
    programCategories {
      id
      name
    }
  }
`;

export const GET_CLUSTERS = gql`
  query Clusters {
    clusters {
      id
      name
    }
  }
`;