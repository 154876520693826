import React from "react";

interface CardFooterProps {
  duration: number;
  category: string;
  skill: string;
  programCategory: string;
}

const CardFooter: React.FC<CardFooterProps> = ({
  duration,
  category,
  skill,
  programCategory,
}) => {
  return (
    <div className="border-t p-5 border-gray-300 dark:border-gray-700">
      <div className="flex flex-wrap items-center justify-between gap-1">
        {/* Category */}
        <a href="#" className="text-sm flex items-center gap-2">
          <i className="mgc_grid_line text-lg"></i>
          <span>{category}</span>
        </a>

        <a href="#" className="text-sm flex items-center gap-2">
          <i className="mgc_time_line text-lg"></i>
          <span>{duration} mins</span>
        </a>
        <a href="#" className="text-sm flex items-center gap-2">
          <i className="mgc_group_line text-lg"></i>
          <span>{programCategory} </span>
        </a>

        {/* Skill */}
        <a href="#" className="text-sm flex items-center gap-2">
          <i className="mgc_light_line text-lg"></i>
          <span>{skill}</span>
        </a>
      </div>
    </div>
  );
};

export default CardFooter;
