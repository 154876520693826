import React, { useState, useEffect, useRef } from 'react';
import PageTitle from '../../components/PageTitle';
import Private from '../../context/Private';
import Skeleton from '../../components/UIElements/Skeleton';
import Modal from '../../components/UIElements/Model';
import { gql, useMutation, useQuery } from '@apollo/client';
import AddVenue from '../../components/schedule/AddVenue';
import VenueItem from '../../components/schedule/VenueItem';
import toast from 'react-hot-toast';
import { DELETE_VENUE } from '../../graphql/mutations/venue';

const GET_VENUES = gql`
    query GetVenues {
        venues {
            id
            name
            place
            host {
                id
                name
            }
        }
    }
`;

const GET_HOSTS = gql`
    query Hosts {
        hosts {
            id
            name
            place
        }
    }
`;

const Venue: React.FC = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedHost, setSelectedHost] = useState<string>("");
    const [venueId, setVenueId] = useState("");
    const [selectedVenue, setSelectedVenue] = useState<any>();

    const [hasMore, _setHasMore] = useState(true);
    const [IsLoadingMore, setIsLoadingMore] = useState<boolean>(false)

    const [deleteModel, setDeleteModel] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const searchInputRef = useRef<HTMLInputElement>(null);

    const { data, loading, error, refetch } = useQuery(GET_VENUES);
    const { data: hostData } = useQuery(GET_HOSTS);
    const [deleteVenue] = useMutation(DELETE_VENUE);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        }

        if (isOpen) {
            searchInputRef.current?.focus();
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen]);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };

    const handleEdit = (venue: any) => {
        console.log(venue);
        setSelectedVenue(venue);
    };

    const handleDeleteConfirm = async (id: string) => {
        setDeleteModel(true);
        setVenueId(id);
    };

    const handleDelete = async () => {
        try {
            const deleteloading = toast.loading('Deleting the venue')
            await deleteVenue({ variables: { deleteVenueId: venueId } });
            refetch();
            toast.remove(deleteloading)
            toast.success("Venue deleted successfully!");
            setDeleteModel(false);
        } catch (error) {
            console.error("Failed to delete the venue", error);
            toast.error("Failed to delete the venue");
        }
    };

    const filteredVenues = data?.venues?.filter((venue: any) => {
        const searchLower = searchQuery.toLowerCase();

        const searchMatch = venue?.name?.toLowerCase().includes(searchLower) ||
            venue?.host?.name?.toLowerCase().includes(searchLower) ||
            venue?.host?.place?.toLowerCase().includes(searchLower)

        const hostMatch = selectedHost ? venue?.host?.name === selectedHost : true;

        return (
            searchMatch &&
            hostMatch
        );
    });

    const handleSave = () => {
        setSelectedVenue(null);
        refetch()
    };

    const handleCancel = () => {
        setSelectedVenue(null);
    };

    return (
        <>
            <PageTitle pagetitle="Venue" subtitle="Schedule" />
            <div className="w-full">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-y-6 md:gap-6">
                    <Private
                        permissions={["create-venue", "update-venue"]}
                        element={<AddVenue
                            selectedVenue={selectedVenue}
                            onSave={handleSave}
                            onCancel={handleCancel}
                            refetch={refetch}
                            setSelectedVenue={setSelectedVenue}
                        />}
                    />
                    <div className="p-6 bg-white dark:bg-slate-800 rounded-lg col-span-2">
                        <div className="flex justify-between align-center">
                            <h2 className="text-base font-medium mb-4">Added Venues</h2>

                        </div>
                        <div className="flex gap-2 mb-7 flex-col sm:flex-row">

                            <div className="relative flex flex-row w-full" ref={dropdownRef}>
                                <div className="w-full min-full sm:w-2/4 flex items-center">
                                    <input
                                        type="text"
                                        value={searchQuery}
                                        onChange={(e) => handleSearch(e)}
                                        placeholder="Search.."
                                        className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                                    />
                                </div>

                                <div className="hidden sm:flex items-center w-2/4 ml-2">
                                    <select
                                        id="host"
                                        name="host"
                                        value={selectedHost}
                                        onChange={(e) => { setSelectedHost(e.target.value) }}
                                        className="text-sm m-1 ml-0 rounded-lg min-w-56 w-full border-2 border-gray-300 p-2 inline-block"
                                    >
                                        <option value="">Select Host</option>
                                        {hostData?.hosts?.map((host: any) => (
                                            <option key={host?.id} value={host?.name}>
                                                {host?.name} | {host?.place}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        {loading ? (
                            <div className='grid place-items-center'>
                                <button
                                    type="button"
                                    className="btn bg-transparent border-gray-300 dark:border-gray-700"
                                >
                                    <i className="mgc_loading_4_line me-2 animate-spin"></i>
                                    <span>Loading</span>
                                </button>
                            </div>
                        ) :
                            (
                                <div className="relative overflow-x-auto">
                                    <table className="w-full divide-y divide-gray-300 dark:divide-gray-700">
                                        <thead className="bg-slate-300 bg-opacity-20 border-t dark:bg-slate-800 divide-gray-300 dark:border-gray-700">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="py-3.5 ps-4 pe-3 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
                                                >
                                                    Venue Name
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
                                                >
                                                    Host
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
                                                >
                                                    Venue Place
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900 dark:text-gray-200"
                                                >
                                                    Action
                                                </th>
                                            </tr>
                                        </thead>
                                        {loading ? (
                                            <Skeleton />
                                        ) : error ? (
                                            <p>Error fetching venues: {error.message}</p>
                                        ) : (
                                            <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                                                {filteredVenues?.map((venue: any) => (
                                                    <VenueItem
                                                        key={venue.id}
                                                        venue={venue}
                                                        handleEdit={() => handleEdit(venue)}
                                                        handleDeleteButton={handleDeleteConfirm}
                                                    />
                                                ))}
                                            </tbody>
                                        )}
                                    </table>
                                    <Modal
                                        setIsOpen={setDeleteModel}
                                        isOpen={deleteModel}
                                        title="Delete Confirmation"
                                        handleSubmit={handleDelete}
                                        buttonText="Delete"
                                        danger={true}
                                        loading={false}
                                    >
                                        <p className="text-center bg-red-100 text-red-800 rounded-md border border-red-600 p-3">
                                            Are you sure want to delete this venue?
                                        </p>
                                    </Modal>
                                    {hasMore && <div className="flex justify-center">
                                        <button
                                            type="button"
                                            className="mt-10 mb-10 btn bg-transparent border-gray-300 dark:border-gray-700"
                                            onClick={async () => {
                                                setIsLoadingMore(true);
                                                setIsLoadingMore(false)
                                            }}
                                        >
                                            {IsLoadingMore && <i className="mgc_loading_4_line me-2 animate-spin"></i>}
                                            <span>{IsLoadingMore ? "Loading..." : "Load More"}</span>
                                        </button>
                                    </div>}
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default Venue;
