import React from "react";

interface cardProps {
  data?: any;
  institutionId: string;
}

const ProfileCard: React.FC<cardProps> = ({ data, institutionId }) => {
  return (
    <div className="bg-white rounded-xl shadow-lg relative p-3">
      <div className="w-full h-32 sm:h-40 rounded-xl bg-gradient-to-r from-purple-600 to-pink-600"></div>

      <div className="bg-white/70 backdrop-blur-xl mx-5 sm:mx-9 rounded-lg flex items-center justify-between p-3 sm:p-4 -translate-y-8 sm:-translate-y-9">
        <div className="flex items-center">
          {institutionId ? (
            <img className="w-12 h-12 sm:w-16 sm:h-16 rounded-lg mr-3 border-gray-300 border" src={data.candidateId.photo} alt={data.candidateId.chestNo} />
          ) : (
            <div className="w-12 h-12 sm:w-16 sm:h-16 bg-gray-700 rounded-lg mr-3"></div>
          )}
          <div>
            <h2 className="text-green-500 font-bold text-base sm:text-lg capitalize">
              {data.shortName ? data.shortName : data.username}{" "}
            </h2>
            <p className="text-gray-500 text-xs capitalize">
              {data.username ? data.role.name : data.name}
            </p>
          </div>
        </div>

        {institutionId && (
          <div className="text-center">
            <p className="text-xl sm:text-2xl font-bold">
              {data.candidateId.chestNo}
            </p>
            <p className="text-xs text-gray-400">Institution code</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfileCard;
