import { gql } from "@apollo/client";

export const CREATE_PROGRAM_CURB = gql`
  mutation CreateProgramCurb($input: CreateProgramCurbInput) {
    createProgramCurb(input: $input) {
      id
    }
  }
`;

export const UPDATE_PROGRAM_CURB = gql`
  mutation UpdateProgramCurb(
    $id: ID!,
    $input: UpdateProgramCurbInput
  ) {
    updateProgramCurb(id: $id, input: $input) {
      id
      name
    }
  }
`;

export const DELETE_PROGRAM_CURB = gql`
  mutation DeleteProgramCurb($id: ID!) {
    deleteProgramCurb(id: $id)
  }
`;
