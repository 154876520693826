import React, { useEffect, useRef, useState } from "react";
import ChatItem from "../../components/support/ChatItem";
import MessageBubble from "../../components/support/MessageBubble";
import { useMutation, useQuery } from "@apollo/client";
import { GET_CHATS, MESSAGE_ADDED } from "../../graphql/queries/chat";
import { SEND_MESSAGE } from "../../graphql/mutations/support";
import { useAuth } from "../../context/AuthContext";
import toast from "react-hot-toast";

interface ChatItemProps {
    id: string;
    name: string;
    time: string;
    userId: {
        id: string;
        username: string;
    };
    isPinned?: boolean;
    isUnread?: boolean;
    typingStatus?: string;
    profileImg: string;
    messages: MessageProps[];
}

interface MessageProps {
    sender: {
        id: string;
        username: string;
    }
    time: string;
    status?: string;
    timestamp: string;
    content: string;
    isCurrentUser?: boolean;
}

const SupportPage: React.FC = () => {

    const [publicChats] = useState<any[]>([
        {
            id: "idfortruth",
            name: "Truth Finder",
            time: "09:12 AM",
            profileImg: "img/logo-sm.png",
            isUnread: true,
            messages: [
                {
                    time: "08:34 AM",
                    content: "A Message from Truth Finder.",
                    isCurrentUser: false,
                },
                {
                    time: "08:34 AM",
                    content: "Another Message from Truth Finder.",
                    isCurrentUser: false,
                },
                {
                    time: "08:34 AM",
                    content: "We are happy to hear from you.",
                    isCurrentUser: true,
                },
            ]
        },
        {
            id: "idforanonymous",
            name: "Anonymous User",
            time: "06:25 AM",
            profileImg: "img/logo-sm.png",
            isUnread: true,
            messages: [
                {
                    time: "08:34 AM",
                    content: "A Message from Anonymous User.",
                    isCurrentUser: false,
                },
                {
                    time: "08:34 AM",
                    content: "Another Message from Anonymous User.",
                    isCurrentUser: false,
                },
                {
                    time: "08:34 AM",
                    content: "We are happy to hear from you.",
                    isCurrentUser: true,
                },
            ]
        },
        {
            id: "idforgood",
            name: "Good Hoper",
            time: "03:11 AM",
            profileImg: "img/logo-sm.png",
            messages: [
                {
                    time: "08:34 AM",
                    content: "A Message from Good Hoper.",
                    isCurrentUser: false,
                },
                {
                    time: "08:34 AM",
                    content: "Another Message from Good Hoper.",
                    isCurrentUser: false,
                },
                {
                    time: "08:34 AM",
                    content: "We are happy to hear from you.",
                    isCurrentUser: true,
                },
            ]
        },
    ]);

    const [chats, setChats] = useState<ChatItemProps[]>([]);
    const [isPrivateSelected, setIsPrivateSelected] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedChat, setSelectedChat] = useState("");
    const [chat, setChat] = useState<ChatItemProps>();
    const [newMessage, setNewMessage] = useState("");
    const [isSearchOpen, setIsSearchOpen] = useState(false);

    const chatContainerRef = useRef<HTMLDivElement>(null);

    const {user} = useAuth();

    const { data, refetch, subscribeToMore } = useQuery(GET_CHATS);
    const [sendMessage] = useMutation(SEND_MESSAGE);

    useEffect(() => {
          const unsubscribe = subscribeToMore({
            document: MESSAGE_ADDED,
            updateQuery: (prev, { subscriptionData }) => {
              if (!subscriptionData) return prev;
              refetch();
              const newMessage = subscriptionData.data.messageAdded;
              
              setChats((prevState) => {
                return prevState.map((item: ChatItemProps) => {
                  if (item.id === newMessage.chatId) {
                    return {
                      ...item,
                      messages: item.messages ? [...item.messages, newMessage] : [newMessage],
                    };
                  } else {
                    return item
                  }
                })
              })
              return {
                getChats: prev.getChats?.map((item: ChatItemProps) => {
                  if (item.id === newMessage.chatId?.id) {
                    return {
                      ...item,
                      messages: item.messages ? [...item.messages, newMessage] : [newMessage],
                    };
                  } else {
                    return item
                  }
                }),
              };
            },
          });
    
          // Optionally return unsubscribe function
          return unsubscribe;
      }, [data, subscribeToMore]);


      useEffect(() => {
        if (data?.getChats) {
            const filteredChats = data.getChats.filter((chat: ChatItemProps) => chat?.messages.length > 0);
            const sortedChats = [...filteredChats].sort((a: ChatItemProps, b: ChatItemProps) => {
                
                const bLatestMessageTime = new Date(Number(b.messages?.[b.messages.length - 1]?.timestamp) || 0).getTime();
                const aLatestMessageTime = new Date(Number(a.messages?.[a.messages.length - 1]?.timestamp) || 0).getTime();
                return bLatestMessageTime - aLatestMessageTime; // Descending order
            });
       
            setChats(sortedChats);
        }
    }, [data]);

    useEffect(() => {
        chats?.map((privateChat) => {
            privateChat.id === selectedChat && privateChat.messages && setChat(privateChat);
        });

        publicChats.map((publicChat) => {
            publicChat.id === selectedChat && publicChat.messages && setChat(publicChat);
        });
        
        
    }, [selectedChat, chats]);

    useEffect(() => {
        if (chatContainerRef.current) {
          chatContainerRef.current.scrollTop =
            chatContainerRef.current.scrollHeight;
        }
      }, [chat]);

    const filteredPrivateChats = chats?.filter((privateChat: any) => {
        const matchesSearch = privateChat.userId?.username
            .toLowerCase()
            .includes(searchTerm.toLowerCase());
        return (matchesSearch);
    })

    const filteredPublicChats = publicChats.filter((publicChat: any) => {
        const matchesSearch = publicChat.name
            .toLowerCase()
            .includes(searchTerm.toLowerCase());
        return (matchesSearch);
    })

    const handleSendMessage = async () => {
        if (newMessage.trim() === "") return;

        try {
            await sendMessage({
              variables: {
                chatId: selectedChat,
                senderId: user?.id,
                content: newMessage,
              },
            });
            refetch();
          } catch (error) {
            toast.error("Failed to send message");
            console.log(error);
          }
        // chat?.messages?.push({ time: time, content: newMessage, isCurrentUser: true });
        setNewMessage("");
    };

    const sendMessageHandler = (e: any) => {
        if (newMessage.trim() === "") return;
        if (e.key === 'Enter') {
            handleSendMessage();
        }
    }

    const toggleSearchBar = () => {
        isSearchOpen && setSearchTerm("");
        isSearchOpen ? setIsSearchOpen(false) : setIsSearchOpen(true);
    }

    return (
        <div className="flex h-full gap-4 overflow-hidden">
            <div className="w-1/4 bg-white rounded-xl p-4 space-y-4">
                <header className="relative flex items-center justify-between mb-4">
                    <div className=" flex items-center">
                        <div className="relative mr-2 rounded-full">
                            <img
                                src="img/logo-sm.png"
                                alt="Sibaq Logo"
                                className="w-12 h-12 rounded-full object-cover"
                            />
                            <div className="absolute bottom-1 right-1 w-3 h-3 border-2 border-white rounded-full bg-green-500"></div>
                        </div>
                        <div>
                            <h1 className="text-md font-bold text-black">SIBĀQ</h1>
                            <p className="text-xs text-gray-500">Support Team</p>
                        </div>
                    </div>
                    <div className="absolute right-0 px-3 py-2 flex items-center justify-between rounded-full bg-gray-200">
                        {isSearchOpen && <input type="search" value={searchTerm} onChange={(e) => { setSearchTerm(e.target.value) }} placeholder="Search Chats" className="h-4 border-none bg-transparent focus:outline-none focus:ring-0" />}
                        <i className={`${isSearchOpen ? "mgc_close_line" : "mgc_search_2_line"} text-xl text-gray-300 cursor-pointer`} onClick={toggleSearchBar}></i>
                    </div>
                </header>
                <div className="w-full grid place-items-center">
                    <div className="w-fit rounded-full px-2 py-1.5 flex items-center justify-evenly bg-gray-100">
                        <p className={`py-1 px-4 rounded-full cursor-pointer ${isPrivateSelected && "bg-white"}`}
                            onClick={() => setIsPrivateSelected(true)}
                        >Private</p>
                        <p className={`py-1 px-4 rounded-full cursor-pointer ${!isPrivateSelected && "bg-white"}`}
                            onClick={() => setIsPrivateSelected(false)}
                        >Public</p>
                    </div>
                </div>
                <div>
                    <h2 className="max-h-full text-gray-600 font-semibold mt-4">Messages</h2>
                    {isPrivateSelected ? filteredPrivateChats?.map((chat) => (
                        <ChatItem key={chat.id} {...chat} handleClick={() => setSelectedChat(chat.id)} />
                    )) :
                        filteredPublicChats.map((chat) => (
                            <ChatItem key={chat.id} {...chat} handleClick={() => setSelectedChat(chat.id)} />
                        ))}
                </div>
            </div>

            {chat ? (
                <div className="w-3/4 flex flex-col rounded-xl overflow-hidden">
                    <header className="flex items-center justify-between p-4 bg-white border-b">
                        <div className="flex items-center space-x-2">
                            {chat?.profileImg 
                            ? <img src={chat?.profileImg} alt="Chat Icon" className="w-12 h-12 rounded-full object-cover" />
                            : <div className="w-12 h-12 rounded-full text-2xl font-bold bg-gray-300 flex items-center justify-center">{chat.userId?.username?.[0]?.toUpperCase()}</div>}
                            <div>
                                <h3 className="font-semibold text-black">{chat?.userId?.username}</h3>
                                <span className="text-xs text-green-500">
                                    {chat?.typingStatus ? chat?.typingStatus : ""}
                                </span>
                            </div>
                        </div>
                        <div className="flex space-x-3 text-gray-500">
                            <i className=""></i>
                            <i className="mgc_more_2_fill"></i>
                        </div>
                    </header>
                    <div ref={chatContainerRef} style={{ backgroundImage: "linear-gradient( rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) ), url(img/chat-bg.jpg)" }} className="p-4 space-y-4 overflow-y-scroll bg-gray-50 bg-opacity-5">
                        {chat?.messages?.map((msg, index) => (
                            <MessageBubble key={index} userId={chat.userId} sender={msg.sender} content={msg.content} timestamp={msg.timestamp} />
                        ))}
                    </div>
                    <div className="bg-white border-t p-4 flex items-center">
                        <input
                            type="text"
                            placeholder="Type a message..."
                            value={newMessage}
                            onChange={(e) => setNewMessage(e.target.value)}
                            onKeyDown={sendMessageHandler}
                            className="flex-1 border-none rounded-lg px-4 py-2 focus:outline-none focus:ring-0"
                        />
                        <button
                            onClick={handleSendMessage}
                            className={`text-xl text-blue-400 focus:outline-none ${newMessage.length > 0 ? "block" : "hidden"}`}
                        >
                            <i className="mgc_send_line"></i>
                        </button>
                    </div>
                </div>
            ) :
                (
                    <div className="w-3/4 flex flex-col rounded-xl overflow-hidden bg-white">

                    </div>
                )}
        </div>
    );
};

export default SupportPage;
