import { gql } from "@apollo/client";

export const GET_CANDIDATE_PARTICIPANTIONS = gql`
  query CandidateParticipants($candidateId: ID!) {
    candidateParticipants(candidateId: $candidateId) {
      codeLetter
      isPresent
      institution {
        groupId {
          id
        }
      }
      program {
        id
        name
        programCode
        programCategory {
          name
        }
        skill {
          name
        }
        eliminationRegistrationStatus {
          key
          value
        }
      }
    }
  }
`;

export const GET_CANDIDATES = gql`
  query Candidates(
    $page: Int
    $limit: Int
    $search: String
    $institutionId: ID
    $categoryId: [ID]
    $classId: ID
  ) {
    candidates(
      page: $page
      limit: $limit
      search: $search
      institutionId: $institutionId
      categoryId: $categoryId
      classId: $classId
    ) {
      candidates {
        chestNo 
        name 
        email 
        section { 
          name
        }
        class { 
          name
        }
        category { 
          name
        }
        institution {
          name
        }
      }
      totalPages
      totalCandidates
    }
  }
`;

export const GET_INSTITUTIONS = gql`
  query Institutions {
    institutions {
      id
      name
      shortName
      groupId {
        section {
          id
        }
      }
    }
  }
`;
export const GET_INSTITUTION_BY_ID = gql`
  query Institution($id: ID!) {
    institution(id: $id) {
      shortName
      name
      id
      groupId {
        section {
          id
        }
      }
    }
  }
`;
