import React from "react";
import { Link } from "react-router-dom";
import Private from "../../context/Private";
import { Candidate } from "./CandidatesTable";

interface CandidateProp {
  candidate:Candidate
  handleEdit: () => void;
  handleDeleteButton: (id: string) => void;
  handleView: (candidate: any) => void;
}

const CandidateItem: React.FC<CandidateProp> = ({
  candidate,
  handleEdit,
  handleDeleteButton,
  handleView,
}) => {
  const handleDelete = async () => {
    handleDeleteButton(candidate.id);
  };

  return (
    <tr key={candidate?.id}>
      <td className="whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900 dark:text-gray-200">
        {candidate?.chestNo}
      </td>
      <td className="whitespace-nowrap py-4 pe-3 text-sm">
        <div className="flex items-center">
          <div className="h-10 w-10 flex-shrink-0">
            <img
              className="inline-block h-10 w-10 bg-rose-500 dark:bg-yellow-400 rounded-full border-2 border-white dark:border-gray-700 object-cover"
              src={`${candidate?.admissionNo === "001" ? candidate?.photo : `https://haris740.github.io/sibaq-image-uploads/profiles/${candidate?.chestNo[0]}000/${candidate?.chestNo}.png`}`}
              alt={candidate?.name}
            />
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900 dark:text-gray-200 ">
        <p className="cursor-pointer" onClick={() => handleView(candidate)}>
          {candidate?.name}
        </p>
      </td>
      <td className="whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900 dark:text-gray-200">
        {candidate?.institution?.shortName}
      </td>
      <td className="whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900 dark:text-gray-200">
        {candidate?.admissionNo}
      </td>
      <td className="whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900 dark:text-gray-200">
        {candidate?.class?.name}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        <div
          className={`inline-flex items-center gap-1.5 py-1 px-3 rounded text-xs font-medium ${
            candidate.category.colorCode ? "" : "bg-primary/25 text-primary"
          }`}
          style={
            candidate.category.colorCode
              ? {
                  backgroundColor: `${candidate.category.colorCode}40`,
                  color: candidate.category.colorCode,
                }
              : {}
          }
        >
          {candidate?.category?.name}
        </div>
      </td>

      <td className="whitespace-nowrap py-4 px-3 text-center text-sm font-medium">
        <Private
          permissions={["show-candidates"]}
          element={
            <a href="#" className="me-0.5">
              <i
                className="mgc_eye_2_line text-xl"
                onClick={() => handleView(candidate)}
              ></i>
            </a>
          }
        />
        <Private
          permissions={["update-candidate"]}
          element={
            <Link to={`/candidates/edit/${candidate.id}`} className="me-0.5">
              <i className="mgc_edit_line text-lg" onClick={handleEdit}></i>
            </Link>
          }
        />
        <Private
          permissions={["delete-candidate"]}
          element={
            <a href="#" className="ms-0.5">
              <i className="mgc_delete_line text-xl" onClick={handleDelete}></i>
            </a>
          }
        />
      </td>
    </tr>
  );
};

export default CandidateItem;
