import { gql } from "@apollo/client";

export const CREATE_GROUP = gql`
  mutation CreateGroup($name: String!, $section: ID!) {
    createGroup(input: { name: $name, section: $section }) {
      id
      name
    }
  }
`;

export const UPDATE_GROUP = gql`
  mutation UpdateGroup($id: ID!, $name: String!, $section: ID!) {
    updateGroup(id: $id, input: { name: $name, section: $section }) {
      id
      name
    }
  }
`;

export const DELETE_GROUP = gql`
  mutation DeleteGroup($id: ID!) {
    deleteGroup(id: $id)
  }
`;
