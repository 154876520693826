import { gql, useQuery } from "@apollo/client";
import React, { useEffect, useRef, useState } from "react";

import PageTitle from "../../../components/PageTitle";
import { GET_CATEGORIES } from "../../../graphql/queries/category";
import { GET_PROGRAM_CATEGORIES } from "../../../graphql/queries/programCategory";
import { GET_SKILLS } from "../../../graphql/queries/skill";
import Pagination from "../../../components/UIElements/Pagination";
import FilterModel from "../../../components/UIElements/FilterModel";
import ProgramTable from "../../../components/result/elimination/ProgramTable";
import { ELIMINATION_PROGRAMS } from "../../../graphql/queries/elimination";
import { useReactToPrint } from "react-to-print";
import AllPrint from "../../../components/result/elimination/AllPrint";
import Skeleton from "../../../components/UIElements/Skeleton";

export const ELIMINATION_RESULT_TO_PUBLISH_COUNT_BY_INSITUTION = gql`
  query EliminationResultToPublishCountByInsitution {
    eliminationResultToPublishCountByInsitution {
      count
      shortName
    }
  }
`;

const EliminationPrograms: React.FC = () => {
  const [page, setPage] = useState<number>(0);
  const limit = 10;

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedProgramCategory, setSelectedProgramCategory] = useState("");
  const [selectedSkill, setSelectedSkill] = useState("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [resultStatus, setResultStatus] = useState("");
  const [programs, setPrograms] = useState([]);
  const [groupId, setGroupId] = useState("");
  const [startTime, setStartTime] = useState<null | number>(null);
  const [endTime, setEndTime] = useState<null | number>(null);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const printRef = useRef<HTMLDivElement>(null);

  const {
    data,
    loading: institutionLoading,
    error: institutionError,
  } = useQuery(ELIMINATION_RESULT_TO_PUBLISH_COUNT_BY_INSITUTION, {
    fetchPolicy: "network-only",
  });

  const {
    data: programsData,
    error,
    loading,
    refetch,
  } = useQuery(ELIMINATION_PROGRAMS, {
    variables: {
      page,
      limit,
      search: searchTerm,
      programCategoryId: selectedProgramCategory,
      categoryId: selectedCategory,
      skillId: selectedSkill,
      resultStatus,
      groupId,
      startTime,
      endTime,
    },
    fetchPolicy: "cache-and-network",
  });

  const { data: allProgramsData } = useQuery(ELIMINATION_PROGRAMS, {
    variables: {
      search: searchTerm,
      programCategoryId: selectedProgramCategory,
      categoryId: selectedCategory,
      skillId: selectedSkill,
      resultStatus,
      groupId,
      startTime,
      endTime,
    },
    fetchPolicy: "cache-and-network",
  });

  const handlePrint = useReactToPrint({
    contentRef: printRef,
    documentTitle: "Elimination Results",
    pageStyle: `
      @media print {
        @page {
          size: A4;
          margin: 0;
          padding: 50px 0px;
        }
        body {
          margin: 0;
          padding: 0;
          -webkit-print-color-adjust: exact; 
          print-color-adjust: exact; 
        }
        // .print-container {
        //      background-image: url(/img/valuation-cover.jpg);
        //      background-size: contain;
        //      background-repeat: repeat;
        // }
      }
    `,
  });

  const handlePageChange = (newPage: number) => {
    setPage(newPage - 1);
    refetch({ page: newPage - 1, limit });
  };

  const { data: categoryData } = useQuery(GET_CATEGORIES);
  const { data: programCategoryData } = useQuery(GET_PROGRAM_CATEGORIES);
  const { data: skillData } = useQuery(GET_SKILLS);

  useEffect(() => {
    if (programsData?.eliminationProgram?.programs) {
      setPrograms(programsData?.eliminationProgram?.programs);
    }
  }, [programsData]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  if (error) {
    return <p>{error.message}</p>;
  }

  const filterConfig = [
    {
      field: "resultStatus",
      label: "Result Status",
      type: "select",
      options: [
        // { id: "Not_Entered", name: "Not_Entered" },
        { id: "Entering", name: "Entering" },
        { id: "Prepared", name: "Prepared" },
        { id: "To_Publish", name: "To_Publish" },
        { id: "Published", name: "Published" },
        { id: "Announced", name: "Announced" },
      ],
    },
    {
      field: "updateTime",
      label: "Update Time",
      type: "fromToDate",
      options: [],
    },
    {
      field: "category",
      label: "Category",
      type: "select",
      options: categoryData?.categories,
    },
    {
      field: "programCategory",
      label: "Program Category",
      type: "select",
      options: programCategoryData?.programCategories,
    },
    {
      field: "skill",
      label: "Skill",
      type: "select",
      options: skillData?.skills,
    },
  ];

  const handleApplyFilters = (filterValue: any) => {
    setSelectedCategory(filterValue?.category);
    setSelectedProgramCategory(filterValue?.programCategory);
    setSelectedSkill(filterValue?.skill);
    setResultStatus(filterValue?.resultStatus);
    setStartTime(
      filterValue?.fromDate ? new Date(filterValue?.fromDate).getTime() : null
    );
    setEndTime(
      filterValue?.toDate ? new Date(filterValue?.toDate).getTime() : null
    );
  };

  return (
    <>
      <PageTitle pagetitle={"Programs"} subtitle={"Elimination"} />

      {/* Responsive form controls */}
      <div className="flex flex-col items-center sm:flex-row justify-between gap-2 mb-7">
        <div>
          <div className="flex flex-grow relative">
            <div className="pointer-events-none absolute top-3.5 left-4 text-gray-900 text-opacity-40 dark:text-gray-200">
              <i className="mgc_search_line text-xl"></i>
            </div>
            <input
              type="search"
              className="h-12 w-full border rounded-lg bg-transparent pl-11 text-gray-900 placeholder-gray-500 dark:placeholder-gray-300 dark:text-gray-200 focus:ring-0 sm:text-sm"
              placeholder="Search Programs..."
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                setPage(0);
              }}
            />
          </div>
        </div>

        <div className="flex items-center gap-3">
          {allProgramsData && (
            <button
              onClick={() => handlePrint()}
              className="btn w-full h-full bg-green-300 inline-flex items-center justify-center text-sm font-medium text-green-700 hover:text-white hover:bg-green-500"
            >
              <i className="mgc_print_line text-xl mr-2"></i>
              Print
            </button>
          )}

          <FilterModel
            onApplyFilters={handleApplyFilters}
            filters={filterConfig}
          />
        </div>
      </div>
      <div className="w-full relative h-24">
        <div className="flex gap-4 absolute w-full overflow-y-scroll no-scrollbar">
          {institutionLoading && <Skeleton />}

          {institutionError && <p>{institutionError.message}</p>}

          {[...(data?.eliminationResultToPublishCountByInsitution || [])]
            .sort((a: any, b: any) => b.count - a.count)
            .map((institution: any) => (
              <div
                className="h-20 bg-gray-500 shadow-md rounded px-2 pt-4"
                key={institution.shortName}
              >
                <h3 className="whitespace-nowrap text-md text-white font-semibold">
                  {institution.shortName}
                </h3>
                <h3 className="whitespace-nowrap text-xl text-white font-bold text-center">
                  {institution.count}
                </h3>
              </div>
            ))}
        </div>
      </div>

      {/* Responsive program cards */}

      {!programsData?.eliminationProgram?.programs?.length && !loading && (
        <p className="text-center text-gray-500">No programs found.</p>
      )}

      {loading ? (
        <div className="flex justify-center items-center">
          <button
            type="button"
            className="btn bg-transparent w-fit border-gray-300 dark:border-gray-700"
          >
            <i className="mgc_loading_4_line me-2 animate-spin"></i>
            <span>Loading</span>
          </button>
        </div>
      ) : (
        <div className="flex flex-auto flex-col mt-6">
          {programsData?.eliminationProgram?.programs?.length ? (
            <ProgramTable
              programs={programs}
              setGroupId={setGroupId}
              groupId={groupId}
            />
          ) : (
            ""
          )}
          {programsData?.eliminationProgram?.totalPages ? (
            <Pagination
              totalPages={programsData?.eliminationProgram?.totalPages}
              page={page + 1}
              onPageChange={handlePageChange}
              className="my-8"
            />
          ) : (
            ""
          )}
        </div>
      )}
      {allProgramsData && (
        <div className="hidden">
          <div ref={printRef} className="print-container">
            <div
              className="mt-[-50px]"
              style={{
                backgroundImage: "url('/img/sibaq-logo-watermark.jpg')",
                backgroundSize: "contain",
                backgroundRepeat: "repeat",
              }}
            >
              <div className="letter-head">
                <img className="mx-auto" src="/img/sibaq-letter-head.jpg" />
              </div>
              <h3 className="text-xl text-center mt-14 font-medium font-cambria text-black mb-4">
                SIBAQ'25 ELIMINATION RESULTS
              </h3>
              <AllPrint
                programIds={allProgramsData?.eliminationProgram?.programs?.map(
                  (program: { id: string }) => program.id
                )}
                groupId={groupId}
              />
              <div className="letter-head mt-8">
                <img className="mx-auto" src="/img/sibaq-letter-foot.jpg" />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EliminationPrograms;
