import React from "react";

interface AddButtonProps {
  text: string;
  onClick?: () => void;
}

const AddButton: React.FC<AddButtonProps> = ({ text, onClick }) => {
  return (
    <button 
        className="bg-gray-200 px-3 py-1 text-xs text-blue-600 rounded hover:bg-blue-100"
        onClick={onClick}
    >
      <i className="mgc_add_circle_line text-sm pr-2"></i>
      {text}
    </button>
  );
};

export default AddButton;
