import { gql } from "@apollo/client";

export const GET_PROGRAM_PARTICIPANTS = gql`
  query GetProgramParticipants($programId: ID!, $groupId: ID, $clusterId: ID) {
    programParticipants(
      programId: $programId
      groupId: $groupId
      clusterId: $clusterId
    ) {
      id
      codeLetter
      candidate {
        id
        name
        photo 
        admissionNo
        chestNo 
        gender
        dob
        email
        category {
          id
          name
        }
        class {
          id
          name
        }
        institution {
          id
          name 
          shortName
        }
      }      
    }
  }
`;

export const GET_PROGRAM_BY_ID = gql`
  query GetProgramById($id: ID!) {
    program(id: $id) {
      id
      name
      programCode
      concept
      duration
      skill {
        name
      }
      noOfCandidates
      category {
        id
        name
      }
      programCategory {
        name
      }
      eliminationRegistrationStatus {
        key
        value
      }
      eliminationDate {
        key
        value
      }
      eliminationEndingTime {
        key
        value
      }
      eliminationStartingTime {
        key
        value
      }
      eliminationVenue {
        key
        value {
          id
          name
        }
      }
      eliminationResultStatus {
        key
        value
      }
      
    }
  }
`;

export const GET_PROGRAMS = gql`
  query GetPrograms(
    $page: Int
    $limit: Int
    $search: String
    $programCategoryId: ID
    $categoryId: ID
    $skillId: ID
  ) {
    programs(
      page: $page
      limit: $limit
      search: $search
      programCategoryId: $programCategoryId
      categoryId: $categoryId
      skillId: $skillId
    ) {
      programs {
        category {
          id
          name
        }
        concept 
        duration 
        eliminationResultStatus {
          key
          value
        }
        publishTimeElimination {
          key
          value
        }
        eliminationVenue {
          key
          value {
            id
            name
          }
        }
        tools
        eliminationDate {
          key
          value
        }
        eliminationEndingTime {
          key
          value
        }
        eliminationStartingTime {
          key
          value
        }
        finalDate
        finalEndingTime
        finalResultStatus
        finalStartingTime
        finalVenue {
          id
          name
        }
        isItemRegistrable
        isProposalSubmission
        isRegistrable
        isStarred
        maxSelection
        mode
        id
        name
        noOfCandidates
        programCategory {
          name
        }
        programCode
        publishTimeFinal
        section {
          name
        }
        status
        type
        skill {
          name
        }
      }
      totalPages
    }
  }
`;

export const GET_CATEGORIES = gql`
  query Categories {
    categories {
      id
      name
    }
  }
`;

export const GET_PROGRAM_CATEGORIES = gql`
  query ProgramCategories {
    programCategories {
      id
      name
    }
  }
`;

export const GET_PARTICIPATIONS = gql`
  query GetParticipations {
    participations {
      program {
        name
        category {
          id
          name
        }
        programCategory {
          id
          name
        }
        skill {
          id
          name
        }
      }
      institution {
        groupId {
          id
          name
        }
        cluster {
          id
          name
        }
      }
    }
  }
`;

export const GET_SKILLS = gql`
  query GetSkills {
    skills {
      id
      name
    }
  }
`;

export const GET_GROUPS = gql`
  query Groups {
    groups {
      id
      name
    }
  }
`;

export const GET_CLUSTERS = gql`
  query Clusters {
    clusters {
      id
      name
    }
  }
`;
