import React from "react";

interface CardProps {
    participantId: string,
    chestNo: string;
    name: string,
    institution: string,
    category: string,
}

const Card: React.FC<CardProps> = ({
    participantId,
    chestNo,
    name,
    institution,
    category,
}) => {
    return (
        <tr key={participantId}>
            <td className="whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900 dark:text-gray-200">
                {chestNo}
            </td>
            <td className="whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900 dark:text-gray-200">
                {name}
            </td>
            <td className="whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900 dark:text-gray-200">
                {institution}
            </td>
            <td className="whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900 dark:text-gray-200">
                {category}
            </td>
        </tr>
    );
};

export default Card;
