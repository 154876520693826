import React, { useEffect, useRef, useState } from "react";
import { UPDATE_GRADE, UPDATE_POSITION } from "../../graphql/mutations/mark";
import { useMutation } from "@apollo/client";
import toast from "react-hot-toast";

interface CardProps {
    item: any;
    textClass: string;
    title: string;
    category?: string;
    mark: string;
    percentage?: number;
    isGrade?: boolean
}

const MarkCard: React.FC<CardProps> = ({ textClass, title, category, mark, item, percentage, isGrade }) => {
    const [inputMark, setInputMark] = useState(mark);
    const [inputPercentage, setInputPercentage] = useState<number>(percentage || 0);
    const [isOpen, setIsOpen] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null); // Create a ref for the input

    useEffect(() => {
        if (isOpen && inputRef.current) {
            inputRef.current.focus(); // Focus the input when it opens
        }
    }, [isOpen]);

    const [updatePosition, { loading: updatePositionLoading }] =
        useMutation(UPDATE_POSITION);
    const [updateGrade, { loading: updateGradeLoading }] =
        useMutation(UPDATE_GRADE);

    const handleSubmit = async () => {
        if (!inputMark?.trim()) {
            toast.error("Please fill in all fields.");
            return;
        }
        
        try {
            if (isGrade) {
                await updateGrade({
                    variables: {
                        id: item.id,
                        input: {
                            mark: parseInt(inputMark?.trim()),
                            percentage: inputPercentage,
                        },
                    },
                });
            } else {
                await updatePosition({
                    variables: {
                        id: item.id,
                        input: {
                            mark: parseInt(inputMark?.trim()),
                        },
                    },
                });
            }
            setIsOpen(false);
            toast.success("Mark updated successfully!");
        } catch (e) {
            console.log("Failed to update the mark", e)
            toast.error("Failed to update the mark")
        }
    }

    const handleClose = () => {
        setInputMark(mark);
        setIsOpen(false);
    }


    return (
        <div className="bg-gray-100 flex justify-between items-center gap-1">
            <div className="flex flex-grow">
                {isOpen ?
                    <input ref={inputRef} onChange={(e) => setInputMark(e.target.value)} type="text" className="w-14 h-10 bg-gray-400 rounded-md mr-3 text-xl text-white" value={inputMark} />
                    : <div className="w-10 h-10 bg-gray-400 rounded-md mr-3 grid place-items-center text-xl text-white">{inputMark}</div>
                }
                <div className="mr-3">
                    <div className={`${textClass} capitalize font-semibold text-gray-700`}>{title}</div>
                    <div className="inline text-xs capitalize text-gray-500">{category}</div>
                    {/* <div className="inline"> &#x2022; </div>
                            <div className="inline text-xs text-gray-500">{subTitle}</div> */}
                </div>
                {percentage && (isOpen ?
                    <input onChange={(e) => setInputPercentage(Number(e.target.value) || 0)} type="text" className="w-14 h-10 bg-gray-400 rounded-md mr-3 text-xl text-white" value={inputPercentage} />
                    : <div className="w-10 h-10 bg-gray-400 rounded-md mr-1 grid place-items-center text-xl text-white">{inputPercentage}</div>
                )}
                {percentage && <span className="text-xl text-gray-500">%</span>}
            </div>
            <div className="flex items-center">
                <button className="text-gray-400 text-xl">
                    {isOpen ?
                        <div className="inline-flex gap-1">
                            <i className="mgc_close_circle_line hover:text-gray-700" onClick={handleClose}></i>
                            <i className={`${(updatePositionLoading || updateGradeLoading) ? "mgc_loading_line" : "mgc_check_circle_line"} mr-2 hover:text-gray-700`} onClick={handleSubmit}></i>
                        </div>
                        : <i className="mgc_edit_3_line mr-2 hover:text-gray-700" onClick={() => setIsOpen(true)}></i>
                    }
                </button>
            </div>
        </div>
    )
}

export default MarkCard;