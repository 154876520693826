import { gql } from "@apollo/client";

export const GET_PROGRAM_CATEGORIES = gql`
  query ProgramCategories {
    programCategories {
      id
      name
      details
    }
  }
`;
