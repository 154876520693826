import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";


import Modal from "../UIElements/Model";
import Input from "../ui-elements/Input";
import { GET_HOSTS } from "../../graphql/queries/host";
import { CREATE_HOST, DELETE_HOST, UPDATE_HOST } from "../../graphql/mutations/host";
import toast from "react-hot-toast";
import Card from "./Card";
import CardStructure from "./CardStructure";
import Skeleton from "../UIElements/Skeleton";
import { GET_CATEGORIES } from "../../graphql/queries/category";
import { GET_GROUPS } from "../../graphql/queries/group";
import Select from "../ui-elements/Select";
import DeleteModal from "../UIElements/DeleteModal";

const Host: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [host, setHost] = useState<string>("");
  const [place, setPlace] = useState<string>("");
  const [categories, setCategories] = useState<string[]>([]);
  const [group, setGroup] = useState<string>("");
  const [editHost, setEditHost] = useState<any>(null);
  const [deleteModel, setDeleteModel] = useState<boolean>(false);
  const [deleteHostId, setDeleteHostId] = useState<string>(""); 

  useEffect(() => {
    if (editHost) {
      setHost(editHost.name);
      setPlace(editHost.place);
      setCategories(editHost.categories?.map((item: any) => item.id));
      setGroup(editHost.group?.id);
      setIsOpen(true);
    }
  }, [editHost]);

  // QUERY
  const {
    data: hostData,
    loading: hostLoading,
    error: hostError,
    refetch,
  } = useQuery(GET_HOSTS);
  const { data: categoryData } = useQuery(GET_CATEGORIES);
  const { data: groupData } = useQuery(GET_GROUPS);

  // MUTATION
  const [createHost, { loading: createHostLoading }] =
    useMutation(CREATE_HOST);
  const [updateHost, { loading: updateHostLoading }] =
    useMutation(UPDATE_HOST);
  const [deleteHost] =
    useMutation(DELETE_HOST);

  if (hostLoading) return <Skeleton />;
  if (hostError) return <p>Error loading hosts: {hostError.message}</p>;

  const handleAddHost = async () => {
    setEditHost(null);
    clearInput();
    setIsOpen(true);
  };

  const handleEditHost = async (item: any) => {
    setEditHost(item);
  };

  const handleDeleteConfirm = async (id: string) => {
    setDeleteModel(true);
    setDeleteHostId(id);
  }

  const handleDelete = async() => {
    try {
      let loading = toast.loading("Deleting host...");
      await deleteHost({ variables: {id: deleteHostId}});
      toast.dismiss(loading)
      toast.success("Host deleted successfully!");
      setDeleteModel(false);
      clearInput();
      refetch();
    } catch (error) {
      console.error("Failed to delete the host", error);
      toast.error("Failed to delete the host")
    }
  }

  const clearInput = () => {
    setHost("");
    setPlace("");
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!host.trim() || !place.trim()) {
      toast.error("Please fill in all fields.");
      return;
    }

    try {
      if (editHost) {
        await updateHost({
          variables: {
            id: editHost.id,
            name: host.trim(),
            place: place.trim(),
            categories: categories,
            group: group
          },
        });
        toast.success("Host updated successfully!");
      } else {  
        await createHost({
          variables: {
            name: host.trim(),
            place: place.trim(),
            categories: categories,
            group: group
          },
        });
        toast.success("Host created successfully!");
      }

      setIsOpen(false);
      clearInput();
      refetch();
    } catch (error) {
      console.error(
        editHost ? `Failed to update the host:` : `Failed to create the host:`,
        error
      );
      toast.error(
        editHost ? `Failed to update the host:` : `Failed to create the host:`
      );
    }
  };

  return (
    <>
      <CardStructure
        Heading="Host"
        addButton="Add"
        addButtonOnCLick={handleAddHost}
        data={hostData.hosts}
        renderCard={(item: any) => (
          <Card
            item={item}
            title={item.name}
            subTitle={item.place}
            handleEditButton={handleEditHost}
            handleDeleteButton={handleDeleteConfirm}
            textClass="text-sm"
          />
        )}
      />
      <Modal
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        title={editHost ? "Edit Host" : "Add Host"}
        handleSubmit={handleSubmit}
        loading={editHost ? updateHostLoading : createHostLoading}
      >
        <form className="flex flex-col gap-5" onSubmit={handleSubmit}>
          <Input
            placeholder="Enter Host Name"
            label="name"
            value={host}
            onChange={(e) => setHost(e.target.value)}
          />
          <Input
            placeholder="Enter Place"
            label="place"
            value={place}
            onChange={(e) => setPlace(e.target.value)}
          />
          <div className="grid grid-cols-4 gap-6">
            <label htmlFor="categories" className="text-sm font-medium text-gray-700">Categories</label>
            <div id="categories" className="md:col-span-3 flex flex-wrap gap-2">
              {categoryData?.categories?.map((category: any) => (
                <div key={category.id} className="flex items-center">
                  <input
                    id={`category-${category.id}`}
                    name="categories"
                    type="checkbox"
                    checked={categories.includes(category.id)}
                    value={category.id}
                    onChange={(e) => setCategories(e.target.checked ? [...categories, category.id] : categories.filter(id => id !== category.id))}
                    className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                  />
                  <label htmlFor={`category-${category.id}`} className="ml-2 block text-sm font-medium text-gray-700">
                    {category.name}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <Select
            label="Group"
            value={group}
            options={groupData?.groups}
            onChange={(e) => setGroup(e.target.value)}
          />
        </form>
      </Modal>
      <DeleteModal
        setIsOpen={setDeleteModel}
        isOpen={deleteModel}
        handleDelete={handleDelete}
      />
    </>
  );
};

export default Host;
