import React, { useState } from "react";
import Card from "./Card";
import Input from "../ui-elements/Input";
import Modal from "../UIElements/Model";
import Skeleton from "../UIElements/Skeleton";
import toast from "react-hot-toast";
import { GET_SECTIONS } from "../../graphql/queries/section";
import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_SECTION } from "../../graphql/mutations/Section";

const Sections: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState("");
  const [chessNoPrefix, setChessNoPrefix] = useState("");
  const [year, setYear] = useState("");
  const [editSection, setEditSection] = useState<any>(null);


  // QUERY
  const { data, loading, error, refetch } = useQuery(GET_SECTIONS);

  // MUTATION
  const [updateSection, { loading: updateLoading }] =
    useMutation(UPDATE_SECTION);

  if (loading) return <Skeleton />;

  if (error) return <p>Error loading sections</p>;



  const handleEditSection = async (item: any) => {
    setEditSection(item);
    setName(item.name);
    setChessNoPrefix(item.chestNoPrefix);
    setYear(item.year)
    setIsOpen(true);
  };

  const clearInput = () => {
    setName("");
    setChessNoPrefix("");
    setYear("")
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!name.trim() || !year?.trim()) {
      toast.error("Please fill in all fields.");
      return;
    }

    try {
      await updateSection({
        variables: {
          id: editSection.id,
          name: name.trim(),
          chestNoPrefix: chessNoPrefix?.trim(),
          year: year.trim(),
        },
      });
      toast.success("Section updated successfully!");

      setIsOpen(false);
      clearInput();
      refetch();
    } catch (error) {
      console.error(
        `Failed to update the Section:${error}`
      );
      toast.error(`Failed to update the Section`);
    }
  };

  if (loading) return <Skeleton />;

  if (error) return <p>Error loading Section</p>;


  const renderCard = (
    item: { name: string; chestNoPrefix?: string; year?: string },
    key: number
  ) => (
    <Card
      key={key}
      item={item}
      title={item.name}
      subTitle={`Chest No Prefix: ${item.chestNoPrefix}`}
      textClass="text-base"
      notDelete
      handleEditButton={handleEditSection}
    />
  );


  return (
    <div className="bg-white py-4 px-4 rounded-lg h-fit-content">
      <div className="flex justify-between items-center mb-2">
        <h2 className="text-xl">Sections</h2>
      </div>
      <div className="space-y-4 bg-gray-100 h-[250px] rounded-xl no-scrollbar overflow-auto px-3 py-4">
        {data.sections.map((section: any, index: number) => renderCard(section, index))}
      </div>
      <Modal
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        title="Edit Section"
        handleSubmit={handleSubmit}
        loading={updateLoading}
      >
        <form className="flex flex-col gap-3" onSubmit={handleSubmit}>
          <Input
            placeholder="Enter Name"
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Input
            placeholder="Enter Chess No Series"
            label="Chess No Prefix"
            value={chessNoPrefix}
            onChange={(e) => setChessNoPrefix(e.target.value)} 
          />
          <Input
            placeholder="Enter Year"
            label="Year"
            value={year}
            onChange={(e) => setYear(e.target.value)} 
          />
        </form>
      </Modal>
    </div>
  );
};

export default Sections;
