import React, { useEffect, useState } from "react";
import Modal from "../UIElements/Model";
import Input from "../ui-elements/Input";
import { useMutation, useQuery } from "@apollo/client";
import { GET_SKILLS } from "../../graphql/queries/skill";
import Card from "./Card";
import CardStructure from "./CardStructure";
import { CREATE_SKILL, DELETE_SKILL, UPDATE_SKILL } from "../../graphql/mutations/skill";
import toast from "react-hot-toast";
import Skeleton from "../UIElements/Skeleton";
import DeleteModal from "../UIElements/DeleteModal";

const Skill: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [editSkill, setEditSkill] = useState<any>(null);
  const [deleteModel, setDeleteModel] = useState<boolean>(false);
  const [deleteSkillId, setDeleteSkillId] = useState<string>("");

  useEffect(() => {
    if (editSkill) {
      setName(editSkill.name);
    }
  }, [editSkill]);

  // QUERY
  const { data, loading, error, refetch } = useQuery(GET_SKILLS);

  // MUTATION
  const [createSkill, { loading: createLoading }] = 
    useMutation(CREATE_SKILL);
  const [updateSkill, { loading: updateLoading }] = 
    useMutation(UPDATE_SKILL);
  const [deleteSkill] =
    useMutation(DELETE_SKILL);

  if (loading) return <Skeleton />;

  if (error) return <p>Error loading sections</p>;

  const handleAddSkill = async () => {
    setEditSkill(null)
    clearInput();
    setIsOpen(true);
  };

  const handleEditSkill = async (item: any) => {
    setEditSkill(item);
    setName(item.name);
    setIsOpen(true);
  };

  const handleDeleteConfirm = async (id: string) => {
    setDeleteModel(true);
    setDeleteSkillId(id);
  }

  const handleDelete = async() => {
    try {
      await deleteSkill({ variables: {id: deleteSkillId}});
      toast.success("Skill deleted successfully!");
      setDeleteModel(false);
      clearInput();
      refetch();
    } catch (error) {
      console.error("Failed to delete the skill", error);
      toast.error("Failed to delete the skill")
    }
  }

  const clearInput = () => {
    setName("");
  };


  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!name.trim()) {
      toast.error("Please fill in all fields.");
      return;
    }

    try {
      if (editSkill) {
        await updateSkill({
          variables: {
            id: editSkill.id,
            name: name.trim(),
          },
        });
        toast.success("Category updated successfully!");
      } else {
        await createSkill({
          variables: {
            name: name.trim(),
          },
        });
        toast.success("Skill created successfully!");
      }

      setIsOpen(false);
      clearInput();
      refetch();
    } catch (error) {
      console.error(
        editSkill
          ? `Failed to update the skill:`
          : `Failed to create the skill`,
        error
      );
      toast.error(
        editSkill
          ? `Failed to update the skill:`
          : `Failed to create the skill`
      );
    }
  };

  if (loading) return <Skeleton />;

  if (error) return <p>Error loading Skills</p>;

  return (
    <>
      <CardStructure
        Heading="Skills"
        addButton="Add"
        addButtonOnCLick={handleAddSkill}
        data={data.skills}
        renderCard={(item: any) => (
          <Card
            title={item.name}
            subTitle={item.name}
            item={item}
            handleEditButton={handleEditSkill}
            handleDeleteButton={handleDeleteConfirm}
            textClass="text-sm"
          />
        )}
      />
      <Modal
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        title="Add Skill"
        handleSubmit={handleSubmit}
        loading={editSkill ? updateLoading : createLoading}
      >
        <form className="flex flex-col gap-3" onSubmit={handleSubmit}>
          <Input
            placeholder="Enter Name"
            label="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </form>
      </Modal>
      <DeleteModal
        setIsOpen={setDeleteModel}
        isOpen={deleteModel}
        handleDelete={handleDelete}
      />
    </>
  );
};

export default Skill;
