import React from "react";
import { useQuery } from "@apollo/client";
import { GET_HOSTS } from "../../graphql/queries/host";
import Skeleton from "../UIElements/Skeleton";

interface HostInputProps {
  host: string;
  setHost: (host: string) => void;
}

const HostInput: React.FC<HostInputProps> = ({ host, setHost }) => {
  const { data, loading, error } = useQuery(GET_HOSTS);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setHost(e.target.value);
  };

  if (loading) return <Skeleton />;
  if (error) return <p>Error loading hosts</p>;

  return (
    <div className="mb-3">
      <label
        htmlFor="hostSelect"
        className="text-gray-800 text-sm font-medium inline-block mb-2"
      >
        Select Host
      </label>
      <select
        id="hostSelect"
        className="form-select"
        value={host}
        onChange={handleChange}
        required
      >
        <option value="" disabled>
          Select a Host
        </option>
        {data.hosts.map((host: any) => (
          <option key={host.id} value={host.id}>
            {host.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default HostInput;
