import { gql } from "@apollo/client";

export const ELIMINATION_PROGRAMS = gql`
  query EliminationProgram(
    $page: Int
    $limit: Int
    $search: String
    $programCategoryId: ID
    $categoryId: ID
    $skillId: ID
    $groupId: ID
    $resultStatus: String
    $startTime: Date
    $endTime: Date
  ) {
    eliminationProgram(
      page: $page
      limit: $limit
      search: $search
      programCategoryId: $programCategoryId
      categoryId: $categoryId
      skillId: $skillId
      groupId: $groupId
      resultStatus: $resultStatus
      startTime: $startTime
      endTime: $endTime
    ) {
      programs {
        id
        name
        programCode
        isGroupwiseResult
        category {
          name
        }
        eliminationResultStatus {
          key
          value
        }
        publishTimeElimination {
          key
          value
        }
      }
      totalPages
      totalPrograms
    }
  }
`;

export const ELIMINATION_RESULT_COUNT_BY_STATUS = gql`
  query Query($groupId: ID!, $status: String!) {
    eliminationResultCountByStatus(groupId: $groupId, status: $status)
  }
`;

export const ELIMINATION_RESULT_COUNT_BY_STATUS_WITH_CATEGORY = gql`
  query EliminationResultCountByStatusWithCategory {
    eliminationResultCountByStatusWithCategory {
      name
      data
    }
  }
`;

export const ELIMINATION_RESULT_BY_PARTICIPATION = gql`
  query EliminationResultByParticipation($participationId: ID!) {
    eliminationResultByParticipation(participationId: $participationId) {
      id
      participation_id {
        id
        is_selected
        codeLetter
      }
      point1
      point2
      point3
      point4
      point5
      remarks
    }
  }
`;

export const PARTICIPATION_WITH_ELIMINATION_RESULT = gql`
  query ParticipationWithEliminationResult($programId: ID!, $groupId: ID) {
    ParticipationWithEliminationResult(
      programId: $programId
      groupId: $groupId
    ) {
      _id
      is_selected
      codeLetter
      eliminationResult {
        id
        participation_id {
          id
          codeLetter
        }
        point1
        point2
        point3
        point4
        point5
        remarks
      }
      program {
        id
        name
      }
      institution {
        id
        name
      }
      candidate {
        id
        name
        chestNo
        institution {
          id
          name
          shortName
        }
      }
    }
  }
`;
