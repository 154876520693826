import React, { useEffect } from "react";
import toast from "react-hot-toast";

interface ScoreRowProps {
  id: string;
  participationId: string;
  codeLetter: string;
  mark1: number;
  mark2: number;
  mark3: number;
  total: number;
  percentage: number;
  remarks: string;
}

interface CandidateProps {
  serialNo: number;
  chestNumber: string;
  candidateName: string;
  institution: string;
  codeLetter: string;
  scores: ScoreRowProps[][];
  setScores: (scores: any[]) => void;
  participationId: string;
  selectedCandidates: any[];
  maxMark: number[];
  index: number;
  duplicateTotals: any;
  handleKeyDown: (
    e: React.KeyboardEvent<HTMLInputElement>,
    idx: number
  ) => void;
  inputRefs: React.MutableRefObject<(HTMLInputElement | null)[]>;
  inputDisable: boolean;
  maxTotalMark: number;
  grades: any[];
}

const Candidate: React.FC<CandidateProps> = ({
  serialNo,
  chestNumber,
  candidateName,
  institution,
  codeLetter,
  scores,
  setScores,
  participationId,
  selectedCandidates,
  maxMark,
  index,
  duplicateTotals,
  handleKeyDown,
  inputRefs,
  inputDisable,
  maxTotalMark,
  grades,
}) => {
  const calculateTotal = (score?: any) => {
    if (!score) return 0;
    return (score.mark1 || 0) + (score.mark2 || 0) + (score.mark3 || 0);
  };

  useEffect(() => {
    if (maxTotalMark > 0) {
      const updatedScores = [...scores];

      // Update percentages for all scores
      updatedScores[index] = updatedScores[index]?.map((score) => ({
        ...score,
        percentage: calculatePercentage(score.total), // Recalculate percentage based on new maxTotalMark
      }));

      setScores(updatedScores);
    }
  }, [maxTotalMark]);

  const calculatePercentage = (total: number) => {
    if (!maxTotalMark) return 0;
    if (maxTotalMark <= 0) return 0;

    return maxTotalMark > 0 ? Math.round((total / maxTotalMark) * 100) : 0;
  };

  const handleScoreChange = (
    participationId: string,
    field: keyof ScoreRowProps,
    value: number
  ) => {
    const updatedScores = scores[index] ? [...scores[index]] : []; // Create a shallow copy of the array

    if (value > maxMark[parseInt(field.slice(4)) - 1]) {
      toast.error(
        `Maximum mark for ${field} is ${maxMark[parseInt(field.slice(4)) - 1]}`
      );
      return;
    }

    if (value < 0) {
      toast.error(`Marks cannot be negative.`);
      return;
    }

    const existingScoreIndex = updatedScores.findIndex(
      (score) => score.participationId === participationId
    );

    if (existingScoreIndex !== -1) {
      // Recalculate total first before updating the field
      const updatedScore = updatedScores[existingScoreIndex];

      // Calculate new total based on updated field
      const newTotal = calculateTotal({
        ...updatedScore,
        [field]: value, // Temporarily set the new value for the field
      });

      // Update the specific field and set the new total
      updatedScores[existingScoreIndex] = {
        ...updatedScore,
        [field]: value,
        total: newTotal, // Set the new total before updating the specific field
        percentage: calculatePercentage(newTotal),
      };
    } else {
      // Add new score if not found
      updatedScores.push({
        id: "",
        participationId,
        codeLetter: "",
        mark1: 0,
        mark2: 0,
        mark3: 0,
        total: value,
        percentage: calculatePercentage(value),
        remarks: "",
        [field]: value,
      });
    }
    const allScores = [...scores];
    allScores[index] = updatedScores;
    setScores(allScores);
  };

  return (
    <tr
      className={`  ${
        scores[index]?.find(
          (score) => score.participationId === participationId
        ) &&
        duplicateTotals[
          scores[index]?.find(
            (score) => score.participationId === participationId
          )!["total"]
        ] > 1
          ? "bg-yellow-100"
          : "bg-white"
      } border-b border-gray-200`}
    >
      <td className="px-4 py-2 text-center">{serialNo}</td>
      <td className="px-4 py-2 text-center font-bold">{chestNumber}</td>
      <td className="px-4 py-2 text-center font-bold">{codeLetter}</td>
      <td className="px-4 py-2 flex items-center space-x-2 w-max">
        <div>
          <p className="font-semibold">{candidateName}</p>
          <p>{institution}</p>
        </div>
      </td>

      {["mark1", "mark2", "mark3"].map((mark, idx) => (
        <td className="px-4 py-2 text-center" key={idx}>
          <input
            type="text"
            pattern="[0-9]"
            className={`w-full p-1 border rounded text-center ${
              inputDisable && "cursor-not-allowed"
            }`}
            disabled={inputDisable}
            value={
              scores[index]?.find(
                (score) => score.participationId === participationId
              )
                ? scores[index]?.find(
                    (score) => score.participationId === participationId
                  )![mark as keyof ScoreRowProps] || 0
                : 0
            }
            onChange={(e) =>
              handleScoreChange(
                participationId,
                mark as keyof ScoreRowProps,
                parseInt(e.target.value) || 0
              )
            }
            ref={(el) => (inputRefs.current[serialNo * 3 + idx] = el)}
            onKeyDown={(e) => handleKeyDown(e, serialNo * 3 + idx)}
          />
        </td>
      ))}
      <td className="px-4 py-2 text-center font-bold">
        {scores[index]?.find(
          (score) => score.participationId === participationId
        )
          ? scores[index]?.find(
              (score) => score.participationId === participationId
            )!["total"] || 0
          : 0}
      </td>

      <td className="px-4 py-2 text-center font-bold">
        {scores[index]?.find(
          (score) => score.participationId === participationId
        )
          ? scores[index]?.find(
              (score) => score.participationId === participationId
            )!["percentage"] || 0
          : 0}
        %
      </td>

      <td className="px-4 py-2 text-center font-bold">
        {(() => {
          const participantScore = scores[index]?.find(
            (score) => score.participationId === participationId
          );
          const percentage = participantScore?.percentage || 0;

          // Find the grade that matches the percentage threshold
          const grade = grades
            ?.filter((g) => percentage >= g.percentage)
            ?.sort((a, b) => b.percentage - a.percentage)[0]?.name;

          return grade || "N/A";
        })()}
      </td>

      <td className="p-3">
        <div
          className={`inline-flex items-center gap-1.5 py-1 px-3 rounded text-xs font-medium ${
            selectedCandidates.some(
              (candidate) => candidate.participationId === participationId
            )
              ? "bg-green-300 text-green-700"
              : "bg-red-300 text-red-700"
          }`}
        >
          {selectedCandidates.some(
            (candidate) => candidate.participationId === participationId
          )
            ? "Selected"
            : "Unselected"}
        </div>
      </td>
    </tr>
  );
};

export default Candidate;
