import React, { Dispatch, SetStateAction } from "react";

interface SearchModalProps {
  isOpen: boolean;
}

interface SearchButtonProps {
  isSearchOpen: boolean;
  setSearchOpen: Dispatch<SetStateAction<boolean>>;
}

const SearchModal: React.FC<SearchModalProps> = ({ isOpen }) => {
  return (
    // {/* Topbar Search Modal */}
    <div
      id="topbar-search-modal"
      className={`fc-modal fixed w-full h-full top-0 start-0 z-50 ${
        isOpen ? "open" : "hidden"
      }`}
    >
      <div className="fc-modal-open:opacity-100 fc-modal-open:duration-500 opacity-0 transition-all sm:max-w-lg sm:w-full m-12 sm:mx-auto">
        <div className="mx-auto max-w-2xl overflow-hidden rounded-xl bg-white shadow-2xl transition-all dark:bg-slate-800">
          <div className="relative">
            <div className="pointer-events-none absolute top-3.5 start-4 text-gray-900 text-opacity-40 dark:text-gray-200">
              <i className="mgc_search_line text-xl"></i>
            </div>
            <input
              type="search"
              className="h-12 w-full border-0 bg-transparent ps-11 pe-4 text-gray-900 placeholder-gray-500 dark:placeholder-gray-300 dark:text-gray-200 focus:ring-0 sm:text-sm"
              placeholder="Search..."
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const SearchButton: React.FC<SearchButtonProps> = ({
  isSearchOpen,
  setSearchOpen,
}) => {
  const toggleSearch = () => {
    setSearchOpen(!isSearchOpen);
    showBackdrop();
  };

  const showBackdrop = () => {
    const backdrop = document.createElement("div");
    backdrop.id = "backdrop";
    backdrop.className =
      "transition-all fixed inset-0 z-40 bg-gray-900 bg-opacity-50 dark:bg-opacity-80";
    document.body.appendChild(backdrop);
    backdrop.addEventListener("click", () => {
      setSearchOpen(false);
      document.documentElement.classList.remove("sidenav-enable");
      backdrop.remove();
    });
  };

  document
    .getElementById("topbar-search-modal")
    ?.addEventListener("click", () => {
      setSearchOpen(false);
      document.getElementById("backdrop")?.remove();
    });

  return (
    // {/* Topbar Search Modal Button */}
    <button
      type="button"
      className="nav-link p-2 me-auto"
      onClick={toggleSearch}
    >
      <span className="sr-only">Search</span>
      <span className="flex items-center justify-center h-6 w-6">
        <i className="mgc_search_line text-2xl"></i>
      </span>
    </button>
  );
};

export default SearchModal;
