import { useQuery } from "@apollo/client";
import React from "react";
import PageTitle from "../../components/PageTitle";
import DashboardCard from "../../components/dashboard/DashboardCard";
import DashboardStatistics from "../../components/dashboard/DashboardStatitics";
import DashboardSummary from "../../components/dashboard/DashboardSummary";
import SkillOverview from "../../components/dashboard/SkillOverveiw";
import { TOTAL_CANDIDATES } from "../../graphql/queries/candidates";
import { TOTAL_INSTITUTIONS } from "../../graphql/queries/institution";
import { GET_PROGRAMS } from "../../graphql/queries/program";
import Skeleton from "../../components/UIElements/Skeleton";

const Dashboard: React.FC = () => {
  const {
    data: candidates,
    error: candidatesError,
    loading: candidatesLoading,
  } = useQuery(TOTAL_CANDIDATES);

  const {
    data: programs,
    error: programsError,
    loading: programsLoading,
  } = useQuery(GET_PROGRAMS);

  const {
    data: institutions,
    error: institutionsError,
    loading: institutionsLoading,
  } = useQuery(TOTAL_INSTITUTIONS);

  const cardsData = [
    {
      id: 1,
      count: "10000+",
      label: "Total Students",
      avatar: "mgc_group_fill",
      className: "bg-primary/25 text-primary",
    },
    {
      id: 2,
      count: candidates?.totalCandidates,
      label: "Total Candidates",
      avatar: "mgc_group_line",
      className: "bg-primary/25 text-primary",
    },
    {
      id: 3,
      count: programs?.programs?.totalPrograms,
      label: "Total Programs",
      avatar: "mgc_microphone_line",
      className: "bg-primary/25 text-primary",
    },
    {
      id: 4,
      count: institutions?.totalInstitutions?.length,
      label: "Total Institutions",
      avatar: "mgc_school_line",
      className: "bg-primary/25 text-primary",
    },
  ];

  if (candidatesError || programsError || institutionsError) {
    return <p>Something Went Wrong</p>;
  }
  if (candidatesLoading || programsLoading || institutionsLoading) {
    return <Skeleton />;
  }

  return (
    <>
      <PageTitle pagetitle={"Dashboard"} subtitle={"Dashboard"} />
      <div className="grid 2xl:grid-cols-4 gap-6 mb-6">
        <div className="2xl:col-span-3 grid gap-6">
          <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-2 gap-6 h-fit">
            {cardsData.map((data) => (
              <DashboardCard
                key={data.id}
                count={data.count}
                label={data.label}
                avatar={data.avatar}
                className={data.className}
              />
            ))}
          </div>
          <DashboardStatistics />
        </div>
        <DashboardSummary />
      </div>
      <div className="grid 2xl:grid-cols-4 md:grid-cols-2 gap-6">
        <SkillOverview />
        <div className="col-span-1">
          <div className="card"></div>
        </div>
      </div>
    </>
  );
};
export default Dashboard;
